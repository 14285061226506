import React, { Component } from 'react'
import { toast } from 'react-toastify';
import Loader from '../../../inc/Loader';

//redux imports
import { connect } from 'react-redux'
import { productAssign } from '../../../actions/storeActions'

const defaultImage = "https://via.placeholder.com/150";

class EditOrder extends Component {
    constructor() {
        super();
        this.state = {
            branchArray: [{ colorVariations: [] }],
        }
    }

    //set branch array basics
    setBranchArray = () => {
        let { order } = this.props.location.state;
        let tempProduct = {
            branch: order.branch._id,
            branchName: order.branch.locationName,
            product: order.product._id,
            store: order.store._id,
            colorVariations: []
        }
        let tempBranchArray = [];
        tempBranchArray.push(tempProduct);
        this.setState({ branchArray: tempBranchArray }, () => {
            this.setColorVariations()
        })
    }
    componentDidMount() {
        this.setBranchArray();
    }

    // this function is made to handle input check if checked and if unchecked
    handleCheck = (color, index, e) => {
        let { branchArray } = this.state;
        let { order } = this.props.location.state
        let price = this.refs['price' + index].value;
        let qty = this.refs['qty' + index].value;
        //check if checkbox is checked or unchecked
        if (e.target.checked) {
            //check if branch array is empty or not
            if (branchArray.length === 0) {
                //now branch array is empty create a full obj and push it to brancg array
                let tempProduct = {
                    branch: order.branch._id,
                    branchName: order.branch.locationName,
                    product: order.product._id,
                    store: order.store._id,
                    colorVariations: [
                        {
                            color: color.color,
                            colorName: color.colorInfo.name,
                            qty: price,
                            price: qty
                        }
                    ]
                }
                branchArray.push(tempProduct);
            }
            else {
                //branch array already have an object so check about color variations and manage color variations now
                let colorObj = {
                    color: color.color,
                    colorName: color.colorInfo.name,
                    price: price,
                    qty: qty
                }
                branchArray[0].colorVariations.push(colorObj)
                this.setState({ branchArray })
            }
        }
        else {
            //check box is unchecked now remove the color variation and check if no color variation is left then empty the array
            let index = branchArray[0].colorVariations.findIndex(x => x.color === color.color);
            branchArray[0].colorVariations.splice(index, 1);
            this.setState({ branchArray });
        }
    }
    setColorVariations = () => {
        let { order } = this.props.location.state;
        let { branchArray } = this.state;
        for (const iterator of order.colorVariations) {
            let colorObj = {
                color: iterator.color._id,
                price: iterator.price,
                qty: iterator.qty,
                colorName: iterator.color.name
            }
            branchArray[0]['colorVariations'].push(colorObj)
        }
        this.setState({ branchArray })
    }
    getColorNameAndCode = (color) => {
        let { order } = this.props.location.state;
        let colorObj = order.colorVariations.find((clr) => {
            return clr.color._id === color.color
        })
        return colorObj
    }
    // checkExistence from branchArray
    checkExistence = (given) => {
        let checked = false
        let { branchArray } = this.state;
        for (const color of branchArray[0].colorVariations) {
            if (color.color === given.color) {
                checked = true
            }
        }
        return checked
    }
    checkExistenceQty = (given, what) => {
        let qty = '';
        let { branchArray } = this.state;
        for (const color of branchArray[0].colorVariations) {
            if (color.color === given.color) {
                qty = color[what]
            }
        }
        return qty;
    }
    handleInp = (e, color) => {
        let { branchArray } = this.state;
        let { order } = this.props.location.state;
        if (branchArray.length === 0) {
            let tempProduct = {
                branch: order.branch._id,
                branchName: order.branch.locationName,
                product: order.product._id,
                store: order.store._id,
                colorVariations: [
                    {
                        color: color.color,
                        [e.target.name]: e.target.value
                    }
                ]
            }
            branchArray.push(tempProduct);
        }
        else {
            let found = false
            for (const iterator of branchArray[0].colorVariations) {
                if (iterator.color === color.color) {
                    iterator[e.target.name] = e.target.value;
                    found = true;
                }
            }
            if (!found) {
                let colorObj = {
                    color: color.color,
                    [e.target.name]: e.target.value
                }
                branchArray[0].colorVariations.push(colorObj);
            }
        }
        this.setState({ branchArray });
    }
    assignProduct = () => {
        let { order, repeat } = this.props.location.state;
        let { branchArray } = this.state;
        let error;
        let body;
        for (const iterator of branchArray) {
            for (const color of iterator.colorVariations) {
                if (color.qty === '') {
                    error = true;
                    toast.error(`Please enter the Quantity of ${color.colorName} color in ${iterator.branchName} branch`);
                    return;
                }
                else if (color.price === '') {
                    error = true;
                    toast.error(`Please enter the Price of ${color.colorName} color in ${iterator.branchName} branch`);
                    //toast error with focus on the empty input
                    return;
                }
            }
        }
        if (!error) {
            if (!repeat) {
                body = {
                    data: branchArray,
                    orderToBeDeleted: order._id
                };
            }
            else {
                body = {
                    data: branchArray
                }
            }
            // console.log(body)
            this.props.productAssign(body);
        }

    }
    render() {
        let { order, repeat } = this.props.location.state;
        return (
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-12 pr-0">
                                        <div className="d-lg-flex">
                                            <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                                {repeat ? ("Repeat Order") : ('Edit Order')}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card shadow">
                                                    <div
                                                        className="card-body text-center d-flex justify-content-center align-items-center"
                                                        style={{ height: "411px" }}
                                                    >
                                                        <img
                                                            src={order.product.imageUrl}
                                                            className="img-fluid"
                                                            alt="productImage"
                                                            style={{ maxHeight: "100%" }}
                                                            onError={e => {
                                                                e.target.onerror = null;
                                                                e.target.src = defaultImage;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card shadow">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <h3 className="text-uppercase text-dark">
                                                                    {order.product.mainCode}
                                                                    <small> ({order.product.brand})</small>
                                                                </h3>
                                                                <h6>{order.product.category}</h6>
                                                            </div>
                                                            <div className="col-3 text-right">
                                                                <h2 className="text-dark">£ {order.product.unitPrice}</h2>
                                                            </div>
                                                        </div>
                                                        <p>{order.product.code}</p>
                                                        <hr />
                                                        <div className="w-100 table-responsive">
                                                            <table className="table no-border td-p">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Category</th>
                                                                        <td>{order.product.category}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Main Code</th>
                                                                        <td>{order.product.mainCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Type</th>
                                                                        <td>{order.product.type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Unit Price</th>
                                                                        <td>£ {order.product.unitPrice}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Cost Price</th>
                                                                        <td>
                                                                            £{" "}
                                                                            {order.product.costPrice
                                                                                ? order.product.costPrice
                                                                                : "-"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Item Type</th>
                                                                        <td>{order.product.itemType}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-12">
                                                <div className="card shadow">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h3 className="text-dark text-uppercase">
                                                                    {order.branch.locationName + " - " + order.branch.locationCode}
                                                                </h3>
                                                                <hr />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover table-striped min-w-700">
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>#</th>
                                                                                <th>Color</th>
                                                                                <th>
                                                                                    Quantity
                                                                                </th>
                                                                                <th>
                                                                                    Price
                                                                                </th>
                                                                                <th>Name</th>
                                                                                <th>Description</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                order.product.colorVariations.map((color, index) => {
                                                                                    let colorObj = this.getColorNameAndCode(color);
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <label className="checkbox">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={this.checkExistence(color)}
                                                                                                        onClick={e => {
                                                                                                            this.handleCheck(color, index, e);
                                                                                                        }}
                                                                                                    />
                                                                                                    <span className="checkmark"></span>
                                                                                                </label>
                                                                                            </td>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {colorObj !== undefined ? (colorObj.color.name + ' - ' + colorObj.color.colorCode) : (color.colorInfo.name + ' - ' + color.colorInfo.colorCode)}
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    style={{
                                                                                                        maxWidth: "100px"
                                                                                                    }}
                                                                                                    value={this.checkExistenceQty(color, 'qty')}
                                                                                                    name="qty"
                                                                                                    type="number"
                                                                                                    className="form-control p-2"
                                                                                                    ref={`qty${index}`}
                                                                                                    onChange={e =>
                                                                                                        this.handleInp(
                                                                                                            e,
                                                                                                            color,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    style={{
                                                                                                        maxWidth: "100px"
                                                                                                    }}
                                                                                                    ref={`price${index}`}
                                                                                                    value={this.checkExistenceQty(color, 'price')}
                                                                                                    name="price"
                                                                                                    type="number"
                                                                                                    className="form-control p-2"
                                                                                                    onChange={e =>
                                                                                                        this.handleInp(
                                                                                                            e,
                                                                                                            color,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </td>

                                                                                            <td>{color.name}</td>
                                                                                            <td>{color.description}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-9"></div>
                            <div className="col-sm-3">
                                <div className="dropdown float-right">
                                    <span className="cursor">
                                        {
                                            this.state.branchArray[0].colorVariations.length !== 0 ? (
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    type="button"
                                                    onClick={this.assignProduct}
                                                    disabled={
                                                        this.state.branchArray.length > 0 ? false : true
                                                    }
                                                    data-toggle="modal"
                                                    data-target="#viewModal"
                                                >
                                                    <i className="mdi mdi-clipboard-check mr-1"></i>
                                                    <span>{repeat ? ('Repeat Order') : ('Edit Order')}</span>
                                                </button>
                                            ) : (
                                                    null
                                                )
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Loader loading={this.props.loadingStore} />
            </div>
        )
    }
    componentWillReceiveProps(newProps) {
        if (newProps.storeAssignResponse !== undefined && newProps.storeAssignResponse !== this.props.storeAssignResponse) {
            if (newProps.storeAssignResponse.data.response) {
                toast.success(newProps.storeAssignResponse.data.msg);
                this.props.history.push('/store/orders', { currentStore: { _id: this.props.location.state.order.store._id } })
            }
            else {
                toast.error(newProps.storeAssignResponse.data.msg)
            }
        }
    }
}
const mapDispatchToProps = dispatch => ({
    productAssign: body => dispatch(productAssign(body))
});

const mapStateToProps = () => state => ({
    loadingStore: state.Store.loader,
    storeAssignResponse: state.Store.storeAssignResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder)