import React, { Component } from 'react'

//components
import HeaderSalesReport from './Header'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../../inc/Loader'

//redux imports
import { connect } from 'react-redux'
import { uploadSalesReport } from '../../../actions/storeActions'

//consts
import { SAMPLE_DOC_URL } from '../../../Config'

class UploadSalesReport extends Component {
    constructor() {
        super();
        this.state = {
            productFile: {
                name: '',
                type: ''
            }
        }
    }
    checkFileFormat = (e) => {
        let fileToSave = e.target.files[0];
        if (fileToSave !== undefined) {
            if (fileToSave.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                this.setState({
                    productFile: fileToSave
                })
            }
            else {
                toast.error("Please Upload a correct format file.")
            }
        }
    }
    saveToDataBase = () => {
        let { store, branchSelected } = this.props.history.location.state;
        let branchId = branchSelected._id;
        let { productFile } = this.state;
        let storeId = store._id;

        let body = {
            branchId, storeId, file: productFile
        }
        if (productFile.name === '') {
            toast.error("Please upload a file to continue");
        }
        else {
            this.props.uploadSalesReport(body);
        }
    }
    render() {
        let { store, branchSelected } = this.props.location.state;
        return (
            <div className="content-wrapper">
                <HeaderSalesReport heading="Upload Store Sales Report" />
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 d-flex flex-row">
                                        <h3>{store.companyName}</h3><span> &nbsp; </span> - <span> &nbsp; </span><h3> {branchSelected.locationName}</h3>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Products Upload File(xls)</label>
                                    <input type="file" name="img[]" className="file-upload-default" />
                                    <div className="input-group col-xs-12" >
                                        <input
                                            name="image"
                                            ref="image"
                                            type="text"
                                            className="form-control file-upload-info"
                                            disabled
                                            value={this.state.productFile.name}
                                        />
                                        <span className="input-group-append">
                                            <label className="file-upload-browse btn btn-primary uploadButton">
                                                <span style={{ color: '#fff' }}>Upload</span>
                                                <input type='file' onChange={(e) => { this.checkFileFormat(e) }} />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px' }} className="d-flex justify-content-between">
                                    <div>
                                        <span>
                                            <button
                                                className="btn btn-success  mr-2  dropdown-toggle"
                                                type="button"
                                                id="options"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i className="mdi mdi-download"></i>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="options">
                                                <h6 className="dropdown-header">Download Sample</h6>
                                                <span
                                                    className="dropdown-item cursor"
                                                    onClick={() => { 
                                                        window.open(SAMPLE_DOC_URL+'store_sales_report_sample.xlsx', '_blank')
                                                    }}
                                                >
                                                    <span>XLS</span>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => { this.saveToDataBase() }}>Submit</button>
                                        <button type="button" className="btn btn-light" onClick={() => { this.props.history.push('/store/') }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <Loader loading={this.props.loader} />
            </div>
        )
    }
    componentWillReceiveProps(newProps) {
        if (newProps.uploadResponse !== this.props.uploadResponse && newProps.uploadResponse !== undefined) {
            let { data } = newProps.uploadResponse;
            if (data.response) {
                toast.success(data.msg)
                this.setState({
                    productFile: {
                        name: '',
                        type: ''
                    }
                })
            }
            else {
                toast.error(data.msg)
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    uploadSalesReport: (body) => dispatch(uploadSalesReport(body))
})

const mapStateToProps = () => state => ({
    uploadResponse: state.Store.storeSalesReportResponse,
    loader: state.Store.loader
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadSalesReport)