import React, { Component } from 'react'
import HeaderExportProduct from '../ExportProduct/HeaderExportProduct'
import FilterExportProduct from './FilterExportProduct'

export default class ExportProductAll extends Component {
    render() {
        return (
            <div className="content-wrapper">
            <HeaderExportProduct heading={"Export All"}/>
            <div className="row mt-3">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <FilterExportProduct />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
