import React, { Component } from "react";
import HeaderExportProduct from "./HeaderExportProduct";
import FiltersExportProduct from "./FiltersExportProduct";
import NewPagination from "../../../Common/NewPagination";
import { toast } from "react-toastify";

//redux improts
import { connect } from "react-redux";
import { productExportJson } from "../../../actions/productActions";

//default image url
const defaultImage = "https://via.placeholder.com/150";

class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPage: 10,
      page: 1,
      supplier: {},
      exhibition: {},
      type: "",
      fromDate: "",
      toDate: "",
      isAllColors: false
    };
  }
  getNextPage = i => {
    let { perPage, fromDate, toDate, type, supplier, exhibition } = this.state;
    let body = {
      perPage,
      page: i,
      fromDate,
      toDate
    };
    if (Object.keys(supplier).length !== 0) {
      body["supplier"] = supplier._id;
    }
    if (Object.keys(exhibition).length !== 0) {
      body["exhibition"] = exhibition._id;
    }
    if (type !== "") {
      body["type"] = type;
    }
    this.props.productExportJson(body);
  };
  setParentState = (what, to) => {
    this.setState({
      [to]: what
    });
  };
  render() {
    let { perPage } = this.state;
    let { products } = this.props;

    return (
      <div className="content-wrapper">
        <HeaderExportProduct heading={"Export Product"} />
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <FiltersExportProduct setParentState={this.setParentState} />
                {this.props.products.data.products.length === 0 ? null : (
                  <div className="row">
                    <div className="col-12 pt-5">
                      <div className="table-responsive">
                        <table className="table table-hover min-w-700 table_heading_view">
                          <thead>
                            <tr className="heading_mine">
                              <th>#</th>
                              <th>Product Image</th>
                              <th>Main Code</th>
                              <th>Unit Price</th>
                              <th>Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.products.data.products.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {this.state.page === 1
                                      ? i + 1
                                      : i +
                                        1 +
                                        (this.state.page - 1) *
                                          this.state.perPage}
                                  </td>
                                  <td>
                                    <img
                                      src={v.imageUrl}
                                      alt="product"
                                      style={styles.imageStyles}
                                      onError={e => {
                                        e.target.onerror = null;
                                        e.target.src = defaultImage;
                                      }}
                                    />
                                  </td>
                                  <td>{v.mainCode}</td>
                                  <td>£ {v.unitPrice}</td>
                                  <td>
                                    <span
                                      onClick={() => {
                                        this.props.history.push(
                                          "/product/view",
                                          { product: v }
                                        );
                                      }}
                                      to="/product/view"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        type="button"
                                        className="btn-sm btn btn-primary btn-icon-text"
                                      >
                                        <i className="mdi mdi-eye btn-icon-prepend"></i>
                                        View
                                      </button>
                                    </span>
                                    <span
                                      onClick={() => {
                                        this.props.history.push(
                                          "/product/edit",
                                          { product: v }
                                        );
                                      }}
                                      className="text-decoration-none"
                                    >
                                      <button
                                        type="button"
                                        className="btn-sm btn ml-2   btn-dark btn-icon-text"
                                      >
                                        <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                        Edit
                                      </button>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {!this.props.productsFound ? (
                          <div className="noProductsFound">
                            <span>No Products Found</span>
                          </div>
                        ) : null}
                        <NewPagination
                          perPage={perPage}
                          response={products.data.products}
                          totalPages={products.data.totalPages}
                          currentPage={products.data.currentPage}
                          getNextPage={i => this.getNextPage(i)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.productsFound !== this.props.productsFound &&
      newProps.productsFound !== undefined
    ) {
      if (!newProps.productsFound) {
        toast.error("No Products Found!");
      }
    }
  }
}

const mapStateToProps = () => state => ({
  products: state.Product.productExportJson,
  productsFound: state.Product.productsFound
});

const mapDispatchToProps = dispatch => ({
  productExportJson: body => dispatch(productExportJson(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(Export);

export const styles = {
  imageStyles: {
    height: "130px",
    borderRadius: "25%",
    width: "auto",
    display: "block",
    margin: "0 auto"
  }
};
