// auto is a package used for auto suggestions still used to get and match 2 values from the object in an array
//   props passed to this package are
// 1. data as array of objects to be matched
// 2. how as array of 2 filters which are to be matched within the object keys
// 3. select value is a function which will give you the selected value
// 4. what as what is the name of the input for what you want the suggestions
// example to use is attached in a file named example_auto_suggest_use.txt you can search the file to check

import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'

export default class Auto extends Component {
    constructor() {
        super();
        this.state = {
            value: '',
            suggestions: [],
            selected: {}
        }
    }
    //supplier auto suggestions
    getSuggestions = value => {
        let { how, data } = this.props;
        let filterArray = data;
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (how.length === 1) {
            return inputLength === 0 ? [] : filterArray.filter(filterArrayElement => filterArrayElement[how[0]].toLowerCase().slice(0, inputLength) === inputValue);
        }
        else if (how.length === 2) {
            return inputLength === 0 ? [] : filterArray.filter(filterArrayElement => filterArrayElement[how[0]].toLowerCase().slice(0, inputLength) === inputValue || filterArrayElement[how[1]].toLowerCase().slice(0, inputLength) === inputValue);
        }
        else if (how.length === 3) {
            return inputLength === 0 ? [] : filterArray.filter(filterArrayElement => filterArrayElement[how[0]].toLowerCase().slice(0, inputLength) === inputValue || filterArrayElement[how[1]].toLowerCase().slice(0, inputLength) === inputValue || filterArrayElement[how[2]].toLowerCase().slice(0, inputLength) === inputValue);
        }
        else if (how.length === 3) {
            return inputLength === 0 ? [] : filterArray.filter(filterArrayElement => filterArrayElement[how[0]].toLowerCase().slice(0, inputLength) === inputValue || filterArrayElement[how[1]].toLowerCase().slice(0, inputLength) === inputValue || filterArrayElement[how[2]].toLowerCase().slice(0, inputLength) === inputValue || filterArrayElement[how[3]].toLowerCase().slice(0, inputLength) === inputValue);
        }
    };

    resetField = () => {
        this.setState({
            value: ''
        })
    }

    getSuggestionValue = suggestion => {
        let { how, selectedValue } = this.props;
        selectedValue(suggestion)
        if (how.length === 1) {
            return (
                suggestion[how[0]].toUpperCase()
            )
        }
        else if (how.length === 2) {
            return (
                suggestion[how[0]].toUpperCase() + ' - ' + suggestion[how[1]].toUpperCase()
            )
        }
        else if (how.length === 3) {
            return (
                suggestion[how[0]].toUpperCase() + ' - ' + suggestion[how[1]].toUpperCase() + ' - ' + suggestion[how[2]].toUpperCase()
            )
        }
        else if (how.length === 4) {
            return (
                suggestion[how[0]].toUpperCase() + ' - ' + suggestion[how[1]].toUpperCase() + ' - ' + suggestion[how[2]].toUpperCase() + ' - ' + suggestion[how[3]].toUpperCase()
            )
        }
    };



    renderSuggestion = (suggestion) => {
        let { how } = this.props;
        if (how.length === 1) {
            return (
                <div className="checkSuggestion">
                    <span className="text-uppercase">{suggestion[this.props.how[0]]}</span>
                </div>
            )
        }
        else if (how.length === 2) {
            return (
                <div className="checkSuggestion">
                    <span className="text-uppercase">{suggestion[this.props.how[0]]} - {suggestion[this.props.how[1]]}</span>
                </div>
            )
        }
        else if (how.length === 3) {
            return (
                <div className="checkSuggestion">
                    <span className="text-uppercase">{suggestion[this.props.how[0]]} - {suggestion[this.props.how[1]]} - {suggestion[this.props.how[2]]}</span>
                </div>
            )
        }
        else if (how.length === 4) {
            return (
                <div className="checkSuggestion">
                    <span className="text-uppercase">{suggestion[this.props.how[0]]} - {suggestion[this.props.how[1]]} - {suggestion[this.props.how[2]]} - {suggestion[this.props.how[3]]}</span>
                </div>
            )
        }
    }

    onChangeSuggestion = (event, { newValue }) => {
        this.setState({
            value: newValue,
        }, () => {
            if (newValue === '') {
                this.props.selectedValue({})
            }
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        let { how, what } = this.props;
        let suggestions = this.getSuggestions(value)
        if (suggestions !== undefined) {
            if( suggestions.length === 0 ){
                suggestions.push({ '_id': '', [how[0]]: 'NO ' + what + ' FOUND', [how[1]]: 'TRY AGAIN!', [how[2]]: '' });
            }
        }
        this.setState({
            suggestions: suggestions
        });
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    // storeInputReference = autosuggest => {
    //     if (autosuggest !== null) {
    //         this.supplierInput = autosuggest.input;
    //     }
    // };
    render() {
        let { value, suggestions } = this.state
        let { what, disabled, ref } = this.props
        const inputProps = {
            placeholder: 'Start typing a ' + what,
            value,
            onChange: this.onChangeSuggestion,
            disabled: disabled
        };
        return (
            <Autosuggest
                className="suggest"
                style={{ height: "34px" }}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                ref={ref}
            />
        )
    }
}