import React, { Component } from "react";
import { toast } from "react-toastify";

export default class SelectBranchModalOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchSelected: {},
            storeSelected: this.props.store
        };
    }
    emptyBranch = () => {
        this.setState({
            branchSelected: {}
        });
    };

    selectBranch = (e, v) => {
        this.setState({
            branchSelected: v
        })
    }

    render() {
        return (
            <div
                className="modal fade"
                id="branchModalOne"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalLabel">
                                Select Branch
              </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="w-100 table-responsive table-hover">
                                <table className="table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Branch Location Name</th>
                                            <th>Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.store.branches.map((v, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-uppercase">
                                                        {v.locationName + " - " + v.locationCode}
                                                    </td>
                                                    <td>
                                                        <input
                                                            name="group"
                                                            type="radio"
                                                            onClick={e => {
                                                                this.selectBranch(e, v);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss={Object.keys(this.state.branchSelected).length === 0 ? "null" : "modal"}
                                onClick={() => {
                                    this.uploadSalesReport();
                                }}
                            >
                                Assign
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    uploadSalesReport = () => {
        let { branchSelected } = this.state;
        let { history, store } = this.props;
        if (Object.keys(branchSelected).length === 0) {
            toast.error("Please select a branch to continue!")
        }
        else {
            history.push("/store/uploadSalesReport", {
                store,
                branchSelected
            })
        }
    }
}
