import React, { Component } from 'react'
import ViewOrder from '../ViewOrder'

export default class ViewOrderAgent extends Component {
    render() {
        return (
            <ViewOrder agentSpecific={true}/>
        )
    }
}
