import React from "react";

export default function ViewExhibitionModal(props) {

  return (
    <div
      className="modal fade"
      id="viewModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              Exhibition Deatils
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{padding: "10px 26px"}}>
            {/* <div className="w-100 text-center py-3">
              <img src="./images/dashboard/group.png" alt="" />
            </div> */}
            <div className="w-100 table-responsive">
              <table className="table no-border">
                <tbody>
                  <tr>
                    <th>Exhibition Name</th>
                    <td className="text-capitalize">{props.view.name}</td>
                  </tr>
                  <tr>
                    <th>Exhibition Season</th>
                    <td className="text-capitalize">{props.view.season}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
