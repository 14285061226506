import React from 'react'

const defaultImage = "https://via.placeholder.com/150";

export default function ViewOrderModal(props) {
    let { products } = props;
    return (
        <div className="modal fade" id="orderModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Order Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 grid-margin stretch-card" >
                            <div className="card-body">

                                {
                                    products.map((product, indexProduct) => {
                                        return (
                                            <div className="row pt-3 pb-1" style={{ backgroundColor: '#eeeeeeaa', marginTop: '10px' }} key={indexProduct}>
                                                <div className="col-md-3">
                                                    <div className="px-2 pb-3 d-flex justify-content-center">
                                                        <img
                                                            src={product.product.imageUrl}
                                                            alt="product"
                                                            className="img-fluid"
                                                            onError={e => {
                                                                e.target.onerror = null;
                                                                e.target.src = defaultImage;
                                                            }}
                                                        />
                                                    </div>
                                                    <h4 className="text-center">
                                                        {product.product.mainCode}
                                                    </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-striped min-w-700">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Variation Code</th>
                                                                    <th>Description</th>
                                                                    <th>Quantity</th>
                                                                    <th>Branch</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    product.colorVariations.map((color, colorIndex) => {
                                                                        let variationCode;
                                                                        let Description;

                                                                        for (const iterator of product.product.colorVariations) {
                                                                            if(iterator.color === color.color){
                                                                                variationCode = iterator.name;
                                                                                Description = iterator.description
                                                                            }
                                                                        }
                                                                        return (
                                                                            <tr key={colorIndex}>
                                                                                <td>{colorIndex + 1}</td>
                                                                                <td>{variationCode}</td>
                                                                                <td>{Description}</td>
                                                                                <td>{color.qty}</td>
                                                                                <td>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
