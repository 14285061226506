import React, { Component } from "react";
import { connect } from 'react-redux';
// import errorHandling from '../../../Common/errorHandling';
import errorHandling from "../../../Common/errorHandling";
import { exhibitionAdd } from '../../../actions/exhibitionActions'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../inc/Loader';

class AddExhibition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      season: "",

      formErrors: {
        name: "",
        season: ""
      }

    };
  }

  handleInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors
    let field = value.trim() !== '';
    fieldValidationErrors[fieldName] = field ? '' : `${fieldName} can't be blank`;
    this.setState({
      formErrors: fieldValidationErrors
    });
  }

  errorClass(error) {
    return (error.length === 0 ? '' : 'invalid-field');
  }

  isValid = () => {
    let fieldValidationErrors = this.state.formErrors;
    let fields = ["name", "season"];
    let valid = true;
    for (const iterator of fields) {
      let field = this.state[`${iterator}`]
      if (field === '') {
        fieldValidationErrors[iterator] = `${iterator} can't be blank`
        this.setState({ formErrors: fieldValidationErrors });
        valid = false;
      }
    }
    return valid;
  }

  //post data to store state for api hit
  exhibitionAdd = async () => {
    let valid = this.isValid();
    if (valid) {
      let { name, season } = this.state;
      let exhibition = {
        name: name,
        season: season
      };
      await this.props.exhibitionAdd(exhibition);
    }
  }


  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mt-2 mr-5 text-uppercase">
                    Add Exhibition
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/exhibition/">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-eye mr-1"></i>All Exhibition
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Exhibition Name</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(this.state.formErrors.name)}`}
                          id="name"
                          value={this.state.name}
                          placeholder="Exhibition Name"
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">{this.state.formErrors.name}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="season">Season</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(this.state.formErrors.season)}`}
                          id="season"
                          value={this.state.season}
                          placeholder="Season"
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">{this.state.formErrors.season}</span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.exhibitionAdd()}
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <button type="reset" className="btn btn-light" onClick={() => this.props.history.push('/exhibition/')}>
                    Cancel
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={this.props.loading} />
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (this.props.response !== newProps.response && newProps.response !== undefined) {
      ////// Check Whether Response is True or Not 
      if (newProps.response.data.response === true) {
        toast.success(newProps.response.data.msg);
        this.setState({
          name: '',
          season: '',
          formErrors: {
            name: '',
            season: ''
          }
        })
      } else {
        //Error Handling
        let status = newProps.response.status
        let msg = newProps.response.data.msg
        errorHandling(this.props, status, msg)
      }
    }
    // } else { toast.warn('No Internet') }
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    exhibitionAdd: (data) => dispatch(exhibitionAdd(data))
  }
}

const mapStateToProps = () => state => ({
  response: state.Exhibition.addExhibitionResponse,
  loading: state.Exhibition.loader
})

export default connect(mapStateToProps, mapDispatchToProps)(AddExhibition);
