import React, { Component } from "react";
import Auto from "./Auto";
import Loader from "../../../inc/Loader";
import { toast } from "react-toastify";
import moment from 'moment'

//redux imports
import { connect } from "react-redux";
import { storeFetch, stockStockReport, stockReportReset, storeFetchAll } from "../../../actions/storeActions";
import Helper from "../../../helper/Helper";

class FiltersStockReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: {
        branches: []
      },
      fromDate: "",
      toDate: "",
      loading: false,
      type: '',
      selectedBranches: [],
      selectedBranchesToSend: [],
      heading: props.heading
    };
  }
  resetThings = () => {
    let tempValue = {
      branches: []
    }
    this.setState({ store: tempValue, selectedBranchesToSend: [], selectedBranches: [] });
    this.props.setParentState(tempValue, "supplier");
    this.props.resetReport();
  }
  autoValueStore = value => {
    this.resetThings();
    if (Object.keys(value).length === 0 || value._id === '') {

    }
    else {
      this.setState({ store: value });
      this.props.setParentState(value, "supplier")
    }
  };
  handleChange = e => {
    let { name, value } = e.target;
    this.props.resetReport()
    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.setParentState(value, name);
      }
    );
  };
  fetchData = () => {
    let validated = this.validate();
    if (validated) {
      let body = this.createBody();
      this.props.fetchStockReport(body);
    }
  };
  toggleLoader = () => {
    this.setState({ loading: !this.state.loading })
  }
  downloadReport = () => {
    this.toggleLoader()
    let table = this.props.getReportTable();
    let pageHeader = `<div style="width: 100%;" id="pageHeader">
                        <div style="font-size: 12px ;width: 30%;float:left">${moment().format("MM/DD/YYYY")}</div>
                        <div style="font-size: 12px ;width: 70%;float:left;text-align:left"> ${ this.state.heading } </div>
                      </div>`
    let pageFooter = `<div id="pageFooter">
    <div style="font-size: 12px ;width: 100%;float:left"></div>
</div>`
    table = pageHeader + table + pageFooter
    // table += '<div id="pageFooter">Default footer</div>'
    let res = Helper('store/product/generateReport', 'POST', { html: table, heading: '' });
    res.then((response) => {
      if (response.data.response) {
        window.open(response.data.filename, '_blank')
      }
    })
      .catch(() => {
        toast.error("Something went wrong while downloading the report, Please try again later!")
      })
      .finally(() => {
        this.toggleLoader()
      })
  }
  handleBranch = (branch) => {
    let { selectedBranches, selectedBranchesToSend } = this.state;
    if (selectedBranches.findIndex(i => i._id === branch._id) === -1 && branch._id !== '' && Object.keys(branch).length !== 0) {
      selectedBranches.push(branch);
      selectedBranchesToSend.push(branch._id);
      this.setState({ selectedBranches })
    }
    else {
      toast.error("Branch Already selected, please select another branch to continue!");
    }
  }
  removeAllBranches = (callback) => {
    this.setState({ selectedBranchesToSend: [], selectedBranches: [] }, () => {
      callback()
    })
  }
  createBody = () => {
    let {
      store,
      // fromDate,
      // toDate,
      type,
      selectedBranchesToSend,
    } = this.state;

    let body = {
      storeId: store._id,
      // fromDate: fromDate,
      // toDate: toDate,
      type,
      branchIDs: selectedBranchesToSend,
    };
    return body;
  };
  validate = () => {
    let { store, type, selectedBranchesToSend } = this.state;
    let validated = true;
    if (Object.keys(store).length === 0) {
      validated = false;
      toast.error("Please Enter Store!");
    }
    else if (type === "") {
      validated = false;
      toast.error("Please enter a Type of the Report!")
    }
    else if (selectedBranchesToSend.length === 0) {
      validated = false;
      toast.error("Please enter atleast one branch to continue!")
    }
    return validated;
  };
  resetFields = () => {
    this.setState(
      {
        store: {
          branches: []
        },
        fromDate: "",
        toDate: "",
        loading: false,
        type: '',
        selectedBranches: [],
        selectedBranchesToSend: []
      },
      () => {
        this.refs["storeref"].resetField();
        // this.props.productExportReset()
      }
    );
  };
  selectAll = () => {
    this.removeAllBranches(() => {
      let { selectedBranches, selectedBranchesToSend, store } = this.state;
      for (const iterator of store.branches) {
        selectedBranches.push(iterator);
        selectedBranchesToSend.push(iterator._id);
      }
      this.setState({ selectedBranches, selectedBranchesToSend })
    });

  }
  removeSelectedBranch = (index) => {
    let { selectedBranches, selectedBranchesToSend } = this.state;
    selectedBranches.splice(index, 1);
    selectedBranchesToSend.splice(index, 1);
    this.setState({ selectedBranches, selectedBranchesToSend })
  }
  render() {
    let { loading } = this.props;
    return (
      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="form-group mb-0">
            <label htmlFor="store">Store</label>
            <Auto
              data={this.props.response.data.stores}
              how={["companyName"]}
              selectedValue={this.autoValueStore}
              what="Store"
              ref="storeref"
              disabled={false}
            />
          </div>
        </div>
        {/* <div className="col-md-4 mb-4">
          <div className="form-gorup mb-0">
            <label
              htmlFor="from"
              style={{ fontSize: "12px" }}
            >From</label>
            <input
              id="from"
              name="fromDate"
              max={this.state.toDate}
              style={{ height: "48px", paddingLeft: 10, marginTop: "4px" }}
              className="form-control"
              type="date"
              value={this.state.fromDate}
              onChange={event => {
                this.handleChange(event);
              }}
            />
          </div>
        </div> */}
        {/* <div className="col-md-4 mb-4">
          <div className="form-gorup mb-0">
            <label
              htmlFor="to"
              style={{ fontSize: "12px" }}
            >To</label>
            <input
              id="to"
              name="toDate"
              min={this.state.fromDate}
              style={{ height: "48px", paddingLeft: 10, marginTop: "4px" }}
              className="form-control"
              type="date"
              value={this.state.toDate}
              onChange={event => {
                this.handleChange(event);
              }}
            />
          </div>
          
        </div> */}
        <div className="col-md-6 mb-4">
          <div className="form-group mb-0">
            <label htmlFor="type">Type Report</label>
            <select
              className="form-control"
              id="type"
              name="type"
              ref="type"
              placeholder="Select Type"
              value={this.state.type}
              style={{ height: "46px", marginTop: "2px" }}
              onChange={event => this.handleChange(event)}
            >
              <option value="" style={{ color: "#e8e8e8" }}>
                Select Type
              </option>
              <option value={'category'}>Category</option>
              <option value={'product'}>Product</option>
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="color">Colors</label>
            <div className="parentColor">

              {/* colors card started */}
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row d-flex align-content-center">
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            this.selectAll();
                            // this.generateNameAndMainCode() 
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-outline btn-icon-prepend"> </i>
                          Select All</button>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover min-w-350">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Branch Name</th>
                              <th>Branch Code</th>
                              <th>Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.store.branches.map(
                              (v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td className="text-capitalize">{v.locationName}</td>
                                    <td className="text-capitalize">{v.locationCode}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                          this.handleBranch(v);
                                          // this.generateNameAndMainCode() 
                                        }}
                                      >
                                        <i className="mdi mdi-eye btn-icon-prepend"></i>
                                        &nbsp;Select
                                                                                                    </button>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                      {
                        this.state.store.branches.length === 0 ? (
                          <div className="noProductsFound"><span style={{ textAlign: 'center' }}>No Branches, Please Store to continue!</span></div>
                        ) : (
                            null
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* card colors ended */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="color">Branches(Selected)</label>
            <div className="parentColor" ref="color">
              {/* colors card started */}
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row d-flex justify-content-end align-content-center">
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => { this.removeAllBranches(_ => { }) }}
                        >
                          <i class="mdi mdi-close-outline btn-icon-prepend"> </i>
                          Remove All</button>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover min-w-350">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Branch Name</th>
                              <th>Branch Code</th>
                              <th>Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.selectedBranches.map(
                              (v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td className="text-capitalize">{v.locationName}</td>
                                    <td className="text-capitalize">{v.locationCode}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => this.removeSelectedBranch(i)}
                                      >
                                        <i className="mdi mdi-eye btn-icon-prepend"></i>
                                        &nbsp;Remove
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                            )}

                          </tbody>
                        </table>
                      </div>
                      {
                        this.state.selectedBranchesToSend.length === 0 ? (
                          <div className="noProductsFound"><span style={{ textAlign: 'center' }}>No Branches selected, Please add to continue</span></div>
                        ) : (
                            null
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* card colors ended */}
            </div>

          </div>
        </div>

        <div className="col-md-12 text-right mb-4">
          {
            (this.state.type === 'product' && this.props.stockReport.data.topRows.length > 0) || (this.state.type === 'category' && this.props.stockReport.data.categoryWiseProductReport.length > 0) ? (
              <span>
                <button
                  className="btn btn-success  mr-2  dropdown-toggle"
                  type="button"
                  id="options"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-download"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="options">
                  <h6 className="dropdown-header">Download format</h6>
                  <span
                    className="dropdown-item cursor"
                    onClick={() => {
                      this.downloadReport();
                    }}
                  >
                    PDF
            </span>
                </div>
              </span>
            ) : (
                null
              )
          }
          <button
            type="button"
            className="btn btn-primary  btn-icon-text"
            onClick={() => {
              this.fetchData();
            }}
          >
            <i className="mdi mdi-filter btn-icon-prepend"></i>
            Filter
          </button>
          <button
            type="button"
            className="btn ml-2 btn-danger  btn-icon-text"
            onClick={() => {
              this.resetFields();
            }}
          >
            <i className="mdi mdi-refresh btn-icon-prepend"></i>
            Reset
          </button>
        </div>
        <Loader loading={loading || this.state.loading} />
      </div>
    );
  }
  componentWillReceiveProps(newProps) {
    this.setState({ heading: newProps.heading })
  }
  componentDidMount() {
    this.props.storeFetch();
  }
}

const mapStateToProps = () => state => ({
  response: state.Store.storeFetchResponse,
  loading: state.Store.loader,
  storesFound: state.Store.storesFound,
  stockReport: state.Store.storeStockReport
});

const mapDispatchToProps = dispatch => ({
  storeFetch: () => dispatch(storeFetchAll()),
  fetchStockReport: (body) => dispatch(stockStockReport(body)),
  resetReport: () => dispatch(stockReportReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersStockReport);