import { COLOR_ADD, COLOR_FETCH, COLOR_UPDATE ,UPDATE_ADD_COLOR_RESPONSE } from './types'

import Helper from '../helper/Helper'

export const colorAdd = (body) => ({
    type: COLOR_ADD,
    payload: Helper('color/save', 'POST', body)
})

export const updateAddColorResponse = (data) => ({
    type: UPDATE_ADD_COLOR_RESPONSE,
    payload: data
})

export const colorFetch = () => ({
    type: COLOR_FETCH,
    payload: Helper('color/fetch', 'GET', null)
})

export const colorUpdate = (id, body) => ({
    type: COLOR_UPDATE,
    payload: Helper('color/edit/' + id, 'PUT', body)
})
