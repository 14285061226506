import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="w-100 clearfix">
                    <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © {new Date().getFullYear().toString()} MINX. All rights reserved.</span>
                    <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart-outline text-danger"></i></span>
                </div>
            </footer>

        )
    }
}
