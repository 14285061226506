import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../App.css";

export default class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      role: localStorage.getItem("role"),
    };
  }
  render() {
    return (
      <nav
        className={`bottom-navbar ${
          this.props?.isOpen ? "header-toggled" : ""
        }`}
      >
        <div className="container">{this.renderMenu()}</div>
      </nav>
    );
  }
  pushToAngular = (url) => {
    window.location.assign(url);
  };
  renderMenu = () => {
    let width = window.innerWidth || 1024;
    // let {hostname} = window.location.hostname
    let hostname = window.location.origin;
    const isDesktop = width >= 500;
    let { role } = this.state;
    if (role === "admin" || role === "staff") {
      return (
        <ul className="nav page-navigation">
          <li onClick={() => this.props?.setIsOpen(false)} className="nav-item">
            <Link to="/" className="nav-link">
              <img
                src={require("./images/sidebar/home.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={isDesktop ? "/product" : ""} className="nav-link">
              <img
                src={require("./images/sidebar/form.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Products</span>
              <i className="menu-arrow"></i>
            </Link>
            <div className="submenu">
              <ul className="submenu-item">
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/create">
                    Add Product
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product">
                    View Products
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/colour">
                    Manage Colours
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/export">
                    Export Product
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/import">
                    Import New Product
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/importupdate">
                    Import Product For Updation
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/barcode_import">
                    Import Barcode
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/imported">
                    View Import
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/barcode_print">
                    Barcode Stickers
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/product/print-barcode">
                    Print Barcode
                  </Link>
                </li>

                {/* <li className="nav-item">
                                    <Link className="nav-link black" to="/product/export_all">Export All</Link>
                                </li> */}
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link to={isDesktop ? "/supplier" : ""} className="nav-link">
              <img
                src={require("./images/sidebar/web.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Supplier</span>
              <i className="menu-arrow"></i>
            </Link>
            <div className="submenu">
              <ul className="submenu-item">
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/supplier">
                    View Suppliers
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/supplier/create">
                    Add Suppliers
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link to={isDesktop ? "/exhibition" : ""} className="nav-link">
              <img
                src={require("./images/sidebar/web.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Exhibition</span>
              <i className="menu-arrow"></i>
            </Link>
            <div className="submenu">
              <ul className="submenu-item">
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/exhibition">
                    View Exhibition
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/exhibition/create">
                    Add Exhibition
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link black"
                    onClick={() => {
                      this.pushToAngular(`${hostname}/ang/exhibitions/sales`);
                    }}
                  >
                    Sales
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link black"
                    onClick={() => {
                      this.pushToAngular(`${hostname}/ang/reporting`);
                    }}
                  >
                    Reporting
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link black"
                    onClick={() => {
                      this.pushToAngular(
                        `${hostname}/ang/exhibitions/customers`
                      );
                    }}
                  >
                    Customer Sales
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link black"
                    onClick={() => {
                      this.pushToAngular(`${hostname}/ang/supplier-reports`);
                    }}
                  >
                    Order Reports
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link black"
                    onClick={() => {
                      this.pushToAngular(`${hostname}/ang/view-orders`);
                    }}
                  >
                    Supplier Orders
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link to={isDesktop ? "/store/" : ""} className="nav-link">
              <img
                src={require("./images/sidebar/form.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Concession (Store)</span>
              <i className="menu-arrow"></i>
            </Link>
            <div className="submenu">
              <ul className="submenu-item">
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/store/">
                    View Stores
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/store/create">
                    Add Store
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/store/salesreport">
                    Best Selling Report
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/store/stockreport">
                    Stock Report
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link black" to="/store/stats">
                    Stats
                  </Link>
                </li> */}
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link to={isDesktop ? "/agent/" : ""} className="nav-link">
              <img
                src={require("./images/sidebar/web.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Agents</span>
              <i className="menu-arrow"></i>
            </Link>
            <div className="submenu">
              <ul className="submenu-item">
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/agent/create">
                    Add Agent
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/agent/">
                    View Agent
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/agent/order">
                    View Order
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link to={isDesktop ? "/staff/" : "  "} className="nav-link">
              <img
                src={require("./images/sidebar/web.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Staff</span>
              <i className="menu-arrow"></i>
            </Link>
            <div className="submenu">
              <ul className="submenu-item">
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/staff/create">
                    Add Staff
                  </Link>
                </li>
                <li
                  onClick={() => this.props?.setIsOpen(false)}
                  className="nav-item"
                >
                  <Link className="nav-link black" to="/staff/">
                    View Staff
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      );
    } else if (role === "agent") {
      return (
        <ul
          className="nav page-navigation"
          style={{ justifyContent: "space-evenly" }}
        >
          <li className="nav-item">
            <Link to="/agent/dashboard" className="nav-link">
              <img
                src={require("./images/sidebar/home.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          {/* <li className="nav-item">
                        <span className="nav-link">
                            <img src={require('./images/sidebar/web.svg')} alt="" className="nav-icon-title" />
                            <span className="menu-title">Orders</span>
                            <i className="menu-arrow"></i>
                        </span>
                        <div className="submenu">
                            <ul className="submenu-item">
                                <li className="nav-item">
                                    <Link className="nav-link black" to="/agent/place_order">Place Order</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link black" to='/agent/order_agent'>View Order</Link>
                                </li>
                            </ul>
                        </div>
                    </li> */}
          <li className="nav-item">
            <Link className="nav-link black" to="/agent/place_order">
              <img
                src={require("./images/sidebar/web.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Place Order</span>
            </Link>
          </li>
        </ul>
      );
    } else if (role === "store") {
      return (
        <ul
          className="nav page-navigation"
          style={{ justifyContent: "space-evenly" }}
        >
          <li className="nav-item">
            <Link to="/store/dashboard" className="nav-link">
              <img
                src={require("./images/sidebar/home.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/store/uploadStoreSalesReport" className="nav-link">
              <img
                src={require("./images/sidebar/form.svg")}
                alt=""
                className="nav-icon-title"
              />
              <span className="menu-title">Concession (Store)</span>
              <i className="menu-arrow"></i>
            </Link>
          </li>
        </ul>
      );
    }
  };
}
