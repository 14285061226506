import React, { Component } from 'react'
import HeaderExportProduct from '../ExportProduct/HeaderExportProduct'
import { toast } from 'react-toastify'
import Loader from '../../../inc/Loader'

//redux imports
import { connect } from 'react-redux'
import { productUpdateImport, productImportEmpty } from '../../../actions/productActions'
import { SAMPLE_DOC_URL } from '../../../Config'

class ImportProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productFile: {
                name: '',
                type: ''
            },
            algoErrors: [],
        }
    }

    checkFileFormat = (e) => {
        let fileToSave = e.target.files[0]
        if (fileToSave !== undefined) {
            if (fileToSave.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                this.setState({
                    productFile: fileToSave
                })
            }
            else {
                toast.error("Please Upload a correct format file.")
            }
        }
    }
    saveToDataBase = () => {
        let { productFile } = this.state
        let body = {
            xlsx: productFile
        }
        this.props.saveUpdatedImportProduct(body);
    }
    render() {
        return (
            <div className="content-wrapper">
                <HeaderExportProduct heading="Import Updated Products" />
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group">
                                    <label>Products Upload File(xls)</label>
                                    <input type="file" name="img[]" className="file-upload-default" />
                                    <div className="input-group col-xs-12" >
                                        <input
                                            name="image"
                                            ref="image"
                                            type="text"
                                            value={this.state.productFile.name}
                                            className="form-control file-upload-info"
                                            disabled
                                        />
                                        <span className="input-group-append">
                                            <label className="file-upload-browse btn btn-primary uploadButton">
                                                <span style={{ color: '#fff' }}>Upload</span>
                                                <input type='file' onChange={(e) => { this.checkFileFormat(e) }} />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px' }} className="d-flex justify-content-between">
                                    <div>
                                        <div>
                                            <span>
                                                <button
                                                    className="btn btn-success  mr-2  dropdown-toggle"
                                                    type="button"
                                                    id="options"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="mdi mdi-download"></i>
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="options">
                                                    <h6 className="dropdown-header">Download Sample</h6>
                                                    <span
                                                        className="dropdown-item cursor"
                                                        onClick={() => {
                                                            window.open(SAMPLE_DOC_URL+'product_update_sample.xlsx', '_blank')
                                                        }}
                                                    >
                                                        <span>XLS</span>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => { this.saveToDataBase() }}>Submit</button>
                                        <button type="button" className="btn btn-light" onClick={() => { this.props.history.push('/product/') }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader loading={this.props.loading} />
            </div>
        )
    }
    componentWillReceiveProps(newProps) {
        if (newProps.responseFile !== this.props.responseFile && Object.keys(newProps.responseFile).length !== 0) {
            let { data } = newProps.responseFile;
            if (data.response) {
                toast.success(data.msg)
                this.setState({
                    algoErrors: data.algoErrors
                })
            }
            else {
                toast.error(data.msg)
            }
            this.setState({ productFile: { name: '', type: '' } })
            this.props.emptyProductsResponse();
        }
    }
}

const mapStateToProps = () => state => ({
    responseFile: state.Product.productUpdatedImportFile,
    loading: state.Product.loader,
})

const mapDispatchToProps = dispatch => ({
    saveUpdatedImportProduct: (body) => dispatch(productUpdateImport(body)),
    emptyProductsResponse: _ => dispatch(productImportEmpty())
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportProduct)