import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import NewPagination from "../../Common/NewPagination";

//redux improts
import { connect } from "react-redux";
import Loader from "../../inc/Loader";
import { productFetch, productSearch } from "../../actions/productActions";
import jsPDF from "jspdf";

const defaultImage = "https://via.placeholder.com/150";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPage: 10,
      page: 1,
      searchText: "",
      search: false
    };
  }

  makePdf = () => {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [4, 2]
    });
    doc.text("Hello World!", 1, 1);
    doc.save("a4.pdf");
  };

  fetchAllProducts = i => {
    let { perPage } = this.state;
    let body = {
      perPage,
      page: i
    };
    this.props.fetchProducts(body);
  };

  searchAllProducts = i => {
    if (i === undefined) {
      i = 1;
    }
    let { perPage, searchText } = this.state;
    let body = {
      perPage,
      page: i,
      searchText
    };
    this.props.searchProducts(body);
  };

  searchNow = e => {
    if (e.key === "Enter") {
      this.searchAllProducts();
    }
  };

  getNextPage = i => {
    this.state.search ? this.searchAllProducts(i) : this.fetchAllProducts(i);
  };

  setPerPage = e => {
    this.setState({ perPage: e.target.value, page: 1 }, () => {
      this.fetchAllProducts();
    });
  };

  search = _.debounce(e => {
    if (this.state.searchText !== "") {
      this.searchAllProductsInSync();
    } else {
      this.fetchAllProductsInSync();
    }
  }, process.env.REACT_APP_DEBOUNCE);

  fetchAllProductsInSync = async () => {
    await this.fetchAllProducts();
    this.setState({ search: false });
  };
  searchAllProductsInSync = async () => {
    await this.searchAllProducts();
    this.setState({ search: true });
  };

  render() {
    let { perPage } = this.state;
    let { response, loading } = this.props;
    return (
      <div className="content-wrapper">
        <div className="row align-items-center">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    All Product
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row align-items-center justify-content-end">
              <div className="col-md-12">
                <div className="dropdown float-right">
                  <Link to="/product/create" className="mx-2">
                    <button
                      className="btn btn-sm bg-white font-weight-bold text-dark"
                      type="button"
                    >
                      <i className="mdi mdi-plus mr-1"></i>Add Product
                    </button>
                  </Link>
                  {/* <button
                    className="btn btn-success  ml-2  dropdown-toggle"
                    type="button"
                    id="options"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-download"></i>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="options">
                    <h6 className="dropdown-header">Download format</h6>
                    <span
                      className="dropdown-item cursor"
                      onClick={() => {
                        this.makePdf()
                      }}
                    >
                      Pdf
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group m-0 mx-2 float-left">
                      <div className="input-group col-xs-12">
                        <input
                          placeholder="Search Product"
                          type="text"
                          className="px-3  py-0 form-control rounded"
                          onChange={e => {
                            this.setState(
                              { searchText: e.target.value },
                              () => {
                                this.search();
                              }
                            );
                          }}
                          style={{ height: "50px" }}
                          value={this.state.searchText}
                          onKeyPress={e => {
                            this.searchNow(e);
                          }}
                        />
                      </div>
                    </div>
                    {this.state.searchText !== "" ? (
                      <span className="mx-2 float-left">
                        <button
                          className="btn bg-danger font-weight-bold text-white"
                          type="button"
                          onClick={() => {
                            this.setState({ searchText: "", search: false });
                            this.props.fetchProducts();
                          }}
                        >
                          <i className="mdi mdi-close mr-1"></i>Clear Search
                        </button>
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <div className="float-right">
                        <select
                          className="form-control"
                          id="type"
                          name="type"
                          placeholder="Select Type"
                          style={{
                            height: "46px",
                            width: "150px",
                            marginTop: "2px"
                          }}
                          onChange={e => {
                            this.setPerPage(e);
                          }}
                        >
                          <option
                            disabled
                            selected
                            value=""
                            style={{ color: "rgb(232, 232, 232)" }}
                          >
                            Enteries/Page
                          </option>
                          <option
                            value={10}
                            style={{ color: "rgb(232, 232, 232)" }}
                          >
                            10
                          </option>
                          <option
                            value={20}
                            style={{ color: "rgb(232, 232, 232)" }}
                          >
                            20
                          </option>
                          <option
                            value={30}
                            style={{ color: "rgb(232, 232, 232)" }}
                          >
                            30
                          </option>
                          <option
                            value={50}
                            style={{ color: "rgb(232, 232, 232)" }}
                          >
                            50
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-2">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700 table_heading_view">
                        <thead>
                          <tr className="heading_mine">
                            <th>#</th>
                            <th>Product Image</th>
                            <th>Main Code</th>
                            <th>Unit Price</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.response.data.products.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {
                                    (response.data.currentPage-1)*this.state.perPage+i+1
                                  }
                                </td>
                                <td>
                                  <img
                                    src={v.imageUrl}
                                    alt="product"
                                    style={{
                                      height: "130px",
                                      borderRadius: "25%",
                                      width: "auto",
                                      display: "block",
                                      margin: "0 auto"
                                    }}
                                    onError={e => {
                                      e.target.onerror = null;
                                      e.target.src = defaultImage;
                                    }}
                                  />
                                </td>
                                <td>{v.mainCode}</td>
                                <td>£ {v.unitPrice}</td>
                                {/* <td style={{ textTransform: 'capitalize' }}>{v.brand}</td>
                                                                    <td>{v.supplier.fullName} - {v.supplier.code}</td>
                                                                    <td>{v.category}</td> */}
                                <td>
                                  <span
                                    onClick={() => {
                                      this.props.history.push("/product/view", {
                                        product: v
                                      });
                                    }}
                                    to="/product/view"
                                    className="text-decoration-none"
                                  >
                                    <button
                                      type="button"
                                      className="btn-sm btn btn-primary btn-icon-text"
                                    >
                                      <i className="mdi mdi-eye btn-icon-prepend"></i>
                                      View
                                    </button>
                                  </span>
                                  <span
                                    onClick={() => {
                                      this.props.history.push("/product/edit", {
                                        product: v
                                      });
                                    }}
                                    className="text-decoration-none"
                                  >
                                    <button
                                      type="button"
                                      className="btn-sm btn ml-2   btn-dark btn-icon-text"
                                    >
                                      <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                      Edit
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {!this.props.productsFound ? (
                        <div className="noProductsFound">
                          <span>No Products Found</span>
                        </div>
                      ) : null}
                      <NewPagination
                        perPage={perPage}
                        response={response.data.products}
                        totalPages={response.data.totalPages}
                        currentPage={response.data.currentPage}
                        getNextPage={i => this.getNextPage(i)}
                        // searchText={searchText}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={loading} />
      </div>
    );
  }
  componentDidMount() {
    let body = {
      page: this.state.page,
      perPage: this.state.perPage
    };
    this.props.fetchProducts(body);
  }
}

const mapStateToProps = () => state => ({
  response: state.Product.productFetchResponse,
  loading: state.Product.loader,
  productsFound: state.Product.productsFound
});

const mapDispatchToProps = dispatch => ({
  fetchProducts: body => dispatch(productFetch(body)),
  searchProducts: body => dispatch(productSearch(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
