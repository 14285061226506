import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import NavBar from "../../inc/NavBar";
import TopNavbar from "../../inc/TopNavbar";

export default function TopBar() {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="horizontal-menu">
      <TopNavbar history={history} setIsOpen={setIsOpen} />
      <NavBar history={history} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
