import React, { Component } from "react";
import Loader from "../../../inc/Loader";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { staffAdd, staffEdit } from "../../../actions/staffActions";
import { Link } from "react-router-dom";

class CreateStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            username: "",
            phone: "",
            password: "",
            email: "",
            formErrors: {
                name: "",
                username: "",
                phone: "",
                password: "",
                email: ""
            },

            edit: props.history.location.state
        };
    }

    //setting the state when changing the value of the text input
    //in value is made empty than making it red
    handleChange = e => {
        let { value, name } = e.target;
        let { formErrors } = this.state;
        if (name === "name") {
            if (/^[A-Za-z ]*$/.test(value)) {
                this.setValue(name, value, _ => { });
            }
        } else if (name === "phone") {
            if (/^[0-9]*$/.test(value)) {
                this.setValue(name, value, _ => { });
            }
        } else if (name === "email") {
            this.setValue(name, value, _ => {
                // if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)){
                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                    formErrors["email"] = "Please Enter a valid Email!!";
                    this.setState({
                        formErrors
                    });
                }
            });
        } else {
            this.setValue(name, value, _ => { });
        }
    };
    componentDidMount() {
        let { edit } = this.state
        if (edit) {
            this.setState({
                name: edit.staff.fullName,
                username: edit.staff.username,
                phone: edit.staff.phoneNumber,
                email: edit.staff.email
            })
        }
    }

    setValue = (name, value, callback) => {
        let { edit } = this.state;
        this.setState({ [name]: value }, () => {
            if (edit) {
                if (name !== 'password') {
                    this.validateField(name, value, _ => {
                        callback();
                    });
                }
            }
            else {
                this.validateField(name, value, _ => {
                    callback();
                });
            }
        });
    };

    validateField = (fieldName, value, callback) => {
        let fieldValidationErrors = this.state.formErrors;
        let field = value.trim() !== "";
        fieldValidationErrors[fieldName] = field
            ? ""
            : `${fieldName} can't be blank`;
        this.setState(
            {
                formErrors: fieldValidationErrors
            },
            () => {
                callback();
            }
        );
    };

    errorClass(error) {
        return error.length === 0 ? "" : "invalid-field";
    }

    isValid = () => {
        let { edit } = this.state;
        let fieldValidationErrors = this.state.formErrors;
        let fields = []
        if (edit) {
            fields = [
                "name",
                "username",
                "phone",
                "commision",
                "email"
            ];
        }
        else {
            fields = [
                "name",
                "username",
                "phone",
                "commision",
                "password",
                "email"
            ];
        }
        let valid = true;
        fields.forEach((v, i) => {
            let field = this.state[`${v}`];
            if (field === "") {
                fieldValidationErrors[v] = `${v} can't be blank`;
                this.setState({ formErrors: fieldValidationErrors });
                valid = false;
            }
        });
        return valid;
    };

    save = () => {
        let valid = this.isValid();
        let { edit } = this.state;
        if (valid) {
            let {
                name,
                username,
                phone,
                password,
                email,
            } = this.state;
            let body = {
                fullName: name,
                username,
                phoneNumber: phone,
                password,
                email,
                role: 'staff'
            };
            if (edit) {
                if (password === '') {
                    delete body['password']
                }
                this.props.staffEdit(edit.staff._id, body);
            }
            else {
                this.props.staffAdd(body);
            }
        }
    };

    render() {
        return (
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                        {this.state.edit?('Edit'):('Add')} Staff
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="dropdown float-right">
                            <Link to="/staff">
                                <button
                                    className="btn btn-sm bg-white font-weight-bold text-dark"
                                    type="button"
                                >
                                    <i className="mdi mdi-eye mr-1"></i>All Staff
                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="suppliername">Full Name</label>
                                                <input
                                                    value={this.state.name}
                                                    ref="name"
                                                    name="name"
                                                    type="text"
                                                    className={`form-control ${this.errorClass(
                                                        this.state.formErrors.name
                                                    )}`}
                                                    id="staffname"
                                                    placeholder="Staff Name"
                                                    required
                                                    onChange={e => {
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                <span className="error-msg">
                                                    {this.state.formErrors.name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="staffUsername">Username</label>
                                                <input
                                                    name="username"
                                                    value={this.state.username}
                                                    ref="username"
                                                    type="text"
                                                    className={`form-control ${this.errorClass(
                                                        this.state.formErrors.username
                                                    )}`}
                                                    id="staffUsername"
                                                    placeholder="Staff Username"
                                                    required
                                                    onChange={e => {
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                <span className="error-msg">
                                                    {this.state.formErrors.username}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="staffemail">Email</label>
                                                <input
                                                    name="email"
                                                    value={this.state.email}
                                                    ref="email"
                                                    type="text"
                                                    className={`form-control ${this.errorClass(
                                                        this.state.formErrors.email
                                                    )}`}
                                                    id="staffemail"
                                                    placeholder="Staff Email"
                                                    required
                                                    onChange={e => {
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                <span className="error-msg">
                                                    {this.state.formErrors.email}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="staffPhone">Phone</label>
                                                <input
                                                    name="phone"
                                                    value={this.state.phone}
                                                    ref="phone"
                                                    type="number"
                                                    className={`form-control ${this.errorClass(
                                                        this.state.formErrors.phone
                                                    )}`}
                                                    id="staffPhone"
                                                    placeholder="Staff Phone"
                                                    onChange={e => {
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                <span className="error-msg">
                                                    {this.state.formErrors.phone}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="staffPassword">Password</label>
                                                <input
                                                    ref="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    type="password"
                                                    className={`form-control ${this.errorClass(
                                                        this.state.formErrors.password
                                                    )}`}
                                                    id="staffPassword"
                                                    placeholder="Password"
                                                    onChange={e => {
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                <span className="error-msg">
                                                    {this.state.formErrors.password}
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary mr-2"
                                        onClick={() => {
                                            this.save();
                                        }}
                                    >
                                        Submit
                  </button>
                                    <button
                                        className="btn btn-light"
                                        onClick={() => {
                                            this.props.history.push("/staff");
                                        }}
                                    >
                                        Cancel
                  </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader loading={this.props.loading} />
            </div>
        );
    }
    componentWillReceiveProps(newProps) {
        if (
            this.props.staffAddResponse !== newProps.staffAddResponse &&
            newProps.staffAddResponse !== undefined
        ) {
            this.updateResponse(newProps.staffAddResponse);
        }
        if (
            this.props.staffEditResponse !== newProps.staffEditResponse &&
            newProps.staffEditResponse !== undefined
        ) {
            if(newProps.staffEditResponse.data.response){
                toast.success(newProps.staffEditResponse.data.msg);
                this.props.history.push('/staff')
            }
            else{
                toast.error(newProps.staffEditResponse.data.msg);
            }
        }
    }
    updateResponse = res => {
        if (res.data.response) {
            toast.success(res.data.msg);
            this.setState({
                name: "",
                username: "",
                phone: "",
                password: "",
                email: ""
            });
        } else {
            toast.error(res.data.msg);
        }
    };
}

const mapDispatchToProps = dispatch => ({
    staffAdd: data => dispatch(staffAdd(data)),
    staffEdit: (id, body) => dispatch(staffEdit(id, body))
});

const mapStateToProps = () => state => ({
    loading: state.Staff.loader,
    staffAddResponse: state.Staff.staffAddResponse,
    staffEditResponse: state.Staff.staffEditResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStaff);
