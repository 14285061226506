import React, { Component } from "react";

export default class CurrentListAgent extends Component {
  render() {
    return (
      <div className="row my-3">
        <div className="col-12 grid-margin stretch-card">
          <div className="card shadow">
            <div className="card-body">
              <div className="row pb-3">
                <div className="col-12">
                  <div className="input-group mb-3 justify-content-between">
                    <h3>List of Products Selected</h3>
                    <button
                      type="button"
                      className="btn-sm btn btn-primary btn-icon-text"
                      onClick={() => {
                        this.props.assignProductsNow();
                      }}
                    >
                      <i className="mdi mdi-check btn-icon-prepend"></i>
                      <span>Generate Product Labels</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-hover min-w-700 table_heading_view">
                      <thead>
                        <tr className="heading_mine">
                          <th>#</th>
                          <th>Main Code</th>
                          <th>Quantity</th>
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.arrayOfProducts.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{v.mainCode}</td>
                              <td>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    style={{width: '50px'}}
                                    className="form-control"
                                    placeholder="Quantity"
                                    aria-label="Quantity"
                                    aria-describedby="button-addon2"
                                    onChange={(e)=>{this.props.handleSelectedProductQuantity(e.target.value, v._id)}}
                                    value={v.qty}
                                  />
                                </div>
                              </td>
                              <td>
                                <span className="text-decoration-none">
                                  <button
                                    type="button"
                                    className="btn-sm btn btn-danger btn-icon-text"
                                    onClick={() => {
                                      this.props.deleteProduct(i, 1);
                                    }}
                                  >
                                    <i className="mdi mdi-close btn-icon-prepend"></i>
                                    <span>Delete</span>
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
