import {
  ADD_SUPPLIER,
  FETCH_SUPPLIER,
  UPDATE_SUPPLIER,
  SEARCH_SUPPLIER,
  FETCH_ALL_SUPPLIER
} from "./types";

import Helper, { HelperFormData } from "../helper/Helper";

export const addSupplier = data => ({
  type: ADD_SUPPLIER,
  payload: HelperFormData("supplier/save", "POST", data)
});
export const fetchSupplier = (body) => ({
  type: FETCH_SUPPLIER,
  payload: Helper("supplier/fetch", "POST", body)
});
export const updateSupplier = (id, data) => ({
  type: UPDATE_SUPPLIER,
  payload: HelperFormData("supplier/edit/" + id, "PUT", data)
});
export const searchSupplier = body => ({
  type: SEARCH_SUPPLIER,
  payload: Helper("supplier/search/", "POST", body)
});
export const fetchAllSupplier=()=>({
  type: FETCH_ALL_SUPPLIER,
  payload: Helper('supplier/fetchAll', 'GET', null)
})