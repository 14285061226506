import React, {Fragment} from "react";

export default function ViewStoreModal(props) {
  let { store } = props;
  return (
    <div
      className="modal fade"
      id="viewModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              Store Details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="w-100 table-responsive">
              <table className="table no-border">
                <tbody>
                  <tr>
                    <th>Comapny Name</th>
                    <td>{store.companyName}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{store.address}</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>{store.country}</td>
                  </tr>
                  <tr>
                    <th>Contact Person</th>
                    <td>{store.contactPerson}</td>
                  </tr>
                  <tr>
                    <th>Mobile Number</th>
                    <td>{store.mobile}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{store.phone}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{store.email}</td>
                  </tr>
                </tbody>
              </table>
            </div>
                  <div className="row pt-3">
                    <div className="col-12 mb-3 card table-responsive">
                      <div className="card-body p-2">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Branch Location Name</th>
                              <th>Brach Location Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            {store.branches.map((v, i) => {
                              return (
                                <Fragment key={i}>
                                  <tr>
                                    <th className="text-capitalize">{v.locationName}</th>
                                    <th>{v.locationCode.toUpperCase()}</th>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
