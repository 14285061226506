export const types = [{
        type: 'SC',
        category: 'Scarf'
    },
    {
        type: 'SK',
        category: 'Skirt'
    },
    {
        type: 'C',
        category: 'Cardigan'
    },
    {
        type: 'T',
        category: 'Top'
    },
    {
        type: 'J',
        category: 'Jacket'
    },
    {
        type: 'P',
        category: 'Poncho'
    },
    {
        type: 'JU',
        category: 'Jumper'
    },
    {
        type: 'B',
        category: 'Blouse'
    },
    {
        type: 'D',
        category: 'Dress'
    },
    {
        type: 'CP',
        category: 'Cape'
    },
    {
        type: 'TR',
        category: 'Trouser'
    },
    {
        type: 'JS',
        category: 'Jumpsuit'
    },
    {
        type: 'SH',
        category: 'Shorts'
    },
    {
        type: 'SET',
        category: 'Sets'
    }
]

export const categories = {
    'SC': 'Scarf',
    'SK': 'Skirt',
    'C': 'Cardigan',
    'T': 'Top',
    'J': 'Jacket',
    'P': 'Poncho',
    'JU': 'Jumper',
    'B': 'Blouse',
    'D': 'Dress',
    'CP': 'Cape',
    'TR': 'Trouser',
    'JS': 'Jumpsuit',
    'SH': 'Shorts',
    'SET': 'Sets',
    '': ''
}