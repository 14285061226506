import React, { Component } from "react";
import Auto from "../../Products/ExportProduct/AutoSuggest";
import ViewOrderModal from "./ViewOrderModal";
import ViewCustomerModal from "./ViewCustomerModal";
import Loader from "../../../inc/Loader";

//redux imports
import { connect } from "react-redux";
import {
  agentFetch,
  agentFetchAllOrders,
  agentOrdersFetchByAgentId,
  agentUpdateOrderStatus
} from "../../../actions/agentActions";
import { toast } from "react-toastify";

// import Pagination2 from "../../../Common/Pagination2";

class AgentOrderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      products: [],
      selectedAgent: { _id: "" },
      tempAgent: { _id: "" },
      orderStatus: "",
      orderStatusResponse: props.agentOrderStatusUpdate,

      //pagination
      setPerPage: 10,
      from: 0,
      noOfPages: 1,
      pageNo: 1
    };
  }
  showAllProducts = () => {
    this.setState({
      selectedAgent: { _id: "" },
      tempAgent: { _id: "" }
    });
  };
  selectedAgent = agent => {
    this.setState({
      tempAgent: agent
    });
  };
  fetchOrderByAgent = () => {
    this.setState({
      selectedAgent: this.state.tempAgent
    });
  };
  setOrderStatus = e => {
    this.setState({
      orderStatus: e.target.value
    });
  };
  updateOrderStatus = v => {
    if (v.status === this.state.orderStatus) {
      toast.error("Status of order is already " + v.status);
    } else {
      let body = {
        orderId: v._id,
        status: this.state.orderStatus
      };
      if (body.status === "") {
        toast.error("Please enter status to be updated!");
      } else {
        this.props.agentUpdateOrderStatus(body);
      }
    }
  };

  setFromAndTo = selectedPage => {
    let { setPerPage } = this.state;
    let from = (selectedPage - 1) * setPerPage;
    this.setState({
      from
    });
  };

  handleInput = e => {
    let { value } = e.target;
    if (value !== "") {
      if (e.target.value <= this.state.noOfPages) {
        this.setState(
          {
            pageNo: e.target.value
          },
          () => this.setFromAndTo(this.state.pageNo)
        );
      } else {
        toast.error(
          `Page number ${e.target.value} doesn't exist and please refresh`
        );
      }
    } else {
      this.setState({ pageNo: value });
    }
  };

  handleArrow = name => {
    let { pageNo, noOfPages } = this.state;
    if (name === "leftArrow" && pageNo > 1) {
      this.setState(
        {
          pageNo: pageNo - 1
        },
        () => this.setFromAndTo(this.state.pageNo)
      );
    } else if (name === "rightArrow" && pageNo < noOfPages) {
      this.setState(
        {
          pageNo: pageNo + 1
        },
        () => this.setFromAndTo(this.state.pageNo)
      );
    }
  };

  claculateNoOfPages = newProps => {
    let { orders } = newProps.agentAllOrders.data;
    let perPage = this.state.setPerPage;
    let length = orders.length;
    let noOfPages = 0;
    if (length < perPage) {
      noOfPages = 1;
    } else {
      noOfPages = Math.ceil(length / perPage);
    }
    this.setState({
      noOfPages
    });
  };

  render() {
    let { agentSpecific } = this.props;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    All Orders
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row mt-3`}>
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {this.renderAgentSearch()}
                <div className={`row ${agentSpecific ? "mt-0" : "mt-5"}`}>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700 table_heading_view">
                        <thead>
                          <tr className="heading_mine">
                            <th>#</th>
                            <th>Agent Name</th>
                            <th>Country</th>
                            <th>Mobile</th>
                            <th>Status</th>
                            <th>Options</th>
                            {agentSpecific ? null : <th>Process</th>}
                          </tr>
                        </thead>
                        <tbody>{this.mapAllOrders()}</tbody>
                      </table>
                      {this.props.agentAllOrders.data.length !== 0 ? (
                        /* pagination */
                        <div className="col-12">
                          <nav>
                            <ul className="pagination flex-wrap pagination-flat pagination-primary justify-content-center pt-3">
                              <li
                                className={
                                  this.state.pageNo === 1
                                    ? "page-item page-link not-actives"
                                    : "page-item page-link actives"
                                }
                                name="leftArrow"
                                onClick={() => this.handleArrow("leftArrow")}
                              >
                                <i className="mdi mdi-chevron-left"></i>
                              </li>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "5px",
                                }}
                              >
                                <h5>&nbsp;Page&nbsp;</h5>
                                <input
                                  type="text"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    display: "flex",
                                    textAlign: "center",
                                    borderRadius: "50%",
                                    border: "1px solid #aee2e3",
                                    marginTop: "-7px"
                                  }}
                                  onChange={this.handleInput}
                                  name="pageInput"
                                  value={this.state.pageNo}
                                />
                                <h5>&nbsp;of&nbsp;</h5>
                                <h5>{this.state.noOfPages}</h5>
                              </div>
                              <li
                                className={
                                  this.state.pageNo === this.state.noOfPages
                                    ? "page-item page-link not-actives"
                                    : "page-item page-link actives"
                                }
                                name="leftArrow"
                                onClick={() => this.handleArrow("rightArrow")}
                              >
                                <i className="mdi mdi-chevron-right"></i>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {this.props[agentSpecific ? "agentOrders" : "agentAllOrders"]
                  .data.orders.length === 0 ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center p-5">
                      <h3>No Orders Found!!</h3>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ViewOrderModal products={this.state.products} />
        <ViewCustomerModal customer={this.state.customer} />
        <Loader loading={this.props.loading} />
      </div>
    );
  }
  mapAllOrders = () => {
    let { agentSpecific } = this.props;
    return this.props[
      agentSpecific ? "agentOrders" : "agentAllOrders"
    ].data.orders.map((v, i) => {
      if (this.state.selectedAgent._id !== "") {
        if (v.agent._id === this.state.selectedAgent._id) {
          return (
            <tr key={i}>
              <td>{i + 1}</td>
              <td className="text-capitalize">{v.agent.name}</td>
              <td>{v.agent.country}</td>
              <td>{v.agent.mobile}</td>
              <td className="text-uppercase">{v.status}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-toggle="modal"
                  data-target="#orderModal"
                  onClick={() => {
                    this.setState({ products: v.products });
                  }}
                >
                  <i className="mdi mdi-eye btn-icon-prepend"></i>
                  <span> Order</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm ml-2"
                  data-toggle="modal"
                  data-target="#customerModal"
                  onClick={() => {
                    this.setState({ customer: v.customer });
                  }}
                >
                  <i className="mdi mdi-eye btn-icon-prepend"></i>
                  <span> Customer</span>
                </button>
              </td>
              {agentSpecific ? null : (
                <td>
                  <div className="d-flex justify-content-center">
                    <select
                      class="form-control"
                      id="type"
                      name="type"
                      placeholder="Select Type"
                      style={{
                        height: "46px",
                        width: "150px",
                        marginTop: "2px"
                      }}
                      onChange={e => {
                        this.setOrderStatus(e);
                      }}
                    >
                      <option disabled selected>
                        Select Status
                      </option>
                      <option disabled="" value="Pending">
                        Pending
                      </option>
                      <option disabled="" value="Processing">
                        Processing
                      </option>
                      <option disabled="" value="Processed">
                        Processed
                      </option>
                    </select>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm ml-2"
                      onClick={() => {
                        this.updateOrderStatus(v);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </td>
              )}
            </tr>
          );
        } else {
          return null;
        }
      } else {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td className="text-capitalize">{v.agent.name}</td>
            <td>{v.agent.country}</td>
            <td>{v.agent.mobile}</td>
            <td className="text-uppercase">{v.status}</td>
            <td>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#orderModal"
                onClick={() => {
                  this.setState({ products: v.products });
                }}
              >
                <i className="mdi mdi-eye btn-icon-prepend"></i>
                <span> Order</span>
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm ml-2"
                data-toggle="modal"
                data-target="#customerModal"
                onClick={() => {
                  this.setState({ customer: v.customer });
                }}
              >
                <i className="mdi mdi-eye btn-icon-prepend"></i>
                <span> Customer</span>
              </button>
            </td>
            {agentSpecific ? null : (
              <td>
                <div className="d-flex justify-content-center">
                  <select
                    className="form-control"
                    id="type"
                    name="type"
                    placeholder="Select Type"
                    style={{
                      height: "46px",
                      width: "150px",
                      marginTop: "2px"
                    }}
                    onChange={e => {
                      this.setOrderStatus(e);
                    }}
                  >
                    <option disabled selected>
                      Select Status
                    </option>
                    <option disabled="" value="Pending">
                      Pending
                    </option>
                    <option disabled="" value="Processing">
                      Processing
                    </option>
                    <option disabled="" value="Processed">
                      Processed
                    </option>
                  </select>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm ml-2"
                    onClick={() => {
                      this.updateOrderStatus(v);
                    }}
                  >
                    Update
                  </button>
                </div>
              </td>
            )}
          </tr>
        );
      }
    });
  };
  renderAgentSearch = () => {
    let { agentSpecific } = this.props;
    if (agentSpecific) {
      return null;
    } else {
      return (
        <span>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="name">Filter by Agent Name</label>
                <Auto
                  data={this.props.response.data.agents}
                  what="Agent Name"
                  how={["name"]}
                  selectedValue={this.selectedAgent}
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={() => {
              this.fetchOrderByAgent();
            }}
          >
            Submit
          </button>
          <button
            type="reset"
            className="btn btn-light"
            onClick={() => {
              this.showAllProducts();
            }}
          >
            Show All
          </button>
          <div
            className="form-group"
            style={{ position: "absolute", right: "31px", marginTop: "-38px" }}
          >
            {this.props.agentAllOrders.data.orders.length !== 0 ? 
              <div className="float-right">
              <select
                className="form-control"
                id="type"
                name="type"
                placeholder="Select Type"
                style={{
                  height: "46px",
                  width: "150px",
                  marginTop: "2px"
                }}
                onChange={this.setPerPage}
              >
                <option disabled="" value={10}>
                  10
                </option>
                <option disabled="" value={20}>
                  20
                </option>
                <option disabled="" value={30}>
                  30
                </option>
                <option disabled="" value={50}>
                  50
                </option>
              </select>
            </div> : null
            }
          </div>
          
        </span>
      );
    }
  };
  componentDidMount() {
    let { agentSpecific } = this.props;
    let fetchBody = {
      showAll: true
    };
    this.props.agentFetch(fetchBody);
    if (agentSpecific) {
      this.props.agentFetchByOrderId();
    } else {
      this.props.agentFetchAllOrders();
    }
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.agentOrderStatusUpdate !== this.props.agentOrderStatusUpdate &&
      newProps.agentOrderStatusUpdate !== undefined
    ) {
      let { response, msg } = newProps.agentOrderStatusUpdate.data;
      if (response) {
        toast.success(msg);
        this.setState({ orderStatus: "" });
        let { agentSpecific } = this.props;
        if (agentSpecific) {
          this.props.agentFetchByOrderId();
        } else {
          this.props.agentFetchAllOrders();
        }
      } else {
        toast.error(msg);
      }
    }
    this.claculateNoOfPages(newProps);
  }
}

const mapStateToProps = () => state => ({
  response: state.Agent.agentFetchResponse,
  loading: state.Agent.loader,
  agentAllOrders: state.Agent.agentAllOrders,
  agentOrders: state.Agent.agentOrdersByAgentId,
  agentOrderStatusUpdate: state.Agent.agentOrderStatusUpdate
});

const mapDispatchToProps = dispatch => ({
  agentFetch: body => dispatch(agentFetch(body)),
  agentFetchAllOrders: _ => dispatch(agentFetchAllOrders()),
  agentFetchByOrderId: _ => dispatch(agentOrdersFetchByAgentId()),
  agentUpdateOrderStatus: body => dispatch(agentUpdateOrderStatus(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentOrderAdmin);
