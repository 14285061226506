import React from 'react'

export default function ViewAgentModal(props) {
    let { agent } = props;
    return (
        <div className="modal fade" id="viewModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Agent Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="w-100 table-responsive">
                            <table className="table no-border">
                                <tbody>
                                    <tr>
                                        <th>
                                            Agent Name
                                    </th>
                                        <td className="text-capitalize">
                                            {agent.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Phone Number
                                    </th>
                                        <td>
                                            {agent.phone}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Agent Address
                                    </th>
                                        <td>
                                            {agent.address}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Country
                                    </th>
                                        <td>
                                            {agent.country}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Mobile Number
                                        </th>
                                        <td>
                                            {agent.mobile}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Zone
                                        </th>
                                        <td>
                                            {agent.zone}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            County
                                        </th>
                                        <td>
                                            {agent.county}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Commission %age
                                        </th>
                                        <td>
                                            {agent.commissionPercentage}
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
