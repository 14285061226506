import React, { Component, Fragment } from "react";
import FiltersSalesReport from './FiltersSalesReport'

//redux improts
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const defaultImage = "https://via.placeholder.com/150";

// let demoData = {
//   "response":true,
//   "topRows":[
//     ["P-2427-01",0,0,6,6,89.94],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//     ["T-JY1808-80",0,0,0,0,0],
//   ],
//   "branches":["GRNT","COL","SHMR"],
//   "detailRows":[
//     {"P-2427-01":{"GRY":[0,0,0],"BLK":[0,0,0],"DEN":[0,0,0],"DP":[0,0,2],"MUS":[0,0,3],"TEL":[0,0,1]}},
//     {"T-JY1808-80":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-81":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-82":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-83":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-84":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-85":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-86":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-87":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}},
//     {"T-JY1808-88":{"ASO":[0,0,0],"MUS":[0,0,0],"DEN":[0,0,0],"NVY":[0,0,0],"GRY":[0,0,0],"TOP":[0,0,0]}}
//   ],
//   "imageUrls":{
//     "P-2427-01":"http://www.lvclothing.co.uk/product_images/import/P-2427-01.JPG",
//     "T-JY1808-80":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-81":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-82":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-83":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-84":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-85":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-86":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-87":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",
//     "T-JY1808-88":"http://www.lvclothing.co.uk/product_images/import/T-JY1808-80.JPG",

//   },
//   "saleAmounts":{
//     "P-2427-01":{"DP":29.98,"MUS":44.97,"TEL":14.99,"grandTotal":89.94}
//   },
//   "msg":"2 records  have been found"
// }

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: {},
      branch: {},
      fromDate: "",
      toDate: "",
      grandTotals: [],
      type: '',
      supplier: {
        branches: [],
        companyName: ''
      }
    };
  }
  setParentState = (what, to) => {
    this.setState({
      [to]: what
    });
  };
  decideHeading = () => {
    return this.state.type === 'product' ? (
      `Sales Report(Product Wise) For ${this.state.supplier.companyName}`
    ) : (
        `Sales Report(Category Wise) For ${this.state.supplier.companyName}`
      )
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    Sales Report
                            </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <FiltersSalesReport setParentState={this.setParentState} getReportTable={this.sendReportTable} heading={this.decideHeading()} />
                {
                  this.props.bestCategoryData.data.branches.length === 0 ? (null) : (
                    <div className="row">
                      <div className="row">
                        <div className="col-12" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                          <h4 id="pdfHeading">{this.decideHeading()}</h4>
                        </div>
                      </div>
                      <div className="col-12 mt-2">
                        <div className="table-responsive" id="generateReport">
                          <table className="table table-hover min-w-700 table_heading_view" style={{ width: '100%', 'marginTop': '5px', 'borderCollapse': 'collapse' }} cellPadding={5}>
                            <thead>
                              <tr className="heading_mine">
                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '9px' }} className="manageFont">#</th>
                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '9px' }} className="manageFont">Product</th>
                                {
                                  this.props.bestCategoryData.data.branches.map((row, index) => {
                                    return (
                                      <th key={index} style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '9px' }} className="manageFont">{row}</th>
                                    )
                                  })
                                }
                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '9px' }} className="manageFont">Total Sale</th>
                                {
                                  this.state.type === 'product' ? (
                                    <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '9px' }} className="manageFont">Sales(£)</th>
                                  ) : (
                                      null
                                    )
                                }
                              </tr>
                            </thead>
                            <tbody >
                              {
                                this.props.bestCategoryData.data.topRows.map((row, index) => {
                                  // demoData.topRows.map((row, index) => {
                                  let currentData = '';
                                  let abc;
                                  return (
                                    <Fragment key={index}>
                                      <tr style={{ backgroundColor: '#eee', pageBreakInside: 'avoid' }}>
                                        <td style={{ fontWeight: 'bold', fontSize: '9px', textAlign: 'center' }} className="manageFont">{index + 1}</td>
                                        {
                                          row.map((data, bi) => {
                                            bi === 0 ? (currentData = data) : (abc = 0);
                                            return (
                                              <td key={bi} style={{ fontWeight: 'bold', fontSize: '9px', textAlign: 'center' }} className="manageFont">
                                                {
                                                  bi === 0 && this.state.type === 'product' ? (
                                                    <Fragment>
                                                      <img
                                                        src={this.props.bestCategoryData.data.imageUrls[row[0]]}
                                                        alt="profile"
                                                        onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                                                        className="profile-icon mr-2"
                                                        style={{
                                                          width: '100px',
                                                          height: 'auto',
                                                          borderRadius: '5%'
                                                        }}
                                                      />
                                                      <br />
                                                      <br />
                                                    </Fragment>
                                                  ) : (null)
                                                }
                                                {data}
                                              </td>
                                            )
                                          })
                                        }
                                      </tr>
                                      {
                                        this.props.bestCategoryData.data.detailRows.map((clrrow, cri) => {
                                          // demoData.detailRows.map((clrrow, cri) => {
                                          return (
                                            Object.keys(clrrow).map((key, ki) => {
                                              if (key === row[0]) { //key is category ie. Blouse, Top etc.
                                                return (
                                                  Object.keys(clrrow[key]).map((clr, cli) => {
                                                    let total = 0;
                                                    let value = 0;
                                                    if (this.state.type === 'product') {
                                                      if (this.props.bestCategoryData.data.saleAmounts[currentData]) {
                                                        if (this.props.bestCategoryData.data.saleAmounts[currentData][clr]) {
                                                          value = this.props.bestCategoryData.data.saleAmounts[currentData][clr]
                                                        }
                                                      }
                                                    }
                                                    return (
                                                      <tr key={ki}>
                                                        <td style={{ textAlign: 'center' }}></td>
                                                        <td style={{ fontWeight: 'bold', fontSize: '9px', textAlign: 'center' }} className="manageFont">{clr}</td>
                                                        {
                                                          clrrow[key][clr].map((amt, i) => {
                                                            total += amt;
                                                            return (
                                                              <td style={{ fontWeight: 'bold', fontSize: '9px', textAlign: 'center' }} className="manageFont">{amt}</td>
                                                            )
                                                          })
                                                        }
                                                        <td style={{ fontWeight: 'bold', fontSize: '9px', textAlign: 'center' }} className="manageFont">{total}</td>
                                                        {
                                                          this.state.type === 'product' ? (
                                                            <td style={{ fontWeight: 'bold', fontSize: '9px', textAlign: 'center' }} className="manageFont">{value ? (value) : ('-')}</td>
                                                          ) : (null)
                                                        }
                                                      </tr>
                                                    )
                                                  })
                                                )
                                              }
                                              else {
                                                return null
                                              }
                                            })
                                          )
                                        })
                                      }
                                    </Fragment>
                                  )
                                })
                              }
                              <tr>
                                <td></td>
                                {
                                  this.state.grandTotals.map((t, i) => {
                                    return (
                                      <td key={i} style={{ fontWeight: 'bold', fontSize: '9px', textAlign: 'center' }} className="manageFont">{i === 0 ? ('Grand Total') : (t)}</td>
                                    )
                                  })
                                }
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
  manageTotals = (topRows) => {
    let grandTotals = [];
    for (let iterator = 0; iterator < topRows.length; iterator++) {
      for (let i = 0; i < topRows[iterator].length; i++) {
        if (iterator === 0) {
          grandTotals.push(topRows[iterator][i])
        }
        else {
          grandTotals[i] += topRows[iterator][i]
        }
      }
    }
    this.setState({ grandTotals })
  }
  componentWillReceiveProps(newProps) {
    if (newProps.bestCategoryData !== this.props.bestCategoryData && newProps.bestCategoryData !== undefined) {
      let { data } = newProps.bestCategoryData;
      if (data.response) {
        this.manageTotals(newProps.bestCategoryData.data.topRows);
        toast.success(data.msg)
      }
      else {
        toast.error(data.msg);
      }
    }
  }
  sendReportTable = () => {
    let table = document.getElementById('generateReport').innerHTML;
    return table;
  }

}

const mapStateToProps = () => state => ({
  bestCategoryData: state.Store.storeBestSellingReportData
});


export default connect(mapStateToProps, null)(Report);

export const styles = {
  imageStyles: {
    height: "130px",
    borderRadius: "25%",
    width: "auto",
    display: "block",
    margin: "0 auto"
  }
};
