import React, { Component } from 'react';
import Loader from '../../../inc/Loader';

//redux imports
import { connect } from 'react-redux';
import { agentFetchAssignedProducts } from '../../../actions/agentActions';

const defaultImage = "https://via.placeholder.com/150";


class ViewAssignedProduct extends Component {
    render() {
        let { agentName, agentId } = this.props.location.state;
        return (
            <div className="content-wrapper">
                <div className="row align-items-center">
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">All Assigned Products : {agentName}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover min-w-700 table_heading_view">
                                                <thead>
                                                    <tr className="heading_mine">
                                                        <th>#</th>
                                                        <th>Product Image</th>
                                                        <th>Main Code</th>
                                                        <th>Unit Price</th>
                                                        <th>Assigned Price</th>
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.props.agentFetchAssignedProductsresponse.data.products.map((v, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td><img src={v.data.product.imageUrl} alt="product" style={{ height: '130px', borderRadius: '25%', width: 'auto', display: 'block', margin: '0 auto' }} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} /></td>
                                                                    <td>{v.data.product.mainCode}</td>
                                                                    <td>£ {v.data.product.unitPrice}</td>
                                                                    <td>£ {v.data.product.assignedPrice}</td>
                                                                    <td>
                                                                        <span
                                                                            onClick={() => { this.props.history.push('/agent/edit_assign', { update: true, selectedProductToSave: v.data.product, agentName, price: v.data.price, agentId, allProducts: this.props.agentFetchAssignedProductsresponse.data }) }}
                                                                            className="text-decoration-none">
                                                                            <button type="button"
                                                                                className="btn-sm btn ml-2   btn-dark btn-icon-text">
                                                                                <i
                                                                                    className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                                                                Edit
                                                                                </button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                   }
                                                </tbody>
                                            </table>
                                                    {
                                                        this.props.agentFetchAssignedProductsresponse.data.products.length === 0?(
                                                            <div className="d-flex justify-content-center mt-4">
                                                                <h3>
                                                                    No Products Found
                                                                </h3>
                                                            </div>
                                                        ):(null)
                                                    }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader loading={this.props.loader} />
            </div>
        )
    }
    componentDidMount() {
        let { agentId } = this.props.location.state;
        this.props.agentFetchAssignedProducts(agentId);
    }
}

const mapStateToProps = () => state => ({
    agentFetchAssignedProductsresponse: state.Agent.agentFetchAssignedProducts,
    loader: state.Agent.loader
})

const mapDispatchToProps = dispatch => ({
    agentFetchAssignedProducts: (agentId) => dispatch(agentFetchAssignedProducts(agentId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewAssignedProduct)