import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../../App.css";
import countries from "../../../Constants/constants";
import { connect } from "react-redux";
import { storeUpdate } from "../../../actions/storeActions";
import { toast } from "react-toastify";
import Loader from "../../../inc/Loader";

class EditStore extends Component {
  constructor(props) {
    super(props);
    let { store } = this.props.history.location.state;
    this.state = {
      storeId: store._id,
      companyName: store.companyName,
      address: store.address,
      country: store.country,
      contactPerson: store.contactPerson,
      mobile: store.mobile,
      phone: store.phone,
      email: store.email,
      locationName: "",
      locationCode: "",
      formErrors: {
        companyName: "",
        address: "",
        country: "",
        contactPerson: "",
        mobile: "",
        phone: "",
        email: "",
        locationName: "",
        locationCode: ""
      },
      branches: store.branches,
      branchLocationName: false,
      branchLocationCode: false
    };
  }

  handleInput = e => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let field = value.trim() !== "";
    fieldValidationErrors[fieldName] = field
      ? ""
      : `${fieldName} can't be blank`;
    if (fieldName === "email") {
      field = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      fieldValidationErrors[fieldName] = field ? "" : `${fieldName} is invalid`;
    }
    this.setState({
      formErrors: fieldValidationErrors
    });
  };

  errorClass(error) {
    if (error !== undefined) return error.length === 0 ? "" : "invalid-field";
    else return 0;
  }

  isValid = () => {
    let fieldValidationErrors = this.state.formErrors;
    let fields = [
      "companyName",
      "address",
      "country",
      "contactPerson",
      "mobile",
      "phone",
      "email",
      "locationName",
      "locationCode"
    ];
    let valid = true;
    fields.forEach((v, i) => {
      let field = this.state[`${v}`];
      if (field === "" && v !== "locationName" && v !== "locationCode") {
        fieldValidationErrors[v] = `${v} can't be blank`;
        this.setState({ formErrors: fieldValidationErrors });
        valid = false;
      }
      else if(field === "" && (v === "locationName" || v === "locationCode") && this.state.branches.length === 0) {
        fieldValidationErrors[v] = `${v} can't be blank`;
        this.setState({ formErrors: fieldValidationErrors });
        valid = false;
      }
      if (v === "email" && field !== "") {
        let isvalid = field.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors[v] = isvalid ? "" : `${v} is invalid`;
        valid = isvalid ? valid : false;
      }
    });
    return valid;
  };

  storeAddNow = () => {
    let valid = this.isValid();
    if (
      valid &&
      !this.state.branchLocationCode &&
      !this.state.branchLocationName
    ) {
      if (this.state.branches.length === 0) {
        toast.error("You havn't click the Add button.");
      } else {
        let {
          companyName,
          address,
          mobile,
          phone,
          contactPerson,
          email,
          country,
          branches
        } = this.state;
        let store = {
          companyName,
          address,
          mobile,
          phone,
          contactPerson,
          email,
          country,
          branches
        };
        this.props.storeUpdate(this.state.storeId, store);
      }
    }
  };

  handleLocation = (e, i) => {
    let arr = this.state.branches;
    if (e.target.name === "Branch Location Name") {
      arr[i].locationName = e.target.value;
      this.setState({
        branches: arr,
        [`name${i}`]: true
      });
    } else if (e.target.name === "Branch Location Code") {
      arr[i].locationCode = e.target.value;
      this.setState({
        branches: arr,
        [`code${i}`]: true
      });
    }
  };

  branchPresent = (e, i) => {
    let present = this.state.branches.some(
      name =>
        name.locationName === e.target.value ||
        name.locationCode === e.target.value
    );
    if (present && e.target.value !== "") {
      toast.error(e.target.name + " already exists");
    }

    if (e.target.id === "locationName") {
      this.setState({
        branchLocationName: present
      });
    } else {
      this.setState({
        branchLocationCode: present
      });
    }
  };

  handleBlur = (e, key) => {
    if (e.target.name === "Branch Location Name") {
      for (let i = 0; i < this.state.branches.length; i++) {
        if (
          i !== key &&
          e.target.value === this.state.branches[i].locationName
        ) {
          toast.error(e.target.name + " already exists");
          this.setState({
            branchLocationName: true
          });
          break;
        }
        this.setState({
          branchLocationName: false
        });
      }
    } else if (e.target.name === "Branch Location Code") {
      for (let i = 0; i < this.state.branches.length; i++) {
        if (
          i !== key &&
          e.target.value === this.state.branches[i].locationCode
        ) {
          toast.error(e.target.name + " already exists");
          this.setState({
            branchLocationCode: true
          });
          break;
        }
        this.setState({
          branchLocationCode: false
        });
      }
    }
  };

  addBranch = () => {
    let branches = {
      locationName: this.state.locationName,
      locationCode: this.state.locationCode
    };
    if (
      branches.locationName !== "" &&
      branches.locationCode !== "" &&
      !this.state.branchLocationName &&
      !this.state.branchLocationCode
    ) {
      this.setState({
        locationName: "",
        locationCode: ""
      });
      this.state.branches.push(branches);
      this.setState({
        branches: this.state.branches
      });
    }
  };

  updateResponse = res => {
    if (res.data.response) {
      toast.success(res.data.msg);
      this.props.history.push("/store");
    } else {
      toast.error(res.data.msg);
    }
  };

  clearBranch = i => {
    if (this.state.branches.length !== 0) {
      let arr = this.state.branches;
      arr.splice(i, 1);
      this.setState({
        branches: arr
      });
    }
  };

  componentWillReceiveProps = newProps => {
    if (
      this.props.response !== newProps.response &&
      newProps.response !== undefined
    ) {
      this.updateResponse(newProps.response);
    }
  };

  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    Edit Store
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/store">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-eye mr-1"></i>
                  All Store
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="companyname">Company Name</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.companyName
                          )}`}
                          id="companyName"
                          placeholder="Company Name"
                          required
                          value={this.state.companyName}
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.companyName}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="mobile">Mobile</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.mobile
                          )}`}
                          id="mobile"
                          value={this.state.mobile}
                          placeholder="Mobile"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.mobile}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label for="address">Address</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.address
                          )}`}
                          id="address"
                          value={this.state.address}
                          placeholder="Address"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.address}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="country">Country</label>
                        <select
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.country
                          )}`}
                          id="country"
                          value={this.state.country}
                          style={{ height: "46px", marginTop: "2px" }}
                          onChange={event => this.handleInput(event)}
                        >
                          <option disabled selected value="">
                            Select Country
                          </option>
                          {countries.map((country, i) => {
                            return (
                              <option key={country.code} value={country.name}>
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                        <span className="error-msg">
                          {this.state.formErrors.country}
                        </span>
                        {/* <input type="number" className={`form-control ${this.errorClass(this.state.formErrors.name)}`} id="suppliercountry"
                                                    placeholder="Country" /> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="contactPerson">Contact Person</label>
                        <input
                          type="tel"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.contactPerson
                          )}`}
                          id="contactPerson"
                          value={this.state.contactPerson}
                          placeholder="Contact Person"
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.contactPerson}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="phone">Phone</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.phone
                          )}`}
                          id="phone"
                          value={this.state.phone}
                          placeholder="Phone"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.phone}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="email">Email</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.email
                          )}`}
                          id="email"
                          value={this.state.email}
                          placeholder="Email"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className="form-group">
                            <label for="locationName">
                              Branch Location Name
                            </label>
                            <input
                              type="text"
                              className={`form-control ${this.errorClass(
                                this.state.formErrors.locationName
                              )}`}
                              id="locationName"
                              name="Branch Location Name"
                              pres="branchLocationName"
                              value={this.state.locationName}
                              placeholder="Branch Location Name"
                              onChange={event => this.handleInput(event)}
                              onBlur={this.branchPresent}
                            />
                            <span className="error-msg">
                              {this.state.formErrors.locationName}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <label for="locationCode">
                              Branch Location Code
                            </label>
                            <input
                              type="text"
                              className={`form-control ${this.errorClass(
                                this.state.formErrors.locationCode
                              )}`}
                              id="locationCode"
                              name="Branch Location Code"
                              pres="branchLocationCode"
                              value={this.state.locationCode}
                              placeholder="Branch Location Code"
                              onChange={event => this.handleInput(event)}
                              onBlur={this.branchPresent}
                            />
                            <span className="error-msg">
                              {this.state.formErrors.locationCode}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div
                            className="btn btn-primary"
                            onClick={this.addBranch}
                          >
                            <i className="mdi mdi-plus btn-icon-prepend"></i>
                            <span className="ml-2">Add</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mb-3 card shadow-sm table-responsive" style={{border:"none"}}>
                          <div className="card-body">
                            <table className="table no-border">
                              <thead>
                                <tr>
                                  <th>Branch Location Name</th>
                                  <th>Brach Location Code</th>
                                  <th>Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.branches.map((v, i) => {
                                  return (
                                      <tr key={i}>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control col-8 py-2"
                                            key={i}
                                            name="Branch Location Name"
                                            value={v.locationName}
                                            onChange={event =>
                                              this.handleLocation(event, i)
                                            }
                                            onBlur={event =>
                                              this.handleBlur(event, i)
                                            }
                                          ></input>
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control col-8 py-2"
                                            key={i}
                                            name="Branch Location Code"
                                            value={v.locationCode}
                                            onChange={event =>
                                              this.handleLocation(event, i)
                                            }
                                            onBlur={event =>
                                              this.handleBlur(event, i)
                                            }
                                          ></input>
                                        </td>
                                        <td>
                                          <div
                                            className="btn btn-danger btn-sm py-1 px-2"
                                            onClick={() => this.clearBranch(i)}
                                          >
                                            <i className="mdi mdi-close"></i>
                                          </div>
                                        </td>
                                      </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.storeAddNow()}
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      this.props.history.push("/store");
                    }}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        <Loader loading={this.props.loading} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeUpdate: (id, data) => dispatch(storeUpdate(id, data))
  };
};

const mapStateToProps = () => state => ({
  response: state.Store.storeEditResponse,
  loading: state.Store.loader
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStore);
