import {
    BARCODE_FETCH_UNUSED, BARCODE_IMPORT, BARCODE_IMPORT_EMPTY
} from '../actions/types'

const initialState = {

    loader: false,

    //unused barcode response
    barCodeResponseUnused: {},

    //import barcode response
    importBarcodeResponse: {},

    //unused barcode used in product section
    barCodeUnusedResponse: {}
}

export default function (state = initialState, action) {
    switch (action.type) {

        //fetching the barcode
        case `${BARCODE_FETCH_UNUSED}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${BARCODE_FETCH_UNUSED}_FULFILLED`:
            return {
                ...state,
                loader: false,
                barCodeResponseUnused: action.payload,
                barCodeUnusedResponse: action.payload
            }
        case `${BARCODE_FETCH_UNUSED}_REJECTED`:
            return {
                ...state,
                loader: false,
                barCodeResponseUnused: action.payload.response !== undefined ? (action.payload.response) : ({}),
                barCodeUnusedResponse: action.payload.response !== undefined ? (action.payload.response) : ({})
            }

        //importing the barcodes
        case `${BARCODE_IMPORT}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${BARCODE_IMPORT}_FULFILLED`:
            return {
                ...state,
                loader: false,
                importBarcodeResponse: action.payload
            }
        case `${BARCODE_IMPORT}_REJECTED`:
            return {
                ...state,
                loader: false,
                importBarcodeResponse: action.payload.response !== undefined ? (action.payload.response) : ({})
            }

        //empty barcode
        case `${BARCODE_IMPORT_EMPTY}`:
            return {
                ...state,
                importBarcodeResponse: action.payload
            }
        default:
            return {
                ...state
            }
    }
}