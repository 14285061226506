import React, { Component } from "react";
import countries from "../../../Constants/country";
import Loader from "../../../inc/Loader";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { agentAdd } from "../../../actions/agentActions";
import { Link } from "react-router-dom";

class AddAgent extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      address: "",
      county: "",
      country: "",
      phone: "",
      mobile: "",
      commision: "",
      zone: "",
      email: "",
      formErrors: {
        name: "",
        address: "",
        county: "",
        country: "",
        phone: "",
        mobile: "",
        commision: "",
        zone: "",
        email: ""
      }
    };
  }

  //setting the state when changing the value of the text input
  //in value is made empty than making it red
  handleChange = e => {
    let { value, name } = e.target;
    let { formErrors } = this.state;
    if (name === "name") {
      if (/^[A-Za-z ]*$/.test(value)) {
        this.setValue(name, value, _ => {});
      }
    } else if (name === "phone" || name === "mobile" || name === "commision") {
      if (/^[0-9]*$/.test(value)) {
        this.setValue(name, value, _ => {});
      }
    } else if (name === "email") {
      this.setValue(name, value, _ => {
        // if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)){
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
        {
          formErrors["email"] = "Please Enter a valid Email!!";
          this.setState({
            formErrors
          });
        }
      });
    } else {
      this.setValue(name, value, _ => {});
    }
  };
  // componentDidUpdate() {
  //     console.log(this.state)
  // }

  setValue = (name, value, callback) => {
    this.setState({ [name]: value }, () => {
      this.validateField(name, value, _ => {
        callback();
      });
    });
  };

  validateField = (fieldName, value, callback) => {
    let fieldValidationErrors = this.state.formErrors;
    let field = value.trim() !== "";
    fieldValidationErrors[fieldName] = field
      ? ""
      : `${fieldName} can't be blank`;
    this.setState(
      {
        formErrors: fieldValidationErrors
      },
      () => {
        callback();
      }
    );
  };

  errorClass(error) {
    return error.length === 0 ? "" : "invalid-field";
  }

  isValid = () => {
    let fieldValidationErrors = this.state.formErrors;
    let fields = [
      "name",
      "address",
      "county",
      "country",
      "phone",
      "mobile",
      "commision",
      "zone",
      "email"
    ];
    let valid = true;
    fields.forEach((v, i) => {
      let field = this.state[`${v}`];
      if (field === "") {
        fieldValidationErrors[v] = `${v} can't be blank`;
        this.setState({ formErrors: fieldValidationErrors });
        valid = false;
      }
    });
    return valid;
  };

  save = () => {
    let valid = this.isValid();
    if (valid) {
      let {
        name,
        address,
        country,
        county,
        phone,
        mobile,
        commision,
        zone,
        email
      } = this.state;
      let body = {
        name,
        address,
        country,
        county,
        phone,
        mobile,
        commissionPercentage: commision,
        zone,
        email
      };
      this.props.agentAdd(body);
    }
  };

  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    Add Agent
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/agent">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-eye mr-1"></i>All Agents
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="suppliername">Name</label>
                        <input
                          value={this.state.name}
                          ref="name"
                          name="name"
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.name
                          )}`}
                          id="agentname"
                          placeholder="Agent Name"
                          required
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="agentcounty">County</label>
                        <input
                          name="county"
                          value={this.state.county}
                          ref="county"
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.county
                          )}`}
                          id="agentcounty"
                          placeholder="Agent County"
                          required
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.county}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="agentaddress">Address</label>
                        <input
                          name="address"
                          value={this.state.address}
                          ref="address"
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.address
                          )}`}
                          id="agentaddress"
                          placeholder="Agent Address"
                          required
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.address}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="agentemail">Email</label>
                        <input
                          name="email"
                          value={this.state.email}
                          ref="email"
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.email
                          )}`}
                          id="agentemail"
                          placeholder="Agent Email"
                          required
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="country">Country</label>
                        <select
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.country
                          )}`}
                          id="country"
                          value={this.state.country}
                          name="country"
                          ref="country"
                          style={{ height: "46px", marginTop: "2px" }}
                          onChange={event => this.handleChange(event)}
                        >
                          <option disabled value="">
                            Select Country
                          </option>
                          {countries.map((country, i) => {
                            return (
                              <option key={country.code} value={country.name}>
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                        <span className="error-msg">
                          {this.state.formErrors.country}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="agentphone">Phone</label>
                        <input
                          name="phone"
                          value={this.state.phone}
                          ref="phone"
                          type="number"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.phone
                          )}`}
                          id="agentphone"
                          placeholder="Agent Phone"
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.phone}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="agentmobile">Mobile</label>
                        <input
                          name="mobile"
                          value={this.state.mobile}
                          ref="mobile"
                          type="number"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.mobile
                          )}`}
                          id="agentmobile"
                          placeholder="Agent Mobile"
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.mobile}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="agentcommision">Commission %age</label>
                        <input
                          ref="commision"
                          value={this.state.commision}
                          name="commision"
                          type="number"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.commision
                          )}`}
                          id="agentcommision"
                          placeholder="Commision %age"
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.commision}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="agentzone">Zone</label>
                        <input
                          ref="zone"
                          name="zone"
                          value={this.state.zone}
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.zone
                          )}`}
                          id="agentzone"
                          placeholder="Zone"
                          onChange={e => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="error-msg">
                          {this.state.formErrors.zone}
                        </span>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={() => {
                      this.save();
                    }}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      this.props.history.push("/agent");
                    }}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={this.props.loading} />
      </div>
    );
  }
  componentWillReceiveProps(newProps) {
    if (
      this.props.agentResponse !== newProps.agentResponse &&
      newProps.agentResponse !== undefined
    ) {
      this.updateResponse(newProps.agentResponse);
    }
  }
  updateResponse = res => {
    if (res.data.response) {
      toast.success(res.data.msg);
      this.setState({
        name: "",
        address: "",
        county: "",
        country: "",
        phone: "",
        mobile: "",
        commision: "",
        zone: "",
        email: ""
      });
    } else {
      toast.error(res.data.msg);
    }
  };
}

const mapDispatchToProps = dispatch => ({
  agentAdd: data => dispatch(agentAdd(data))
});

const mapStateToProps = () => state => ({
  loading: state.Agent.loader,
  agentResponse: state.Agent.agentResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAgent);
