import {
    AGENT_ADD, AGENT_FETCH, AGENT_UPDATE, AGENT_SEARCH, AGENT_ASSIGN_PRODCUTS, AGENT_FETCH_ASSIGNED_PRODUCTS, AGENT_CUSTOMER_DETAILS, AGENT_CUSTOMER_DETAILS_RESET, AGENT_CUSTOMER_ORDER, AGENT_FETCH_ALL_ORDERS, AGENT_FETCH_ORDERS_BY_ID, AGENT_UPDATE_ORDER_STATUS, AGENT_FETCH_CUSTOMERS
} from '../actions/types'

const initialState = {

    //add response
    agentResponse: {},
    loader: false,

    //fetch response
    agentFetchResponse: {
        data: {
            agents: []
        }
    },

    //edit response
    agentEditResponse: {},

    //agent assign products response
    agentAssignProduct: {},

    //agent check the product is already assigned or not 
    agentAssignProductCheck: {},

    //fetch agent assigned products data
    agentFetchAssignedProducts: {
        data: {
            products: []
        }
    },

    //agent customer details saved
    agentCustomerDetailsResponse: {},

    //agent customer order place response
    agentCustomerOrderResponse: {},

    //agent all orders
    agentAllOrders: {
        data: {
            orders: []
        }
    },

    //agent orders by agent id
    agentOrdersByAgentId: {
        data: {
            orders: []
        }
    },

    //agent update order status
    agentOrderStatusUpdate: {},

    //agent customers 
    agentAllCustomers: {
        data: {
            customers: []
        }
    }
}

export default function (state = initialState, action) {
    switch (action.type) {

        //adding the agent
        case `${AGENT_ADD}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${AGENT_ADD}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentResponse: action.payload
            }
        case `${AGENT_ADD}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentResponse: action.payload.response !== undefined ? (action.payload.response) : ({})
            }

        //fetching the agent
        case `${AGENT_FETCH}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${AGENT_FETCH}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentFetchResponse: action.payload
            }
        case `${AGENT_FETCH}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentFetchResponse: action.payload.response !== undefined ? (action.payload.response) : ({
                    data: {
                        products: []
                    }
                })
            }

        //editing the agent
        case `${AGENT_UPDATE}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${AGENT_UPDATE}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentEditResponse: action.payload
            }
        case `${AGENT_UPDATE}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentEditResponse: action.payload.response
            }

        //searching the agent
        case `${AGENT_SEARCH}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${AGENT_SEARCH}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentFetchResponse: action.payload
            }
        case `${AGENT_SEARCH}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentFetchResponse: action.payload.response
            }

        //assigning products to the agent
        case `${AGENT_ASSIGN_PRODCUTS}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_ASSIGN_PRODCUTS}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentAssignProduct: action.payload,
            }
        case `${AGENT_ASSIGN_PRODCUTS}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentFetchResponse: action.payload.response
            }

        //fetching assigned products
        case `${AGENT_FETCH_ASSIGNED_PRODUCTS}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_FETCH_ASSIGNED_PRODUCTS}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentFetchAssignedProducts: action.payload,
            }
        case `${AGENT_FETCH_ASSIGNED_PRODUCTS}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentFetchAssignedProducts: action.payload.response
            }

        //agent customer details saved
        case `${AGENT_CUSTOMER_DETAILS}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_CUSTOMER_DETAILS}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentCustomerDetailsResponse: action.payload,
            }
        case `${AGENT_CUSTOMER_DETAILS}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentCustomerDetailsResponse: action.payload.response
            }

        //agent customer detaisl reset
        case AGENT_CUSTOMER_DETAILS_RESET:
            return {
                agentCustomerDetailsResponse: action.payload
            }

        //agent customer order placed
        case `${AGENT_CUSTOMER_ORDER}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_CUSTOMER_ORDER}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentCustomerOrderResponse: action.payload,
            }
        case `${AGENT_CUSTOMER_ORDER}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentCustomerOrderResponse: action.payload.response
            }

        //agent fetch all orders
        case `${AGENT_FETCH_ALL_ORDERS}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_FETCH_ALL_ORDERS}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentAllOrders: action.payload,
            }
        case `${AGENT_FETCH_ALL_ORDERS}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentAllOrders: action.payload.response
            }

        //agent fetch orders by agent id
        case `${AGENT_FETCH_ORDERS_BY_ID}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_FETCH_ORDERS_BY_ID}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentOrdersByAgentId: action.payload,
            }
        case `${AGENT_FETCH_ORDERS_BY_ID}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentOrdersByAgentId: action.payload.response
            }

        //agent order status update
        case `${AGENT_UPDATE_ORDER_STATUS}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_UPDATE_ORDER_STATUS}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentOrderStatusUpdate: action.payload,
            }
        case `${AGENT_UPDATE_ORDER_STATUS}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentOrderStatusUpdate: action.payload.response
            }
        //agent customers
        case `${AGENT_FETCH_CUSTOMERS}_PENDING`:
            return {
                ...state,
                loader: true
            }
        case `${AGENT_FETCH_CUSTOMERS}_FULFILLED`:
            return {
                ...state,
                loader: false,
                agentAllCustomers: action.payload,
            }
        case `${AGENT_FETCH_CUSTOMERS}_REJECTED`:
            return {
                ...state,
                loader: false,
                agentAllCustomers: action.payload.response
            }
        default:
            return {
                ...state
            }
    }
}
