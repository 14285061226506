import React, { Component } from "react";
import { Link } from "react-router-dom";
import ViewSupplierModal from "./ViewSupplierModal";
import { connect } from "react-redux";
import { fetchSupplier, searchSupplier } from "../../actions/supplierActions";
// import Pagination from "../../Common/Pagination";
import Loader from "../../inc/Loader";
import NewPagination from "../../Common/NewPagination";

const defaultImage = "https://via.placeholder.com/150";
class Supplier extends Component {
  state = {
    currentSupplier: {
      fullName: '',
      code: '',
      country: '',
      address: '',
      phoneNumber: '',
    },
    searchText: '',
    temporaryText: '',
    perPage: 10,
    page: 1
  };

  componentDidMount() {
    let body = {
      perPage: this.state.perPage,
      page: this.state.page
    };
    this.props.fetchSupplier(body);
  }

  //Searching starts here

  searchSupplier = () => {
    // To remove clicking of ctrl, alt, etc
    if (this.state.searchText !== this.state.temporaryText) {
      this.setState({
        page: 1
      });
      let body = {
        page: this.state.page,
        perPage: this.state.perPage,
        searchText: this.state.searchText
      };
      this.setState({
        temporaryText: this.state.searchText
      });

      //To check if there's any text in search
      if (this.state.searchText.length !== 0) {
        this.props.searchSupplier(body);
      } else {
        this.props.fetchSupplier(body);
      }
    }
  };

  debounce = (callback, delay) => {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback();
      }, delay);
    };
  };

  getData = this.debounce(this.searchSupplier, process.env.REACT_APP_DEBOUNCE);

  stateSet = e => {
    let val = e.target.value;
    if (
      val === "\\" ||
      val === "+" ||
      val === "(" ||
      val === ")" ||
      val === "?" ||
      val === "{" ||
      val === "}" ||
      val === "[" ||
      val === "]"
    ) {
      e.target.value = "";
    }
    this.setState({
      searchText: e.target.value
    });
  };

  clearSearch = () => {
    this.setState({
      searchText: ""
    });
    let body = {
      perPage: this.state.perPage,
      page: this.state.page
    };
    this.props.fetchSupplier(body);
  };

  // Searching ends here

  // For setting no of records per page
  setPerPage = e => {
    this.setState(
      {
        perPage: e.target.value,
        page: 1
      },
      () => {
        let body = {
          page: 1,
          perPage: this.state.perPage
        };
        this.props.fetchSupplier(body);
      }
    );
  };

  //Pagination: To get next page
  getNextPage = i => {
    //if search is empty then get response from fetchSupplier
    this.setState({
      page: i
    });
    if (this.state.searchText === "") {
      let body = {
        perPage: this.state.perPage,
        page: i
      };
      this.setState({
        page: i
      });
      this.props.fetchSupplier(body);
    }

    //Else get search from searchSupplier
    else {
      let body = {
        searchText: this.state.searchText,
        perPage: this.state.perPage,
        page: i
      };
      this.props.searchSupplier(body);
    }
  };

  render() {

    let { perPage, currentSupplier } = this.state;
    let { response, loading } = this.props;
    return (
      <div className="content-wrapper">
        <div className="row align-items-center">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    All Suppliers
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="row align-items-center justify-content-end">
              <div className="col-md-12">
                <div className="dropdown float-right">
                  <Link to="/supplier/create" className="mx-2">
                    <button
                      className="btn btn-sm bg-white font-weight-bold text-dark"
                      type="button"
                    >
                      <i className="mdi mdi-plus mr-1"></i>Add Supplier
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group m-0 mx-2 float-left">
                      <div className="col-xs-12">
                        <input
                          placeholder="Search ..."
                          type="text"
                          className="form-control rounded"
                          onChange={this.stateSet}
                          onKeyUp={this.getData}
                          value={this.state.searchText}
                        />
                      </div>
                    </div>
                    <div className="mx-2 float-left">
                      {
                        this.state.searchText !== '' ? 
                        (<button
                        className="btn bg-danger font-weight-bold text-white"
                        type="button"
                        onClick={this.clearSearch}
                      >
                        <i className="mdi mdi-close mr-1"></i>Clear Search
                      </button>
                      )
                      :
                      null
                    }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-right">
                        <select
                          className="form-control"
                          id="type"
                          name="type"
                          placeholder="Select Type"
                          style={{
                            height: "46px",
                            width: "150px",
                            marginTop: "2px"
                          }}
                          onChange={this.setPerPage}
                        >
                          <option disabled="" value={10}>
                            10
                          </option>
                          <option disabled="" value={20}>
                            20
                          </option>
                          <option disabled="" value={30}>
                            30
                          </option>
                          <option disabled="" value={50}>
                            50
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-2">
                    <hr />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700 table_heading_view">
                        <thead>
                        <tr className="heading_mine">                          
                            <th>#</th>
                            <th>Supplier Card</th>
                            <th>Supplier Name</th>
                            <th>Country</th>
                            <th>Code</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.response.data.suppliers.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {this.state.page === 1
                                    ? i + 1
                                    : i +
                                    1 +
                                    (this.state.page - 1) *
                                    this.state.perPage}
                                </td>
                                <td>
                                  <img
                                    src={v.businessCard}
                                    alt="supplierImage"
                                    style={{
                                      width: "auto",
                                      height: "36px",
                                      borderRadius: "25%"
                                    }}
                                    onError={(e)=>{e.target.onerror = null; e.target.src=defaultImage}}
                                  />
                                </td>
                                <td className="text-capitalize">{v.fullName}</td>
                                <td>{v.country===''?('-'):(v.country)}</td>
                                <td>{v.code}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    data-toggle="modal"
                                    data-target="#viewModal"
                                    onClick={() => {
                                      this.setState({ currentSupplier: v });
                                    }}
                                  >
                                    <i className="mdi mdi-eye btn-icon-prepend"></i>
                                    View Suppliers
                                  </button>
                                  <span
                                    onClick={() => {
                                      this.props.history.push(
                                        "/supplier/edit/",
                                        { supplier: v }
                                      );
                                    }}
                                    className="text-decoration-none"
                                  >
                                    <button
                                      type="button"
                                      className="btn-sm btn ml-2 btn-dark btn-icon-text"
                                    >
                                      <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                      Edit
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      {this.props.response.data.suppliers.length === 0
                        ? (<div className="noProductsFound">
                          <span>No Suppliers Found</span>
                        </div>)
                        : null}
                      {/* Pagination start */}

                      <NewPagination
                        perPage={perPage}
                        response={response.data.suppliers}
                        totalPages={response.data.totalPages}
                        currentPage={response.data.currentPage}
                        getNextPage={i => this.getNextPage(i)}
                      // searchText={searchText}
                      />

                      {/* Pagination Ends */}
                    </div>
                  </div>
                </div>
                <ViewSupplierModal supplier={currentSupplier} />
                <Loader loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchSupplier: data => dispatch(fetchSupplier(data)),
    searchSupplier: data => dispatch(searchSupplier(data))
  };
};

const mapStateToProps = () => state => ({
  response: state.Supplier.allSuppliers,
  loading: state.Supplier.loader
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Supplier);
