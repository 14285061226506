export const Titles = {
    Login: 'Minx::Login',
    Signup: 'Minx::Signup',
    Dashboard: 'Minx::Dashboard'
};

let PHOTO_URL = "";
let DOCUMENTS_URL = "";
let BASE_URL = ""
let SAMPLE_DOC_URL = ""

if (process.env.NODE_ENV === 'production') {
    // PHOTO_URL = 'http://35.173.196.18/public/images/';
    // BASE_URL = 'http://35.173.196.18/v1/';
    // DOCUMENTS_URL = 'http://35.173.196.18/public/documents/';
    // http://52.55.123.29/v1/member

    PHOTO_URL = 'http://minxapi.nugen.co.in/public/images/';
    BASE_URL = 'http://minxapi.nugen.co.in/v1/';
    DOCUMENTS_URL = 'http://minxapi.nugen.co.in/public/documents/';
    SAMPLE_DOC_URL = 'http://minxapi.nugen.co.in/public/sample_docx/'


} else if (process.env.NODE_ENV === 'development') {
    // PHOTO_URL = 'https://minx-staging.herokuapp.com/public/images/';
    // DOCUMENTS_URL = 'https://minx-staging.herokuapp.com/public/documents/';
    // BASE_URL = 'https://minx-staging.herokuapp.com/v1/';
    // PHOTO_URL = 'http://192.168.1.8:3000/public/images/';
    // BASE_URL = 'http://192.168.1.8:3000/v1/';
    // DOCUMENTS_URL = 'http://192.168.1.8:3000/public/documents/';
    // PHOTO_URL = 'http://35.173.196.18/public/images/';
    // BASE_URL = 'http://35.173.196.18/v1/';
    // DOCUMENTS_URL = 'http://35.173.196.18/public/documents/';
    // PHOTO_URL = 'http://localhost:3000/public/images/';
    // BASE_URL = 'http://localhost:3000/v1/';
    // DOCUMENTS_URL = 'http://localhost:3000/public/documents/';
    // SAMPLE_DOC_URL = 'http://localhost:3000/public/sample_docx/'
    PHOTO_URL = 'http://minxapi.nugen.co.in/public/images/';
    BASE_URL = 'http://minxapi.nugen.co.in/v1/';
    DOCUMENTS_URL = 'http://minxapi.nugen.co.in/public/documents/';
    SAMPLE_DOC_URL = 'http://minxapi.nugen.co.in/public/sample_docx/'

}

export {
    PHOTO_URL,
    DOCUMENTS_URL,
    BASE_URL,
    SAMPLE_DOC_URL
};