import React, { Component } from "react";
import ColorModal from "../../Color/Color.module.css";

export default class ViewProduct extends Component {
  render() {
    let { product } = this.props.history.location.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    Product Info
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <span
                onClick={() => {
                  this.props.history.push("/product");
                }}
              >
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-chevron-double-left mr-1"></i>Back
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card shadow">
                      <div
                        className="card-body text-center d-flex justify-content-center align-items-center"
                        style={{ height: "529px" }}
                      >
                        <img
                          src={product.imageUrl}
                          className="img-fluid"
                          alt="ProductImage"
                          style={{ maxHeight: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card shadow">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-9">
                            <h3 className="text-uppercase text-dark">
                              {product.mainCode}
                              <small> ({product.brand})</small>
                            </h3>
                            <h6>{product.category}</h6>
                          </div>
                          <div className="col-3 text-right">
                            <h2 className="text-dark">£ {product.unitPrice}</h2>
                          </div>
                        </div>
                        <p>{product.code}</p>
                        <hr />
                        <div className="w-100 table-responsive">
                          <table className="table no-border td-p">
                            <tbody>
                              <tr>
                                <th>Supplier</th>
                                <td>
                                  <span className="text-capitalize">
                                    {product.supplier.fullName}
                                  </span>{" "}
                                  - {product.supplier.code}
                                </td>
                              </tr>
                              <tr>
                                <th>Category</th>
                                <td>{product.category}</td>
                              </tr>
                              <tr>
                                <th>Main Code</th>
                                <td>{product.mainCode}</td>
                              </tr>
                              <tr>
                                <th>Type</th>
                                <td>{product.type}</td>
                              </tr>
                              <tr>
                                <th>Unit Price</th>
                                <td>£ {product.unitPrice}</td>
                              </tr>
                              <tr>
                                <th>Cost Price</th>
                                <td>
                                  £{" "}
                                  {product.costPrice === ""
                                    ? "-"
                                    : product.costPrice}
                                </td>
                              </tr>
                              <tr>
                                <th>Product Code</th>
                                <td>{product.code}</td>
                              </tr>
                              <tr>
                                <th>Item Type</th>
                                <td>{product.itemType}</td>
                              </tr>
                              <tr>
                                <th>Season/Exhibition</th>
                                <td>
                                  <span className="text-capitalize">
                                    {product.exhibition.name}
                                  </span>{" "}
                                  - {product.exhibition.season}
                                </td>
                              </tr>
                              <tr>
                                <th>Description</th>
                                <td>
                                  <span className="text-capitalize">
                                    {product.description ? (product.description) : ('-')}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="card shadow">
                      <div className="card-body w-100">
                        <div className="table-responsive" id="print">
                          <table
                            className="table table-hover"
                            style={{ minWidth: "800px" }}
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Colour Name</th>
                                <th>Colour Code</th>
                                <th>Colour</th>
                                <th>Bar Code</th>
                                <th>Pack Size</th>
                                <th>Stock Level</th>
                                <th>Name</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {product.colorVariations.map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {v.color.name}
                                    </td>
                                    <td style={{ textTransform: "uppercase" }}>
                                      {v.color.colorCode}
                                    </td>
                                    <td>
                                      <div
                                        className={ColorModal.colorBox}
                                        style={{ background: v.color.colorSwatch === '#FFFFFF' ? (v.color.name) : (v.color.colorSwatch) }}
                                      ></div>
                                    </td>
                                    <td>{v.barcode.barcode}</td>
                                    <td>{v.packSize}</td>
                                    <td>{v.stock}</td>
                                    <td>{`${v.name}-P${v.packSize}`}</td>
                                    <td style={{ textTransform: "uppercase" }}>
                                      {v.description}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
