import React from "react";
import ColorModal from './Color.module.css';

export default function ViewColorModal(props) {
  return (
    <div
      className="modal fade"
      id="viewModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              Colour Details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "10px 26px" }}>
            {/* <div className="w-100 text-center py-3">
              <img src="./images/dashboard/group.png" alt="" />
            </div> */}
            <div className="w-100 table-responsive">
              <table className="table no-border">
                <tbody>
                  <tr>
                    <th>Color Name</th>
                    <td>{props.view.name}</td>
                    <th>Color Code</th>
                    <td>{props.view.colorCode}</td>
                  </tr>
                  <tr>
                    <th>Color Swatch</th>
                    <td>{props.view.colorSwatch}
                    </td>
                    <td>
                      <div className={ColorModal.colorBox} style={{ 'background': props.view.colorSwatch === '#FFFFFF' ? (props.view.name) : (props.view.colorSwatch) }}></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
