import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default (props, status, msg) => {

    if (status >= 300 && status < 400) {
        toast.error(msg)
    }
    else if (status >= 400 && status < 500) {

        if (status === 401) {
            toast.error(msg)
            localStorage.removeItem('access_token');
            props.history.push('/login')
        } else {
            toast.error(msg)
        }
    }
    else if (status >= 500 && status < 600) {
        toast.error(msg)
    } else {
        toast.error(" Something Went Wrong ")
    }
};