import React, { Component } from "react";
import HeaderExportProduct from "../ExportProduct/HeaderExportProduct";
import { toast } from "react-toastify";
import Loader from "../../../inc/Loader";
import CountUp from "react-countup";
import './Dashboard.css';

//redux imports
import { connect } from "react-redux";
import {
  barcodeImport,
  barcodeImportResponseEmpty
} from "../../../actions/barcodeActions";
import { dashboardAdminFetchData } from "../../../actions/dashboardActions";
import { SAMPLE_DOC_URL } from "../../../Config";

class ImportProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcodeFile: {
        name: "",
        type: ""
      }
    };
  }
  checkFileFormat = e => {
    let fileToSave = e.target.files[0];
    if (
      fileToSave.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      this.setState({
        barcodeFile: fileToSave
      });
    } else {
      toast.error("Please Upload a correct format file.");
    }
  };
  saveToDataBase = () => {
    let { barcodeFile } = this.state;
    let body = {
      xlsx: barcodeFile
    };
    this.props.saveImportBarcode(body);
  };
  render() {
    return (
      <div className="content-wrapper">
        <HeaderExportProduct heading="Import Barcodes" />
        <div className="col-sm-9"></div>
        <div className="row mt-3">
          <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Total</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={
                        this.props.dashboardData.data.totalBarCodes > 0
                          ? this.props.dashboardData.data.totalBarCodes
                          : 0
                      }
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Used</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={this.props.dashboardData.data.usedBarCodes > 0
                        ? this.props.dashboardData.data.usedBarCodes
                        : 0}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
            <div className="card">
              <div className="card-body" style={{ background: this.props.dashboardData.data.freeBarCodes < 50 ? "#f00" : '#fff' }}>
                <h4 className="card-title" style={{ color: this.props.dashboardData.data.freeBarCodes < 50 ? "#fff" : '#000' }}>Free</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold" style={{ color: this.props.dashboardData.data.freeBarCodes < 50 ? "#fff" : '#000' }}>
                    <CountUp
                      className={this.props.dashboardData.data.freeBarCodes < 50 ? 'countUpDanger' : 'countUpNotDanger'}
                      start={0}
                      end={this.props.dashboardData.data.freeBarCodes > 0
                        ? this.props.dashboardData.data.freeBarCodes
                        : 0}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label>Barcodes Upload File(xlsx)</label>
                  <input
                    type="file"
                    name="img[]"
                    className="file-upload-default"
                  />
                  <div className="input-group col-xs-12">
                    <input
                      name="image"
                      ref="image"
                      type="text"
                      value={this.state.barcodeFile.name}
                      className="form-control file-upload-info"
                      disabled
                    />
                    <span className="input-group-append">
                      <label className="file-upload-browse btn btn-primary uploadButton">
                        <span style={{ color: "#fff" }}>Upload</span>
                        <input
                          type="file"
                          onChange={e => {
                            this.checkFileFormat(e);
                          }}
                        />
                      </label>
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: "20px" }} className="d-flex justify-content-between">
                  <div>
                    <span>
                      <button
                        className="btn btn-success  mr-2  dropdown-toggle"
                        type="button"
                        id="options"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="mdi mdi-download"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="options">
                        <h6 className="dropdown-header">Download Sample</h6>
                        <span
                          className="dropdown-item cursor"
                          onClick={() => {
                            window.open(SAMPLE_DOC_URL+'barcode_sample.xlsx', '_blank')
                          }}
                        >
                          <span>XLS</span>
                        </span>
                      </div>
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={() => {
                        this.saveToDataBase();
                      }}
                    >
                      Submit
                </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        this.props.history.push("/product/");
                      }}
                    >
                      Cancel
                </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={this.props.loading} />
      </div>
    );
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.responseFile !== this.props.responseFile &&
      Object.keys(newProps.responseFile).length !== 0
    ) {
      let { data } = newProps.responseFile;
      if (data.response) {
        toast.success(data.msg);
      } else {
        toast.error(data.msg);
      }
      this.setState({ barcodeFile: { name: "", type: "" } });
      this.props.emptyImportBarcode();
    }
  }

  // componentDidMount() {
  //   this.props.fetchDashboardData();
  // }
}

const mapStateToProps = () => state => ({
  responseFile: state.Barcode.importBarcodeResponse,
  loading: state.Barcode.loader,
  dashboardData: state.Dashboard.dashboardAdminFetchResponse
});

const mapDispatchToProps = dispatch => ({
  saveImportBarcode: body => dispatch(barcodeImport(body)),
  // fetchDashboardData: () => dispatch(dashboardAdminFetchData()),
  emptyImportBarcode: _ => dispatch(barcodeImportResponseEmpty())
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportProduct);
