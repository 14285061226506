import {
    PRODUCT_ADD,
    PRODUCT_FETCH,
    PRODUCT_EDIT,
    PRODUCT_SEARCH,
    PRODUCT_EXPORT_JSON,
    PRODUCT_EXPORT_FILE,
    PRODUCT_EXPORT_RESET,
    PRODUCT_EXPORT_ALL_FILE,
    PRODUCT_IMPORT_FILE,
    PRODUCT_IMPORT_EMPTY,
    PRODUCT_SEARCH_RESET,
    PRODUCT_ALL_IMPORTED_DATA,
    PRODUCT_UPDATED_IMPORT_DATA,
    PRODUCT_STICKERS,
    PRODUCT_STICKER_SAVE_LIST,
    PRODUCT_STICKERS_PRINT_65_LABELS,
    PRODUCT_STICKERS_PRINT_XLS,
    PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS
} from './types'

import Helper, { HelperFormData } from '../helper/Helper'

export const productAdd = (body) => ({
    type: PRODUCT_ADD,
    payload: HelperFormData('product/save', 'POST', body)
})

export const productFetch = (body) => ({
    type: PRODUCT_FETCH,
    payload: Helper('product/fetch', 'POST', body)
})

export const productEdit = (id, body) => ({
    type: PRODUCT_EDIT,
    payload: HelperFormData('product/edit/' + id, 'PUT', body)
})

export const productSearch = (body) => ({
    type: PRODUCT_SEARCH,
    payload: Helper('product/search/', 'POST', body)
})

export const productExportJson = (body) => ({
    type: PRODUCT_EXPORT_JSON,
    payload: Helper('product/export/json', 'POST', body)
})

export const productExportFile = (body, type) => ({
    type: PRODUCT_EXPORT_FILE,
    payload: Helper('product/export/' + type + '/all', 'POST', body)
})

export const productExportReset = () => dispatch => {
    return dispatch({
        type: PRODUCT_EXPORT_RESET,
        payload: {
            data: {
                products: []
            }
        }
    })
}

export const productExportAll = (body) => ({
    type: PRODUCT_EXPORT_ALL_FILE,
    payload: Helper('product/export/xlsx/all', 'POST', body)
})

export const productImport = (body) => ({
    type: PRODUCT_IMPORT_FILE,
    payload: HelperFormData('product/import', 'POST', body)
})

export const productImportEmpty = () => dispatch => {
    dispatch({
        type: PRODUCT_IMPORT_EMPTY,
        payload: {}
    })
}

export const productUpdateImport = (body) => ({
    type: PRODUCT_UPDATED_IMPORT_DATA,
    payload: HelperFormData('product/bulkUpdateProduct', 'POST', body)
})

export const productSearchReset = () => dispatch => {
    dispatch({
        type: PRODUCT_SEARCH_RESET,
        payload: {
            data: {
                products: []
            }
        }
    })
}

export const productAllImportedData = () => ({
    type: PRODUCT_ALL_IMPORTED_DATA,
    payload: Helper('product/productImportData', 'GET', null)
})

export const printFourSticker = (body) => ({
    type: PRODUCT_STICKERS,
    payload: Helper('product/printProductStickers', 'POST', body)
})

export const print65Labels = (body) => ({
    type: PRODUCT_STICKERS_PRINT_65_LABELS,
    payload: Helper('product/printCustomerBarcodes', 'POST', body)
})
export const printXlsLabels = (body) => ({
    type: PRODUCT_STICKERS_PRINT_XLS,
    payload: Helper('product/exportDataToExcel', 'POST', body)
})

export const printBarcodeSaveList = (currentList) => dispatch => {
    dispatch({
        type: PRODUCT_STICKER_SAVE_LIST,
        payload: currentList
    })
}

export const productFetchAddProductThings=()=>({
    type: PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS,
    payload: Helper('product/getProductDetails', 'GET', null)
})