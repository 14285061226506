import { STAFF_ADD, STAFF_FETCH, STAFF_EDIT } from '../actions/types'

const initialState = {
    loader: false,

    //staff add response
    staffAddResponse: {},

    //staff fetch response
    staffFetchResponse: {
        data: {
            users: []
        }
    },

    //staff edit response
    staffEditResponse: {
        data: {}
    }
}

export default function (state = initialState, action) {
    switch (action.type) {

        //adding the staff
        case `${STAFF_ADD}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${STAFF_ADD}_FULFILLED`:
            return {
                ...state,
                loader: false,
                staffAddResponse: action.payload
            }
        case `${STAFF_ADD}_REJECTED`:
            return {
                ...state,
                loader: false,
                staffAddResponse: action.payload.response !== undefined ? (action.payload.response) : ({})
            }

        //fetching the staff
        case `${STAFF_FETCH}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${STAFF_FETCH}_FULFILLED`:
            return {
                ...state,
                loader: false,
                staffFetchResponse: action.payload
            }
        case `${STAFF_FETCH}_REJECTED`:
            return {
                ...state,
                loader: false,
                staffFetchResponse: action.payload.response !== undefined ? (action.payload.response) : ({
                    data: {
                        products: []
                    }
                })
            }

        //editing the staff
        case `${STAFF_EDIT}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${STAFF_EDIT}_FULFILLED`:
            return {
                ...state,
                loader: false,
                staffEditResponse: action.payload
            }
        case `${STAFF_EDIT}_REJECTED`:
            return {
                ...state,
                loader: false,
                staffEditResponse: action.payload.response !== undefined ? (action.payload.response) : ({})
            }

        default:
            return {
                ...state,
            }
    }
}