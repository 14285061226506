import React, { Component } from "react";
import "./Pagination.css";

export default class NewPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: [],
      currentPage: 1,
      prevPage: 1
    };
  }

  generateLi = () => {
    let li = [];
    let TP = this.props.totalPages;
    let CP = this.props.currentPage;
    let diff = TP - CP;

    let startingIndex = CP;

    if (diff < 10) {
      if (TP - 9 > 0) startingIndex = TP - 9;
      else {
        startingIndex = 1;
      }
    }

    if (CP === startingIndex && CP !== 1) {
      startingIndex--;
    }

    for (let i = startingIndex; i <= TP && i <= startingIndex + 9; i++) {
      li.push(
        <li
          className={
            i === this.state.currentPage ? "page-item active" : "page-item"
          }
          key={i}
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.props.getNextPage(i);
            this.setState({
              currentPage: i,
              prevPage: this.props.currentPage
            });
          }}
        >
          <div className="page-link">{i}</div>
        </li>
      );
    }
    return li;
  };

  handleButtonClick = right => {
    let { currentPage } = this.state;
    if (right) {
      if (currentPage < this.props.totalPages) {
        this.setState(
          {
            currentPage: this.state.currentPage + 1
          },
          () => {
            this.props.getNextPage(this.state.currentPage);
          }
        );
      }
    }
    if (!right) {
      if (currentPage > 1) {
        this.setState(
          {
            currentPage: this.state.currentPage - 1
          },
          () => {
            this.props.getNextPage(this.state.currentPage);
          }
        );
      }
    }
  };

  render() {
    return (
      <div className="col-12">
        <nav>
          <ul className="pagination flex-wrap pagination-flat pagination-primary justify-content-center pt-3">
            <li
              className="page-item page-link"
              name="leftArrow"
              onClick={() => {
                this.handleButtonClick(false);
              }}
            >
              <i className="mdi mdi-chevron-left"></i>
            </li>

            {/* Generate Page Numbers */}
            {this.generateLi()}

            <li
              className="page-item page-link actives"
              name="leftArrow"
              onClick={() => {
                this.handleButtonClick(true);
              }}
            >
              <i className="mdi mdi-chevron-right"></i>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
