import React, { Component } from 'react'

export default class HeaderSalesReport extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-sm-12 pr-0">
                            <div className="d-lg-flex">
                                <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                    {
                                        this.props.heading
                                    }
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
