import { DASHBOARD_ADMIN_DATA, DASHBOARD_ADMIN_SALES } from '../actions/types';

const initialState = {

    //loading all dashboard hits
    loading: false,

    //dashboard admin fetch response
    dashboardAdminFetchResponse: {
        data: {
            agents: 0,
            customers: 0,
            customerOrders: 0,
            suppliers: 0,
            exhibitions: 0,
            stores: 0,
            products: 0,
            colors: 0,
            totalBarCodes: 0,
            usedBarCodes: 0,
            freeBarCodes: 0
        }
    },

    //dashboard sales graphs data
    dashboardSalesData: {
        data: {
            labels: [],
            data: []
        }
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case `${DASHBOARD_ADMIN_DATA}_PENDING`:
            return {
                ...state,
                loading: true
            }
        case `${DASHBOARD_ADMIN_DATA}_FULFILLED`:
            return {
                ...state,
                loading: false,
                dashboardAdminFetchResponse: action.payload
            }
        case `${DASHBOARD_ADMIN_DATA}_REJECTED`:
            return {
                ...state,
                loading: false,
                dashboardAdminFetchResponse: action.payload.response !== undefined ? (action.payload.response) : ({
                    data: {
                        agents: 0,
                        customers: 0,
                        customerOrders: 0,
                        suppliers: 0,
                        exhibitions: 0,
                        stores: 0,
                        products: 0,
                        colors: 0
                    }
                })
            }

        //sales report dashboard admin
        case `${DASHBOARD_ADMIN_SALES}_PENDING`:
            return {
                ...state,
                loading: true
            }
        case `${DASHBOARD_ADMIN_SALES}_FULFILLED`:
            return {
                ...state,
                loading: false,
                dashboardSalesData: action.payload
            }
        case `${DASHBOARD_ADMIN_SALES}_REJECTED`:
            return {
                ...state,
                loading: false,
                dashboardSalesData: action.payload.response !== undefined ? (action.payload.response) : ({ data: {} })
            }
        default:
            return {
                ...state
            }
    }
}