import {
    STORE_ADD, STORE_FETCH, STORE_UPDATE, STORE_SEARCH, PRODUCT_ASSIGN, STORE_UPLOAD_SALES_REPORT, FETCH_ALL_SALES, STORE_BEST_SELLING_CATEGORY_REPORT, STORE_ORDER_FINALIZE, STORE_STOCK_REPORT, STORE_REPORT_RESET, STORE_SPECIFIC_SALES_REPORT, STORE_PRINT_PRODUCT_LABEL, STORE_PRINT_21_LABEL, STORE_SAVE_DELIVERY_NOTE
} from './types'

import Helper, { HelperFormData } from '../helper/Helper'

export const storeAdd = (data) => ({
    type: STORE_ADD,
    payload: Helper('store/save', 'POST', data)
})
export const storeFetch = (body) => ({
    type: STORE_FETCH,
    payload: Helper('store/fetch', 'POST', body)
})
export const storeFetchAll = (body) => ({
    type: STORE_FETCH,
    payload: Helper('store/fetch', 'GET', body)
})
export const storeUpdate = (id, data) => ({
    type: STORE_UPDATE,
    payload: Helper('store/edit/' + id, 'PUT', data)
})
export const storeSearch = (data) => ({
    type: STORE_SEARCH,
    payload: Helper('store/search/', 'POST', data)
})
export const productAssign = (body) => ({
    type: PRODUCT_ASSIGN,
    payload: Helper('store/product/save/', 'POST', body)
})
export const uploadSalesReport = (body) => ({
    type: STORE_UPLOAD_SALES_REPORT,
    payload: HelperFormData('store/product/importSaleReport', 'POST', body)
})
export const storeFetchAllSales = (storeId) => ({
    type: FETCH_ALL_SALES,
    payload: Helper('store/product/fetchAllSales/'+storeId, 'GET', null)
})
// export const storeBestSellingCategoryReport = (body)=>({
//     type: STORE_BEST_SELLING_CATEGORY_REPORT,
//     payload: Helper('store/product/bestSellingCategoryReport', 'POST', body)
// })
export const storeBestSellingCategoryReport = (body)=>({
    type: STORE_BEST_SELLING_CATEGORY_REPORT,
    payload: Helper('store/product/getBestSellingSaleReport', 'POST', body)
})
export const storeOrderFinalize=(ord)=>({
    type: STORE_ORDER_FINALIZE,
    payload: Helper('store/product/finalizeOrder', 'POST', {ord})
})
export const stockStockReport=(body)=>({
    type: STORE_STOCK_REPORT,
    payload: Helper('store/product/categoryWiseProductReport', 'POST', body)
})
export const stockReportReset=()=>({
    type: STORE_REPORT_RESET,
    payload: {}
})
export const uploadStoreSalesReport = (body) => ({
    type: STORE_SPECIFIC_SALES_REPORT,
    payload: HelperFormData('store/product/importSaleReport', 'POST', body)
})
export const storePrintLabel=(ord, xlsx)=>({
    type: STORE_PRINT_PRODUCT_LABEL,
    payload: Helper('store/product/getPrintDetailsForOrder', 'POST', {ord, xlsx})
})

export const storePrintLabel21=(ord)=>({
    type: STORE_PRINT_21_LABEL,
    payload: Helper('store/product/printTwentyOneSticker', 'POST', {ord: ord})
})

export const storeSaveDeliveryNote=(body) =>({
    type: STORE_SAVE_DELIVERY_NOTE,
    payload: Helper('store/prouct/saveDeliveryNote', 'POST', body)
})