import React, { Component, Fragment } from "react";
import Header from "../UploadSalesReport/Header";
import ViewOrderDetails from "./ViewOrderDetails";
import moment from "moment";
import Loader from "../../../inc/Loader";
// import DeliveryNoteModal from "./DeliveryNoteModal";
// import ViewPrintLabels from './PrintLabelModal'
import _ from "lodash";

//redux imports
import { connect } from "react-redux";
import {
  storeFetchAllSales,
  storeOrderFinalize,
  storePrintLabel,
  storePrintLabel21
} from "../../../actions/storeActions";
import {
  printXlsLabels,
} from '../../../actions/productActions';
import { toast } from "react-toastify";
import { DOCUMENTS_URL } from "../../../Config";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrder: [
        {
          product: {
            image: "",
            mainCode: ""
          },
          branch: {
            locationName: "",
            locationCode: ""
          },
          colorVariations: []
        }
      ],
      currentFileName: '',
      orderArray: [],
      store: this.props.location.state.currentStore,
      deliveryDate: "",
      selectedOrderId: "",
      loading: false,
      selectedBranch: "",
      currentPrintLabels: [],

      //pagination
      setPerPage: 10,
      from: 0,
      noOfPages: 1,
      pageNo: 1,

      //search
      searchText: "",
      filteredArr: [],

      //previous delivery note
      previousDeliveryNote: null,

    };
    this.deliveryNoteModal = React.createRef();
  }
  setFromAndTo = selectedPage => {
    let { setPerPage } = this.state;
    let from = (selectedPage - 1) * setPerPage;
    this.setState({
      from
    });
  };

  setPerPage = e => {
    this.setState({
      setPerPage: e.target.value,
      pageNo: 1
    }, () => { this.claculateNoOfPages(this.props); this.setFromAndTo(1) });
  };

  setSearch = e => {
    if (this.state.searchText === "") {
      this.setState({
        filteredArr: []
      });
    }
    this.setState(
      {
        searchText: e.target.value,
        loading: true
      },
      () => this.search()
    );
  };

  search = _.debounce(e => {
    if (this.state.searchText !== "") {
      this.setState({
        loading: false
      });
      this.filterArr();
    } else {
      this.setState({
        loading: false,
        filteredArr: []
      });
    }
  }, 1000);

  filterArr = () => {
    let { searchText } = this.state;
    let { storeSales } = this.props.sales.data;
    let filteredArr = [];
    for (const obj of storeSales) {
      if (
        searchText === obj.ord.substring(0, searchText.length).toLowerCase() ||
        searchText === obj.branch.locationName.substring(0, searchText.length).toLowerCase() ||
        searchText === obj.store.companyName.substring(0, searchText.length).toLowerCase()
      ) {
        filteredArr.push(obj);
      }
    }
    this.setState({
      filteredArr
    }, () => this.claculateNoOfPagesForFilterArray(filteredArr));
  };

  clearSearch = () => {
    this.setState({
      searchText: ""
    });
  };

  //pagination
  handleInput = e => {
    let { value } = e.target;
    if (value !== '') {
      if (e.target.value <= this.state.noOfPages) {
        this.setState(
          {
            pageNo: e.target.value,
          }, () => this.setFromAndTo(this.state.pageNo)
        );
      } else {
        toast.error(
          `Page number ${e.target.value} doesn't exist and please refresh`
        );
      }
    }
    else {
      this.setState({ pageNo: value })
    }
  };

  handleArrow = name => {
    let { pageNo, noOfPages } = this.state;
    if (name === "leftArrow" && pageNo > 1) {
      this.setState(
        {
          pageNo: pageNo - 1,
        }, () => this.setFromAndTo(this.state.pageNo)
      );
    } else if (name === "rightArrow" && pageNo < noOfPages) {
      this.setState(
        {
          pageNo: pageNo + 1,
        }, () => this.setFromAndTo(this.state.pageNo)
      );
    }
  };

  claculateNoOfPagesForFilterArray = filteredArr => {
    let perPage = this.state.setPerPage
    let length = filteredArr.length;
    let noOfPages = 0;
    if (length < perPage) {
      noOfPages = 1;
    } else {
      noOfPages = Math.ceil(length / perPage);
    }
    this.setState({
      noOfPages
    });
  }

  claculateNoOfPages = newProps => {
    let { storeSales } = newProps.sales.data;
    let perPage = this.state.setPerPage
    let length = storeSales.length;
    let noOfPages = 0;
    if (length < perPage) {
      noOfPages = 1;
    } else {
      noOfPages = Math.ceil(length / perPage);
    }
    this.setState({
      noOfPages
    });
  };

  render() {
    let { selectedOrder } = this.state;
    let { storeSales } = this.props.sales.data;
    return (
      <div className="content-wrapper">
        <Header heading="ORDERS" />
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group m-0 mx-2 float-left">
                      <div className="col-xs-12">
                        <input
                          placeholder="Search ..."
                          type="text"
                          className="form-control rounded"
                          onChange={this.setSearch}
                          // onKeyUp={(e) => this.getData(e)}
                          value={this.state.searchText}
                        />
                      </div>
                    </div>
                    <div className="mx-2 float-left">
                      {this.state.searchText !== "" ? (
                        <button
                          className="btn bg-danger font-weight-bold text-white"
                          type="button"
                          onClick={this.clearSearch}
                        >
                          <i className="mdi mdi-close mr-1"></i>Clear Search
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-right">
                        <select
                          className="form-control"
                          id="type"
                          name="type"
                          placeholder="Select Type"
                          style={{
                            height: "46px",
                            width: "150px",
                            marginTop: "2px"
                          }}
                          onChange={this.setPerPage}
                        >
                          <option disabled="" value={10}>
                            10
                          </option>
                          <option disabled="" value={20}>
                            20
                          </option>
                          <option disabled="" value={30}>
                            30
                          </option>
                          <option disabled="" value={50}>
                            50
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-2">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      {storeSales.length !== 0 ? (
                        <>
                          <table className="table table-hover min-w-700 table_heading_view">
                            <thead>
                              <tr className="heading_mine">
                                <th>#</th>
                                <th>Date</th>
                                <th>Company Name</th>
                                <th>Branch</th>
                                <th>Options</th>
                              </tr>
                            </thead>
                            <tbody>{this.renderOrders()}</tbody>
                          </table>

                          {/* pagination */}
                          <div className="col-12">
                            <nav>
                              <ul className="pagination flex-wrap pagination-flat pagination-primary justify-content-center pt-3">
                                <li
                                  className={this.state.pageNo === 1 ? "page-item page-link not-actives" : "page-item page-link actives"}
                                  name="leftArrow"
                                  onClick={() => this.handleArrow("leftArrow")}
                                >
                                  <i className="mdi mdi-chevron-left"></i>
                                </li>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                  <h5>&nbsp;Page&nbsp;</h5>
                                  <input
                                    type="text"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      display: "flex",
                                      textAlign: "center",
                                      borderRadius: "50%",
                                      border: '1px solid #aee2e3',
                                      marginTop: '-7px'
                                    }}
                                    onChange={this.handleInput}
                                    name="pageInput"
                                    value={this.state.pageNo}
                                  />
                                  <h5>&nbsp;of&nbsp;</h5>
                                  <h5>{this.state.noOfPages}</h5>
                                </div>
                                <li
                                  className={this.state.pageNo === this.state.noOfPages ? "page-item page-link not-actives" : "page-item page-link actives"}
                                  name="leftArrow"
                                  onClick={() => this.handleArrow("rightArrow")}
                                >
                                  <i className="mdi mdi-chevron-right"></i>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </>
                      ) : (
                          <h2
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            No Orders Found!
                          </h2>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewOrderDetails orders={selectedOrder} />
        <Loader loading={this.state.loading || this.props.loading || this.props.productLoading} />
      </div>
    );
  }
  renderOrders = () => {
    let { storeSales } = this.props.sales.data;
    let { from, setPerPage, searchText, filteredArr } = this.state;
    let count = 0;
    if (searchText.length === 0) {
      return storeSales.map((sale, index) => {
        count++;
        if (index >= from && count <= (parseInt(from) + parseInt(setPerPage))) {
          let date = moment(sale.createdAt).format("Do, MMM, YYYY");
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{date}</td>
              <td>{sale.store.companyName}</td>
              <td className="text-capitalize">{`${sale.branch.locationName} - ${sale.branch.locationCode}`}</td>
              <td
                className="d-flex justify-content-start"
                style={{
                  padding: sale.status ? "8px 8px 8px 20px" : "8px 8px 8px 28px"
                }}
              >
                {sale.status ? (
                  <button
                    type="button"
                    className="btn btn-dark btn-sm ml-2"
                    onClick={() => {
                      this.props.history.push('/store/deliveryNote', { previousDeliveryNote: sale.deliveryNote, branch: sale.branch.locationName, orderId: sale.ord, deliveryDate: date, store: this.state.store })
                      this.setState({
                        deliveryDate: date,
                        selectedOrderId: sale.ord,
                        selectedBranch: sale.branch.locationName,
                        previousDeliveryNote: sale.deliveryNote
                      });
                    }}
                  >
                    <i className="mdi mdi-printer btn-icon-prepend"> </i>
                    <span>Delivery Note</span>
                  </button>
                ) : (
                    <button
                      type="button"
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        this.props.history.push("/store/assignProducts", {
                          orders: sale.repeat,
                          repeat: false,
                          edit: true,
                          currentStore: sale.store,
                          deleteId: sale.ord
                        });
                      }}
                      style={{ padding: "8px 37px" }}
                    >
                      <i className="mdi mdi-eye btn-icon-prepend"> </i>
                      Edit
                    </button>
                  )}
                <button
                  type="button"
                  className="btn btn-primary btn-sm ml-2"
                  data-toggle="modal"
                  data-target="#viewOrder"
                  onClick={() => {
                    this.setState({ selectedOrder: sale.sales });
                  }}
                >
                  <i className="mdi mdi-eye btn-icon-prepend"> </i>
                  <span>View Order</span>
                </button>
                <button
                  type="button"
                  className="btn btn-dark btn-sm ml-2"
                  onClick={() => {
                    this.props.history.push("/store/assignProducts", {
                      orders: sale.repeat,
                      repeat: true,
                      currentStore: sale.store,
                      edit: false
                    });
                  }}
                >
                  <i className="mdi mdi-repeat btn-icon-prepend"> </i>
                  <span>Repeat</span>
                </button>
                {sale.status ? <Fragment>
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2"
                    data-toggle="modal"
                    data-target="#printLabelModal"
                    onClick={() => {
                      this.handlePrintLabels(sale.ord)
                    }}
                  >
                    <i className="mdi mdi-printer btn-icon-prepend"> </i>
                    <span>65 Labels</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2"
                    data-toggle="modal"
                    data-target="#printLabelModal"
                    onClick={() => {
                      this.props.print21Labels(sale.ord)
                    }}
                  >
                    <i className="mdi mdi-printer btn-icon-prepend"> </i>
                    <span>21 Labels</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm ml-2"
                    onClick={() => {
                      this.createXls(sale)
                    }}
                  >
                    <i className="mdi mdi-download btn-icon-prepend">  </i>
                  </button>
                </Fragment> : (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm ml-2"
                      onClick={() => {
                        this.props.orderFinalize(sale.ord);
                      }}
                    >
                      <i className="mdi mdi-clock btn-icon-prepend"> </i>
                      <span>Complete</span>
                    </button>
                  )}

              </td>
            </tr>
          );
        }
      });
    } else if (filteredArr.length !== 0) {
      return filteredArr.map((sale, index) => {
        count++;
        if (index >= from && count <= from + setPerPage) {
          let date = moment(sale.createdAt).format("Do, MMM, YYYY");
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{date}</td>
              <td>{sale.store.companyName}</td>
              <td className="text-capitalize">{`${sale.branch.locationName} - ${sale.branch.locationCode}`}</td>
              <td
                className="d-flex justify-content-start"
                style={{
                  padding: sale.status ? "8px 8px 8px 20px" : "8px 8px 8px 28px"
                }}
              >
                {sale.status ? (
                  <button
                    type="button"
                    className="btn btn-dark btn-sm ml-2"
                    onClick={() => {
                      this.props.history.push('/store/deliveryNote', { previousDeliveryNote: sale.deliveryNote, branch: sale.branch.locationName, orderId: sale.ord, deliveryDate: date, store: this.state.store })
                      this.setState({
                        deliveryDate: date,
                        selectedOrderId: sale.ord,
                        selectedBranch: sale.branch.locationName,
                        previousDeliveryNote: sale.deliveryNote
                      });
                    }}
                  >
                    <i className="mdi mdi-printer btn-icon-prepend"> </i>
                    <span>Delivery Note</span>
                  </button>
                ) : (
                    <button
                      type="button"
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        this.props.history.push("/store/assignProducts", {
                          orders: sale.repeat,
                          repeat: false,
                          edit: true,
                          currentStore: sale.store,
                          deleteId: sale.ord
                        });
                      }}
                      style={{ padding: "8px 37px" }}
                    >
                      <i className="mdi mdi-eye btn-icon-prepend"> </i>
                      Edit
                    </button>
                  )}
                <button
                  type="button"
                  className="btn btn-primary btn-sm ml-2"
                  data-toggle="modal"
                  data-target="#viewOrder"
                  onClick={() => {
                    this.setState({ selectedOrder: sale.sales });
                  }}
                >
                  <i className="mdi mdi-eye btn-icon-prepend"> </i>
                  <span>View Order</span>
                </button>
                <button
                  type="button"
                  className="btn btn-dark btn-sm ml-2"
                  onClick={() => {
                    this.props.history.push("/store/assignProducts", {
                      orders: sale.repeat,
                      repeat: true,
                      currentStore: sale.store,
                      edit: false
                    });
                  }}
                >
                  <i className="mdi mdi-repeat btn-icon-prepend"> </i>
                  <span>Repeat</span>
                </button>
                {sale.status ? <Fragment>
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2"
                    data-toggle="modal"
                    data-target="#printLabelModal"
                    onClick={() => {
                      this.handlePrintLabels(sale.ord)
                    }}
                  >
                    <i className="mdi mdi-printer btn-icon-prepend"> </i>
                    <span>65 Labels</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2"
                    data-toggle="modal"
                    data-target="#printLabelModal"
                    onClick={() => {
                      this.props.print21Labels(sale.ord)
                    }}
                  >
                    <i className="mdi mdi-printer btn-icon-prepend"> </i>
                    <span>21 Labels</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm ml-2"
                    onClick={() => {
                      this.createXls(sale)
                    }}
                  >
                    <i className="mdi mdi-download btn-icon-prepend">  </i>
                  </button>
                </Fragment> : (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm ml-2"
                      onClick={() => {
                        this.props.orderFinalize(sale.ord);
                      }}
                    >
                      <i className="mdi mdi-clock btn-icon-prepend"> </i>
                      <span>Complete</span>
                    </button>
                  )}
              </td>
            </tr>
          );
        }
      });
    }
  };
  //printing product labels
  handlePrintLabels = (ord) => {
    this.props.printLabels(ord, false);
  }
  createXls = (sale) => {
    // let arrayOfColorVariations = [];
    // let newArrayOfColorVariations = []
    // let header = ["Style Code", "Color", "Type of Product", "RRP", "Our Price", "Quantity"];
    // for (const order of sale.sales) {
    //   for (const color of order.colorVariations) {
    //     let singleRRP = +order.rrp * +color.price;
    //     singleRRP % 5 === 0 ? (singleRRP = singleRRP - 0.01) : (
    //       singleRRP = (Math.ceil(singleRRP / 5) * 5) - 0.01
    //     )

    //     let variationCode;
    //     let description;
    //     for (const iterator of order.product.colorVariations) {
    //       if (iterator.color === color._id) {
    //         variationCode = iterator.name;
    //         description = iterator.description
    //       }
    //     }
    //     arrayOfColorVariations.push({
    //       variationCode,
    //       color: color.color.name,
    //       qty: color.qty,
    //       price: order.product.unitPrice,
    //       rrp: singleRRP,
    //       type: order.product.type
    //     })
    //   }
    // }
    // for (const print of arrayOfColorVariations) {
    //   let obj = {
    //     "Style Code": print.variationCode,
    //     "Color": print.color,
    //     "Type of Product": print.type,
    //     "RRP": print.rrp,
    //     "Our Price": print.price,
    //     "Quantity": print.qty
    //   };
    //   newArrayOfColorVariations.push(obj);
    // }
    // this.props.createXls({
    //   data: newArrayOfColorVariations,
    //   headerInfo: header
    // });
    this.props.printLabels(sale.ord, true);
  }
  toggleLoader = () => {
    this.setState({ loading: this.state.loading });
  };
  componentDidMount() {
    //fetching sales orders now
    this.props.fetchSalesNow(this.state.store._id);
    // this.claculateNoOfPages();Will
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.responseFinalize !== this.props.responseFinalize &&
      newProps.responseFinalize !== undefined
    ) {
      let { data } = newProps.responseFinalize;
      if (data.response) {
        toast.success(data.msg);
        this.props.fetchSalesNow(this.state.store._id);
      } else {
        toast.error(data.msg);
      }
    }
    if (newProps.productLabels !== undefined && newProps.productLabels !== this.props.productLabels) {
      if (newProps.productLabels.data.response) {
        window.open(DOCUMENTS_URL + newProps.productLabels.data.filename, '_blank')
        // this.setState({
        //   currentPrintLabels: newProps.productLabels.data.printLabels,
        //   currentFileName: newProps.productLabels.data.filename
        // })

      }
    }

    if (newProps.productLabels21 !== undefined && newProps.productLabels21 !== this.props.productLabels21) {

      if (newProps.productLabels21.data.response) {
        window.open(DOCUMENTS_URL + newProps.productLabels21.data.filename, '_blank')
        // this.setState({
        //   currentPrintLabels: newProps.productLabels.data.printLabels,
        //   currentFileName: newProps.productLabels.data.filename
        // })

      }
    }
    if (this.props.responseXls !== newProps.responseXls && newProps.responseXls !== undefined) {
      let { data } = newProps.responseXls;
      if (data.response) {
          toast.success(data.msg);
          window.open(DOCUMENTS_URL + data.filename, '_blank');
      }
      else {
          toast.error(data.msg)
      }
  }

    this.claculateNoOfPages(newProps);
  }
}

const mapDispatchToProps = dispatch => ({
  fetchSalesNow: body => dispatch(storeFetchAllSales(body)),
  orderFinalize: ord => dispatch(storeOrderFinalize(ord)),
  printLabels: (ord, xlsx) => dispatch(storePrintLabel(ord, xlsx)),
  print21Labels: ord => dispatch(storePrintLabel21(ord)),
  createXls: body => dispatch(printXlsLabels(body))
});

const mapStateToProps = () => state => ({
  sales: state.Store.storeAllSalesResponse,
  loading: state.Store.loader,
  productLoading: state.Product.loader,
  responseFinalize: state.Store.storeOrderFinalize,
  productLabels: state.Store.storeProductLabels,
  productLabels21: state.Store.store21ProductLabels,
  responseXls: state.Product.productStickerXlsResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
