import React from 'react'

export default function AssignProductConfirmationModal(props) {
    return (
        <div className="modal fade" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Please verify the color variations you want to add and enter the price for the product.</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="unitprice">Price</label>
                                <input
                                    name="unitPrice"
                                    type="number"
                                    placeholder="Enter Price"
                                    className="form-control"
                                    id="unitprice"
                                    min={0}
                                    onChange={(e)=>{props.handleChangePrice(e.target.value)}}
                                    value={props.productToSend.price === undefined || props.productToSend.price === null ?(''):(props.productToSend.price)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss={props.checkPrice()?("modal"):("")} onClick={() => { props.assignProduct() }}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
