import React from 'react'
//redux imports
import { Provider } from 'react-redux'
import store from './store'

//screen imports
import Main from './components/Main'
import Login from './components/Login'
import NotFound from './components/NotFound'
import { ToastContainer } from 'react-toastify'

//navigation imports
import { BrowserRouter, Route, Switch } from 'react-router-dom'

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>.
          <Route path='/' render={(props)=>{
            return localStorage.getItem('access_token') !== null ? <Main {...props}/> : <Login {...props}/>
          }} />
          {/* <Route path='/' component={Login}/> */}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
      <ToastContainer/>
    </Provider>
  )
}
