import React, { Component } from 'react'
import HeaderExportProduct from '../ExportProduct/HeaderExportProduct'
import { toast } from 'react-toastify';
import DateModal from './DateModal'

//redux imports 
import {connect} from 'react-redux'
import {printFourSticker} from '../../../actions/productActions'
import Loader from '../../../inc/Loader';


class BarcodePrint extends Component {
    constructor() {
        super();
        this.state = {
            printBy: ''
        }
    }
    handleChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    validate = () => {
        let result = false;
        let { printBy } = this.state;
        if (printBy === '') {
            toast.error("Please select At least one Option to Continue!!");
        }
        else {
            result = true;
        }
        return result
    }
    handleSubmit = () => {
        let { printBy } = this.state;
        let validated = this.validate();
        if (validated) {
            // this.props.history.push('/product/imported', { barcodePrint: true, singleFile: printBy === 'file' ? (false) : (true) })
            if(printBy==='product'){
                this.props.history.push('/product/barcode_products')
                //testing pdf
                // this.props.printFourSticker()
            }
            else{
                this.props.history.push('/product/imported', { barcodePrint: true, singleFile: false })
            }
        }

    }
    render() {
        let { printBy } = this.state;
        return (
            <div className="content-wrapper">
                <HeaderExportProduct heading="print barcodes" button={false} />
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <form className="forms-sample">

                                    <div className="form-group">
                                        {/* <label htmlFor="country">PRINT BY</label> */}
                                        <select
                                            className={`form-control`}
                                            style={{ height: "46px", marginTop: "2px" }}
                                            onChange={(e) => { this.handleChange(e) }}
                                            name="printBy"
                                        >
                                            <option disabled selected value="">
                                                SELECT PRINT BY
                                            </option>
                                            <option value="file">
                                                Print by File
                                            </option>
                                            <option value="product">
                                                Print by Selecting Products
                                            </option>
                                            <option value="date">
                                                Print by Date
                                            </option>
                                        </select>
                                        <span className="error-msg">
                                        </span>

                                    </div>
                                    {
                                        printBy === 'date' ? (
                                            <button
                                                type="button"
                                                data-toggle="modal"
                                                data-target="#dateModal"
                                                className="btn btn-primary mr-2"
                                            >
                                                Submit
                                            </button>
                                        ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mr-2"
                                                    onClick={() => { this.handleSubmit() }}
                                                >
                                                    Submit
                                                </button>
                                            )
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <DateModal />
                <Loader loading={this.props.loading}/>
            </div>
        )
    }
}


const mapDispatchToProps=dispatch=>({
    printFourSticker: ()=>dispatch(printFourSticker())
})

const mapStateToProps = () => state => ({
    productStickerData: state.Product.productStickerData,
    loading: state.Product.loader
  });


export default connect(mapStateToProps, mapDispatchToProps)(BarcodePrint)