import { DASHBOARD_ADMIN_DATA, DASHBOARD_ADMIN_SALES } from './types';

import Helper from '../helper/Helper';

export const dashboardAdminFetchData = () => ({
    type: DASHBOARD_ADMIN_DATA,
    payload: Helper('member/dashboardDetails', 'GET', null)
})
export const dashboardAdminSales = () => ({
    type: DASHBOARD_ADMIN_SALES,
    payload: Helper('store/product/getStoreSaleStats', 'GET', null)
})