import React, { Component } from "react";
import Auto from "./AutoSuggest";
import { types } from "../../../Constants/listTypes";
import Loader from "../../../inc/Loader";
import { toast } from "react-toastify";

//redux imports
import { connect } from "react-redux";
import { fetchAllSupplier } from "../../../actions/supplierActions";
import { exhibitionFetchAll } from "../../../actions/exhibitionActions";
import {
  productExportJson,
  productExportReset,
  productExportFile
} from "../../../actions/productActions";
import { DOCUMENTS_URL } from "../../../Config";

class FiltersExportProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier: {},
      exhibition: {},
      type: "",
      isAllColors: false,
      fromDate: "",
      toDate: ""
    };
  }
  autoValueExhibition = value => {
    if (value._id !== "") {
      this.setState(
        {
          exhibition: value
        },
        () => {
          this.props.setParentState(value, "exhibition");
        }
      );
    }
  };
  autoValueSupplier = value => {
    if (value._id !== "") {
      this.setState(
        {
          supplier: value
        },
        () => {
          this.props.setParentState(value, "supplier");
        }
      );
    }
  };
  handleChange = e => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.setParentState(value, name);
      }
    );
  };
  fetchData = () => {
    let validated = this.validate();
    if (validated) {
      let body = this.createBody();
      delete body["isAllColors"];
      this.props.productExportJson(body);
    } else {
      toast.error("Please enter date from and to");
    }
  };
  createBody = () => {
    let {
      type,
      supplier,
      exhibition,
      isAllColors,
      fromDate,
      toDate
    } = this.state;

    let body = {
      isAllColors,
      fromDate: fromDate,
      toDate: toDate
    };

    if (Object.keys(supplier).length !== 0) {
      body["supplier"] = supplier._id;
    }
    if (Object.keys(exhibition).length !== 0) {
      body["exhibition"] = exhibition._id;
    }
    if (type !== "") {
      body["type"] = type;
    }
    return body;
  };
  validate = () => {
    // let { fromDate, toDate } = this.state;
    let validated = true;
    // if (fromDate === '' || toDate === '') {
    //     validated = false;
    // }
    return validated;
  };
  resetFields = () => {
    this.setState(
      {
        supplier: {},
        exhibition: {},
        type: "",
        isAllColors: false,
        fromDate: "",
        toDate: ""
      },
      () => {
        this.refs["supplierref"].resetField();
        this.refs["exhibitionref"].resetField();
        this.props.productExportReset();
      }
    );
  };
  downloadFile = type => {
    let validated = this.validate();
    if (validated) {
      var includeAllColors = window.confirm(
        "Do you want to include all color vairations."
      );
      this.setState(
        {
          isAllColors: includeAllColors
        },
        () => {
          let body = this.createBody();
          this.props.productExportFile(body, type);
        }
      );
    } else {
      toast.error("Please enter date from and to");
    }
  };
  render() {
    let { loading, loadingExhibitions, loadingSuppliers } = this.props;
    return (
      <div className="row align-items-center">
        <div className="col-md-3 mb-4">
          <div className="form-group mb-0">
            <label htmlFor="supplier">Supplier</label>
            <Auto
              data={this.props.allSuppliers.data.suppliers}
              how={["fullName", "code"]}
              selectedValue={this.autoValueSupplier}
              what="Brand"
              ref="supplierref"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="form-group mb-0">
            <label htmlFor="exhibition">Exhibition</label>
            <Auto
              data={this.props.exhibitionResponse.data.exhibitions}
              how={["name", "season"]}
              selectedValue={this.autoValueExhibition}
              what="Exhibition"
              ref="exhibitionref"
            />
          </div>
        </div>
        <div className="col-md-2 mb-4">
          <div className="form-group mb-0">
            <label htmlFor="type">Type/Category</label>
            <select
              className="form-control"
              id="type"
              name="type"
              ref="type"
              placeholder="Select Type"
              value={this.state.type}
              style={{ height: "46px", marginTop: "2px" }}
              onChange={event => this.handleChange(event)}
            >
              <option value="" style={{ color: "#e8e8e8" }}>
                Select Type
              </option>
              {types.map((v, i) => {
                return (
                  <option key={i} value={v.type}>
                    {v.type} - {v.category}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-md-2 mb-4">
          <div className="form-gorup mb-0">
            <label htmlFor="from">From</label>
            <input
              id="from"
              name="fromDate"
              max={this.state.toDate}
              style={{ height: "48px", paddingLeft: 10 }}
              className="form-control"
              type="date"
              value={this.state.fromDate}
              onChange={event => {
                this.handleChange(event);
              }}
            />
          </div>
        </div>
        <div className="col-md-2 mb-4">
          <div className="form-gorup mb-0">
            <label htmlFor="to">To</label>
            <input
              id="to"
              name="toDate"
              min={this.state.fromDate}
              style={{ height: "48px", paddingLeft: 10 }}
              className="form-control"
              type="date"
              value={this.state.toDate}
              onChange={event => {
                this.handleChange(event);
              }}
            />
          </div>
        </div>
        {/* <div className="col-md-12 text-center">
                            <div className="form-group mb-1">
                                <input
                                    type='checkbox'
                                    className='ios8-switch ios8-switch-lg'
                                    id='checkbox-3'
                                    checked={this.state.isAllColors}
                                    onChange={() => { this.setState({ isAllColors: !this.state.isAllColors }) }}
                                />
                                <label htmlFor='checkbox-3'>All Colors</label>
                            </div>
                        </div> */}
        <div className="col-md-12 text-right mb-4">
          <button
            type="button"
            className="btn btn-primary  btn-icon-text"
            onClick={() => {
              this.fetchData();
            }}
          >
            <i className="mdi mdi-filter btn-icon-prepend"></i>
            Filter
          </button>
          <button
            type="button"
            className="btn ml-2 btn-danger  btn-icon-text"
            onClick={() => {
              this.resetFields();
            }}
          >
            <i className="mdi mdi-refresh btn-icon-prepend"></i>
            Reset
          </button>
          <button
            className="btn btn-success  ml-2  dropdown-toggle"
            type="button"
            id="options"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="mdi mdi-download"></i>
          </button>
          <div className="dropdown-menu" aria-labelledby="options">
            <h6 className="dropdown-header">Download format</h6>
            <span
              className="dropdown-item cursor"
              onClick={() => {
                this.downloadFile("xlsx");
              }}
            >
              Excel
            </span>
          </div>
        </div>
        <Loader loading={loading || loadingSuppliers || loadingExhibitions} />
      </div>
    );
  }
  componentDidMount() {
    this.props.fetchSupplier();
    this.props.exhibitionFetch();
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.productFileDownload !== this.props.productFileDownload &&
      newProps.productFileDownload !== undefined
    ) {
      if (newProps.productFileDownload.data.response) {
        toast.success(newProps.productFileDownload.data.msg);
        window.open(
          DOCUMENTS_URL + newProps.productFileDownload.data.filename,
          "_blank"
        );
      } else {
        toast.error("No Products Found!");
      }
    }
  }
}

const mapStateToProps = () => state => ({
  allSuppliers: state.Supplier.totalAllSuppliers,
  exhibitionResponse: state.Exhibition.exhibitionAllFetchResponse,
  loading: state.Product.loader,
  products: state.Product.productExportJson,
  productFileDownload: state.Product.productExportFile,
  loadingSuppliers: state.Supplier.loader,
  loadingExhibitions: state.Exhibition.loader
});

const mapDispatchToProps = dispatch => ({
  fetchSupplier: _ => dispatch(fetchAllSupplier()),
  exhibitionFetch: _ => dispatch(exhibitionFetchAll()),
  productExportJson: body => dispatch(productExportJson(body)),
  productExportReset: () => dispatch(productExportReset()),
  productExportFile: (body, type) => dispatch(productExportFile(body, type))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersExportProduct);
