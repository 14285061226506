import React, { Component } from "react";
import { DOCUMENTS_URL } from "../../../Config";
import Loader from '../../../inc/Loader'
import moment from 'moment';
import { toast } from "react-toastify";

//redux improts
import { connect } from "react-redux";
import { productAllImportedData, printFourSticker } from "../../../actions/productActions";
import ViewErrors from "./ViewErrors";

class Product extends Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [], // array of objects of filename with quanitity
      errors: []
    }
  }
  generateBarcodes = () => {
    let { list } = this.state;
    if (this.validate() && this.validateQty()) {
      let body = {
        json: list,
        isFile: true
      }
      this.props.printSticker(body)
    }
  }

  validateQty = () => {
    let { list } = this.state;
    let valid = true;
    for (const iterator of list) {
      if (iterator.qty === '') {
        valid = false;
        toast.error("Please enter qty of file " + iterator.fileName + " !!");
        break;
      }
    }
    return valid
  }

  getQtyValue = (fileName) => {
    let { list } = this.state;
    let index = list.findIndex(i => i.fileName === fileName);
    if (index !== -1) {
      return list[index].qty
    }
    else {
      return ''
    }
  }

  validate = () => {
    let { list } = this.state;
    let valid = false;
    if (list.length === 0) {
      toast.error("Please select at least one File to continue!!")
    }
    else {
      valid = true;
    }
    return valid
  }
  handleCheckbox = (e, file) => {
    // let { checked } = e.target;
    let { list } = this.state;
    //checking whether the list is already having this checkbox or not by finding the index
    let index = list.findIndex(i => i.fileName === file.filename);
    if (index === -1) {
      //now the file doesn't exist in our list
      list.push({ fileName: file.filename, qty: '' });
    }
    else {
      //file already exist in our list remove it from our list
      list.splice(index, 1);
    }
    this.setState({ list });
  }
  handleQty = (qty, file) => {
    let { list } = this.state;
    let index = list.findIndex(i => i.fileName === file.filename);
    if (index === -1) {
      list.push({ fileName: file.filename, qty: qty });
    }
    else {
      if (qty !== '') {
        list[index].qty = qty;
      }
      else {
        list.splice(index, 1);
      }
    }
    this.setState({ list })
  }
  checkAll = (e) => {
    let { checked } = e.target;
    let list = [];
    if (checked) {
      let { productImportData } = this.props.productImportedData.data;

      for (const iterator of productImportData) {
        list.push({ fileName: iterator.filename, qty: '' })
      }
      this.setState({ list })
    }
    else {
      this.setState({
        list: []
      })
    }
  }
  checkChecked = (file) => {
    let { list } = this.state;
    if (list.findIndex(i => i.fileName === file.filename) === -1) {
      return false
    }
    else return true
  }
  render() {
    let { barcodePrint, singleFile } = this.props.history.location.state ? (this.props.history.location.state) : (false)
    return (
      <div className="content-wrapper">
        <div className="row align-items-center">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    VIEW IMPORTED PRODUCTS
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {
                  barcodePrint && !singleFile ? (
                    <div className="col-12 justify-content-end d-flex">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 mb-2"
                        onClick={() => { this.generateBarcodes() }}
                      >
                        Generate Barcodes
                  </button>
                    </div>
                  ) : (null)
                }
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700 table_heading_view">
                        <thead>
                          <tr className="heading_mine">
                            <th>#</th>
                            {
                              barcodePrint && !singleFile ? (
                                <th>Check
                                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2px' }}>
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        onClick={(e) => { this.checkAll(e) }}
                                        ref="checkAllCheckBox"
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </th>
                              ) : (null)
                            }
                            <th>File Name</th>
                            <th>Total Records</th>
                            <th>Date</th>
                            {
                              barcodePrint && !singleFile ? (
                                <th>Quantity</th>
                              ) : (barcodePrint && singleFile ? (
                                <th>Options</th>
                              ) : (<th>Options</th>))
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.productImportedData.data.productImportData.map(
                            (v, i) => {
                              return (
                                <tr key={i}>
                                  <td key={i}>{i + 1}</td>
                                  {
                                    barcodePrint && !singleFile ? (
                                      <td className="d-flex justify-content-center">
                                        <label className="checkbox">
                                          <input
                                            type="checkbox"
                                            onClick={(e) => { this.handleCheckbox(e, v) }}
                                            checked={this.checkChecked(v)}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </td>
                                    ) : (null)
                                  }
                                  <td>{v.filename}</td>
                                  <td>{v.recordsProcessed}</td>
                                  <td>{moment(v.createdAt).format('Do, MMM, YYYY, LT')}</td>

                                  {
                                    barcodePrint && !singleFile ? (
                                      <td>
                                        <input type="text" style={{ width: '50px' }} onChange={(e) => { this.handleQty(e.target.value, v) }} value={this.getQtyValue(v.filename)} />
                                      </td>
                                    ) : (
                                        barcodePrint && singleFile ? (
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => { this.props.history.push('barcode_products') }}
                                            >
                                              Select
                                            </button>
                                          </td>
                                        )
                                          : (
                                            <td>
                                              {
                                                v.processed ? (
                                                  v.importErrors.length === 0 ? (
                                                    <button
                                                      type="button"
                                                      className="btn btn-success"
                                                    >
                                                      Processed (0 Errors)
                                                    </button>
                                                  ) : (
                                                      <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        data-toggle="modal"
                                                        data-target={"#viewErrors"}
                                                        onClick={() => { this.setState({ errors: v.importErrors }) }}
                                                      >
                                                        Errors ({v.importErrors.length})
                                                      </button>
                                                    )
                                                ) : (
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                    >
                                                      Processing
                                                    </button>
                                                  )
                                              }
                                            </td>
                                          )
                                      )
                                  }
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {
                  barcodePrint && !singleFile ? (
                    <div className="col-12 justify-content-end d-flex">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 "
                        onClick={() => { this.generateBarcodes() }}
                      >
                        Generate Barcodes
                  </button>
                    </div>
                  ) : (null)
                }
              </div>
            </div>
          </div>
        </div>
        <ViewErrors errors={this.state.errors} />
        <Loader loading={this.props.loader} />
      </div>
    );
  }
  componentDidMount() {
    this.props.importedData();
  }
  componentWillReceiveProps(newProps) {
    if (newProps.productStickerData !== undefined && this.props.productStickerData !== newProps.productStickerData) {
      let { data } = newProps.productStickerData;
      if (data.response) {
        toast.success(data.msg);
        window.open(DOCUMENTS_URL + data.filename);
        this.setState({
          list: []
        })
        this.refs['checkAllCheckBox'].checked = false
      }
      else {
        toast.error(data.msg)
      }
    }
  }

}

const mapStateToProps = () => state => ({
  productImportedData: state.Product.productImportedData,
  loader: state.Product.loader,
  productStickerData: state.Product.productStickerData
});

const mapDispatchToProps = dispatch => ({
  importedData: () => dispatch(productAllImportedData()),
  printSticker: (body) => dispatch(printFourSticker(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
