import React, { Component } from 'react'
import './filterStyle.css'
import Loader from '../../../inc/Loader'
import {  toast } from 'react-toastify'
import { DOCUMENTS_URL } from '../../../Config'

//redux imports
import { connect } from 'react-redux'
import { productExportAll } from '../../../actions/productActions'

class FilterExportProduct extends Component {
    constructor() {
        super();
        this.state = {
            isAllColors: false 
        }
    }
    resetFields = () => {
        this.setState({
            isAllColors: false
        })
    }
    downloadFile = () => {
        let body = {
            isAllColors: this.state.isAllColors
        }
        this.props.productExportAll(body);
    }
    render() {
        return (
            <div className="row align-items-end">
                <div className="col-md-3 mb-4">
                    {/* <div className="form-group mb-0"> */}
                    <input
                        type='checkbox'
                        className='ios8-switch ios8-switch-lg'
                        id='checkbox-3'
                        checked={this.state.isAllColors}
                        onChange={() => { this.setState({ isAllColors: !this.state.isAllColors }) }}
                    />
                    <label for='checkbox-3'>Include All Colours</label>
                    {/* </div> */}
                </div>
                <div className="col-md-3 mb-4 text-center">
                    <button type="button" className="btn ml-2 btn-danger py-2 px-2 btn-icon-text" onClick={() => { this.resetFields() }}>
                        <i className="mdi mdi-refresh btn-icon-prepend"></i>
                        Reset
                                    </button>
                    <button className="btn btn-success  py-2 ml-2 px-2 dropdown-toggle" type="button" id="options"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="mdi mdi-download"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="options">
                        <h6 className="dropdown-header">Download Format</h6>
                        {/* <span className="dropdown-item cursor" href="#">CSV</span> */}
                        <span className="dropdown-item crusor" onClick={() => { this.downloadFile() }}>Excel</span>
                    </div>
                </div>
                <Loader loading={this.props.loading} />
            </div>
        )
    }
    componentWillReceiveProps(newProps) {
        if (newProps.productFileDownload !== this.props.productFileDownload && newProps.productFileDownload !== undefined) {
            if (newProps.productFileDownload.data.response) {
                toast.success(newProps.productFileDownload.data.msg);
                window.open(DOCUMENTS_URL + newProps.productFileDownload.data.filename, '_blank')
            }
            else {
                toast.error("No Products Found!")
            }
        }
    }
}

const mapStateToProps = () => state => ({
    productFileDownload: state.Product.productExportFile,
    loading: state.Product.loader,
})

const mapDispatchToProps = dispatch => ({
    productExportAll: (body) => dispatch(productExportAll(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterExportProduct)