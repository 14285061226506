import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../../App.css";
import countries from "../../../Constants/constants";
import { connect } from "react-redux";
import { addSupplier, fetchAllSupplier } from "../../../actions/supplierActions";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../inc/Loader";
import Helper from "../../../helper/Helper";

class AddSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      code: "",
      email: "",
      contactPersonName: "",
      address: "",
      country: "",
      phone: "",
      photo: "",
      photoName: "",
      formErrors: {
        name: "",
        code: "",
        email: ""
      },
      validMessages:{
        name: '',
        code:''
      },
      displayurl: "",
    };
  }

  handleInput = e => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      if (name !== "address") this.validateField(name, value);
    });
    if (name === "photo") {
      this.setState(
        {
          [name]: e.target.files[0],
          displayurl: URL.createObjectURL(e.target.files[0]),
          photoName: e.target.files[0].name
        }
      );
    }
  };

  handleBlur = e => {
    let name = e.target.id;
    let value = e.target.value.trim();
    if(value){
        let data = {};
        if (name === "name") {
            data = {
                fullName: value
            }
        } else if (name === "code") {
            data = {
                code: value
            }
        }
        // api call for checking supplier name and code already exist
        let fieldValidationErrors = this.state.formErrors;
        let validMessages = this.state.validMessages;
        Helper("supplier/count", "POST", data)
        .then(result => {
            if(result.data.count === 0){
                validMessages[name] = `That ${name} is valid.`
                this.setState({
                    validMessages
                })
            }else{
                fieldValidationErrors[name] = `That ${name} is taken. Try another.`
                validMessages[name] = ''
                this.setState({
                    formErrors: fieldValidationErrors,
                    validMessages
                })
            }
        })
        .catch(() => {
            toast.error("Check your internet connection.")
        });
    }   
  }

  validateField = (fieldName, value) => {
    if(fieldName === 'name' || fieldName === 'code' || fieldName === 'email'){
      let fieldValidationErrors = this.state.formErrors;
      let validMessages = this.state.validMessages;
      let field = value.trim() !== "";
      fieldValidationErrors[fieldName] = field ? "" : `${fieldName} can't be blank`;
      validMessages[fieldName] = '';
      if (fieldName === "email") {
        let isEmail = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors[fieldName] = isEmail ? "" : field ? `${fieldName} is invalid` : "";
      }
      this.setState({
        validMessages,
        formErrors: fieldValidationErrors
      });
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "invalid-field";
  }

  showMsg = (msg,isError) => {
    if(msg){
      if(isError){
        return(
          <div className="error-msg"><i className = "mdi mdi-close mdi-18px"/><span>{msg}</span></div>
        );
      }else{
        return(
          <div className="valid-msg"><i className = "mdi mdi-check mdi-18px"/><span>{msg}</span></div>
        );
      }
    }
  };

  isValid = () => {
    let fieldValidationErrors = this.state.formErrors;
    let fields = ["name","code"];
    let valid = true;
    fields.forEach((v, i) => {
      let field = this.state[`${v}`];
      if (field === "") {
        fieldValidationErrors[v] = `${v} can't be blank`;
        this.setState({ formErrors: fieldValidationErrors });
        valid = false;
      }
    });
    if(fieldValidationErrors['email']!==""){
      valid = false;
    }
    let duplicateCheck = ["name","code"];
    duplicateCheck.forEach((v,i)=>{
        let err = fieldValidationErrors[v]
        if(err){
            valid = false;
        }
    })
    return valid;
  };

  addSupplier = () => {
    let valid = this.isValid();
    if (valid) {
      let {
        name,
        code,
        email,
        contactPersonName,
        address,
        phone,
        country,
        photo
      } = this.state;
      let supplier = {
        fullName: name,
        code: code,
        email: email,
        contactPersonName: contactPersonName,
        address: address,
        phoneNumber: phone,
        country: country,
        businessCard: photo
      };
      this.props.addSupplier(supplier);
    }
  };

  updateResponse = res => {
    if (res.data.response) {
      toast.success(res.data.msg);
      this.setState({
        name: "",
        code: "",
        email: "",
        contactPersonName: "",
        address: "",
        country: "",
        phone: "",
        photo: "",
        photoName: "",
        formErrors: {
          name: "",
          code: "",
          email: ""
        },
        validMessages:{
            name: '',
            code:''
        },
        displayurl: ""
      });
      this.props.fetchSuppliers();
    } else {
      toast.error(res.data.msg);
    }
  };

  componentWillReceiveProps = newProps => {
    if (
      this.props.response !== newProps.response &&
      newProps.response !== undefined
    ) {
      this.updateResponse(newProps.response);
    }
  };

  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    Add Supplier
                  </h3>
                </div>
              </div>
            </div>
          </div>
          {
            this.props.product ? (null) : (<div className="col-sm-3">
              <div className="dropdown float-right">
                <Link to="/supplier" href="./suppliers.html">
                  <button
                    className="btn btn-sm bg-white font-weight-bold text-dark"
                    type="button"
                  >
                    <i className="mdi mdi-eye mr-1"></i>
                    All Suppliers
                </button>
                </Link>
              </div>
            </div>)
          }
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Supplier Name</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.name
                          )}`}
                          id="name"
                          ref="supplierName"
                          placeholder="Supplier Name"
                          required
                          value={this.state.name}
                          onChange={event => this.handleInput(event)}
                          onBlur={event => this.handleBlur(event)}
                        />
                        {this.showMsg(this.state.formErrors.name, true)}
                        {this.showMsg(this.state.validMessages.name, false)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="code">
                          Code (Unique Code ALPHANUMERIC)
                        </label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.code
                          )}`}
                          id="code"
                          value={this.state.code}
                          placeholder="Code (Unique Code ALPHANUMERIC)"
                          required
                          onChange={event => this.handleInput(event)}
                          onBlur={event => this.handleBlur(event)}
                        />
                        {this.showMsg(this.state.formErrors.code, true)}
                        {this.showMsg(this.state.validMessages.code, false)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">Supplier Email</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(
                            this.state.formErrors.email
                          )}`}
                          id="email"
                          placeholder="Supplier Email"
                          required
                          value={this.state.email}
                          onChange={event => this.handleInput(event)}
                        />
                        {this.showMsg(this.state.formErrors.email, true)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="contactPersonName">
                          Contact Person Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="contactPersonName"
                          value={this.state.contactPersonName}
                          placeholder="Contact Person Name"
                          required
                          onChange={event => this.handleInput(event)}
                          onBlur={event => this.handleBlur(event)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="address">Supplier Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          value={this.state.address}
                          placeholder="Supplier Address"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="country">Country</label>
                        <select
                          className="form-control"
                          id="country"
                          value={this.state.country}
                          style={{ height: "46px", marginTop: "2px" }}
                          onChange={event => this.handleInput(event)}
                          onBlur={event => this.handleBlur(event)}
                        >
                          <option disabled value="" selected>
                            Select Country
                          </option>
                          {countries.map((country, i) => {
                            return (
                              <option key={country.code} value={country.name}>
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          type="number"
                          className="form-control"
                          id="phone"
                          value={this.state.phone}
                          placeholder="Phone Number"
                          onChange={event => this.handleInput(event)}
                          onBlur={event => this.handleBlur(event)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Supplier Business Card Photo</label>
                    <div className="input-group col-xs-12">
                      <input
                        type="text"
                        className="form-control file-upload-info"
                        disabled
                        placeholder="Upload Image"
                        required
                        id="photo"
                        value={this.state.photoName}
                      />
                      <span className="input-group-append">
                        <label className="file-upload-browse btn btn-primary uploadButton">
                          <span style={{ color: "#fff" }}>Upload</span>
                          <input
                            id="photo"
                            type="file"
                            name="img[]"
                            className="file-upload-default"
                            onChange={event => this.handleInput(event)}
                          />
                        </label>
                      </span>
                    </div>
                  </div>
                  {this.state.displayurl !== "" ? (
                    <div className="form-group">
                      <div className="input-group col-xs-12">
                        <img
                          src={this.state.displayurl}
                          style={{ height: "100px", width: "auto" }}
                          alt="displayImage"
                        />
                      </div>
                    </div>
                  ) : null}
                  <button
                    type="button"
                    onClick={() => this.addSupplier()}
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  {
                    !this.props.product ? (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          this.props.history.push("/supplier")
                        }}
                      >
                        Cancel
                  </button>
                    ) : (
                        null
                      )
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={this.props.loading} />
        {
          this.props.product?(null):(<ToastContainer />)
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addSupplier: data => dispatch(addSupplier(data)),
    fetchSuppliers: _ => dispatch(fetchAllSupplier())
  };
};

const mapStateToProps = () => state => ({
  response: state.Supplier.supplierResponse,
  loading: state.Supplier.loader
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSupplier);
