import axios from "axios";
import { BASE_URL } from '../Config';

export default (url, method, body) => {

    let token = localStorage.getItem('access_token');
    var response
    response = axios({
        method: method,
        url: BASE_URL + url,
        data: body === null ? (null) : (body),
        headers: {
            'Authorization': "Bearer " + token,
        }
    })
        .catch((error) => {
            if (error.message === "Request failed with status code 401") {
                localStorage.removeItem('access_token');
                localStorage.removeItem('role');
                window.location.href = "/";
            }
        })
    return response
}

export const HelperFormData = (url, method, body) => {
    let token = localStorage.getItem('access_token');
    let data = new FormData();
    Object.keys(body).forEach(function (key) {
        if (body[key] && typeof (body[key]) === 'object') {
            if (body[key].type === 'image/jpeg' || body[key].type === 'image/jpg' || body[key].type === 'image/png' || body[key].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                data.append(key, body[key])
            }
            else {
                data.append(key, JSON.stringify(body[key]))
            }
        }
        else {
            data.append(key, body[key])
        }
    });

    var response
    response = axios({
        method: method,
        url: BASE_URL + url,
        data: data,
        headers: {
            'Authorization': "Bearer " + token,
        },
    })

    response.catch((error) => {
        if (error.message === "Request failed with status code 401") {
            localStorage.removeItem('access_token');
            localStorage.removeItem('role');
            window.location.href = "/";
        }
    })
    return response
}
