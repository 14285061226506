// Pagination issue when go to 2 page and select 20 from dropdown then nothing comes back on supplier page.

//react imports
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

//toast imports
import { toast } from 'react-toastify'
import Loader from '../../../inc/Loader'
import 'react-toastify/dist/ReactToastify.css'
import './addProdcut.css'
import ColorModal from '../../Color/Color.module.css'
import ViewColorModal from '../ViewColorModal/'
import { types, categories } from '../../../Constants/listTypes'
import Autosuggest from 'react-autosuggest'
import ViewSupplierModal from '../ViewSupplierModal'

//redux imports
import { connect } from 'react-redux'
import { productAdd, productFetchAddProductThings } from '../../../actions/productActions'
import Helper from '../../../helper/Helper'
// import { fetchAllSupplier } from '../../../actions/supplierActions'
// import { exhibitionFetchAll } from '../../../actions/exhibitionActions'
// import { colorFetch } from '../../../actions/colorActions'
// import { barcodeFetchUnused } from '../../../actions/barcodeActions'

let selectedBarcodeIndex = 0;

class AddProduct extends Component {

    //state
    constructor(props) {
        super(props);
        this.state = {
            brand: '',
            supplier: '',
            color: {},
            unitPrice: '',
            mainCode: '',
            type: '',
            category: '',
            code: '',
            itemType: '',
            exhibition: '',
            productImage: '',
            colorsResponse: this.props.colorResponse.data.colors.slice(),
            selectedColors: [],
            displayurl: '',
            supplierCodeDisplay: '',
            costPrice: '',
            barcodes: [],
            description: '',

            //supplier suggestions
            value: '',
            suggestions: [],

            //exhibition suggestions
            exhibitionValue: '',
            exhibitionSuggestions: [],

            //color suggestiosn
            colorValue: '',
            colorSuggestions: [],
            tempColor: {},

            //temp stock level and pack size
            stockLevelForAllColorVariations: '',
            packSizeForAllColorVariations: '',

            //is loading 
            isDeletingColor: false,
            isAddingColor: false
        }
    }

    //color auto suggestions started
    getSuggestionsColor = colorValue => {
        let colors = this.state.colorsResponse
        const inputValue = colorValue.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : colors.filter(color =>
            color.name.toLowerCase().slice(0, inputLength) === inputValue || color.colorCode.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValueColor = color => {
        this.setState({
            tempColor: color
        })
        this.colorInput.style.borderStyle = 'solid';
        this.colorInput.style.borderWidth = '1px';
        this.colorInput.style.borderColor = '#e9e9e9';

        if (color._id === '' || color._id === undefined) {
            document.getElementById('openColorModal').click();
            return ''
        }
        else {
            return color.name.toUpperCase() + ' - ' + color.colorCode.toUpperCase()
        }
    };

    renderSuggestionColor = color => (
        <div className="checkSuggestion">
            <span className="text-uppercase">{color.name} - {color.colorCode}</span>
        </div>
    );

    onChangeColorSuggestion = (event, { newValue }) => {
        this.setState({
            colorValue: newValue
        });
    };

    onColorsFetchRequested = ({ value }) => {
        let suggestions = this.getSuggestionsColor(value)
        if (suggestions.length === 0) {
            suggestions.push({ '_id': '', 'name': 'NO COLORS FOUND', 'colorCode': 'TRY AGAIN!' });
        }
        this.setState({
            colorSuggestions: suggestions
        });
    };
    onColorClearRequested = () => {
        this.setState({
            colorSuggestions: []
        });
    };

    colorInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.colorInput = autosuggest.input;
        }
    };

    //color auto suggestions ended


    //exhibtion auto suggestions started
    getSuggestionsExhibition = exhibitionValue => {
        let { exhibitions } = this.props.exhibitionResponse.data
        const inputValue = exhibitionValue.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : exhibitions.filter(exhibition =>
            exhibition.name.toLowerCase().slice(0, inputLength) === inputValue || exhibition.season.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValueExhibition = exhibition => {
        this.setState({
            exhibition: exhibition._id,
        }, () => {
            this.exhibitionInput.style.borderStyle = 'solid';
            this.exhibitionInput.style.borderWidth = '1px';
            this.exhibitionInput.style.borderColor = '#e9e9e9';
        })
        if (exhibition._id === '') {
            return ''
        }
        else {
            return (
                exhibition.name.toUpperCase() + ' - ' + exhibition.season.toUpperCase()
            )
        }
    };

    renderSuggestionExhibition = exhibition => (
        <div className="checkSuggestion">
            <span className="text-uppercase">{exhibition.name + ' - ' + exhibition.season}</span>
        </div>
    );

    onChangeExhibitionSuggestion = (event, { newValue }) => {
        this.setState({
            exhibitionValue: newValue
        });
    };

    onExhibitionsFetchRequested = ({ value }) => {
        let suggestions = this.getSuggestionsExhibition(value)
        if (suggestions.length === 0) {
            suggestions.push({ '_id': '', 'name': 'NO EXHIBITIONS FOUND', 'season': 'TRY AGAIN!' });
        }
        this.setState({
            exhibitionSuggestions: suggestions
        });
    };
    onExhibitionClearRequested = () => {
        this.setState({
            exhibitionSuggestions: []
        });
    };

    exhibitionInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.exhibitionInput = autosuggest.input;
        }
    };

    //exhibtion auto suggestions ended

    //supplier auto suggestions
    getSuggestions = value => {
        let { suppliers } = this.props.allSuppliers.data
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : suppliers.filter(supplier =>
            supplier.fullName.toLowerCase().slice(0, inputLength) === inputValue || supplier.code.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValue = suggestion => {

        this.setState({
            supplier: suggestion._id,
        }, () => {
            this.setBrand();
            this.generateMainCode()
            this.supplierInput.style.borderStyle = 'solid';
            this.supplierInput.style.borderWidth = '1px';
            this.supplierInput.style.borderColor = '#e9e9e9';
        })
        if (suggestion._id === '') {
            document.getElementById("openSupplierModal").click();
            return ''
        }
        else {
            return (
                suggestion.fullName.toUpperCase() + ' - ' + suggestion.code.toUpperCase()
            )
        }
    };

    renderSuggestion = suggestion => (
        <div className="checkSuggestion">
            <span className="text-uppercase">{suggestion.fullName} - {suggestion.code}</span>
        </div>
    );

    onChangeSupplierSuggestion = (event, { newValue }) => {
        if (newValue === '') {
            this.setState({
                supplier: '',
                value: newValue
            })
        }
        else {
            this.setState({
                value: newValue
            });
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        let suggestions = this.getSuggestions(value)
        if (suggestions.length === 0) {
            suggestions.push({ '_id': '', 'fullName': 'NO BRANDS FOUND', 'code': 'TRY AGAIN!' });
        }
        this.setState({
            suggestions: suggestions
        });
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    storeInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.supplierInput = autosuggest.input;
        }
    };

    //validating the states on user click submit button checking if any of the requied value in empty than notifying the user
    //if all values are ok than saving it to api in last else part this.saveToDatabase() is called
    validate = () => {
        let { brand, supplier, color, category, unitPrice, mainCode, type, code, itemType, exhibition, productImage } = this.state;
        if (type === '') {
            this.showErrorToast('type')
        }
        else if (supplier === '') {
            this.showErrorToast('supplier')
        }
        else if (unitPrice === '') {
            this.showErrorToast('unitPrice')
        }
        else if (code === '') {
            this.showErrorToast('code')
        }
        else if (itemType === '') {
            this.showErrorToast('itemType')
        }
        else if (exhibition === '') {
            this.showErrorToast('exhibition')
        }
        else if (category === '') {
            this.showErrorToast('category')
        }
        else if (mainCode === '') {
            this.showErrorToast('mainCode')
        }
        else if (brand === '') {
            this.showErrorToast('brand')
        }
        else if (Object.keys(color).length === 0) {
            this.showErrorToast('color')
        } else if (productImage === '') {
            this.showErrorToast('image')
        }
        else if (this.checkColor()[0]) {
            this.showErrorToast('colordetails', this.checkColor()[1], this.checkColor()[2], this.checkColor()[3])
        }
        else {
            this.saveToDataBase()
        }
    }

    checkColor = () => {
        let { color } = this.state;
        let result = true;
        let name = '';
        let colorName = ''
        let refName = ''

        for (var key in color) {
            if (color[key]['barcode'] === '' || color[key]['packSize'] === '') {
                if (color[key]['barcode'] === '') {
                    name = 'Barcode';
                    colorName = color[key].colorName;
                    refName = `barcode${key}`
                }
                else if (color[key]['packSize'] === '') {
                    name = 'Pack Size';
                    colorName = color[key].colorName;
                    refName = `packSize${key}`
                }
                result = true;
                break;
            }
            else {
                this.refs['colordetails'].classList.remove('red')
                result = false
            }
        }
        return [result, name, colorName, refName];
    }

    selectAll = () => {
        let { colorResponse } = this.props;
        let { barcodes } = this.state;
        if (barcodes.length < colorResponse.data.colors.length) {
            alert("Insuffcient Barcodes")
        }
        else {
            let { color } = this.state;
            let allColors = colorResponse.data.colors.slice();
            for (const iterator of colorResponse.data.colors) {
                color[iterator._id] = {
                    name: '',
                    barcode: barcodes[selectedBarcodeIndex]._id,
                    description: '',
                    packSize: '',
                    colorName: iterator.name,
                    colorCode: iterator.colorCode,
                    barcodeToDisplay: barcodes[selectedBarcodeIndex].barcode
                }
                allColors[selectedBarcodeIndex]['barcode'] = barcodes[selectedBarcodeIndex]._id;
                allColors[selectedBarcodeIndex]['barcodeToDisplay'] = barcodes[selectedBarcodeIndex].barcode;
                allColors[selectedBarcodeIndex]['barCodeIndex'] = selectedBarcodeIndex;

                barcodes[selectedBarcodeIndex].used = true
                selectedBarcodeIndex += 1;
            }
            this.setState({
                selectedColors: allColors,
                colorsResponse: [],
                color: this.state.color
            }, () => {
            })
        }
    }

    //showing notification of required field and focusing on the text input left empty
    //making the requied field red
    showErrorToast = (name, fieldName, colorName, refName) => {
        if (name === "colordetails") {
            toast.error(`Please enter ${fieldName} in ${colorName} color!`);
            this.refs[refName].focus();
            this.refs[name].classList.add('red')
        }
        else if (name === 'supplier') {
            toast.error('PLEASE ENTER A BRAND !');
            this.supplierInput.focus();
            this.supplierInput.style.borderStyle = 'solid';
            this.supplierInput.style.borderWidth = '1px';
            this.supplierInput.style.borderColor = 'red';
        }
        else if (name === 'exhibition') {
            toast.error('PLEASE ENTER A SEASON !');
            this.exhibitionInput.focus();
            this.exhibitionInput.style.borderStyle = 'solid';
            this.exhibitionInput.style.borderWidth = '1px';
            this.exhibitionInput.style.borderColor = 'red';
        }
        else if (name === 'color') {
            toast.error('PLEASE ENTER A COLOR !');
            this.colorInput.focus();
            this.colorInput.style.borderStyle = 'solid';
            this.colorInput.style.borderWidth = '1px';
            this.colorInput.style.borderColor = 'red';
        }
        else {
            toast.error('PLEASE ENTER ' + name.toUpperCase() + '!');
            this.refs[name].focus();
            this.refs[name].classList.add('red')
        }
    }

    //setting the state when changing the value of the text input
    //in value is made empty than making it red
    handleChange = (e) => {
        let { value, name } = e.target;
        if (value === '') {
            this.refs[name].classList.add('red')
        }
        else {
            this.refs[name].classList.remove('red')
        }
        this.setState({
            [name]: value
        }, () => {
            this.generateMainCode()
            if (Object.keys(this.state.color).length !== 0) {
                Object.keys(this.state.color).map((v, i) => {
                    this.generateNameAndDescription(v)
                    return (null)
                })
            }
            if (name === 'type') {
                this.setState({
                    category: categories[this.state.type]
                })
            }
            if (name === 'supplier') {
                this.setBrand();
            }
        })
    }

    checkUndfined = (value) => {
        return value === undefined ? ('') : (value)
    }

    generateMainCode = () => {
        let { type, code, supplier } = this.state;
        let { suppliers } = this.props.allSuppliers.data;
        var supplierCode;
        suppliers.forEach(element => {
            if (element._id === supplier) {
                supplierCode = element.code;
            }
        });
        this.setState({
            mainCode: `${this.checkUndfined(type)}-${this.checkUndfined(code)}-${this.checkUndfined(supplierCode)}`,
            supplierCodeDisplay: supplierCode
        })
    }

    setBrand = () => {
        let { suppliers } = this.props.allSuppliers.data;
        suppliers.forEach(element => {
            if (element._id === this.state.supplier) {
                this.setState({
                    brand: element.fullName,
                    supplierCodeDisplay: element.code
                }, () => {
                    if (Object.keys(this.state.color).length !== 0) {
                        Object.keys(this.state.color).map((v, i) => {
                            this.generateNameAndDescription(v)
                            return (null)
                        })
                    }
                })
            }
        });

    }

    //remove all colors
    removeAllColors = () => {
        for (const iterator of this.state.barcodes) {
            iterator.used = false
        }
        selectedBarcodeIndex = 0
        this.setState({
            colorsResponse: this.props.colorResponse.data.colors.slice(),
            color: {},
            selectedColors: [],
            barcodes: this.state.barcodes
        })
    }


    checkAvailableBarcodes = () => {
        let i = 0
        let result = true;
        let index = ''
        for (let iterator of this.props.barCodeUnusedResponse.data.barcodes) {
            if (!iterator.used) {
                result = false;
                index = i;
            }
            i += 1;
        }
        return [result, index];
    }
    //update color object on pack size and stock level change  
    updateColorObject = (off, e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => {
            let { color } = this.state;
            Object.keys(color).map((v, i) => {
                off === 'packsize' ? (color[v].packSize = value) : (color[v].stock = value)
                this.generateNameAndDescription(v)
                return null
            })
            this.setState({
                color: color
            })
        })
    }


    //addselected colors
    //three arrays are taken 1. color is send to api is the list of ids of selected colors
    //1. selectedcolors is to display the selected colors by the user [{name: 'Green', _id: '12', colorCode: ''}]
    //2. colorsResponse is to display the current list of colors 

    selectColor = async (v) => {
        try {
            this.setState({
                isAddingColor: true
            })
            let response = await Helper('barcode/getFreeBarcode', 'GET');
            if (response?.data && response?.data?.barcode) {
                const { barcode, _id: barcodeId } = response?.data;
                let { color, selectedColors, colorsResponse, barcodes } = this.state;
                if (this.colorNotPresentInSelectedColors(selectedColors, v)) {
                    color[v._id] = {
                        stock: this.state.stockLevelForAllColorVariations,
                        name: '',
                        description: '',
                        packSize: this.state.packSizeForAllColorVariations,
                        colorName: v.name,
                        colorCode: v.colorCode,
                        barcode: barcodeId,
                        barcodeToDisplay: barcode,
                        barCodeIndex: null,
                    }
                    selectedColors.push({
                        name: v.name,
                        _id: v._id,
                        colorCode: v.colorCode,
                        barcode: barcodeId,
                        barcodeToDisplay: barcode,
                        barCodeIndex: null
                    });
                    // colorsResponse.forEach((object, i) => {
                    //     if (object._id === v._id) {
                    //         colorsResponse.splice(i, 1)
                    //     }
                    // })
                    this.setState({
                        color: color,
                        selectedColors: selectedColors,
                        colorsResponse: colorsResponse,
                        tempColor: {},
                        colorValue: ''
                    })
                } else {
                    let colorToMatch = this.state.colorValue.split(' - ')[0]
                    if (this.state.colorsResponse.findIndex((v) => { return v.name.toLowerCase() === colorToMatch.toLowerCase() }) === -1) {
                        toast.error("Please select color from the list.");
                    } else {
                        toast.error("You have already selected this color.", 5000);
                    }
                    this.emptySearchOptions();
                }
                this.generateNameAndDescription(v._id);
            }
        }
        catch (error) {
            alert("Error occured while getting barcode, Please check your internet connection");
        }
        finally{
            this.setState({
                isAddingColor: false
            })
        }
    }
    colorNotPresentInSelectedColors = (selectedColors, colorToBeMatched) => {

        return (selectedColors.findIndex((v) => { return v._id === colorToBeMatched._id })) === -1 ? true : false;
    }
    //remove colors
    removeColor = async (v) => {
        let { color, selectedColors, colorsResponse, barcodes } = this.state;
        try {
            this.setState({
                isDeletingColor: true
            })
            const response = await Helper('barcode/freeBarcode', 'POST', { id: v.barCodeId })
            if (response?.status === 200) {
                // barcodes[v.barCodeIndex].used = false;
                delete color[v._id];
                colorsResponse.push({ name: v.name, _id: v._id, colorCode: v.colorCode })
                selectedColors.forEach((object, i) => {
                    if (object._id === v._id) {
                        selectedColors.splice(i, 1)
                    }
                })
                this.setState({
                    color: color,
                    colorsResponse: colorsResponse,
                    selectedColors: selectedColors
                }, () => {
                    this.handleColorValidations()
                })
                this.checkAndEmptyAllPackStockState();
            }
        }
        catch (error) {
            toast.error("Error occured while delete the color, Please check your internet connection");
        }
        finally {
            this.setState({
                isDeletingColor: false
            })
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.validate();
        }
    }

    checkAndEmptyAllPackStockState = () => {
        if (this.state.selectedColors.length === 0) {
            this.setState({
                packSizeForAllColorVariations: '',
                stockLevelForAllColorVariations: ''
            })
        }
    }

    handleColorValidations = () => {
        if (Object.keys(this.state.color).length === 0) {
            this.refs['color'].classList.add('red')
        }
        else {
            this.refs['color'].classList.remove('red')
        }
    }

    saveToColor = (e, key) => {
        let { name, value } = e.target;
        let { color } = this.state;
        color[key][name] = value;
        this.setState({
            color: color,
        }, () => {
            this.generateNameAndDescription(key)
            this.checkColor()
        })
    }

    generateNameAndDescription = (key) => {
        let { color, supplierCodeDisplay, code, type } = this.state;
        if (color[key]['packSize'] !== '') {
            let newColor = this.state.color
            newColor[key]['name'] = `${this.checkUndfined(type)}-${this.checkUndfined(newColor[key]['colorCode'])}-${this.checkUndfined(code)}-${this.checkUndfined(supplierCodeDisplay)}-P${this.checkUndfined(newColor[key]['packSize'])}`
            newColor[key]['description'] = `${this.checkUndfined(type)}-${this.checkUndfined(code)}-${this.checkUndfined(supplierCodeDisplay)} ${this.checkUndfined(newColor[key]['colorName'])}`
            this.setState({
                color: newColor
            })
        }
    }

    render() {
        const { value, suggestions, exhibitionValue, exhibitionSuggestions, colorValue, colorSuggestions } = this.state;
        const inputProps = {
            placeholder: 'Start typing a Brand',
            value,
            onChange: this.onChangeSupplierSuggestion,

        };
        const inputPropsExhibition = {
            placeholder: 'Start typing a Season Code',
            value: exhibitionValue,
            onChange: this.onChangeExhibitionSuggestion,

        };
        const inputPropsColor = {
            placeholder: 'Start typing a color and press enter',
            value: colorValue,
            onChange: this.onChangeColorSuggestion,

        };
        return (
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-7">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                        Add Product
                                </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="dropdown">

                            <Link to="/product" style={{ float: 'right', margin: '5px' }}>
                                <button className="btn btn-sm bg-white font-weight-bold text-dark" type="button">
                                    <i className="mdi mdi-eye mr-1"></i>All Products
                                </button>
                            </Link>
                            <button
                                style={{ display: 'flex', justifyConten: 'center', alignItems: 'center', margin: '5px', float: 'left' }}
                                type="button"
                                id="openSupplierModal"
                                data-toggle="modal"
                                data-target="#viewModalSupplier"
                                className="btn btn-primary btn-sm float-right d-flex justify-content-center"
                            >
                                Add Supplier
                                                    <i className="mdi mdi-plus btn-icon-prepend"> </i>
                            </button>
                            <button
                                style={{ display: 'flex', justifyConten: 'center', alignItems: 'center', margin: '5px', float: 'left' }}
                                type="button"
                                className="btn btn-primary btn-sm float-right d-flex justify-content-center"
                                data-toggle="modal"
                                data-target="#viewModal"
                            >
                                Add Colour
                                                    <i className="mdi mdi-plus btn-icon-prepend"> </i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="productType">Type</label>
                                                <select
                                                    className="form-control"
                                                    id="type"
                                                    name="type"
                                                    ref="type"
                                                    placeholder="Select Type"
                                                    value={this.state.type}
                                                    style={{ height: '46px', marginTop: '2px' }}
                                                    onChange={(event) => this.handleChange(event)}>
                                                    <option disabled selected value="" style={{ color: "#e8e8e8" }}>Select Type</option>
                                                    {
                                                        types.map((v, i) => {
                                                            return (
                                                                <option key={i} value={v.type}>{v.type} - {v.category}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/* supplier started */}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="supplier">Brand</label>

                                                {/* <select
                                                    className="form-control"
                                                    id="suppliercountry"
                                                    name="supplier"
                                                    ref="supplier"
                                                    placeholder="Select Supplier"
                                                    value={this.state.supplier}
                                                    style={{ height: '46px', marginTop: '2px' }}
                                                    onChange={(event) => this.handleChange(event)}>
                                                    <option disabled selected value="" style={{ color: "#e8e8e8" }}>Select Supplier</option>
                                                    {
                                                        this.props.allSuppliers.data.suppliers.map((v, i) => {
                                                            return (
                                                                <option key={i} value={v._id} >{v.fullName} - {v.code}</option>
                                                            )
                                                        })
                                                    }
                                                </select> */}
                                                <Autosuggest
                                                    className="suggest"
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    renderSuggestion={this.renderSuggestion}
                                                    inputProps={inputProps}
                                                    ref={this.storeInputReference}
                                                />
                                            </div>
                                        </div>
                                        {/* supplier ended */}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="unitprice">Unit Price</label>
                                                <input
                                                    name="unitPrice"
                                                    ref="unitPrice"
                                                    type="number"
                                                    placeholder="Enter Unit Price"
                                                    value={this.state.unitPrice}
                                                    className="form-control"
                                                    id="unitprice"
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    onKeyPress={this.handleKeyPress}
                                                    min={0}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="productbrand">Cost Price</label>
                                                <input
                                                    name="costPrice"
                                                    ref="costPrice"
                                                    type="number"
                                                    value={this.state.costPrice}
                                                    className="form-control"
                                                    id="costprice"
                                                    placeholder="Cost Price"
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    onKeyPress={this.handleKeyPress}
                                                    min={0}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="productcode">Product Code</label>
                                                <input
                                                    name="code"
                                                    ref="code"
                                                    type="text"
                                                    placeholder="Enter Product Code"
                                                    value={this.state.code}
                                                    className="form-control"
                                                    id="productcode"
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="itemtype">Item Type</label>
                                                <input
                                                    name="itemType"
                                                    ref="itemType"
                                                    min={0}
                                                    type="number"
                                                    placeholder="Enter Item Type"
                                                    value={this.state.itemType}
                                                    className="form-control"
                                                    id="itemtype"
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exhibition">Season</label>
                                                <Autosuggest
                                                    suggestions={exhibitionSuggestions}
                                                    onSuggestionsFetchRequested={this.onExhibitionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onExhibitionClearRequested}
                                                    getSuggestionValue={this.getSuggestionValueExhibition}
                                                    renderSuggestion={this.renderSuggestionExhibition}
                                                    inputProps={inputPropsExhibition}
                                                    ref={this.exhibitionInputReference}
                                                />
                                                {/* <select className="form-control"
                                                    name="exhibition"
                                                    ref="exhibition"
                                                    value={this.state.exhibition}
                                                    style={{ height: '46px', marginTop: '2px' }}
                                                    onChange={(event) => this.handleChange(event)}>
                                                    <option disabled selected value="">Select Season</option>
                                                    {
                                                        this.props.exhibitionResponse.data.exhibitions.map((v, i) => {
                                                            return (
                                                                <option key={i} value={v._id}>{v.name} - {v.season}</option>
                                                            )
                                                        })
                                                    }
                                                </select> */}
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ display: 'none' }}>
                                            <div className="form-group">
                                                <label htmlFor="productcategory">Category</label>
                                                <input
                                                    name="category"
                                                    ref="category"
                                                    type="text"
                                                    placeholder="Enter Category"
                                                    value={this.state.category}
                                                    className="form-control"
                                                    id="productcategory"
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="maincode">Main Code</label>
                                                <input
                                                    name="mainCode"
                                                    ref="mainCode"
                                                    placeholder="Main Code"
                                                    value={this.state.mainCode.toUpperCase()}
                                                    type="text"
                                                    className="form-control"
                                                    id="maincode"
                                                    disabled
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ display: 'none' }}>
                                            <div className="form-group">
                                                <label htmlFor="productbrand">Brand</label>
                                                <input
                                                    name="brand"
                                                    ref="brand"
                                                    type="text"
                                                    value={this.state.brand}
                                                    className="form-control"
                                                    id="productbrand"
                                                    placeholder="Brand"
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="productbrand">Color</label>

                                                <Autosuggest
                                                    className="suggest"
                                                    suggestions={colorSuggestions}
                                                    onSuggestionsFetchRequested={this.onColorsFetchRequested}
                                                    onSuggestionsClearRequested={this.onColorClearRequested}
                                                    getSuggestionValue={this.getSuggestionValueColor}
                                                    renderSuggestion={this.renderSuggestionColor}
                                                    inputProps={inputPropsColor}
                                                    ref={this.colorInputReference}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea
                                                rows="8"
                                                name="description"
                                                ref="description"
                                                type="text"
                                                value={this.state.description}
                                                className="form-control placeholder"
                                                id="productdescription"
                                                placeholder="Description"
                                                onChange={(e) => { this.handleChange(e) }}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Product Image</label>
                                        <input type="file" name="img[]" className="file-upload-default" />
                                        <div className="input-group col-xs-12" >
                                            <input
                                                name="image"
                                                ref="image"
                                                type="text"
                                                value={this.state.productImage.name}
                                                className="form-control file-upload-info"
                                                disabled
                                            />
                                            <span className="input-group-append">
                                                <label className="file-upload-browse btn btn-primary uploadButton">
                                                    <span style={{ color: '#fff' }}>Upload</span>
                                                    <input type='file' accept="image/*" onChange={(e) => { this.setState({ productImage: e.target.files[0], displayurl: URL.createObjectURL(e.target.files[0]) }, () => { this.refs['image'].classList.remove('red') }) }} />
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        this.state.displayurl !== '' ? (
                                            <div className="form-group">
                                                <div className="input-group col-xs-12">
                                                    <img src={this.state.displayurl} style={{ height: '100px', width: 'auto' }} alt="displayImage" />
                                                </div>
                                            </div>
                                        ) : (null)
                                    }

                                    <div className="row" style={{ display: 'none' }}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="color">Colors</label>
                                                <div className="parentColor">

                                                    {/* colors card started */}
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row d-flex justify-content-end align-content-center">
                                                                        <button
                                                                            type="button"
                                                                            style={{ marginRight: '10px' }}
                                                                            className="btn btn-success btn-sm"
                                                                            onClick={() => {
                                                                                this.selectAll();
                                                                                // this.generateNameAndMainCode() 
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-checkbox-marked-outline btn-icon-prepend"> </i>
                                                                            Select All</button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-sm"
                                                                            data-toggle="modal"
                                                                            data-target="#viewModal"
                                                                            id="openColorModal"
                                                                        >
                                                                            <i className="mdi mdi-plus btn-icon-prepend"> </i>
                                                                            Add Color</button>
                                                                    </div>
                                                                    <div className="table-responsive">
                                                                        <table className="table table-hover min-w-350">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Color Name</th>
                                                                                    <th>Color Code</th>
                                                                                    <th>Color</th>
                                                                                    <th>Options</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.colorsResponse.map(
                                                                                    (v, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td>{i + 1}</td>
                                                                                                <td style={{ textTransform: 'capitalize' }}>{v.name}</td>
                                                                                                <td>{v.colorCode}</td>
                                                                                                <td>
                                                                                                    <div className={ColorModal.colorBox} style={{ 'background': v.name }}></div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-primary btn-sm"
                                                                                                        onClick={() => {
                                                                                                            this.selectColor(v);
                                                                                                            // this.generateNameAndMainCode() 
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="mdi mdi-eye btn-icon-prepend"></i>
                                                                                                        &nbsp;Select
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    {
                                                                        this.state.colorsResponse.length === 0 ? (
                                                                            <div className="noProductsFound"><span style={{ textAlign: 'center' }}>Please add more colors to continue</span></div>
                                                                        ) : (
                                                                            null
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* card colors ended */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={{ display: 'none' }}>
                                            <div className="form-group">
                                                <label htmlFor="color">Colors(Selected)</label>
                                                <div className="parentColor" ref="color">
                                                    {/* colors card started */}
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row d-flex justify-content-end align-content-center">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() => { this.removeAllColors() }}
                                                                        >
                                                                            <i className="mdi mdi-close-outline btn-icon-prepend"> </i>
                                                                            Remove All</button>
                                                                    </div>
                                                                    <div className="table-responsive">
                                                                        <table className="table table-hover min-w-350">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Color Name</th>
                                                                                    <th>Color Code</th>
                                                                                    <th>Color</th>
                                                                                    <th>Options</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.selectedColors.map(
                                                                                    (v, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td>{i + 1}</td>
                                                                                                <td>{v.name}</td>
                                                                                                <td>{v.colorCode}</td>
                                                                                                <td>
                                                                                                    <div className={ColorModal.colorBox} style={{ 'background': v.name }}></div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                        onClick={() => this.removeColor(v)}
                                                                                                    >
                                                                                                        <i className="mdi mdi-eye btn-icon-prepend"></i>
                                                                                                        &nbsp;Remove
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }
                                                                                )}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    {
                                                                        this.state.selectedColors.length === 0 ? (
                                                                            <div className="noProductsFound"><span style={{ textAlign: 'center' }}>No Colors found, Please add to continue</span></div>
                                                                        ) : (
                                                                            null
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* card colors ended */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        Object.keys(this.state.color).length === 0 ? (null) : (
                                            <div className="table-responsive border w-100 py-3" ref="colordetails">
                                                <table className="table table-hover" style={{ minWidth: "850px" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Color
                                                            </th>
                                                            <th>
                                                                <input
                                                                    name="packSizeForAllColorVariations"
                                                                    ref='tempPackSize'
                                                                    type="number"
                                                                    placeholder="Pack Size"
                                                                    className=" w-75 form-control p-2 mb-2"
                                                                    value={this.state.packSizeForAllColorVariations}
                                                                    onChange={(e) => { this.updateColorObject('packsize', e) }}
                                                                    min={0}
                                                                />
                                                                Pack Size
                                                            </th>
                                                            <th>
                                                                <input
                                                                    name="stockLevelForAllColorVariations"
                                                                    ref='tempStockLevel'
                                                                    type="number"
                                                                    placeholder="Stock"
                                                                    className="form-control p-2 mb-2 w-75"
                                                                    value={this.state.stockLevelForAllColorVariations}
                                                                    onChange={(e) => { this.updateColorObject('stocklevel', e) }}
                                                                    min={0}
                                                                />
                                                                Stock
                                                            </th>
                                                            <th>
                                                                Bar Code
                                                            </th>
                                                            <th>
                                                                Name
                                                            </th>
                                                            <th>
                                                                Description
                                                            </th>
                                                            <th>
                                                                Options
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Object.keys(this.state.color).map((v, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td style={{ lineHeight: '20px' }}>
                                                                            <div className={ColorModal.colorBox} style={{ 'background': this.state.color[v]['colorName'] }}></div>
                                                                            {this.state.color[v]['colorName'].toUpperCase()}
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                style={{ width: '100px' }}
                                                                                name="packSize"
                                                                                ref={`packSize${v}`}
                                                                                type="number"
                                                                                placeholder="Size"
                                                                                className="form-control"
                                                                                id="packsize"
                                                                                value={this.state.color[v]['packSize']}
                                                                                onChange={(e) => { this.saveToColor(e, v) }}
                                                                                onKeyPress={this.handleKeyPress}
                                                                                min={0}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                style={{ width: '100px' }}
                                                                                name="stock"
                                                                                ref={`stock${v}`}
                                                                                type="number"
                                                                                placeholder="Stock"
                                                                                className="form-control"
                                                                                id="stock"
                                                                                value={this.state.color[v]['stock']}
                                                                                onChange={(e) => { this.saveToColor(e, v) }}
                                                                                onKeyPress={this.handleKeyPress}
                                                                                min={0}

                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                name="barcode"
                                                                                ref={`barcode${v}`}
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="productbarcode"
                                                                                disabled
                                                                                placeholder="BarCode"
                                                                                onChange={(e) => { this.saveToColor(e, v) }}
                                                                                value={this.state.color[v]['barcodeToDisplay']}
                                                                                onKeyPress={this.handleKeyPress}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <input
                                                                                name="name"
                                                                                type="text"
                                                                                disabled={true}
                                                                                className="form-control"
                                                                                id="productbarcode"
                                                                                placeholder="Name"
                                                                                onChange={(e) => { this.saveToColor(e, v) }}
                                                                                value={this.state.color[v]['name'].toUpperCase()}
                                                                                onKeyPress={this.handleKeyPress}
                                                                            />

                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                name="description"
                                                                                type="text"
                                                                                disabled={true}
                                                                                className="form-control"
                                                                                id="productbarcode"
                                                                                placeholder="Description"
                                                                                onChange={(e) => { this.saveToColor(e, v) }}
                                                                                value={this.state.color[v]['description'].toUpperCase()}
                                                                                onKeyPress={this.handleKeyPress}
                                                                            />

                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm"
                                                                                onClick={() => { this.removeColor({ name: this.state.color[v]['colorName'], _id: v, colorCode: this.state.color[v]['colorCode'], barCodeIndex: this.state.color[v]['barCodeIndex'], barCodeId: this.state.color[v]['barcode'] }) }}
                                                                                disabled={this.state.isDeletingColor}
                                                                            >
                                                                                <i className="mdi mdi-minus btn-icon-prepend"> </i></button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                    <div style={{ marginTop: '20px' }} />
                                    <button type="button" className="btn btn-primary mr-2" onClick={() => { this.validate() }}>Submit</button>
                                    <button type="button" className="btn btn-light" onClick={() => { this.props.history.push('/product/') }}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader loading={this.props.loading || this.state.isDeletingColor || this.state.isAddingColor} />
                <ViewColorModal />
                <ViewSupplierModal />
            </div>
        )
    }
    updateResponse = (res) => {
        if (res.data.response) {
            toast.success(res.data.msg);
            this.setState({
                color: {},
                unitPrice: '',
                mainCode: '',
                code: '',
                productImage: { name: '' },
                colorsResponse: this.props.colorResponse.data.colors.slice(),
                selectedColors: [],
                displayurl: '',
                barcodes: [],
                costPrice: '',
                colorValue: ''
            }, () => {
                this.generateMainCode()
            })
            // this.props.colorFetch();
            // this.props.barCodeFetchUnused();
            this.props.productFetchAddProductThings()
        }
        else {
            toast.error(res.data.msg)
        }
    }
    saveToDataBase = () => {
        let { brand, supplier, color, category, unitPrice, mainCode, type, code, itemType, exhibition, productImage, costPrice, description } = this.state;
        let body = {
            brand, supplier, colorVariations: color, category, unitPrice, mainCode, type, code, itemType, exhibition, productImage, costPrice, description
        }
        this.props.productAdd(body)
    }
    componentDidMount() {
        // this.props.barCodeFetchUnused();
        // this.props.fetchSupplier();
        // this.props.exhibitionFetch();
        // this.props.colorFetch();
        this.props.productFetchAddProductThings()
        this.colorInput.addEventListener("keydown", (event) => {
            if (event.keyCode === 13) {
                if (this.state.tempColor._id !== undefined) {
                    this.selectColor(this.state.tempColor);
                }
                else {
                    toast.error("Please select color from the list.")
                }
                this.emptySearchOptions();
            }
        });
    }

    emptySearchOptions = () => {
        this.setState({
            colorValue: '',
            tempColor: {}
        })
    }
    componentWillReceiveProps(newProps) {
        if (this.props.productResponse !== newProps.productResponse && newProps.productResponse !== undefined) {
            this.updateResponse(newProps.productResponse)
        }
        if (this.props.barCodeUnusedResponse !== newProps.barCodeUnusedResponse && newProps.barCodeUnusedResponse !== undefined) {
            this.setState({
                barcodes: newProps.barCodeUnusedResponse.data.barcodes.slice()
            })
        }
        if (this.props.colorResponse !== newProps.colorResponse && newProps.colorResponse !== undefined) {
            this.setState({
                colorsResponse: newProps.colorResponse.data.colors.slice()
            }, () => {
                let newResponse = this.state.colorsResponse.filter(elem => {
                    return !this.state.selectedColors.some(o => {
                        return o._id === elem._id
                    })
                })
                this.setState({
                    colorsResponse: newResponse
                })
            })
        }
    }
}

const mapDispatchToProps = dispatch => ({
    productAdd: data => dispatch(productAdd(data)),
    // fetchSupplier: _ => dispatch(fetchAllSupplier()),
    // exhibitionFetch: _ => dispatch(exhibitionFetchAll()),
    // colorFetch: _ => dispatch(colorFetch()),
    // barCodeFetchUnused: _ => dispatch(barcodeFetchUnused()),
    productFetchAddProductThings: _ => dispatch(productFetchAddProductThings())
})

const mapStateToProps = () => state => ({
    loading: state.Product.loader,

    productResponse: state.Product.productResponse,

    // allSuppliers: state.Supplier.totalAllSuppliers,
    // exhibitionResponse: state.Exhibition.exhibitionAllFetchResponse,
    // colorResponse: state.Color.colorResponse,
    // barCodeUnusedResponse: state.Barcode.barCodeResponseUnused,

    allSuppliers: state.Product.addProductThings,
    exhibitionResponse: state.Product.addProductThings,
    colorResponse: state.Product.addProductThings,
    barCodeUnusedResponse: state.Product.addProductThings,

    responseDetialsAll: state.Product.addProductThings
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
