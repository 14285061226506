import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../inc/Loader";
import { connect } from "react-redux";
import { agentFetch, agentSearch } from "../../actions/agentActions";
import NewPagination from "../../Common/NewPagination";
import ViewAgentModal from "./ViewAgentModal";

import 'jspdf-autotable';
// import jsPDF from "jspdf";
// import { renderToString } from 'react-dom/server'

// const print = (agents) => {
//   const string = renderToString(<AgentRender agents={agents} />);
//   const pdf = new jsPDF();

//   // pdf.fromHTML(string);

//   // var columns = [
//   //   {title: "Sno", dataKey: "#"},
//   //   {title: "Agent Name", dataKey: "name"}, 
//   //   {title: "Country", dataKey: "country"},
//   //   {title: "County", dataKey: "county"},
//   //   {title: "Mobile", dataKey: "mobile"},
//   //   {title: "Commission % age", dataKey: "commission"},
//   // ];

//   var columns = [
//     { title: "ID", dataKey: "id" },
//     { title: "Name", dataKey: "name" },
//     { title: "Country", dataKey: "address" },
//     { title: "Image", dataKey: 'image' }
//   ];
//   var rows = [
//     { id: 1, name: "Shaw", address: { country: "Tanzania" }, image: 'http://themes.saturateui.com/saturate/img/profile-2.jpg' },
//     { id: 2, name: "Nelson", address: { country: "Kazakhstan" }, image: 'http://themes.saturateui.com/saturate/img/profile-2.jpg' },
//     { id: 3, name: "Garcia", address: { country: "Madagascar" }, image: 'http://themes.saturateui.com/saturate/img/profile-2.jpg' },
//   ];

//   pdf.autoTable(columns, rows, {
//     didParseCell: function (data) {
//       if (data.column.dataKey === 'address') {
//         // data.cell.text = data.cell.raw.country;
//         if (data.cell.raw.country === undefined) {
//           //header
//           data.cell.text = 'Country'
//         }
//         else {
//           //body
//           data.cell.text = data.cell.raw.country;
//         }
//       }
//       if(data.column.dataKey === 'image' && data.cell.section === 'body'){
//         data.cell.text = ''
//       }
//     },
//     didDrawCell: function(data) {
//       if (data.column.index === 3 && data.cell.section === 'body') {
//          var img = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gKgSUNDX1BST0ZJTEUAAQEAAAKQbGNtcwQwAABtbnRyUkdCIFhZWiAH3QAIAA4AFgAoAB1hY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAtkZXNjAAABCAAAADhjcHJ0AAABQAAAAE53dHB0AAABkAAAABRjaGFkAAABpAAAACxyWFlaAAAB0AAAABRiWFlaAAAB5AAAABRnWFlaAAAB+AAAABRyVFJDAAACDAAAACBnVFJDAAACLAAAACBiVFJDAAACTAAAACBjaHJtAAACbAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAABwAAAAcAHMAUgBHAEIAIABiAHUAaQBsAHQALQBpAG4AAG1sdWMAAAAAAAAAAQAAAAxlblVTAAAAMgAAABwATgBvACAAYwBvAHAAeQByAGkAZwBoAHQALAAgAHUAcwBlACAAZgByAGUAZQBsAHkAAAAAWFlaIAAAAAAAAPbWAAEAAAAA0y1zZjMyAAAAAAABDEoAAAXj///zKgAAB5sAAP2H///7ov///aMAAAPYAADAlFhZWiAAAAAAAABvlAAAOO4AAAOQWFlaIAAAAAAAACSdAAAPgwAAtr5YWVogAAAAAAAAYqUAALeQAAAY3nBhcmEAAAAAAAMAAAACZmYAAPKnAAANWQAAE9AAAApbcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltwYXJhAAAAAAADAAAAAmZmAADypwAADVkAABPQAAAKW2Nocm0AAAAAAAMAAAAAo9cAAFR7AABMzQAAmZoAACZmAAAPXP/bAEMABQMEBAQDBQQEBAUFBQYHDAgHBwcHDwsLCQwRDxISEQ8RERMWHBcTFBoVEREYIRgaHR0fHx8TFyIkIh4kHB4fHv/bAEMBBQUFBwYHDggIDh4UERQeHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHv/AABEIABgAGAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAABwYI/8QAJxAAAQMEAQMDBQAAAAAAAAAAAQIDBAAFBhEhEiIxBxNBFjJRYXH/xAAZAQACAwEAAAAAAAAAAAAAAAAAAwEEBQb/xAAjEQABAwMDBQEAAAAAAAAAAAABAAIDBAUREjHBITJBUWHR/9oADAMBAAIRAxEAPwDbTM5x+0YE5lYlx7hEZ6W1JhyEOkvK8NbBICufn4BNS+7et31DZJlonW6Lbo01Pt+83KJcbTvfIOgeBo615qNYplFvhTDCessNmySnEiWw2pXU5pCkpWVkk7T1E8Ac7/NVbJIuGW7DlS4NsTCQ5BLSAqO2tEpSk9ijvvQrz3A62N6oqbjI7DCN1bp7fGdUgcOiy8eEJLYmwVbbcOkFKu0jyRz92tUpiubWGBbXrbd8MiSYpTpo2+c7HU0oAd/QSpClk8lSgfNKNZyoGMbKRpRw4jWlAbT+q6Q9EfTy85RhMJN7aadQ06JVvZkOnpcaI0UqPI6SeQn+71ulKWWhz2tPn8KZTjuPocrO5xYo87KH3I9si2dMhKBHaRFUlKhyknpA7TxsnQApSlZVRVyQENaustVvpauMulYMj6Rzhf/Z'
//          var dim = data.cell.height - data.cell.padding('vertical');
//          var textPos = data.cell.textPos;
//          pdf.addImage(img, textPos.x,  textPos.y, dim, dim);
//       }
//     }
//   });

//   pdf.save("pdf.pdf");
// };


// const AgentRender = (agents) => (
//   <table id="table-pdf">
//     <thead>
//       <tr>
//         <th>#</th>
//         <th>Agent Name</th>
//         <th>County</th>
//         <th>Country</th>
//         <th>Mobile</th>
//         <th>Commission %age</th>
//       </tr>
//     </thead>
//     <tbody>
//       {agents.agents.map((v, i) => {
//         return (
//           <tr key={i}>
//             <td>
//               {i + 1}
//             </td>
//             <td>{v.name}</td>
//             <td>{v.county}</td>
//             <td>{v.country}</td>
//             <td>{v.mobile}</td>
//             <td>{v.commissionPercentage}</td>
//           </tr>
//         );
//       })}

//     </tbody>
//   </table>
// )

class Agent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAgent: {
        name: '',
        county: '',
        country: '',
        address: '',
        phone: '',
        mobile: '',
        zone: '',
        commissionPercentage: '',
      },
      page: 1,
      //User need to set the value from button and pagination also
      perPage: 10,
      searchText: ''
    };
  }

  //Searching starts here

  searchAgent = () => {
    // To remove clicking of ctrl, alt, etc
    if (this.state.searchText !== this.state.temporaryText) {
      this.setState({
        page: 1
      });
      let body = {
        page: this.state.page,
        perPage: this.state.perPage,
        searchText: this.state.searchText
      };
      this.setState({
        temporaryText: this.state.searchText
      });

      //To check if there's any text in search
      if (this.state.searchText.length !== 0) {
        this.props.agentSearch(body);
      } else {
        this.props.agentFetch(body);
      }
    }
  };

  // For setting no of records per page
  setPerPage = e => {
    this.setState(
      {
        perPage: e.target.value,
        page: 1
      },
      () => {
        let body = {
          page: 1,
          perPage: this.state.perPage
        };
        this.props.agentFetch(body);
      }
    );
  };

  debounce = (callback, delay) => {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback();
      }, delay);
    };
  };

  getData = this.debounce(this.searchAgent, 400);

  stateSet = e => {
    let val = e.target.value;
    if (
      val === "\\" ||
      val === "+" ||
      val === "(" ||
      val === ")" ||
      val === "?" ||
      val === "{" ||
      val === "}" ||
      val === "[" ||
      val === "]"
    ) {
      e.target.value = "";
    }
    this.setState({
      searchText: e.target.value
    });
  };

  clearSearch = () => {
    this.setState({
      searchText: ""
    });
    let body = {
      perPage: this.state.perPage,
      page: this.state.page
    };
    this.props.agentFetch(body);
  };

  //Searching ends here

  //Pagination Handling methods set page no
  getNextPage = i => {
    //if search is empty then get response from fetchSupplier
    this.setState({
      page: i
    });
    if (this.state.searchText === "") {
      let body = {
        perPage: this.state.perPage,
        page: i
      };
      this.props.agentFetch(body);
    }

    //Else get search from searchSupplier
    else {
      let body = {
        searchText: this.state.searchText,
        perPage: this.state.perPage,
        page: i
      };
      this.props.agentSearch(body);
    }
  };

  render() {
    let { perPage, currentAgent } = this.state;
    let { response, loading } = this.props;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    All Agents
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/agent/create">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-plus mr-1"></i>Add Agent
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group m-0 mx-2 float-left">
                      <div className="col-xs-12">
                        <input
                          placeholder="Search ..."
                          type="text"
                          className="form-control rounded"
                          onChange={this.stateSet}
                          onKeyUp={this.getData}
                          value={this.state.searchText}
                        />
                      </div>
                    </div>
                    {
                      this.state.searchText === '' ? (null) :
                      (<div className="mx-2 float-left">
                      <button
                        className="btn bg-danger font-weight-bold text-white"
                        type="button"
                        onClick={this.clearSearch}
                      >
                        Clear
                      </button>
                    </div>)}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-right">
                        <select
                          className="form-control"
                          id="type"
                          name="type"
                          placeholder="Select Type"
                          style={{
                            height: "46px",
                            width: "150px",
                            marginTop: "2px"
                          }}
                          onChange={this.setPerPage}
                        >
                          <option disabled="" value="10">
                            10
                          </option>
                          <option disabled="" value="20">
                            20
                          </option>
                          <option disabled="" value="30">
                            30
                          </option>
                          <option disabled="" value="50">
                            50
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-2">
                    <hr />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700 table_heading_view" id="table-print">
                        <thead>
                          <tr className="heading_mine">
                            <th>#</th>
                            <th>Agent Name</th>
                            <th>County</th>
                            <th>Country</th>
                            <th>Mobile</th>
                            <th>Commission %age</th>
                            <th>Options</th>
                            <th>Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.response.data.agents.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {this.state.page === 1
                                    ? i + 1
                                    : i +
                                    1 +
                                    (this.state.page - 1) *
                                    this.state.perPage}
                                </td>
                                <td className="text-capitalize">{v.name}</td>
                                <td>{v.county}</td>
                                <td>{v.country}</td>
                                <td>{v.mobile}</td>
                                <td>{v.commissionPercentage}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    data-toggle="modal"
                                    data-target="#viewModal"
                                    onClick={() => {
                                      this.setState({ currentAgent: v });
                                    }}
                                  >
                                    <i className="mdi mdi-eye btn-icon-prepend"></i>
                                    View Agent
                                  </button>
                                  <span
                                    onClick={() => {
                                      this.props.history.push("/agent/edit", {
                                        agent: v
                                      });
                                    }}
                                    className="text-decoration-none"
                                  >
                                    <button
                                      type="button"
                                      className="btn-sm btn ml-2   btn-dark btn-icon-text"
                                    >
                                      <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                      Edit
                                    </button>
                                  </span>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => { this.props.history.push('/agent/view_assign', { agentId: v._id, agentName: v.name }) }}
                                  >
                                    <i className="mdi mdi-eye btn-icon-prepend"></i>
                                    &nbsp;Products
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-dark btn-sm ml-2 "
                                    onClick={() => { this.props.history.push('/agent/assign', { agentName: v.name, agentId: v._id }) }}
                                  >
                                    &nbsp;Assign Products
                                  </button>
                                </td>
                              </tr>
                            );
                          })}

                        </tbody>
                      </table>
                      {this.props.response.data.agents.length === 0
                        ? (<div className="noProductsFound">
                          <span>No Agents Found</span>
                        </div>)
                        : null}
                      {/* Pagination start */}

                      <NewPagination
                        perPage={perPage}
                        totalPages={response.data.totalPages}
                        currentPage={response.data.currentPage}
                        getNextPage={i => this.getNextPage(i)}
                      // searchText={searchText}
                      />

                      {/* Pagination Ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button onClick={() => { print(this.props.response.data.agents) }}>Save PDF</button> */}
        <Loader loading={loading} />
        <ViewAgentModal agent={currentAgent} />
      </div>
    );
  }

  // hit server to  get agent data
  componentDidMount() {
    let body = {
      perPage: this.state.perPage,
      page: this.state.page
    };
    this.props.agentFetch(body);
  }
}

const mapStateToProps = () => state => ({
  response: state.Agent.agentFetchResponse,
  loading: state.Agent.loader
});

const mapDispatchToProps = dispatch => ({
  agentFetch: page => dispatch(agentFetch(page)),
  agentSearch: data => dispatch(agentSearch(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agent);
