import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../inc/Loader'
import Helper from '../../helper/Helper';
import { Titles } from '../../Config';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: '',
            password: '',
            loginResponse: [],
            loading: false
        }
        this.checkAlreadyLoggedIn();
        document.title = Titles.Login;
    }

    checkAlreadyLoggedIn = () => {
        return localStorage.getItem('access_token') !== null ? (this.props.history.push('/')) : null
    }

    toggleLoader = () => {
        this.setState({ loading: !this.state.loading })
    }

    handleKeyPress = (e) => {
        return e.key === 'Enter' ? (this.validate()) : (null)
    }

    handleOnChange = (e) => {
        let { name, value } = e.target
        this.setState({
            [name]: value
        })
        if (value === '') {
            this.refs[name].classList.add('red')
        }
        else {
            this.refs[name].classList.remove('red')
        }
    }

    showToastAndFocus = (toastField, focusField) => {
        toast.error(toastField + " is required!");
        this.refs[focusField].focus();
        this.refs[focusField].classList.add('red')
    }

    validate = () => {
        let { loginId, password } = this.state;
        if (loginId === '') {
            this.showToastAndFocus('Username', 'loginId')
        }
        else if (password === '') {
            this.showToastAndFocus('Password', 'password')
        }
        else {
            this.loginNow()
        }
    }

    loginNow = () => {
        this.toggleLoader()
        let { loginId, password } = this.state;
        let body = { loginId, password }
        let res = Helper('member/login', 'POST', body);
        res.then((response) => {
            this.toggleLoader();
            this.setState({
                loginResponse: response
            })
            let { role } = response.data;
            if (response.data.response) {
                localStorage.setItem('access_token', response.data.accessToken);
                localStorage.setItem('role', role)
                this.props.history.push('/')
            }
            else{
                toast.error(response.data.msg);
            }
        })
            .catch((err) => {
                this.toggleLoader()
                this.setState({
                    loginResponse: err.response
                })
                toast.error(err.response.data.msg);
            })

            //Dummy login for store

        // if(loginId === 'hi' && password === 'hi') {
        //     localStorage.setItem('access_token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJJZCI6IjVkZTdjNjgzZTk3NDBkZmJjZDAyYzg4OSIsInVzZXJuYW1lIjoiYWRtaW4iLCJwaG9uZU51bWJlciI6Ijk5MTUyOTY4NjYiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE1NzU5NTYxMTQsImV4cCI6MTU3ODU0ODExNH0.EQr9MB6mMJ4ID85DYci4ecNV8R4xZx9edIK7sSaNN6Q')
        //     localStorage.setItem('role', 'store')
        //     localStorage.setItem('branchId', '5de90ad29f9be200246431ae')
        //     localStorage.setItem('storeId', '5de90ad29f9be200246431ac')
        //     this.props.history.push('/')
        // }
    }

    render() {
        return (
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper d-flex align-items-center auth px-0">
                            <div className="row w-100 mx-0">
                                <div className="col-lg-4 mx-auto">
                                    <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                        <div className="brand-logo">
                                            <img src={require('../../inc/images/logo.png')} alt="logo" />
                                        </div>
                                        <h4>Welcome to Inventory Management System !!!</h4>
                                        <h6 className="font-weight-light">Sign in to continue.</h6>
                                        <form className="pt-3">
                                            <div className="form-group">
                                                <input
                                                    name="loginId"
                                                    ref="loginId"
                                                    type="email"
                                                    className="form-control form-control-lg"
                                                    id="exampleInputEmail1"
                                                    placeholder="Username"
                                                    onChange={(e) => { this.handleOnChange(e) }}
                                                    onKeyPress={(e) => { this.handleKeyPress(e) }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    name="password"
                                                    ref="password"
                                                    type="password"
                                                    className="form-control form-control-lg"
                                                    id="exampleInputPassword1"
                                                    placeholder="Password"
                                                    onChange={(e) => { this.handleOnChange(e) }}
                                                    onKeyPress={(e) => { this.handleKeyPress(e) }}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <span className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={() => { this.validate() }}>SIGN IN</span>
                                            </div>
                                            <div className="my-2 text-right">
                                                <span className="auth-link text-muted mt-2 cursor">Forgot password?</span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader loading={this.state.loading} />
                <ToastContainer/>
            </div>
        )
    }
}