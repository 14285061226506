import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class HeaderExportProduct extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-sm-12 pr-0">
                            <div className="d-lg-flex">
                                <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                    {
                                        this.props.heading
                                    }
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.button ?(<div className="col-sm-3">
                    <div className="dropdown float-right">
                        <Link to="/product/create">
                            <button className="btn btn-sm bg-white font-weight-bold text-dark" type="button">
                                <i className="mdi mdi-plus mr-1"></i>Add Product
                            </button>
                        </Link>
                    </div>
                </div>):(null)
                }
            </div>
        )
    }
}
