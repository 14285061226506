import React, { Component } from "react";
import ViewExhibitionModal from "./ViewExhibitionModal";
import { exhibitionFetch } from "../../actions/exhibitionActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../inc/Loader";
import NewPagination from "../../Common/NewPagination";

class Exhibition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalValue: {},
      page: 1,
      perPage: 10
    };
  }

  //For Mdoal
  setModalValuesToState = v => {
    this.setState({
      modalValue: v
    });
  };

  //Pagination Handling methods set page no
  getNextPage = i => {
    let body = {
      perPage: this.state.perPage,
      page: i
    };
    this.setState({
      page: i
    })
    this.props.exhibitionFetch(body);
  };

  render() {

    let { perPage, modalValue } = this.state;
    let { response, loading } = this.props;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    All Exhibition
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/exhibition/create">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-plus mr-1"></i>Add Exhibition
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700 table_heading_view">
                        <thead>
                          <tr className="heading_mine">
                            <th>#</th>
                            <th>Exhibition Name</th>
                            <th>Exhibition Season</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.response.data.exhibitions.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {this.state.page === 1
                                    ? i + 1
                                    : i +
                                    1 +
                                    (this.state.page - 1) *
                                    this.state.perPage}
                                </td>
                                <td className="text-capitalize">{v.name}</td>
                                <td className="text-capitalize">{v.season}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    data-toggle="modal"
                                    data-target="#viewModal"
                                    onClick={() =>
                                      this.setModalValuesToState(v)
                                    }
                                  >
                                    <i className="mdi mdi-eye btn-icon-prepend"></i>
                                    &nbsp;Details
                                  </button>
                                  <span
                                    onClick={() => {
                                      this.props.history.push(
                                        "/exhibition/edit/",
                                        { editExhibition: v }
                                      );
                                    }}
                                    className="text-decoration-none"
                                  >
                                    <button
                                      type="button"
                                      className="btn-sm btn ml-2 btn-dark btn-icon-text"
                                    >
                                      <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                      Edit
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}

                        </tbody>
                      </table>
                      {response.data.exhibitions.length === 0
                        ? (<div className="noProductsFound">
                          <span>No Exhibitions Found</span>
                        </div>)
                        : null}
                      {/*  pagination  */}
                      <NewPagination
                        perPage={perPage}
                        response={response.data.exhibitions}
                        totalPages={response.data.totalPages}
                        currentPage={response.data.currentPage}
                        getNextPage={i => this.getNextPage(i)}
                      // searchText={searchText}
                      />
                      {/*  pagination  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ViewExhibitionModal view={modalValue} />
        </div>
        <Loader loading={loading} />
      </div>
    );
  }

  // hit server to  get exhibition data
  componentDidMount() {
    let body = {
      perPage: this.state.perPage,
      page: this.state.page
    };
    this.props.exhibitionFetch(body);
  }
}

const mapStateToProps = () => state => ({
  response: state.Exhibition.exhibitionFetchResponse,
  loading: state.Exhibition.loader
});

const mapDispatchToProps = dispatch => ({
  exhibitionFetch: (body) => dispatch(exhibitionFetch(body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exhibition);
