//product types
export const PRODUCT_ADD = 'PRODUCT_ADD'
export const PRODUCT_FETCH = 'PRODUCT_FETCH'
export const PRODUCT_EDIT = 'PRODUCT_EDIT'
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH'
export const PRODUCT_SEARCH_RESET = 'PRODUCT_SEARCH_RESET'
export const PRODUCT_EXPORT_JSON = 'PRODUCT_EXPORT_JSON'
export const PRODUCT_EXPORT_FILE = 'PRODUCT_EXPORT_FILE'
export const PRODUCT_EXPORT_RESET = 'PRODUCT_EXPORT_RESET'
export const PRODUCT_EXPORT_ALL_FILE = 'PRODUCT_EXPORT_ALL_FILE'
export const PRODUCT_IMPORT_FILE = 'PRODUCT_IMPORT_FILE'
export const PRODUCT_IMPORT_EMPTY = 'PRODUCT_IMPORT_EMPTY'
export const PRODUCT_ALL_IMPORTED_DATA = 'PRODUCT_ALL_IMPORTED_DATA'
export const PRODUCT_UPDATED_IMPORT_DATA = 'PRODUCT_UPDATED_IMPORT_DATA'
export const PRODUCT_STICKERS = 'PRODUCT_STICKERS'
export const PRODUCT_STICKER_SAVE_LIST = 'PRODUCT_STICKER_SAVE_LIST'
export const PRODUCT_STICKERS_PRINT_65_LABELS = 'PRODUCT_STICKERS_PRINT_65_LABELS'
export const PRODUCT_STICKERS_PRINT_XLS = 'PRODUCT_STICKERS_PRINT_XLS'
export const PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS = 'PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS'

//supplier types
export const ADD_SUPPLIER = 'ADD_SUPPLIER'
export const FETCH_SUPPLIER = 'FETCH_SUPPLIER'
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER'
export const SEARCH_SUPPLIER = 'SEARCH_SUPPLIER'
export const FETCH_ALL_SUPPLIER = 'FETCH_ALL_SUPPLIER'

//exhibition types
export const EXHIBITION_ADD = 'EXHIBITION_ADD'
export const EXHIBITION_FETCH = 'EXHIBITION_FETCH'
export const EXHIBITION_UPDATE = 'EXHIBITION_UPDATE'

//exhibition color
export const COLOR_ADD = 'COLOR_ADD'
export const COLOR_FETCH = 'COLOR_FETCH'
export const COLOR_UPDATE = 'COLOR_UPDATE'
export const UPDATE_ADD_COLOR_RESPONSE = 'UPDATE_ADD_COLOR_RESPONSE'
export const EXHIBITION_FETCH_ALL = 'EXHIBITION_FETCH_ALL'

//agent types
export const AGENT_ADD = 'AGENT_ADD'
export const AGENT_UPDATE = 'AGENT_UPDATE'
export const AGENT_FETCH = 'AGENT_FETCH'
export const AGENT_SEARCH = 'AGENT_SEARCH'
export const AGENT_ASSIGN_PRODCUTS = 'AGENT_ASSIGN_PRODCUTS'
export const AGENT_ASSIGN_PRODUCT_CHECK = 'AGENT_ASSIGN_PRODUCT_CHECK'
export const AGENT_FETCH_ASSIGNED_PRODUCTS = 'AGENT_FETCH_ASSIGNED_PRODUCTS'
export const AGENT_CUSTOMER_DETAILS = 'AGENT_CUSTOMER_DETAILS'
export const AGENT_CUSTOMER_DETAILS_RESET = 'AGENT_CUSTOMER_DETAILS_RESET'
export const AGENT_CUSTOMER_ORDER = 'AGENT_CUSTOMER_ORDER'
export const AGENT_FETCH_ALL_ORDERS = 'AGENT_FETCH_ALL_ORDERS'
export const AGENT_FETCH_ORDERS_BY_ID = 'AGENT_FETCH_ORDERS_BY_ID'
export const AGENT_UPDATE_ORDER_STATUS = 'AGENT_UPDATE_ORDER_STATUS'
export const AGENT_FETCH_CUSTOMERS = 'AGENT_FETCH_CUSTOMERS'

//store types
export const STORE_ADD = 'STORE_ADD'
export const STORE_FETCH = 'STORE_FETCH'
export const STORE_UPDATE = 'STORE_UPDATE'
export const STORE_SEARCH = 'STORE_SEARCH'
export const PRODUCT_ASSIGN = 'PRODUCT_ASSIGN'
export const STORE_UPLOAD_SALES_REPORT = 'STORE_UPLOAD_SALES_REPORT'
export const FETCH_ALL_SALES = 'FETCH_ALL_SALES'
export const STORE_BEST_SELLING_CATEGORY_REPORT = 'STORE_BEST_SELLING_CATEGORY_REPORT'
export const STORE_STOCK_REPORT = 'STORE_STOCK_REPORT'
export const STORE_ORDER_FINALIZE = 'STORE_ORDER_FINALIZE'
export const STORE_REPORT_RESET = 'STORE_REPORT_RESET'
export const STORE_SPECIFIC_SALES_REPORT = 'STORE_SPECIFIC_SALES_REPORT' 
export const STORE_PRINT_21_LABEL = 'STORE_PRINT_21_LABEL'
export const STORE_PRINT_PRODUCT_LABEL = 'STORE_PRINT_PRODUCT_LABEL'
export const STORE_SAVE_DELIVERY_NOTE = 'STORE_SAVE_DELIVERY_NOTE'

//barcod types
export const BARCODE_FETCH_USED = 'BARCODE_FETCH_USED'
export const BARCODE_FETCH_UNUSED = 'BARCODE_FETCH_UNUSED'
export const BARCODE_FETCH_ALL = 'BARCODE_FETCH_ALL'
export const BARCODE_IMPORT = 'BARCODE_IMPORT'
export const BARCODE_IMPORT_EMPTY = 'BARCODE_IMPORT_EMPTY'

//dashboard types
export const DASHBOARD_ADMIN_DATA = 'DASHBOARD_ADMIN_DATA'
export const DASHBOARD_ADMIN_SALES = 'DASHBOARD_ADMIN_SALES'

//staff types
export const STAFF_ADD = 'STAFF_ADD'
export const STAFF_FETCH = 'STAFF_FETCH'
export const STAFF_EDIT = 'STAFF_EDIT'