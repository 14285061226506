import React from "react";
// import { PHOTO_URL } from "../../Config";
const defaultImage = "https://via.placeholder.com/150";

export default function ViewSupplierModal(props) {
  let { supplier } = props;
  return (
    <div
      className="modal fade"
      id="viewModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              Supplier Details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="w-100 text-center py-3">
              <img
                src={supplier.businessCard}
                alt="imageBusinessCard"
                style={{ width: "100%", height: "auto" }}
                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
              />
            </div>
            <div className="w-100 table-responsive">
              <table className="table no-border">
                <tbody>
                  <tr>
                    <th>Supplier Name</th>
                    <td className="text-capitalize">{supplier.fullName}</td>
                  </tr>
                  <tr>
                    <th>Code</th>
                    <td>{supplier.code}</td>
                  </tr>
                  <tr>
                    <th>Supplier Email</th>
                    <td>{supplier.email === '' ? ('-') : (supplier.email)}</td>
                  </tr>
                  <tr>
                    <th>Contact Person Name</th>
                    <td>{supplier.contactPersonName === '' ? ('-') : (supplier.contactPersonName)}</td>
                  </tr>
                  <tr>
                    <th>Supplier Address</th>
                    <td>{supplier.address === '' ? ('-') : (supplier.address)}</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>{supplier.country === '' ? ('-') : (supplier.country)}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{supplier.phoneNumber === '' ? ('-') : (supplier.phoneNumber)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
