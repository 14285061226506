import {
    STAFF_ADD, STAFF_FETCH, STAFF_EDIT
} from './types'

import Helper from '../helper/Helper'

export const staffAdd = (data) => ({
    type: STAFF_ADD,
    payload: Helper('member/signup', 'POST', data)
})

export const staffFetch = () => ({
    type: STAFF_FETCH,
    payload: Helper('member/fetchUsersByType','POST', {userType: 'staff'})
})

export const staffEdit =(id, body)=>({
    type: STAFF_EDIT,
    payload: Helper('member/updateStaff/'+id, 'PUT', body)
})