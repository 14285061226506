import {
  PRODUCT_ADD,
  PRODUCT_FETCH,
  PRODUCT_EDIT,
  PRODUCT_SEARCH,
  PRODUCT_EXPORT_JSON,
  PRODUCT_EXPORT_FILE,
  PRODUCT_EXPORT_RESET,
  PRODUCT_EXPORT_ALL_FILE,
  PRODUCT_IMPORT_FILE,
  PRODUCT_IMPORT_EMPTY,
  PRODUCT_SEARCH_RESET,
  PRODUCT_ALL_IMPORTED_DATA,
  PRODUCT_UPDATED_IMPORT_DATA,
  PRODUCT_STICKERS,
  PRODUCT_STICKER_SAVE_LIST,
  PRODUCT_STICKERS_PRINT_65_LABELS,
  PRODUCT_STICKERS_PRINT_XLS,
  PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS
} from "../actions/types";

const initialState = {
  //add response
  productResponse: {},
  loader: false,

  //fetch response
  productFetchResponse: {
    data: {
      products: []
    }
  },

  productsFound: true,

  //edit response
  productEditResposne: {},

  //search response
  productSearchResponse: {
    data: {
      products: []
    }
  },

  //export product
  productExportJson: {
    data: {
      products: []
    }
  },

  //export product file
  productExportFile: {},

  //product import file
  productImportFile: {},

  //product updated import file
  productUpdatedImportFile: {},

  //All Imported Data
  productImportedData: {
    data: {
      productImportData: []
    }
  },
  importedData: {},

  //product sticker 4 walaa
  productStickerData: {
    data: {

    }
  },

  //product print barcode lists
  productPrintBarcodeLists: [],

  //product 65 labels in product barcode section
  productSticker65Labels: {},
  productStickerXlsResponse: {},

  //add product things
  addProductThings: {
    data: {
      colors: [],
      barcodes: [],
      exhibitions: [],
      suppliers: []
    }
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    //adding the product
    case `${PRODUCT_ADD}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_ADD}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productResponse: action.payload
      };
    case `${PRODUCT_ADD}_REJECTED`:
      return {
        ...state,
        loader: false,
          productResponse:
          action.payload.response !== undefined ? action.payload.response : {}
      };

      //fetching the product
    case `${PRODUCT_FETCH}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_FETCH}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productFetchResponse: action.payload,
          productsFound: action.payload.data.products.length === 0 ? false : true
      };
    case `${PRODUCT_FETCH}_REJECTED`:
      return {
        ...state,
        loader: false,
          productFetchResponse:
          action.payload.response !== undefined ?
          action.payload.response : {
            data: {
              products: []
            }
          }
      };

      //editing the product
    case `${PRODUCT_EDIT}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_EDIT}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productEditResposne: action.payload
      };
    case `${PRODUCT_EDIT}_REJECTED`:
      return {
        ...state,
        loader: false,
          productEditResposne: action.payload.response
      };

      //searching product
    case `${PRODUCT_SEARCH}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_SEARCH}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productFetchResponse: action.payload,
          productsFound: action.payload.data.products.length === 0 ? false : true
      };
    case `${PRODUCT_SEARCH}_REJECTED`:
      return {
        ...state,
        loader: false,
          productFetchResponse: action.payload.response
      };

      //exporting product json
    case `${PRODUCT_EXPORT_JSON}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_EXPORT_JSON}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productExportJson: action.payload,
          productsFound: action.payload.data.products.length === 0 ? false : true
      };
    case `${PRODUCT_EXPORT_JSON}_REJECTED`:
      return {
        ...state,
        loader: false,
          productExportJson: action.payload.response
      };

      //exporting product
    case `${PRODUCT_EXPORT_FILE}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_EXPORT_FILE}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productExportFile: action.payload
      };
    case `${PRODUCT_EXPORT_FILE}_REJECTED`:
      return {
        ...state,
        loader: false,
          productExportFile: action.payload.response
      };
      //export products reset
    case PRODUCT_EXPORT_RESET:
      return {
        ...state,
        productExportJson: action.payload
      };

      //export product all
    case `${PRODUCT_EXPORT_ALL_FILE}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_EXPORT_ALL_FILE}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productExportFile: action.payload
      };
    case `${PRODUCT_EXPORT_ALL_FILE}_REJECTED`:
      return {
        ...state,
        loader: false,
          productExportFile: action.payload.response
      };

    case `${PRODUCT_IMPORT_FILE}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_IMPORT_FILE}_FULFILLED`:
      return {
        ...state,
        loader: false,
          importedData: action.payload
      };
    case `${PRODUCT_IMPORT_FILE}_REJECTED`:
      return {
        ...state,
        loader: false,
          importedData: action.payload.response
      };

      //update import data
    case `${PRODUCT_UPDATED_IMPORT_DATA}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_UPDATED_IMPORT_DATA}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productUpdatedImportFile: action.payload
      };
    case `${PRODUCT_UPDATED_IMPORT_DATA}_REJECTED`:
      return {
        ...state,
        loader: false,
          productUpdatedImportFile: action.payload.response
      };

      //empty product import respose
    case PRODUCT_IMPORT_EMPTY:
      return {
        ...state,
        productImportFile: action.payload
      };
      //emtpy product search response
    case PRODUCT_SEARCH_RESET:
      return {
        ...state,
        productFetchResponse: action.payload
      };

      //export all imported data
    case `${PRODUCT_ALL_IMPORTED_DATA}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_ALL_IMPORTED_DATA}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productImportedData: action.payload
      };
    case `${PRODUCT_ALL_IMPORTED_DATA}_REJECTED`:
      return {
        ...state,
        loader: false,
          productImportedData: action.payload.response
      };

      //product sticker
    case `${PRODUCT_STICKERS}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_STICKERS}_FULFILLED`:
      return {
        ...state,
        loader: false,
          productStickerData: action.payload
      };
    case `${PRODUCT_STICKERS}_REJECTED`:
      return {
        ...state,
        loader: false,
          productStickerData: action.payload.response
      };

      //product print barcode
    case PRODUCT_STICKER_SAVE_LIST:
      return {
        ...state,
        productPrintBarcodeLists: action.payload
      }

      //product print 65 product labels
      case `${PRODUCT_STICKERS_PRINT_65_LABELS}_PENDING`:
        return {
          ...state,
          loader: true
        };
      case `${PRODUCT_STICKERS_PRINT_65_LABELS}_FULFILLED`:
        return {
          ...state,
          loader: false,
            productSticker65Labels: action.payload
        };
      case `${PRODUCT_STICKERS_PRINT_65_LABELS}_REJECTED`:
        return {
          ...state,
          loader: false,
            productSticker65Labels: action.payload.response
        };


        //product print xls product labels
      case `${PRODUCT_STICKERS_PRINT_XLS}_PENDING`:
        return {
          ...state,
          loader: true
        };
      case `${PRODUCT_STICKERS_PRINT_XLS}_FULFILLED`:
        return {
          ...state,
          loader: false,
            productStickerXlsResponse: action.payload
        };
      case `${PRODUCT_STICKERS_PRINT_XLS}_REJECTED`:
        return {
          ...state,
          loader: false,
            productStickerXlsResponse: action.payload.response
        };

        //add product things
      case `${PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS}_PENDING`:
        return {
          ...state,
          loader: true
        };
      case `${PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS}_FULFILLED`:
        return {
          ...state,
          loader: false,
            addProductThings: action.payload
        };
      case `${PRODUCT_FETCH_ALL_ADD_PRODUCT_THINGS}_REJECTED`:
        return {
          ...state,
          loader: false,
            addProductThings: action.payload.response
        };
      default:
        return {
          ...state
        };
  }
}