import React, { Component } from "react";
import ViewColorModal from "./ViewColorModal";
import { colorFetch } from "../../actions/colorActions";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import Loader from '../../inc/Loader'
import ColorModal from './Color.module.css';

class Color extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalValue: {}
    }
    props.fetchColors();
  }


  setModalValuesToState = (v) => {
    this.setState({
      modalValue: v
    }, () => {
    })
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    All Color
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to='/colour/create'>
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-plus mr-1"></i>Add Color
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Colour Name</th>
                            <th>Colour Code</th>
                            <th>Colour Swatch</th>
                            <th>Colour</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.colors.data.colors.map(
                            (v, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td style={{textTransform: 'capitalize'}}>{v.name}</td>
                                  <td style={{textTransform: 'uppercase'}}>{v.colorCode}</td>
                                  <td style={{textTransform: 'uppercase'}}>{v.colorSwatch}</td>
                                  <td>
                                  <div className={ColorModal.colorBox} style={{'background': v.colorSwatch==='#FFFFFF'?(v.name):(v.colorSwatch)}}></div>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      data-toggle="modal"
                                      data-target="#viewModal"
                                      onClick={() => this.setModalValuesToState(v)}
                                    >
                                      <i className="mdi mdi-eye btn-icon-prepend"></i>
                                      &nbsp;Details
                                    </button>
                                    <span
                                      onClick={() => { this.props.history.push('/colour/edit', { editColor: v }) }}
                                      className="text-decoration-none">
                                      <button
                                        type="button"
                                        className="btn-sm btn ml-2 btn-dark btn-icon-text"
                                      >
                                        <i
                                          className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                        Edit
                                     </button>
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewColorModal view={this.state.modalValue} />
        <Loader loading={this.props.loading} />
      </div>
    );
  }
}

const mapStateToProps = () => state => {
  return ({
    colors: state.Color.colorResponse,
    loading: state.Color.loader
  })
}

const mapDispatchToProps = dispatch => ({
  fetchColors: () => dispatch(colorFetch())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Color);
