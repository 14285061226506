import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../inc/Loader";
import ViewStoreModal from "./ViewStoreModal";
import _ from "lodash";
import NewPagination from "../../Common/NewPagination";
// import SelectBranchModal from './SelectBranchModal'
import SelectBranchModalOne from "./SelectBranchModalOne";

//redux imports
import { connect } from "react-redux";
import { storeFetch, storeSearch } from "../../actions/storeActions";

class Store extends Component {
  constructor() {
    super();
    this.state = {
      allSuppliers: [],
      currentStore: {
        companyName: "",
        address: "",
        country: "",
        mobile: "",
        phone: "",
        branches: [],
        email: "",
        contactPerson: ""
      },
      searchText: "",
      perPage: 10,
      page: 0
    };
    this.selectBranchModalRef = React.createRef();
  }

  componentDidMount() {
    this.props.storeFetch();
  }

  searchNow = (e, i) => {
    if (e.key === "Enter") {
      this.props.storeSearch({ searchText: this.state.searchText });
    }
  };

  getNextPage = i => {
    let body = {
      perPage: this.state.perPage,
      page: i
    };
    this.props.storeFetch(body);
  };

  // For setting no of records per page
  setPerPage = e => {
    this.setState(
      {
        perPage: e.target.value,
        page: 1
      },
      () => {
        let body = {
          page: 1,
          perPage: this.state.perPage
        };
        this.props.storeFetch(body);
      }
    );
  };

  search = _.debounce(e => {
    if (this.state.searchText !== "") {
      this.props.storeSearch({ searchText: this.state.searchText });
    } else {
      this.props.storeFetch();
    }
  }, 400);

  render() {
    let { perPage, currentStore } = this.state;
    let { response, loading } = this.props;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                    All Stores
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/store/create">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-plus mr-1"></i>Add Store
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-5 pb-3">
                    <div className="form-group m-0 mx-2 float-left">
                      <div className="input-group col-xs-12">
                        <input
                          placeholder="Search Store"
                          type="text"
                          className="px-3  py-0 form-control rounded"
                          onChange={e => {
                            this.setState(
                              { searchText: e.target.value },
                              () => {
                                this.search();
                              }
                            );
                          }}
                          style={{ height: "50px", marginBottom: "10px" }}
                          value={this.state.searchText}
                          onKeyPress={e => {
                            this.searchNow(e);
                          }}
                        />
                      </div>
                    </div>
                    {this.state.searchText !== "" ? (
                      <span className="mx-2 float-left">
                        <button
                          className="btn bg-danger font-weight-bold text-white"
                          type="button"
                          onClick={() => {
                            this.setState({ searchText: "" });
                            this.props.storeFetch();
                          }}
                        >
                          <i className="mdi mdi-close mr-1"></i>Clear Search
                        </button>
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-right">
                        <select
                          className="form-control"
                          id="type"
                          name="type"
                          placeholder="Select Type"
                          style={{
                            height: "46px",
                            width: "150px",
                            marginTop: "2px"
                          }}
                          onChange={this.setPerPage}
                        >
                          <option disabled="" value="10">
                            10
                          </option>
                          <option disabled="" value="20">
                            20
                          </option>
                          <option disabled="" value="30">
                            30
                          </option>
                          <option disabled="" value="50">
                            50
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover min-w-700 table_heading_view">
                        <thead>
                          <tr className="heading_mine">
                            <th>#</th>
                            <th>Company Name</th>
                            <th>Mobile</th>
                            <th>Country</th>
                            {/* <th>email</th> */}
                            <th>Options</th>
                            <th>Order</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.response.data.stores.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>{(response.data.currentPage-1)*this.state.perPage+i+1}</td>
                                <td>{v.companyName}</td>
                                <td>{v.mobile}</td>
                                <td>{v.country}</td>
                                {/* <td>{v.email}</td> */}
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    data-toggle="modal"
                                    data-target="#viewModal"
                                    onClick={() => {
                                      this.setState({ currentStore: v });
                                    }}
                                  >
                                    <i className="mdi mdi-eye btn-icon-prepend">
                                      {" "}
                                    </i>
                                    View Store
                                  </button>
                                  <span
                                    onClick={() => {
                                      this.props.history.push("/store/edit", {
                                        store: v
                                      });
                                    }}
                                    className="text-decoration-none"
                                  >
                                    <button
                                      type="button"
                                      className="btn-sm btn ml-1 btn-dark btn-icon-text"
                                    >
                                      <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                      Edit
                                    </button>

                                  </span>
                                  <button
                                    type="button"
                                    className="btn-sm btn ml-1 btn-primary btn-icon-text"
                                    data-toggle="modal"
                                    data-target="#branchModalOne"
                                    onClick={() => {
                                      this.setState({ currentStore: v });
                                    }}
                                    // style={{ marginLeft: 5 }}
                                  >
                                     <i className="mdi mdi-cloud-upload btn-icon-prepend"></i>
                                    Upload Sales
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={(e)=>{this.props.history.push('/store/orders', {currentStore: v})}}
                                    // style={{ marginLeft: 5 }}
                                  >
                                    {/* <i className="mdi mdi-eye btn-icon-prepend"></i> */}
                                    View Orders
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm ml-1" 
                                    onClick={() => {
                                      this.setState({ currentStore: v },()=>{
                                        this.props.history.push("/store/assignProducts", {currentStore: v, repeat: false, orders: [], edit: false})
                                      });
                                      // this.selectBranchModalRef.current.emptyArray();
                                    }}
                                    // style={{ marginLeft: 5 }}
                                  >
                                    Place Order
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {!this.props.storesFound ? (
                        <div className="noProductsFound">
                          <h5>No Stores Found</h5>
                        </div>
                      ) : null}
                      <NewPagination
                        perPage={perPage}
                        totalPages={response.data.totalPages}
                        currentPage={response.data.currentPage}
                        getNextPage={i => this.getNextPage(i)}
                      // searchText={searchText}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewStoreModal store={currentStore} />
        {/* <SelectBranchModal store={currentStore} history={this.props.history} ref={this.selectBranchModalRef} /> */}
        <SelectBranchModalOne store={currentStore} history={this.props.history} ref={this.selectBranchModalOneRef} />
        <Loader loading={loading} />
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    storeFetch: (body) => dispatch(storeFetch(body)),
    storeSearch: data => dispatch(storeSearch(data))
  };
};

const mapStateToProps = () => state => ({
  response: state.Store.storeFetchResponse,
  loading: state.Store.loader,
  storesFound: state.Store.storesFound
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store);
