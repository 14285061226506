import React from 'react'
import ColorModal from '../../Color/Color.module.css'

export default function ViewColorVariations(props) {
    let { selectedProduct } = props;
    return (
        <div className="modal fade" id="viewColorVariations" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Color Variations</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 grid-margin stretch-card" >
                            <div className="card-body">
                                {/* <div className="row pb-1">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped min-w-700">
                                            </table>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 mt-4">
                                    <div className="card shadow">
                                        <div className="card-body w-100">
                                            <div className="table-responsive" id="print">
                                                <table
                                                    className="table table-hover"
                                                    style={{ minWidth: "800px" }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Colour Name</th>
                                                            <th>Colour Code</th>
                                                            <th>Colour</th>
                                                            <th>Bar Code</th>
                                                            <th>Pack Size</th>
                                                            <th>Stock Level</th>
                                                            <th>Name</th>
                                                            <th>Description</th>
                                                            <th>Options</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedProduct.colorVariations.map((v, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td style={{ textTransform: "capitalize" }}>
                                                                        {v.color.name}
                                                                    </td>
                                                                    <td style={{ textTransform: "uppercase" }}>
                                                                        {v.color.colorCode}
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            className={ColorModal.colorBox}
                                                                            style={{ background: v.color.colorSwatch === '#FFFFFF' ? (v.color.name) : (v.color.colorSwatch) }}
                                                                        ></div>
                                                                    </td>
                                                                    <td>{v.barcode.barcode}</td>
                                                                    <td>{v.packSize}</td>
                                                                    <td>{v.stock}</td>
                                                                    <td>{`${v.name}-P${v.packSize}`}</td>
                                                                    <td style={{ textTransform: "uppercase" }}>
                                                                        {v.description}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            props.checkColorVariationExistence(selectedProduct.mainCode, v) ? (
                                                                                <button
                                                                                    className="btn btn-danger"
                                                                                    type="button"
                                                                                    id="button-addon2"
                                                                                    onClick={() => { props.deleteColorVariation(selectedProduct.mainCode, v)  }}
                                                                                >
                                                                                    <span>Unselect</span>
                                                                                </button>
                                                                            ) : (
                                                                                    <button
                                                                                        className="btn btn-primary"
                                                                                        type="button"
                                                                                        id="button-addon2"
                                                                                        onClick={() => { props.selectColorVariation(selectedProduct.mainCode, selectedProduct.description, v) }}
                                                                                    >
                                                                                        <span>Select</span>
                                                                                    </button>
                                                                                )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
