import { COLOR_ADD, COLOR_FETCH,COLOR_UPDATE, UPDATE_ADD_COLOR_RESPONSE } from "../actions/types";

const initialState = {
  colorResponse: {
      data:{colors:[]}
  },
  loader: false,
  addColorResponse:{
      data:{}
  },
  colorUpdateResponse:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    //update add color response 
    case `${UPDATE_ADD_COLOR_RESPONSE}`:
        return {
          ...state,
          addColorResponse: action.payload
        }
    // add color
    case `${COLOR_ADD}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${COLOR_ADD}_FULFILLED`:
      return {
        ...state,
        loader: false,
        addColorResponse: action.payload
      };
    case `${COLOR_ADD}_REJECTED`:
      return {
        ...state,
        loader: false,
        addColorResponse: action.payload.response
      };

      // fetch color
    case `${COLOR_FETCH}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${COLOR_FETCH}_FULFILLED`:
      return {
        ...state,
        loader: false,
        colorResponse: action.payload
      };
    case `${COLOR_FETCH}_REJECTED`:
      return {
        ...state,
        loader: false
      };
    // update color
    case `${COLOR_UPDATE}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${COLOR_UPDATE}_FULFILLED`:
      return {
        ...state,
        loader: false,
        colorUpdateResponse: action.payload
      };
    case `${COLOR_UPDATE}_REJECTED`:
      return {
        ...state,
        loader: false,
        colorUpdateResponse: action.payload.response
      };
    default:
      return {
        ...state
      };
  }
}
