import React, { Component } from "react";
import { connect } from 'react-redux';
import { colorAdd, updateAddColorResponse } from '../../../actions/colorActions'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../inc/Loader';
import { SketchPicker } from 'react-color'

class AddColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      colorCode: "",
      colorSwatch: '',
    };
  }

  validateField = () => {
    let { name, colorCode } = this.state;
    if (name === "") {
      this.showErrorToast('name')
    } else if (colorCode === "") {
      this.showErrorToast('colorCode');
    } else {
      this.saveColor();
    }
  }

  //post data to store state for api hit
  saveColor = () => {
    let { name, colorCode, colorSwatch } = this.state;
    let color = {
      name,
      colorCode,
      colorSwatch
    };
    this.props.colorAdd(color);
  };

  //showing notification of required field and focusing on the text input left empty
  //making the requied field red
  showErrorToast = (name) => {
    toast.error('PLEASE ENTER ' + name.toUpperCase() + '!');
    this.refs[name].focus();
    this.refs[name].classList.add('red')
  }

  //setting the state when changing the value of the text input
  //in value is made empty than making it red
  handleInput = (e) => {
    let { value, name } = e.target;
    if (value === '') {
      this.refs[name].classList.add('red')
    }
    else {
      this.refs[name].classList.remove('red')
    }
    this.setState({
      [name]: value
    })
  }
  handleChangeComplete = (color) => {
    this.setState({ colorSwatch: color.hex });
  };

  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mt-2 mr-5 text-uppercase">
                    Add Colour
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/colour">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-eye mr-1"></i>All Colours
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label for="colorname">Colour Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="colorname"
                          name="name"
                          ref="name"
                          value={this.state.name}
                          placeholder="Colour Name"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label for="colorcode">Color Code</label>
                        <input
                          type="text"
                          className='form-control'
                          id="colorCode"
                          name="colorCode"
                          ref="colorCode"
                          value={this.state.colorCode}
                          placeholder="Colour Code"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="colorSwatch">Colour Swatch</label>
                        <input
                          type="text"
                          className={`form-control`}
                          name="colorSwatch"
                          id="colorSwatch"
                          ref="colorSwatch"
                          placeholder="Colour Swatch"
                          disabled
                          value={this.state.colorSwatch}
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <button
                        type="button"
                        className="btn btn-dark mr-2"
                        data-toggle="modal"
                        data-target="#viewPicker"
                        style={{ marginTop: '35px' }}
                      >Pick Now
                  </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.validateField()}
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <button type="button" className="btn btn-light" onClick={()=>{this.props.history.push('/colour')}}>
                    Cancel
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
        {
          this.checkUpdates()
        }
        <Loader loading={this.props.loading} />
        <div className="modal fade" id="viewPicker" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">Colour Swatch</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <SketchPicker width="90%" color={this.state.colorSwatch} onChangeComplete={this.handleChangeComplete} />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" ref="modalClose" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" ref="modalClose" className="btn btn-success" data-dismiss="modal">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  updateAndEmptyBox = () => {
    this.props.updateAddColorResponse({
      data: {}
    });
    this.setState({
      name: '',
      colorCode: ''
    })
  }

  checkUpdates = () => {
    let { data } = this.props.addColorResponse;
    if (data.response === false) {
      toast.error(data.msg.toUpperCase());
      this.props.updateAddColorResponse({
        data: {}
      });
    } else if (data.response) {
      toast.success(data.msg.toUpperCase());
      this.updateAndEmptyBox();
    }
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    colorAdd: (data) => dispatch(colorAdd(data)),
    updateAddColorResponse: (data) => dispatch(updateAddColorResponse(data))
  }
}

const mapStateToProps = () => state => ({
  addColorResponse: state.Color.addColorResponse,
  loading: state.Color.loader
})

export default connect(mapStateToProps, mapDispatchToProps)(AddColor);
