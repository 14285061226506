import React, { Component } from 'react';
import ConfirmModal from './ConfirmModal';
import { toast } from 'react-toastify';
import Helper from '../../../helper/Helper';

export default class EditAssignProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProduct: {},
            productToSend: {},
            arrayOfProducts: []
        }
    }
    //checking the price existence for modal 
    // if use have not entered the price then modal will not close with the click of button
    //else modal willclose witht he click of button
    checkPrice = () => {
        let { productToSend } = this.state;
        if (productToSend.price === '' || productToSend.price === undefined) {
            return false
        }
        else {
            return true
        }
    }
    //saving the product got from the api to local var
    //saving the price to local var
    componentDidMount() {
        let { selectedProductToSave, price } = this.props.location.state;
        this.savePreviousColorVariations(selectedProductToSave, price);
        this.generatePreviousSelectedProducts()
        this.setState({
            selectedProduct: selectedProductToSave
        });
    }
    generatePreviousSelectedProducts = () => {
        let { allProducts } = this.props.location.state;
        let arrayOfProducts = [];
        for (const iterator of allProducts.products) {
            let tempSingleProduct = {};
            tempSingleProduct.productId = iterator._id;
            tempSingleProduct.colorVariations = iterator.data.colorVariations;
            tempSingleProduct.price = iterator.data.price;
            arrayOfProducts.push(tempSingleProduct)
        }
        this.setState({
            arrayOfProducts
        })
    }
    //handeling price input 
    handleChangePrice = (value) => {
        let { productToSend } = this.state;
        productToSend.price = value;
        this.setState({
            productToSend
        })
    }
    //saving previous color variations that are assigned already to local var
    savePreviousColorVariations = (selectedProductToSave, price) => {
        let tempProductToSend = {};
        tempProductToSend.productId = selectedProductToSave._id;
        tempProductToSend.price = price;
        tempProductToSend.colorVariations = [];
        for (let i = 0; i < selectedProductToSave.colorVariations.length; i++) {
            if (selectedProductToSave.colorVariations[i].assigned) {
                tempProductToSend.colorVariations.push(selectedProductToSave.colorVariations[i].color._id)
            };
        };
        this.setState({
            productToSend: tempProductToSend,
        });
    }
    //checking if user checked the checkbox
    //1. if user checked the checkbox for 1st time it. no other checkbox is checked
    //2. if user checked second and more time ie. at least one checkbox is checked 
    //3. else user is unchecking the checkbox
    handleCheck = (e, product, indexcolor) => {
        let { checked } = e.target;
        let { productToSend, selectedProduct } = this.state;
        if (checked && Object.keys(productToSend).length === 0) {
            let tempColorVariations = [];
            tempColorVariations.push(product.color._id)
            let tempProductToSend = {
                productId: selectedProduct._id,
                colorVariations: tempColorVariations,
                mainCode: selectedProduct.mainCode,
                unitPrice: selectedProduct.unitPrice
            }
            let tempSelectedColor = this.state.selectedProduct;
            tempSelectedColor.colorVariations[indexcolor].assigned = true
            this.setState({
                productToSend: tempProductToSend,
                selectedProduct: tempSelectedColor
            })
        }
        else if (checked && Object.keys(productToSend).length !== 0) {
            let temporaryColorVariations = productToSend.colorVariations;
            temporaryColorVariations.push(product.color._id);
            let tempSelectedColor = this.state.selectedProduct;
            tempSelectedColor.colorVariations[indexcolor].assigned = true
            this.setState({
                ...productToSend, colorVariations: temporaryColorVariations,
                selectedProduct: tempSelectedColor
            })
        }
        else {
            let indexOfColorToDelete = 0;
            for (const iterator of productToSend.colorVariations) {
                if (iterator === product.color._id) {
                    let tempProductColors = productToSend.colorVariations;
                    tempProductColors.splice(indexOfColorToDelete, 1);
                    if (tempProductColors.length === 0) {
                        let tempSelectedColor = this.state.selectedProduct;
                        tempSelectedColor.colorVariations[indexcolor].assigned = false
                        this.setState({
                            productToSend: {},
                            selectedProduct: tempSelectedColor
                        })
                    }
                    else {
                        let tempSelectedColor = this.state.selectedProduct;
                        tempSelectedColor.colorVariations[indexcolor].assigned = false
                        this.setState({
                            ...productToSend, colorVariations: tempProductColors,
                            selectedProduct: tempSelectedColor
                        })
                    }
                }
                indexOfColorToDelete = indexOfColorToDelete + 1;
            }
        }
    }
    //toggle Loader
    toggleLoader = () => {
        this.setState({
            loading: !this.state.loading
        })
    }
    assignProduct = () => {
        let { productToSend, arrayOfProducts } = this.state;
        let { agentId } = this.props.location.state;
        if (productToSend.price === '') {
            toast.error("Please enter the price of the product");
        }
        else {
            this.toggleLoader();
            let indexFound = 0;
            for (const iterator of arrayOfProducts) {
                if (iterator.productId === productToSend.productId) {
                    arrayOfProducts.splice(indexFound, 1);
                }
                indexFound = indexFound + 1;
            }
            arrayOfProducts.push(productToSend);
            let body = {
                updateAssignedProduct: true,
                productAssigned: arrayOfProducts
            }
            let res = Helper('agent/assignProduct/' + agentId, 'PUT', body);
            res.then((response) => {
                if (response.data.response) {
                    toast.success(response.data.msg)
                    this.props.history.push('/agent/')
                }
                else {
                    toast.error(response.data.msg)
                }
            })
                .catch((error) => {
                    alert("Something went wrong, Please try again later.")
                })
                .finally(() => {
                    this.toggleLoader()
                })
        }
    }
    render() {
        let { selectedProduct } = this.state;
        let { agentName } = this.props.location.state;
        return (
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-12 pr-0">
                                        <div className="d-lg-flex">
                                            <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                                Assigning Product: {agentName}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card shadow">
                                                    <div
                                                        className="card-body text-center d-flex justify-content-center align-items-center"
                                                        style={{ height: "454px" }}
                                                    >
                                                        {selectedProduct.imageUrl === null ||
                                                            selectedProduct.imageUrl === "" ||
                                                            selectedProduct.imageUrl === undefined ? null : (
                                                                <img
                                                                    src={selectedProduct.imageUrl}
                                                                    className="img-fluid"
                                                                    alt="productImage"
                                                                    style={{ maxHeight: "100%" }}

                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card shadow">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <h3 className="text-uppercase text-dark">
                                                                    {selectedProduct.mainCode}
                                                                    <small> ({selectedProduct.brand})</small>
                                                                </h3>
                                                                <h6>{selectedProduct.category}</h6>
                                                            </div>
                                                            <div className="col-3 text-right">
                                                                <h2 className="text-dark">£ {selectedProduct.unitPrice}</h2>
                                                            </div>
                                                        </div>
                                                        <p>{selectedProduct.code}</p>
                                                        <hr />
                                                        <div className="w-100 table-responsive">
                                                            <table className="table no-border td-p">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Category</th>
                                                                        <td>{selectedProduct.category}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Main Code</th>
                                                                        <td>{selectedProduct.mainCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Type</th>
                                                                        <td>{selectedProduct.type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Unit Price</th>
                                                                        <td>£ {selectedProduct.unitPrice}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Cost Price</th>
                                                                        <td>
                                                                            £{" "}
                                                                            {selectedProduct.costPrice
                                                                                ? selectedProduct.costPrice
                                                                                :  "-"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Assigned Price</th>
                                                                        <td>£ {selectedProduct.assignedPrice}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Item Type</th>
                                                                        <td>{selectedProduct.itemType}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        Object.keys(this.state.selectedProduct).length === 0 ? (
                                            null
                                        ) : (
                                                <div className="row m-3">
                                                    <div className="col-12">
                                                        <div className="card shadow">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-hover table-striped min-w-700">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th>#</th>
                                                                                        <th>Color</th>
                                                                                        <th>Barcode</th>
                                                                                        <th>Name</th>
                                                                                        <th>Description</th>
                                                                                        {/* <th>Unit Price</th> */}
                                                                                        {/* <th>Cost Price</th> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.selectedProduct.colorVariations.map(
                                                                                        (product, indexcolor) => {
                                                                                            return (
                                                                                                <tr key={indexcolor}>
                                                                                                    <td>
                                                                                                        <label className="checkbox">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                checked={product.assigned}
                                                                                                                onClick={e => {
                                                                                                                    this.handleCheck(
                                                                                                                        e,
                                                                                                                        product,
                                                                                                                        indexcolor,
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                            <span className="checkmark"></span>
                                                                                                        </label>
                                                                                                    </td>
                                                                                                    <td>{indexcolor + 1}</td>
                                                                                                    <td>
                                                                                                        {product.color.name}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {product.barcode.barcode}
                                                                                                    </td>
                                                                                                    <td>{product.name}</td>
                                                                                                    <td>
                                                                                                        {product.description}
                                                                                                    </td>
                                                                                                    {/* <td>
                                                                                                            {selectedProduct.unitPrice}
                                                                                                        </td> */}
                                                                                                    {/* <td>
                                                                                                            {selectedProduct.costPrice === null || selectedProduct === '' ? ('-') : (selectedProduct.price)}
                                                                                                        </td> */}
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            Object.keys(this.state.productToSend).length !== 0 ? (
                                <div className="row">
                                    <div className="col-sm-9"></div>
                                    <div className="col-sm-3">
                                        <div className="dropdown float-right">
                                            <span className="cursor">
                                                {/* display add product button when at least one product is selected */}
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#confirmModal"
                                                >
                                                    <i className="mdi mdi-clipboard-check mr-1"></i> <span>Edit Product</span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                    null
                                )
                        }
                    </div>
                </div>
                <ConfirmModal 
                    assignProduct={this.assignProduct} 
                    checkPrice={this.checkPrice} 
                    handleChangePrice={this.handleChangePrice} 
                    productToSend={this.state.productToSend} 
                />
            </div>
        )
    }
}
