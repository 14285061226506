import {
    ADD_SUPPLIER, FETCH_SUPPLIER, UPDATE_SUPPLIER, SEARCH_SUPPLIER, FETCH_ALL_SUPPLIER
} from '../actions/types'

const initialState = {

    //add supplier states
    loader: false,
    supplierResponse: {},
    allSuppliers: { data: { suppliers: [] } },
    supplierUpdateResponse: {},
    searchSupplierResponse: [],
    totalAllSuppliers: { data: { suppliers: [] } },
}

export default function (state = initialState, action) {
    switch (action.type) {
        //add suppliers
        case `${ADD_SUPPLIER}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${ADD_SUPPLIER}_FULFILLED`:
            return {
                ...state,
                loader: false,
                supplierResponse: action.payload
            }
        case `${ADD_SUPPLIER}_REJECTED`:
            return {
                ...state,
                loader: false,
                supplierResponse: action.payload.response
            }

        //fetch Suppliers
        case `${FETCH_SUPPLIER}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${FETCH_SUPPLIER}_FULFILLED`:
            return {
                ...state,
                loader: false,
                allSuppliers: action.payload
            }
        case `${FETCH_SUPPLIER}_REJECTED`:
            return {
                ...state,
                loader: false
            }

        //update supplier
        case `${UPDATE_SUPPLIER}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${UPDATE_SUPPLIER}_FULFILLED`:
            return {
                ...state,
                loader: false,
                supplierUpdateResponse: action.payload
            }
        case `${UPDATE_SUPPLIER}_REJECTED`:
            return {
                ...state,
                loader: false,
                supplierUpdateResponse: action.payload.response
            }

        //fetch All Suppliers
        case `${FETCH_ALL_SUPPLIER}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${FETCH_ALL_SUPPLIER}_FULFILLED`:
            return {
                ...state,
                loader: false,
                totalAllSuppliers: action.payload
            }
        case `${FETCH_ALL_SUPPLIER}_REJECTED`:
            return {
                ...state,
                loader: false
            }

        // SEARCH_SUPPLIER
        case `${SEARCH_SUPPLIER}_PENDING`:
            return {
                ...state,
                loader: true,
            }
        case `${SEARCH_SUPPLIER}_FULFILLED`:
            return {
                ...state,
                loader: false,
                allSuppliers: action.payload
            }
        case `${SEARCH_SUPPLIER}_REJECTED`:
            return {
                ...state,
                loader: false,
                allSuppliers: action.payload.response
            }

        default:
            return {
                ...state
            }

    }
}
