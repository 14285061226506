import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../inc/Loader";
import { connect } from "react-redux";
import { staffFetch } from "../../actions/staffActions";
import NewPagination from "../../Common/NewPagination";
// import ViewAgentModal from "./ViewAgentModal";

class Staff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAgent: {
                name: '',
                county: '',
                country: '',
                address: '',
                phone: '',
                mobile: '',
                zone: '',
                commissionPercentage: '',
            },
            page: 1,
            //User need to set the value from button and pagination also
            perPage: 10,
            searchText: ''
        };
    }

    //Searching starts here

    // searchAgent = () => {
    //     // To remove clicking of ctrl, alt, etc
    //     if (this.state.searchText !== this.state.temporaryText) {
    //         this.setState({
    //             page: 1
    //         });
    //         let body = {
    //             page: this.state.page,
    //             perPage: this.state.perPage,
    //             searchText: this.state.searchText
    //         };
    //         this.setState({
    //             temporaryText: this.state.searchText
    //         });

    //         //To check if there's any text in search
    //         if (this.state.searchText.length !== 0) {
    //             this.props.agentSearch(body);
    //         } else {
    //             this.props.agentFetch(body);
    //         }
    //     }
    // };

    // For setting no of records per page
    // setPerPage = e => {
    //     this.setState(
    //         {
    //             perPage: e.target.value,
    //             page: 1
    //         },
    //         () => {
    //             let body = {
    //                 page: 1,
    //                 perPage: this.state.perPage
    //             };
    //             this.props.agentFetch(body);
    //         }
    //     );
    // };

    debounce = (callback, delay) => {
        let timer;
        return function () {
            clearTimeout(timer);
            timer = setTimeout(() => {
                callback();
            }, delay);
        };
    };

    // getData = this.debounce(this.searchStaff, 400);

    searchStaff=()=>{
        let {searchText} = this.state;
    }

    stateSet = e => {
        let val = e.target.value;
        if (
            val === "\\" ||
            val === "+" ||
            val === "(" ||
            val === ")" ||
            val === "?" ||
            val === "{" ||
            val === "}" ||
            val === "[" ||
            val === "]"
        ) {
            e.target.value = "";
        }
        this.setState({
            searchText: e.target.value
        });
    };

    // clearSearch = () => {
    //     this.setState({
    //         searchText: ""
    //     });
    //     let body = {
    //         perPage: this.state.perPage,
    //         page: this.state.page
    //     };
    //     this.props.agentFetch(body);
    // };

    //Searching ends here

    //Pagination Handling methods set page no
    // getNextPage = i => {
    //     //if search is empty then get response from fetchSupplier
    //     this.setState({
    //         page: i
    //     });
    //     if (this.state.searchText === "") {
    //         let body = {
    //             perPage: this.state.perPage,
    //             page: i
    //         };
    //         this.props.agentFetch(body);
    //     }

    //     //Else get search from searchSupplier
    //     else {
    //         let body = {
    //             searchText: this.state.searchText,
    //             perPage: this.state.perPage,
    //             page: i
    //         };
    //         this.props.agentSearch(body);
    //     }
    // };

    render() {
        // let { perPage, currentAgent } = this.state;
        let { response, loading } = this.props;
        return (
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                        All Staff
                  </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="dropdown float-right">
                            <Link to="/staff/create">
                                <button
                                    className="btn btn-sm bg-white font-weight-bold text-dark"
                                    type="button"
                                >
                                    <i className="mdi mdi-plus mr-1"></i>Add Staff
                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="form-group m-0 mx-2 float-left">
                                            <div className="col-xs-12">
                                                <input
                                                    placeholder="Search ..."
                                                    type="text"
                                                    className="form-control rounded"
                                                    onChange={this.stateSet}
                                                    onKeyUp={this.searchStaff}
                                                    value={this.state.searchText}
                                                />
                                            </div>
                                        </div>
                                        {
                                            this.state.searchText === '' ? (null) :
                                                (<div className="mx-2 float-left">
                                                    <button
                                                        className="btn bg-danger font-weight-bold text-white"
                                                        type="button"
                                                        onClick={this.clearSearch}
                                                    >
                                                        Clear
                      </button>
                                                </div>)}
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="float-right">
                                                <select
                                                    className="form-control"
                                                    id="type"
                                                    name="type"
                                                    placeholder="Select Type"
                                                    style={{
                                                        height: "46px",
                                                        width: "150px",
                                                        marginTop: "2px"
                                                    }}
                                                    onChange={this.setPerPage}
                                                >
                                                    <option disabled="" value="10">
                                                        10
                          </option>
                                                    <option disabled="" value="20">
                                                        20
                          </option>
                                                    <option disabled="" value="30">
                                                        30
                          </option>
                                                    <option disabled="" value="50">
                                                        50
                          </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12 py-2">
                                        {/* <hr /> */}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover min-w-700 table_heading_view" id="table-print">
                                                <thead>
                                                    <tr className="heading_mine">
                                                        <th>#</th>
                                                        <th>Staff Name</th>
                                                        <th>Username</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {response.data.users.map((v, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    {i + 1}
                                                                </td>
                                                                <td className="text-capitalize">{v.fullName}</td>
                                                                <td>{v.username}</td>
                                                                <td>{v.email}</td>
                                                                <td>{v.phoneNumber}</td>
                                                                <td>
                                                                    <span
                                                                        onClick={() => {
                                                                            this.props.history.push("/staff/create", {
                                                                                staff: v,
                                                                                edit: true
                                                                            });
                                                                        }}
                                                                        className="text-decoration-none"
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn ml-2   btn-dark btn-icon-text"
                                                                        >
                                                                            <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                                                            Edit
                                                                    </button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                            {response.data.users.length === 0
                                                ? (<div className="noProductsFound">
                                                    <span>No Staff Found</span>
                                                </div>)
                                                : null}
                                            {/* Pagination start */}

                                            {/* <NewPagination
                                                perPage={perPage}
                                                totalPages={response.data.totalPages}
                                                currentPage={response.data.currentPage}
                                                getNextPage={i => this.getNextPage(i)}
                                            // searchText={searchText}
                                            /> */}

                                            {/* Pagination Ends */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button onClick={() => { print(this.props.response.data.agents) }}>Save PDF</button> */}
                <Loader loading={loading} />
                {/* <ViewAgentModal agent={currentAgent} /> */}
            </div>
        );
    }

    // hit server to  get agent data
    componentDidMount() {
        this.props.staffFetch();
    }
}

const mapStateToProps = () => state => ({
    response: state.Staff.staffFetchResponse,
    loading: state.Staff.loader
});

const mapDispatchToProps = dispatch => ({
    staffFetch: page => dispatch(staffFetch(page)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Staff);
