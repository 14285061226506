import { EXHIBITION_ADD, EXHIBITION_FETCH, EXHIBITION_UPDATE, EXHIBITION_FETCH_ALL } from './types'

import Helper from '../helper/Helper'

//Inserting new exhibition action
export const exhibitionAdd = (body) => ({
  type: EXHIBITION_ADD,
  payload: Helper('exhibition/save', 'POST', body)
})

export const exhibitionFetch = (body) => ({
  type: EXHIBITION_FETCH,
  payload: Helper('exhibition/fetch', "POST", body)
})

//Editing action
export const exhibitionUpdate = (id, body) => ({
  type: EXHIBITION_UPDATE,
  payload: Helper('exhibition/edit/' + id, 'PUT', body)
})

export const exhibitionFetchAll=()=>({
  type: EXHIBITION_FETCH_ALL,
  payload: Helper('exhibition/fetch/', 'GET', null)
})