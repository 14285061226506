import React, { Component } from "react";
import { connect } from 'react-redux';
import { colorUpdate } from '../../../actions/colorActions'
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import Loader from '../../../inc/Loader'
import { SketchPicker } from 'react-color'

class EditColor extends Component {
  constructor(props) {

    super(props);
    let { editColor } = this.props.history.location.state;
    this.state = {
      colorId: editColor._id,
      name: editColor.name,
      colorCode: editColor.colorCode,
      colorSwatch: editColor.colorSwatch
    };
  }

  handleInput = (e) => {
    let { value, name } = e.target;
    if (value === '') {
      this.refs[name].classList.add('red')
    }
    else {
      this.refs[name].classList.remove('red')
    }
    this.setState({
      [name]: value
    })
  }

  validate = () => {
    let { name, colorCode } = this.state;
    if (name === '') {
      this.showErrorToast('name', "name")
    }
    else if (colorCode === '') {
      this.showErrorToast('colorCode', 'Color Code')
    }
    else {
      this.updateColorNow()
    }
  }

  showErrorToast = (name, toDisplay) => {
    toast.error('PLEASE ENTER ' + toDisplay.toUpperCase() + '!');
    this.refs[name].focus();
    this.refs[name].classList.add('red')
  }

  buttonTitle = () => {
    if (this.state.formValid === false) {
      return "Please fill all details";
    }
    return "";
  };

  updateColorNow = async () => {
    let { name, colorCode, colorId, colorSwatch } = this.state;
    let color = {
      name,
      colorCode,
      colorSwatch
    };
    await this.props.updateColor(colorId, color);
  };

  updateResponse = res => {
    if (res.data.response) {
      this.props.history.push('/colour', { msgg: res.data.response, response: res.data.msg });

    } else {
      toast.error(res.data.msg);
    }
  };

  componentWillReceiveProps = newProps => {
    if (
      this.props.response !== newProps.response &&
      newProps.response !== undefined
    ) {
      this.updateResponse(newProps.response);
      if(newProps.response.data.response) {
        toast.success(newProps.response.data.msg)
      } 
      else{
        toast.error(newProps.response.data.msg)
      }
    }
  };
  handleChangeComplete = (color) => {
    this.setState({ colorSwatch: color.hex });
  };

  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mt-2 mr-5 text-uppercase">
                    Edit Colour
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/colour/">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-eye mr-1"></i>All Colours
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="colorname">Colour Name</label>
                        <input
                          type="text"
                          className={`form-control `}
                          id="name"
                          name="name"
                          ref="name"
                          placeholder="Colour Name"
                          required
                          value={this.state.name}
                          onChange={event => this.handleInput(event)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="colorname">Colour Code</label>
                        <input
                          type="text"
                          className={`form-control `}
                          name="colorCode"
                          id="colorCode"
                          ref="colorCode"
                          placeholder="Colour Code"
                          required
                          value={this.state.colorCode}
                          onChange={event => this.handleInput(event)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="colorSwatch">Colour Swatch</label>
                        <input
                          type="text"
                          className={`form-control`}
                          name="colorSwatch"
                          id="colorSwatch"
                          ref="colorSwatch"
                          placeholder="Colour Swatch"
                          disabled
                          value={this.state.colorSwatch}
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <button
                        type="button"
                        className="btn btn-dark mr-2"
                        data-toggle="modal"
                        data-target="#viewPicker"
                        style={{ marginTop: '35px' }}
                      >Pick Now
                  </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.validate()}
                    className="btn btn-primary mr-2"
                  >
                    Update
                  </button>
                  <button type="button" className="btn btn-light" onClick={() => { this.props.history.push('/colour') }}>
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={this.props.loading} />
        <div className="modal fade" id="viewPicker" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">Colour Swatch</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <SketchPicker width="90%" color={this.state.colorSwatch} onChangeComplete={this.handleChangeComplete} />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" ref="modalClose" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" ref="modalClose" className="btn btn-success" data-dismiss="modal">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateColor: (id, data) => dispatch(colorUpdate(id, data))
  }
}

const mapStateToProps = () => state => ({
  response: state.Color.colorUpdateResponse,
  loading: state.Color.loader
})

export default connect(mapStateToProps, mapDispatchToProps)(EditColor);

