import React, { Component } from 'react'
import './loader.css'

export default class Loader extends Component {
    render() {
        if(this.props.loading){
            return (
                <div className="parentLoading">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            )
        }
        else{
            return null
        }
    }
}
