import React, { Component } from "react";
import { toast } from "react-toastify";

//redux imports
import { connect } from 'react-redux'
import { printFourSticker } from '../../../actions/productActions'
import { DOCUMENTS_URL } from "../../../Config";
import Loader from "../../../inc/Loader";

class DateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateI: '',
            dateE: '',
            qty: ''
        }
    }
    handleChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value })
    }
    validate = () => {
        let { dateE, dateI, qty } = this.state;
        let valid = false;
        if (dateI === '') {
            toast.error("Please enter From Date!!");
        }
        else if (dateE === '') {
            toast.error("Please enter End Date!!");
        }
        else if (qty === '') {
            toast.error("Please enter Quantity!!");
        }
        else {
            valid = true;
        }
        return valid
    }
    checkButton = () => {
        let { dateE, dateI, qty } = this.state;
        if (dateE === "" || dateI === "" || qty === "") return false
        else return true
    }
    printStickers = () => {
        if (this.validate()) {
            let { dateI, dateE, qty } = this.state;
            let body = {
                isDate: true,
                from: dateI,
                to: dateE,
                qty
            }
            this.props.productBarcodePrint(body)
        }
    }
    render() {
        return (
            <div
                className="modal fade"
                id="dateModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalLabel">
                                Please Enter Date and Quantity
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ padding: "20px 26px" }}>
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <form className="forms-sample">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="date">Start Date</label>
                                                            <input
                                                                name="dateI"
                                                                ref="dateI"
                                                                type="date"
                                                                className="form-control"
                                                                onChange={(e) => { this.handleChange(e) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="date">End Date</label>
                                                            <input
                                                                name="dateE"
                                                                ref="dateE"
                                                                type="date"
                                                                className="form-control"
                                                                onChange={(e) => { this.handleChange(e) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="date">Quantity</label>
                                                            <input
                                                                name="qty"
                                                                ref="qty"
                                                                type="number"
                                                                className="form-control"
                                                                min={0}
                                                                placeholder="Quantity"
                                                                onChange={(e) => { this.handleChange(e) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-target="#dateModal"
                                data-toggle="modal"
                            >
                                Cancel
                            </button>
                            {
                                this.checkButton() ? (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => { this.printStickers() }}
                                        data-target="#dateModal"
                                        data-toggle="modal"
                                    >
                                        Print Now
                                    </button>
                                ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => { this.printStickers() }}
                                        >
                                            Print Now
                                    </button>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <Loader loading={this.props.loading} />
            </div>
        );
    }
    componentWillReceiveProps(newProps) {
        if (newProps.productStickerData !== undefined && this.props.productStickerData !== newProps.productStickerData) {
            let { data } = newProps.productStickerData;
            if (data.response) {
                toast.success(data.msg);
                window.open(DOCUMENTS_URL + data.filename);
            }
            else {
                toast.error(data.msg)
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    productBarcodePrint: body => dispatch(printFourSticker(body))
});

const mapStateToProps = () => state => ({
    productStickerData: state.Product.productStickerData,
    loading: state.Product.loader
});
export default connect(mapStateToProps, mapDispatchToProps)(DateModal)