import React, { Component } from "react";
import Loader from "../../inc/Loader";
import _ from "lodash";

import { connect } from "react-redux";
import {
  productSearch,
  productSearchReset
} from "../../actions/productActions";

import { productAssign } from "../../actions/storeActions";
import { toast } from "react-toastify";
import AssignProductConfirmationModal from "./AssignProductConfirmationModal";

const defaultImage = "https://via.placeholder.com/150";

class AssignProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      selectedProduct: {},
      // branches: this.props.history.location.state.branches,
      tempColorVariation: [],
      // storeId: this.props.history.location.state.storeID,
      branchArray: [],
      checkBoxClicked: false,
      store: this.props.history.location.state.currentStore,

      tempQty: '',
      tempPrice: '',
      selectedProducts: [],
    };
  }

  //repeat order check
  componentDidMount() {
    if (this.props.history.location.state.repeat || this.props.history.location.state.edit) {
      let { repeat, orders, edit } = this.props.history.location.state;
      if (repeat || edit) {
        this.mapBranchAndSelectedProducts(orders);
      }
    }
  }
  findColorId = (id, colorVariations) => {
    let index = colorVariations.findIndex(i => i.color._id === id);
    return index
  }
  mapBranchAndSelectedProducts = (orders) => {
    let { selectedProducts, store, branchArray } = this.state;
    for (const order of orders) {
      selectedProducts.push(order.product);
      for (const iterator of store.branches) {
        let allTempColorVariations = [];
        for (const colorObject of order.product.colorVariations) {
          let indexColor = this.findColorId(colorObject.color._id, order.colorVariations)
          allTempColorVariations.push({
            color: colorObject.color._id,
            qty: order.branch === iterator._id ? (indexColor !== -1 ? (order.colorVariations[indexColor].qty) : ('')) : (''),
            price: indexColor !== -1 ? (order.colorVariations[indexColor].price) : (''),
            colorName: colorObject.color.name
          });
        }
        branchArray.push({
          store: store._id,
          branch: iterator._id,
          product: order.product._id,
          branchName: iterator.locationName,
          colorVariations: allTempColorVariations,
          productName: order.product.mainCode,
          rrp: order.rrp
        });
      }
      this.setState({
        selectedProducts,
        selectedProduct: order.product,
        searchText: '',
        branchArray
      }, () => { this.props.searchProductsReset() })
    }
  }


  search = _.debounce(e => {
    let { searchText } = this.state;
    let body = {
      searchText
    };
    if (searchText !== "") {
      this.props.searchProducts(body);
    }
  }, process.env.REACT_APP_DEBOUNCE);



  saveProduct = v => {

    //list of products that is alreayd selected by the user
    let { selectedProducts, branchArray, store } = this.state;

    //finding index if product alreayd existed or not
    let index = selectedProducts.findIndex(i => i._id === v._id); // 

    //if product do not exist in the existing list then add the product to the list and empty the current response of the search product lists
    if (index === -1) {
      selectedProducts.push(v);
      for (const iterator of store.branches) {
        let allTempColorVariations = [];
        for (const colorObject of v.colorVariations) {
          allTempColorVariations.push({
            color: colorObject.color._id,
            qty: '',
            price: '',
            colorName: colorObject.color.name
          });
        }
        branchArray.push({
          store: store._id,
          branch: iterator._id,
          product: v._id,
          branchName: iterator.locationName,
          colorVariations: allTempColorVariations,
          productName: v.mainCode,
          rrp: 0
        });
      }
      this.setState({
        selectedProducts,
        selectedProduct: v,
        searchText: '',
        branchArray
      }, () => { this.props.searchProductsReset() })
    }
    //if product already existed then notify user that product already in the list
    else {
      toast.error("Product already in the list below");
    }
  };


  handlePriceInput = (color, product, value) => {
    let { branchArray } = this.state;
    let productIndex = [];
    for (let i = 0; i < branchArray.length; i++) {
      if (branchArray[i].product === product._id) {
        productIndex.push(i);
      }
    }

    for (let j = 0; j < productIndex.length; j++) {
      for (const iterator of branchArray[productIndex[j]].colorVariations) {
        if (iterator.color === color.color._id) {
          iterator.price = value
        }
      }
    }
    this.setState({
      branchArray
    })
  }

  handleAllPrice = (value, productIndex, branchLength) => {
    let { branchArray } = this.state;
    for (let i = 0; i < branchLength; i++) {
      for (const iterator of branchArray[productIndex * branchLength + i].colorVariations) {
        iterator.price = value;
      }
    }
    this.setState({ branchArray })
  }

  assignProduct = () => {
    let { branchArray } = this.state;
    let { edit } = this.props.history.location.state;
    let arrayToSend = [];
    for (const order of branchArray) {
      let singleOrder = {
        store: order.store,
        branch: order.branch,
        colorVariations: [],
        product: order.product,
        rrp: order.rrp
      }
      for (const colorVariation of order.colorVariations) {
        if (colorVariation.qty !== '' && colorVariation.qty !== 0) {
          let colorVariationToPush = {
            color: colorVariation.color,
            qty: colorVariation.qty,
            price: colorVariation.price
          }
          singleOrder.colorVariations.push(colorVariationToPush)
        }
      }
      if (singleOrder.colorVariations.length !== 0) {
        arrayToSend.push(singleOrder);
      }
    }
    let body = {}
    if (edit) {
      body = {
        data: arrayToSend,
        orderToBeDeleted: this.props.history.location.state.deleteId
      };
    }
    else {
      body = {
        data: arrayToSend
      };
    }
    this.props.productAssign(body);
  };
  handleAllQty = (value, branchIndex, branchArrLen, productIndex) => {
    let dataUpdateIndex = (productIndex * branchArrLen) + branchIndex;
    let { branchArray } = this.state;
    for (const iterator of branchArray[dataUpdateIndex].colorVariations) {
      iterator.qty = value
    }
    this.setState({ branchArray })
  }
  handleRRP = (value, product) => {
    let { branchArray } = this.state;
    for (const branch of branchArray) {
      if (branch.product === product._id) {
        branch.rrp = value
      }
    }
    this.setState({ branchArray });
  }
  render() {
    let { selectedProducts, store, searchText, branchArray } = this.state;
    let { repeat } = this.props.history.location.state.repeat ? (this.props.history.location.state) : (false);
    let { edit } = this.props.history.location.state.edit ? (this.props.history.location.state) : (false);
    let lastIndex = 0;
    return (
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-12 pr-0">
                    <div className="d-lg-flex">
                      <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                        {repeat ? ('Repeat Order') : (edit ? ('Edit Order') : ('Place Order'))}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 grid-margin stretch-card">
                <div className="card shadow">
                  <div className="card-body">
                    {/* search product started */}
                    <div className="row pb-3">
                      <div className="col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            aria-label="Product Name"
                            aria-describedby="button-addon2"
                            value={searchText}
                            onChange={e => {
                              this.setState(
                                { searchText: e.target.value },
                                () => {
                                  this.search();
                                }
                                // { searchText: "b" }
                              );
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              type="button"
                              id="button-addon2"
                              onClick={() => {
                                this.search();
                              }}
                            >
                              Search Products
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.props.response.data.products.length === 0 ? null : (
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-hover min-w-700 table_heading_view">
                              <thead>
                                <tr className="heading_mine">
                                  <th>#</th>
                                  <th>Product Image</th>
                                  <th>Main Code</th>
                                  <th>Unit Price</th>
                                  <th>Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.response.data.products.map(
                                  (v, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          <img
                                            src={v.imageUrl}
                                            alt="product"
                                            style={{
                                              height: "130px",
                                              borderRadius: "25%",
                                              width: "auto",
                                              display: "block",
                                              margin: "0 auto"
                                            }}
                                            onError={e => {
                                              e.target.onerror = null;
                                              e.target.src = defaultImage;
                                            }}
                                          />
                                        </td>
                                        <td >{v.mainCode}</td>
                                        <td>£ {v.unitPrice}</td>
                                        <td>
                                          <span
                                            onClick={() => {
                                              this.props.history.push(
                                                "/product/view",
                                                { product: v }
                                              );
                                            }}
                                            to="/product/view"
                                            className="text-decoration-none"
                                          >
                                            <button
                                              type="button"
                                              className="btn-sm btn btn-primary btn-icon-text"
                                            >
                                              <i className="mdi mdi-eye btn-icon-prepend"></i>
                                              View
                                            </button>
                                          </span>
                                          <span className="text-decoration-none">
                                            <button
                                              type="button"
                                              className="btn-sm btn ml-2 btn-dark btn-icon-text"
                                              onClick={() => {
                                                this.saveProduct(v);
                                              }}
                                            >
                                              <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                              Select Product
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                            {!this.props.productsFound ? (
                              <div className="noProductsFound">
                                <span>No Products Found</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {
                      selectedProducts.map((product, i) => {
                        let lastColor = false
                        return (
                          <div className="card shadow mt-2" key={i}>
                            <div className="row pb-4">
                              <div className="col-2">
                                <div className="d-flex mt-5 align-items-center ml-5 flex-column" style={{ height: '100%' }}>
                                  <div className="d-flex flex-row p-3 justify-content-center align-items-center">
                                    <h5 className="mt-1">RRP:</h5>
                                    <input type="number" onChange={(e) => { this.handleRRP(e.target.value, product) }} value={branchArray[i * (store.branches.length)].rrp} placeholder="RRP" style={{ width: '50px', borderColor: '#eee', borderRadius: '1px' }} />
                                  </div>
                                  <img
                                    src={product.imageUrl}
                                    alt="product"
                                    style={{ width: '100%' }}
                                    onError={e => {
                                      e.target.onerror = null;
                                      e.target.src = defaultImage;
                                    }}
                                  />
                                  <h4 className="mt-2" style={{ textAlign: 'center', justifyContent: 'center' }}>{product.mainCode}</h4>
                                  <span className="mt-1">U.P - £{product.unitPrice}</span>
                                  <span >C.P - £{product.costPrice}</span>
                                </div>
                              </div>
                              <div className="col-10">
                                <div className="card-body">
                                  <div className="col-12 d-flex justify-content-end">
                                    <button
                                      className="btn btn-sm btn-danger"
                                      type="button"
                                      onClick={() => { this.deleteCheck(product, i) }}
                                    >
                                      <i className="mdi mdi-minus"></i>
                                    </button>
                                  </div>
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Colour</th>
                                          <th>Bar Code</th>
                                          <th>Description</th>
                                          <th>
                                            <input type="number" placeholder="Price." style={{ width: '50px' }} onChange={(e) => { this.handleAllPrice(e.target.value, i, store.branches.length) }} /><br />
                                            Price
                                          </th>
                                          {
                                            store.branches.map((branch, branchIndex) => {
                                              return (
                                                <th className="text-capitalize" key={branchIndex}>
                                                  <input type="number" placeholder="Qty." style={{ width: '50px' }} onChange={(e) => { this.handleAllQty(e.target.value, branchIndex, store.branches.length, i) }} /><br />{branch.locationName}
                                                </th>
                                              )
                                            })
                                          }
                                          <th>
                                            RRP
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          product.colorVariations.map((color, colorIndex) => {
                                            let currentRRP = branchArray[i * (store.branches.length)].rrp;
                                            let singleRRP = +currentRRP * +branchArray[lastIndex].colorVariations[colorIndex].price;
                                            singleRRP % 5 === 0 ? (singleRRP = singleRRP - 0.01) : (
                                              singleRRP = (Math.ceil(singleRRP / 5) * 5) - 0.01
                                            )
                                            if (colorIndex === product.colorVariations.length - 1) {
                                              lastColor = true;
                                            }
                                            return (
                                              <tr key={colorIndex}>
                                                <td style={{ textTransform: "capitalize" }}>
                                                  {color.color.name + ' - ' + color.color.colorCode}
                                                </td>
                                                <td>{color.barcode.barcode}</td>
                                                <td style={{ textTransform: "uppercase" }}>
                                                  {color.description}
                                                </td>
                                                <td >
                                                  <input style={{ width: '50px' }} type="number" ref={i + 'price' + colorIndex} value={branchArray[lastIndex].colorVariations[colorIndex].price} onChange={(e) => { this.handlePriceInput(color, product, e.target.value) }} />
                                                </td>
                                                {
                                                  store.branches.map((b, bi) => {
                                                    if (lastColor && (bi === store.branches.length - 1)) {
                                                      lastIndex = 1 + lastIndex + bi;
                                                      return (
                                                        <td key={bi}>
                                                          <input style={{ width: '50px' }} value={branchArray[lastIndex - 1].colorVariations[colorIndex].qty} type="number" onChange={(e) => { this.handleQtyValue(b, color, product, e.target.value, i, colorIndex) }} />
                                                        </td>
                                                      )
                                                    }
                                                    else {
                                                      return (
                                                        <td key={bi}>
                                                          <input style={{ width: '50px' }} value={branchArray[lastIndex + bi].colorVariations[colorIndex].qty} type="number" onChange={(e) => { this.handleQtyValue(b, color, product, e.target.value, i, colorIndex) }} />
                                                        </td>
                                                      )
                                                    }
                                                  })
                                                }
                                                <td>{singleRRP===-0.01 ?(' - '):(singleRRP)}</td>
                                              </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-9"></div>
              <div className="col-sm-3">
                <div className="dropdown float-right">
                  <span className="cursor">
                    {
                      Object.keys(this.state.selectedProduct).length !== 0 && this.state.branchArray.length !== 0 ? (
                        <button
                          className="btn btn-sm btn-primary"
                          type="button"
                          // onClick={this.assignProduct}
                          disabled={
                            this.state.branchArray.length > 0 ? false : true
                          }
                          data-toggle="modal"
                          data-target="#viewModal"
                        >
                          <i className="mdi mdi-clipboard-check mr-1"></i> {edit ? ('Edit Order') : ('Place Order')}
                        </button>
                      ) : (
                          null
                        )
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={this.props.loading || this.props.loadingStore} />
        <AssignProductConfirmationModal assignProduct={this.assignProduct} />
      </div>
    );
  }
  handleQtyValue = (branch, color, product, value, fi, si) => {
    let branchArray = this.state.branchArray.slice();
    //finding if the branch and product already exists in the branch array
    let productExisted = branchArray.findIndex(i => i.product === product._id && i.branch === branch._id); // 
    if (productExisted === -1) {
      //product does not exst in the array
      branchArray.push({
        store: this.state.store._id,
        branch: branch._id,
        product: product._id,
        branchName: branch.locationName,
        productName: product.mainCode,
        colorVariations: [
          {
            color: color.color._id,
            qty: value,
            price: this.refs[`${fi}price${si}`].value,
            colorName: color.color.name,
            fi: fi,
            si: si
          }
        ]
      });
    }
    else {
      //product and branch existed now check if the color variation is alreayd existed or not
      let colorIndex = branchArray[productExisted].colorVariations.findIndex(colorNew => colorNew.color === color.color._id);
      if (colorIndex !== -1) {
        branchArray[productExisted].colorVariations[colorIndex].qty = value;
        branchArray[productExisted].colorVariations[colorIndex].fi = fi;
        branchArray[productExisted].colorVariations[colorIndex].si = si;
        branchArray[productExisted].colorVariations[colorIndex].price = this.refs[`${fi}price${si}`].value;
      }
      else {
        branchArray[productExisted].colorVariations.push({
          color: color.color._id,
          qty: value,
          price: this.refs[`${fi}price${si}`].value,
          colorName: color.color.name,
          fi: fi,
          si: si
        })
      }
    }
    this.setState({ branchArray })
  }
  deleteCheck = (product, i) => {
    let { branchArray, selectedProducts } = this.state;
    //delete product from selected products vaali list
    selectedProducts.splice(i, 1);

    branchArray.filter(x => x.product === product._id).forEach(x => branchArray.splice(branchArray.indexOf(x), 1));
    this.setState({ branchArray, selectedProducts })
  }

  componentWillReceiveProps(newProps) {
    if (newProps.storeAssignResponse !== undefined && newProps.storeAssignResponse !== this.props.storeAssignResponse) {
      if (newProps.storeAssignResponse.data.response) {
        toast.success(newProps.storeAssignResponse.data.msg);
        this.props.history.push('/store/orders', { currentStore: this.state.store })
      }
      else {
        toast.error(newProps.storeAssignResponse.data.msg)
      }
    }
  }
}

const mapDispatchToProps = dispatch => ({
  searchProducts: body => dispatch(productSearch(body)),
  searchProductsReset: () => dispatch(productSearchReset()),
  productAssign: body => dispatch(productAssign(body))
});
const mapStateToProps = () => state => ({
  response: state.Product.productFetchResponse,
  loading: state.Product.loader,
  productsFound: state.Product.productsFound,
  loadingStore: state.Store.loader,
  storeAssignResponse: state.Store.storeAssignResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignProduct);
