import {
  STORE_ADD,
  STORE_FETCH,
  STORE_UPDATE,
  STORE_SEARCH,
  PRODUCT_ASSIGN,
  STORE_UPLOAD_SALES_REPORT,
  FETCH_ALL_SALES,
  STORE_BEST_SELLING_CATEGORY_REPORT,
  STORE_ORDER_FINALIZE,
  STORE_STOCK_REPORT,
  STORE_SPECIFIC_SALES_REPORT,
  STORE_PRINT_PRODUCT_LABEL,
  STORE_PRINT_21_LABEL
} from "../actions/types";

const initialState = {
  //add response
  storeResponse: {},
  loader: false,

  //fetch response
  storeFetchResponse: {
    data: {
      stores: []
    }
  },

  storesFound: true,

  //edit response
  storeEditResponse: {},

  //upload sales report store
  storeSalesReportResponse: {},

  //allsales
  storeAllSalesResponse: {
    data: {
      storeSales: []
    }
  },
  //best selling category report data
  storeBestSellingReportData: {
    data: {
      branches: [],
      detailRows: [],
      topRows: [
        []
      ],
      imageUrls: [],
      saleAmounts: []
    }
  },
  //store order finalize repsonse
  storeOrderFinalize: {
    data: {}
  },

  //store stock report is now avialable only on zomato 
  storeStockReport: {
    data: {
      categoryWiseProductReport: [],
      branches: [],
      topRows: [],
      detailRows: [],
      imageUrls: []
    }
  },

  //store login upload report
  storeSpecificSalesReportResponse: {},
  //store product labels
  storeProductLabels: {
    data: {
      printLabels: []
    }
  },
  //store product labels 21
  store21ProductLabels: {},

  //save delivery note response
  storeDeliveryNote: {
    data: {
      
    }
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    //adding the product
    case `${STORE_ADD}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_ADD}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeResponse: action.payload
      };
    case `${STORE_ADD}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeResponse:
          action.payload.response !== undefined ? action.payload.response : {}
      };

    //fetching the product
    case `${STORE_FETCH}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_FETCH}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeFetchResponse: action.payload,
        storesFound: action.payload.data.stores.length === 0 ? false : true
      };
    case `${STORE_FETCH}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeFetchResponse:
          action.payload.response !== undefined ?
            action.payload.response : {
              data: {
                stores: []
              }
            }
      };

    //editing the product
    case `${STORE_UPDATE}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_UPDATE}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeEditResponse: action.payload
      };
    case `${STORE_UPDATE}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeEditResponse:
          action.payload.response !== undefined ? action.payload.response : {}
      };

    //searching store
    case `${STORE_SEARCH}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_SEARCH}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeFetchResponse: action.payload,
        storesFound: action.payload.data.stores.length === 0 ? false : true
      };
    case `${STORE_SEARCH}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeFetchResponse: action.payload.response
      };

    //assigning product to store
    case `${PRODUCT_ASSIGN}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${PRODUCT_ASSIGN}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeAssignResponse: action.payload
      };
    case `${PRODUCT_ASSIGN}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeAssignResponse: action.payload.response
      };

    //upload sales report response
    case `${STORE_UPLOAD_SALES_REPORT}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_UPLOAD_SALES_REPORT}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeSalesReportResponse: action.payload
      };
    case `${STORE_UPLOAD_SALES_REPORT}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeSalesReportResponse: action.payload.response
      };

    //All sales
    case `${FETCH_ALL_SALES}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${FETCH_ALL_SALES}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeAllSalesResponse: action.payload
      };
    case `${FETCH_ALL_SALES}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeAllSalesResponse: action.payload.response
      };


    //Best selling category report
    case `${STORE_BEST_SELLING_CATEGORY_REPORT}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_BEST_SELLING_CATEGORY_REPORT}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeBestSellingReportData: action.payload
      };
    case `${STORE_BEST_SELLING_CATEGORY_REPORT}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeBestSellingReportData: action.payload.response
      };

    //Store finalize the order
    case `${STORE_ORDER_FINALIZE}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_ORDER_FINALIZE}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeOrderFinalize: action.payload
      };
    case `${STORE_ORDER_FINALIZE}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeOrderFinalize: action.payload.response
      };

    //Store fetch stock report
    case `${STORE_STOCK_REPORT}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_STOCK_REPORT}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeStockReport: action.payload
      };
    case `${STORE_STOCK_REPORT}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeStockReport: action.payload.response
      };

    case 'STORE_REPORT_RESET':
      return {
        ...state,
        storeStockReport: {
          data: {
            categoryWiseProductReport: [],
            branches: [],
            topRows: [],
            detailRows: [],
            imageUrls: []
          }
        },
        storeBestSellingReportData: {
          data: {
            branches: [],
            detailRows: [],
            topRows: [
              []
            ],
            imageUrls: [],
            saleAmounts: []
          }
        }
      }

    //upload sales report response
    case `${STORE_SPECIFIC_SALES_REPORT}_PENDING`:
      break;
    case `${STORE_SPECIFIC_SALES_REPORT}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeSpecificSalesReportResponse: action.payload
      };
    case `${STORE_SPECIFIC_SALES_REPORT}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeSpecificSalesReportResponse: action.payload.response
      }
    //Store product labels
    case `${STORE_PRINT_PRODUCT_LABEL}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_PRINT_PRODUCT_LABEL}_FULFILLED`:
      return {
        ...state,
        loader: false,
        storeProductLabels: action.payload
      };
    case `${STORE_PRINT_PRODUCT_LABEL}_REJECTED`:
      return {
        ...state,
        loader: false,
        storeProductLabels: action.payload.response
      };
      //store print 21 product labels
      case `${STORE_PRINT_21_LABEL}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${STORE_PRINT_21_LABEL}_FULFILLED`:
      return {
        ...state,
        loader: false,
        store21ProductLabels: action.payload
      };
    case `${STORE_PRINT_21_LABEL}_REJECTED`:
      return {
        ...state,
        loader: false,
        store21ProductLabels: action.payload.response
      };

    default:
      return {
        ...state
      };
  }
}