import React, { Component } from "react";
import { connect } from "react-redux";
import {
  productSearch,
  productSearchReset,
  printFourSticker
} from "../../../actions/productActions";
import {
  agentAssignProduct,
  agentAssignProductCheck
} from "../../../actions/agentActions";
import _ from "lodash";
import { toast } from "react-toastify";
import Loader from "../../../inc/Loader";
// import ConfirmModal from "./ConfirmModal";
import CurrentListAgent from "./CurrentListAgent";
import { DOCUMENTS_URL } from "../../../Config";
import ViewSingleProduct from "./ViewSingleProduct";

const defaultImage = "https://via.placeholder.com/150";

class AssingProductAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: {},
      searchText: "",
      productToSend: {},
      arrayOfProducts: [],
      loader: false,
      selectedProdutToView: {
        imageUrl: '',
        mainCode: '',
        brand: '',
        category: '',
        unitPrice: '',
        code: '',
        supplier: {
          fullName: '',
          code: '',
          category: '',
          mainCode: '',
          type: '',
          unitPrice: '',
          costPrice: '',
        },
        type: '',
        itemType: '',
        exhibition: {
          name: '',
          season: ''
        },
        colorVariations: [
          {
            color: {
              name: '',
              colorSwatch: '',
              colorCode: '',
            },
            barcode: {
              barcode: ''
            },
            packSize: '',
            stock: '',
            description: '',
          }
        ],
      }
    };
  }

  //searching the typed product
  search = _.debounce(e => {
    let { searchText } = this.state;
    let body = {
      searchText
    };
    if (searchText !== "") {
      this.props.searchProducts(body);
    }
  }, process.env.REACT_APP_DEBOUNCE);

  //loader toggler
  toggleLoader = () => {
    this.setState({
      loader: !this.state.loader
    });
  };

  //save product temporarly as in selected product by user
  // saveProduct = async v => {
  //if the product is already assigned then toast the user that product is alreayd assigned

  // this.setState(
  //   {
  //     selectedProduct: v,
  //     searchText: ""
  //   },
  //   () => {
  // this.assignProduct(v)
  //     this.props.searchProductsReset();
  //   }
  // );
  // }


  //handeling price input
  handleChangePrice = value => {
    let { productToSend } = this.state;
    productToSend.price = value;
    this.setState({
      productToSend
    });
  };
  //delete product from arrray of selected products to assign
  deleteProduct = (from, howMuch) => {
    let { arrayOfProducts } = this.state;
    arrayOfProducts.splice(from, howMuch);
    this.setState({
      arrayOfProducts
    });
  };
  checkPrice = () => {
    let { productToSend } = this.state;
    if (productToSend.price === "" || productToSend.price === undefined) {
      return false;
    } else {
      return true;
    }
  };
  assignProduct = (v) => {
    this.setState({
      selectedProduct: v
    }, () => {
      let { arrayOfProducts, selectedProduct } = this.state;
      //check if product already exists or not
      let productAlreadyExisted = false;
      let i = 0;

      for (i = 0; i < arrayOfProducts.length; i++) {
        if (arrayOfProducts[i]._id === selectedProduct._id) {
          // product already exists
          productAlreadyExisted = true;
        }
        i = i + 1;
      }
      //if product alreayd existed delete that product and replace it with new item
      if (productAlreadyExisted) {
        toast.error("Product Already exists in the list below!")
      }
      //else push the product as it is
      else {
        let tempColors = [];
        for (const iterator of selectedProduct.colorVariations) {
          tempColors.push({ colorName: iterator.color.name, barcode: iterator.barcode.barcode })
        }
        var sortedColors = _.sortBy(tempColors, "colorName").reverse()
        let newProduct = {
          _id: selectedProduct._id,
          colors: sortedColors,
          mainCode: selectedProduct.mainCode,
          unitPrice: selectedProduct.unitPrice,
          imageUrl: selectedProduct.imageUrl,
          qty: ''
        }
        arrayOfProducts.push(newProduct);
        this.setState({
          productToSend: {},
          selectedProduct: {},
          searchText: "",
          arrayOfProducts
        });
        this.props.searchProductsReset();
      }
    })
  };

  validate = () => {
    let { arrayOfProducts } = this.state;
    let validated = true;
    for (const iterator of arrayOfProducts) {
      if (iterator.qty === '') {
        validated = false
        toast.error("Please enter Quantity of product with Main Code: " + iterator.mainCode + " !!");
        break;
      }
    }
    return validated;
  }

  //hitting the api to assign the array of products in arrrya of products to a specific agent
  assignProductsNow = () => {
    if (this.validate()) {
      let { arrayOfProducts } = this.state;
      let body = {
        json: arrayOfProducts,
        isProduct: true
      };
      //this is body of the product
      this.props.productBarcodePrint(body)
    }
  };
  handleSelectedProductQuantity = (qty, id) => {
    let { arrayOfProducts } = this.state;
    for (const iterator of arrayOfProducts) {
      if (iterator._id === id) {
        iterator.qty = qty
      }
    }
    this.setState({ arrayOfProducts })
  }
  render() {
    return (
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-12 pr-0">
                    <div className="d-lg-flex">
                      <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                        Select Products
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 grid-margin stretch-card">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="row pb-3">
                      <div className="col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            aria-label="Product Name"
                            aria-describedby="button-addon2"
                            value={this.state.searchText}
                            onChange={e => {
                              this.setState(
                                {
                                  searchText: e.target.value,
                                  selectedProduct: {},
                                  productToSend: {}
                                },
                                () => {
                                  this.search();
                                }
                              );
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              type="button"
                              id="button-addon2"
                            >
                              <span>Search Products</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-8 offset-md-2 text-center">
                      {selectedProduct.imageUrl === null ||
                      selectedProduct.imageUrl === "" ||
                      selectedProduct.imageUrl === undefined ? null : (
                        <img
                          src={selectedProduct.imageUrl}
                          className="img-fluid"
                          alt="productImage"
                        />
                      )}
                      <h2 className="pt-3">{selectedProduct.mainCode}</h2>
                    </div> */}
                    {this.props.response.data.products.length === 0 ? null : (
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-hover min-w-700 table_heading_view">
                              <thead>
                                <tr className="heading_mine">
                                  <th>#</th>
                                  <th>Product Image</th>
                                  <th>Main Code</th>
                                  <th>Unit Price</th>
                                  <th>Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.response.data.products.map(
                                  (v, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          <img
                                            src={v.imageUrl}
                                            alt="product"
                                            style={{
                                              height: "130px",
                                              borderRadius: "25%",
                                              width: "auto",
                                              display: "block",
                                              margin: "0 auto"
                                            }}
                                            onError={e => {
                                              e.target.onerror = null;
                                              e.target.src = defaultImage;
                                            }}
                                          />
                                        </td>
                                        <td>{v.mainCode}</td>
                                        <td>£ {v.unitPrice}</td>
                                        <td>
                                          <span
                                            onClick={() => {
                                              this.setState({ selectedProdutToView: v })
                                            }}
                                            className="text-decoration-none"
                                          >
                                            <button
                                              type="button"
                                              className="btn-sm btn btn-primary btn-icon-text"
                                              data-toggle="modal"
                                              data-target="#singleProductModal"
                                            >
                                              <i className="mdi mdi-eye btn-icon-prepend"></i>
                                              <span>View</span>
                                            </button>
                                          </span>
                                          <span className="text-decoration-none">
                                            <button
                                              type="button"
                                              className="btn-sm btn ml-2 btn-dark btn-icon-text"
                                              onClick={() => {
                                                this.assignProduct(v);
                                              }}
                                            >
                                              <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                              <span>Select</span>
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                            {!this.props.productsFound ? (
                              <div className="noProductsFound">
                                <span>No Products Found</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* this is the current list of products to be assigned */}
            {this.state.arrayOfProducts.length === 0 ? null : (
              <CurrentListAgent
                arrayOfProducts={this.state.arrayOfProducts}
                history={this.props.history}
                deleteProduct={this.deleteProduct}
                assignProductsNow={this.assignProductsNow}
                handleSelectedProductQuantity={this.handleSelectedProductQuantity}
              />
            )}
          </div>
        </div>
        {/* confirmation modal for user */}
        {/* <ConfirmModal
          assignProduct={this.assignProduct}
          checkPrice={this.checkPrice}
          handleChangePrice={this.handleChangePrice}
          productToSend={this.state.productToSend}
        /> */}
        {/* lodaer */}
        <ViewSingleProduct product={this.state.selectedProdutToView} />
        <Loader
          loading={
            this.props.loading ||
            this.props.assigningProductsLoader ||
            this.state.loader
          }
        />
        {/* toaster */}
      </div>
    );
  }
  componentWillReceiveProps = newProps => {
    if (
      newProps.agentAssignProductResponse !==
      this.props.agentAssignProductResponse &&
      newProps.agentAssignProductResponse !== undefined
    ) {
      if (newProps.agentAssignProductResponse.data.response) {
        toast.success(newProps.agentAssignProductResponse.data.msg);
      } else {
        toast.error(newProps.agentAssignProductResponse.data.msg);
      }
      this.setState({
        arrayOfProducts: []
      });
    }
    if (newProps.productStickerData !== this.props.productStickerData && newProps.productStickerData !== undefined) {
      let { data } = newProps.productStickerData;
      if (data.response) {
        toast.success(data.msg);
        window.open(DOCUMENTS_URL + data.filename);
        this.setState({
          productToSend: {},
          selectedProduct: {},
          searchText: "",
          arrayOfProducts: []
        });
      }
      else {
        toast.error(data.msg)
      }
    }

  };
}
const mapDispatchToProps = dispatch => ({
  searchProducts: body => dispatch(productSearch(body)),
  searchProductsReset: () => dispatch(productSearchReset()),
  assingProductsToAgent: (agentId, body) =>
    dispatch(agentAssignProduct(agentId, body)),
  agentAssignProductCheck: body => dispatch(agentAssignProductCheck(body)),
  productBarcodePrint: body => dispatch(printFourSticker(body))
});
const mapStateToProps = () => state => ({
  response: state.Product.productFetchResponse,
  loading: state.Product.loader,
  productsFound: state.Product.productsFound,
  assigningProductsLoader: state.Agent.loader,
  agentAssignProductResponse: state.Agent.agentAssignProduct,
  agentAssignProductCheck: state.Agent.agentAssignProductCheck,
  productStickerData: state.Product.productStickerData
});

export default connect(mapStateToProps, mapDispatchToProps)(AssingProductAgent);
