import { BARCODE_FETCH_UNUSED, BARCODE_IMPORT, BARCODE_IMPORT_EMPTY } from './types'

import Helper, { HelperFormData } from '../helper/Helper'

export const barcodeFetchUnused = () => ({
    type: BARCODE_FETCH_UNUSED,
    payload: Helper('barcode/fetch', 'POST', { used: false })
})

export const barcodeImport = (body) => ({
    type: BARCODE_IMPORT,
    payload: HelperFormData('barcode/import', 'POST', body)
})

export const barcodeImportResponseEmpty = () =>dispatch=>{
    dispatch({
        type: BARCODE_IMPORT_EMPTY,
        payload: {}
    })
}