import React, { Component } from "react";
import { toast } from "react-toastify";
import Helper from '../../../helper/Helper'
import Loader from "../../../inc/Loader";

export default class ViewStoreModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colors: [],
            dNoteNumber: '',
            date: props.deliveryDate,
            isDeliveryNoteGenerated: false,
            loader: false,
            total: 0,
            previousDeliveryNote: this.props.location.state.previousDeliveryNote,
            disableFieldNow: false
        }
    }
    manageState = () => {
        let {deliveryDate} = this.props.location.state;
        this.setState({
            colors: [],
            dNoteNumber: '',
            date: deliveryDate,
            isDeliveryNoteGenerated: false,
            loader: false,
            total: 0
        }, () => {
        })
    }
    componentDidMount() {
        let {previousDeliveryNote} = this.props.location.state;
        if(previousDeliveryNote!== null && previousDeliveryNote!== ''){
            this.setState({
                dNoteNumber: previousDeliveryNote
            },()=>{
                this.getDeliveryNoteEarly()
            })
        }
    }
    validate = () => {
        let { dNoteNumber } = this.state;
        let valid = true;
        if (dNoteNumber === '') {
            valid = false;
            toast.error("Please enter Delivery Note Number!")
        }
        return valid
    }
    handleChangeNumber = () => {
        let { orderId } = this.props.location.state;
        //hit here to generate the delivery note
        let valid = this.validate();
        if (valid) {
            this.toggleLoader();
            let res = Helper('store/product/getDeliveryNote', 'POST', { ord: orderId });
            res.then((response) => {
                this.saveDeliveryNote()
                let { data } = response;
                if (data.response) {
                    this.getTotal(data.deliveryNote)
                    this.setState({
                        colors: data.deliveryNote,
                        isDeliveryNoteGenerated: true,
                    })
                    toast.success(data.msg)
                }
                else {
                    toast.error(data.msg)
                }
            })
                .catch(() => {
                    toast.error("Something went wrong while fetching the Delivery Note, Please try again later", 2000)
                })
                .finally(() => {
                    this.toggleLoader()
                })
        }
    }
    saveDeliveryNote=()=>{
        this.toggleLoader();
            let res = Helper('store/product/saveDeliveryNote', 'POST', { deliveryNote: this.state.dNoteNumber, ord: this.props.location.state.orderId });
            res.then((response) => {
                let {data} = response;
                if(data.response){
                    this.setState({disableFieldNow: true})
                }
            })
                .catch(() => {
                    toast.error("Something went wrong while Saving the Delivery Note, Please try again later", 2000)
                })
                .finally(() => {
                    this.toggleLoader()
                })
    }

    getDeliveryNoteEarly = () => {
        let { orderId } = this.props.location.state;
        this.toggleLoader();
        let res = Helper('store/product/getDeliveryNote', 'POST', { ord: orderId });
        res.then((response) => {
            let { data } = response;
            if (data.response) {
                this.getTotal(data.deliveryNote)
                this.setState({
                    colors: data.deliveryNote,
                    isDeliveryNoteGenerated: true,
                })
                toast.success(data.msg)
            }
            else {
                toast.error(data.msg)
            }
        })
            .catch(() => {
                toast.error("Something went wrong while fetching the Delivery Note, Please try again later", 2000)
            })
            .finally(() => {
                this.toggleLoader()
            })
    }
    getTotal = (deliveryNote) => {
        let total = 0;
        for (const iterator of deliveryNote) {
            total += +iterator.Quantity
        }
        this.setState({ total })
    }
    toggleLoader = () => {
        this.setState({ loader: !this.state.loader })
    }
    downloadReport = () => {
        this.toggleLoader()
        let table = document.getElementById('print').innerHTML;
        let res = Helper('store/product/generateReport', 'POST', { html: table });
        res.then((response) => {
            if (response.data.response) {
                window.open(response.data.filename, '_blank')
            }
        })
            .catch((err) => {
                toast.error("Something went wrong while downloading the report, Please try again later!")
            })
            .finally(() => {
                this.toggleLoader();
                this.manageState();
            })
    }
    render() {
        let { colors, dNoteNumber, total } = this.state;
        return (
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                        Delivery Note
                                </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-10 grid-margin stretch-card offset-sm-1">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Deliver Note Number</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Number"
                                                    required
                                                    value={dNoteNumber}
                                                    disabled={((this.state.previousDeliveryNote !== null && this.state.previousDeliveryNote!=='') || this.state.disableFieldNow )? (true) : (false)}
                                                    onChange={(e) => { this.setState({ dNoteNumber: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    colors.length > 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div id="print">

                                                    <table className="table table-hover table_heading_view" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                        <tr>
                                                            <th style={{ border: '1px solid #dddddd', padding: '8px', color: 'maroon', fontStyle: 'italic', textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }} colspan="4" className="text-align-center fontCheck"><b>MINX RETAIL LTD</b></th>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }} colspan="4" class="text-align-center fontCheck"><b>47C BROUGHTON STREET, MANCHESTER, M8 8NN</b></th>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }} colspan="4" class="text-align-center fontCheck"><b>Tel: 0161 839 1100 &nbsp; &nbsp; Fax: 0161 833 1002</b></th>
                                                        </tr>
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>{this.props.location.state.store.companyName} - LV Concession</td>
                                                            <th className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>Delivery Note</th>
                                                            <th className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>{dNoteNumber}</th>
                                                            <th className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></th>
                                                        </tr>
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>Vivary Way</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px', textTransform: 'capitalize' }}>{this.props.location.statebranch}</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>Lancashire</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>BB8 9NW</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>Order Number:</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>{this.props.location.state.orderId.toUpperCase()}</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>VAT NO. GB307919192</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <th className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>Date:</th>
                                                            <th className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>{this.props.location.state.deliveryDate}</th>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-align-center fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px', fontWeight: 'bold' }}>Style</th>
                                                            <th className="text-align-center fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px', fontWeight: 'bold' }}>Quantity</th>
                                                            <th className="text-align-center fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px', fontWeight: 'bold' }}>Order Number</th>
                                                            <th className="text-align-center fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px', fontWeight: 'bold' }}>Box Number</th>
                                                        </tr>

                                                        {
                                                            colors.map((color, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>{color.colorName}</td>
                                                                        <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>{color.Quantity}</td>
                                                                        <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                                        <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        <tr>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}>{total}</td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                            <td className="fontCheck" style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', fontSize: '10px' }}></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                            null
                                        )
                                }
                                <div className="row mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        onClick={() => { this.manageState(); this.props.history.push('/store') }}
                                    >
                                        <span>Close</span>
                                    </button>
                                    {
                                        this.state.isDeliveryNoteGenerated ? (
                                            <button
                                                type="button"
                                                className="btn btn-primary ml-2"
                                                data-dismiss="modal"
                                                onClick={() => { this.downloadReport() }}
                                            >
                                                <span>Print Delivery Note</span>
                                            </button>
                                        ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary ml-2"
                                                    onClick={() => { this.handleChangeNumber() }}
                                                >
                                                    <span>Generate Delivery Note</span>
                                                </button>
                                            )
                                    }
                                    <Loader loading={this.state.loader} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}