import { combineReducers } from 'redux'
import productReducer from './productReducer'
import supplierReducer from './supplierReducer'
import exhibitionReducer from './exhibitionReducer'
import agentRecuder from './agentReducer'
import ColorReducer from './ColorReducer'
import StoreReducer from './StoreReducer'
import BarcodeReducer from './barcodeReducer'
import DashboardReducer from './dashboardReducer'
import staffReducer from './staffReducer'

export default combineReducers({
    Product: productReducer,
    Supplier: supplierReducer,
    Exhibition: exhibitionReducer,
    Agent: agentRecuder,
    Color: ColorReducer,
    Store: StoreReducer,
    Barcode: BarcodeReducer,
    Dashboard: DashboardReducer,
    Staff: staffReducer
})