import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { salesOverviewOptions } from "./Data";
import CountUp from "react-countup";
import './Dashboard.css';

//redux imports
import { connect } from "react-redux";
import {
  dashboardAdminFetchData,
  dashboardAdminSales
} from "../../actions/dashboardActions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesData: {
        labels: props.salesData.data.labels,
        datasets: [
          {
            label: "New Tickets",
            data: props.salesData.data.data,
            backgroundColor: [
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539"
            ],
            borderColor: [
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539",
              "#0ddbb9",
              "#464dee",
              "#fcd539"
            ],
            borderWidth: 1,
            fill: false
          }
        ]
      }
    };
  }
  logout=()=>{
    localStorage.removeItem('access_token');
    localStorage.removeItem('role');
    this.props.history.push('/')
  }
  render() {
    let { data } = this.props.dashboardData;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5">
                    Dashboard
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* first row started */}
        <div className="row mt-3">
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Products</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.products}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Stores</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.stores}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Exhibitions</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.exhibitions}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Colors</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.colors}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Store</h4>
                                <p className="text-small"><strong>Jalandhar</strong> has hishest sales</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="text-dark font-weight-bold">23,000<span className="text-muted text-extra-small">/ per month</span></h2>
                                    <div className="text-success font-weight-bold d-flex justify-content-between align-items-center">
                                        <i className="mdi mdi-chevron-up mdi-24px"></i> <span className=" text-extra-small">130.5%</span>
                                    </div>
                                </div>
                                <Bar
                                    data={ordersData}
                                    options={ordersOptions}
                                />
                            </div>
                        </div>
                    </div> */}
          {/* <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Agent</h4>
                                <p className="text-danger text-small">6 Orders Pending</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="text-dark font-weight-bold">Ekam<span className="text-muted text-extra-small">/ zone</span></h2>
                                </div>
                                <Bar
                                    data={growthData}
                                    options={growthOptions}
                                />
                            </div>
                        </div>
                    </div> */}
          {/* <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Revenue</h4>
                                <p className="text-small"> <strong>+7.00%</strong> Since last month</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="text-dark font-weight-bold">USD 42,9536<span className="text-muted text-extra-small">/ per month</span></h2>
                                </div>
                                <Bar
                                    data={revenueData}
                                    options={revenueOptions}
                                />
                            </div>
                        </div>
                    </div> */}
        </div>
        {/* first row ended */}

        <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mr-5">
                    Barcodes
                  </h3>
                </div>
              </div>
            </div>
          </div>
        <div className="row mt-3">
          <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Total</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.totalBarCodes > 0 ? data.totalBarCodes : 0}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Used</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.usedBarCodes > 0 ? data.usedBarCodes : 0}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
            <div className="card">
            <div className="card-body" style={{background: this.props.dashboardData.data.freeBarCodes < 50 ? "#f00" : '#fff'}}>
                <h4 className="card-title"  style={{color: this.props.dashboardData.data.freeBarCodes < 50 ? "#fff" : '#000'}}>Free</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    {
                      <CountUp
                      className={this.props.dashboardData.data.freeBarCodes < 50 ? 'countUpDanger' : 'countUpNotDanger'}
                      start={0}
                      end={data.freeBarCodes ? data.freeBarCodes : 0}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>}
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body px-0 pb-0 border-bottom">
                                <div className="px-4">
                                    <div className="d-flex justify-content-between mb-2">
                                        <h4 className="card-title ml-2">Analytics</h4>
                                        <div className="mr-2">
                                            <button className="btn py-0 text-dark btn-sm">1D</button>
                                            <button className="btn py-0 text-dark btn-sm">1W</button>
                                            <button className="btn py-0 text-dark btn-sm">1M</button>
                                            <button className="btn py-0 pr-0 text-dark btn-sm">1Y</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-3">
                                <div>
                                    <div className="total-analysis-position">
                                        <h6 className="text-dark font-weight-bold mb-2">Total Analytics</h6>
                                        <h2 className="text-dark font-weight-bold">$32456.65<span className="text-muted text-extra-small pl-3">10.5% of 20000 Total</span></h2>
                                    </div>
                                    <div className="flot-chart-container flothcart-container-dashboard">
                                        <Chart
                                            type="scatter"
                                            data={d1}
                                            options={curvedLineOptions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body pb-4">
                                <h4 className="card-title">Visitors</h4>
                                <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt,</p>
                                <Line
                                    data={visitorsDarkData}
                                    options={visitorsDarkOptions}
                                   />
                            </div>
                            <div className="card-body p-0 border-top">
                                <div className="px-4 text-center">
                                    <div id="chart-visitors-legend" className="chart-visitors-legend">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="row flex-grow ">
          {/* <div className="col-lg-12 col-xl-6 grid-margin-md stretch-card flex-column d-flex">
                        <div className="row flex-grow">
                            <div className="col-sm-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body px-0 pb-0 border-bottom">
                                        <div className="px-4 pb-3">
                                            <h4 className="card-title ml-1">Resources overview</h4>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Chart
                                            type="line"
                                            options={resourcesOverviewOptions}
                                            data={resourcesOverviewData}
                                        />
                                        <div className="row mt-4">
                                            <div className="col-sm-6">
                                                <h6 className="text-dark font-weight-bold">ACTIVE RESOURCE</h6>
                                                <h3 className="text-dark font-weight-bold">$123,657</h3>
                                                <p className="mt-2 text-small">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                                                </p>
                                            </div>
                                            <div className="col-sm-6">
                                                <h6 className="text-dark font-weight-bold">INACTIVE RESOURCE</h6>
                                                <h3 className="text-dark font-weight-bold">$100,278</h3>
                                                <p className="mt-2 text-small">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="row" >
                                                <Chart
                                                            type="pie"
                                                            data={circledata}
                                                            options={circleOptions}
                                                        />
                                                </div>
                                            <div className="col-sm-5">
                                                <h6 className="text-dark font-weight-bold">Avg. Profit share</h6>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                <h6 className="text-dark font-weight-bold">Avg. Daily sales</h6>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div> */}

          {/* second row started */}
          <div className="col-lg-12 col-xl-12 grid-margin stretch-card flex-column">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Sales overview</h4>
                <p>Showing data overall</p>
                {/* <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h6 className="text-dark font-weight-bold mt-2">OVERALL SALES</h6>
                                        <div className="d-flex align-items-center">
                                            <h2 className="text-dark font-weight-bold mr-1 mb-0">$ 14,000</h2>
                                            <div className="text-success text-small d-flex align-items-center">
                                                <h6><i className="mdi mdi-chevron-up mdi-24px"></i> <span>30.68%</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="text-dark font-weight-bold mt-2">TODAY'S SALES</h6>
                                        <div className="d-flex align-items-center">
                                            <h2 className="text-dark font-weight-bold mr-1 mb-0">$ 41,160</h2>
                                            <div className="text-success text-small  d-flex align-items-center">
                                                <h6><i className="mdi mdi-chevron-up mdi-24px"></i> <span>68.22%</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                <Bar
                  type="bar"
                  data={
                    // salesOverviewData
                    this.state.salesData
                  }
                  options={salesOverviewOptions}
                />
                {/* <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Users</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img src={require('../../inc/images/faces/face29.png')} alt="" />
                                                        <div className="ml-3">
                                                            <div className="font-weight-bold  text-dark mb-1">Benjamin Rodriguez</div><div className="font-weight-medium">bria_morissette@gmail.com</div>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td><span className="badge badge-opacity-success">completed</span></td>
                                                <td><div className="font-weight-bold  text-dark mb-1">1,12,900</div><div className="font-weight-medium">Earning</div></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img src={require('../../inc/images/faces/face30.png')} alt="" />
                                                        <div className="ml-3">
                                                            <div className="font-weight-bold  text-dark mb-1">Franklin Williams</div><div className="font-weight-medium">franklinwilliams@gmail.com</div>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td><span className="badge badge-opacity-success">completed</span></td>
                                                <td><div className="font-weight-bold  text-dark mb-1">6,00,00</div><div className="font-weight-medium">Earning</div></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img src={require('../../inc/images/faces/face31.png')} alt="" />
                                                        <div className="ml-3">
                                                            <div className="font-weight-bold  text-dark mb-1">Jordan Fox</div><div className="font-weight-medium">jordanfox@gmail.com</div>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td><span className="badge badge-opacity-primary">Pending</span></td>
                                                <td><div className="font-weight-bold  text-dark mb-1">3,42,230</div><div className="font-weight-medium">Earning</div></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12 col-xl-6 grid-margin stretch-card flex-column">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Products overview</h4>
                                <p>Showing data from March 3 - 30 2019</p>
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h6 className="text-dark font-weight-bold mt-2">TOTAL PRODUCTS</h6>
                                        <div className="d-flex align-items-center">
                                            <h2 className="text-dark font-weight-bold mr-1 mb-0">14,000</h2>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="text-dark font-weight-bold mt-2">NEWLY ADDED</h6>
                                        <div className="d-flex align-items-center">
                                            <h2 className="text-dark font-weight-bold mr-1 mb-0">41,160</h2>
                                        </div>
                                    </div>
                                </div>
                                <Chart
                                    type="horizontalBar"
                                    data={productsOverviewData}
                                    options={productsOverviewOptions}
                                />
                            </div>
                        </div>
                    </div> */}
        </div>
        {/* second row ended */}
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Agents</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.agents}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Customers</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.customers}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Customer Orders</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.customerOrders}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Suppliers</h4>
                <p className="text-small">
                  <strong>Total</strong>{" "}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="text-dark font-weight-bold">
                    <CountUp
                      start={0}
                      end={data.suppliers}
                      duration={3}
                      separator=" "
                      decimals={0}
                      decimal=","
                    ></CountUp>
                    <span className="text-muted text-extra-small">
                      / overall
                    </span>
                  </h2>
                  <div className="text-danger font-weight-bold d-flex justify-content-between align-items-center">
                    {/* <i className="mdi mdi-chevron-down mdi-24px"></i> <span className=" text-extra-small">40.8%</span> */}
                  </div>
                </div>
                {/* <Bar
                                    data={customerData}
                                    options={customerOptions}
                                /> */}
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Store</h4>
                                <p className="text-small"><strong>Jalandhar</strong> has hishest sales</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="text-dark font-weight-bold">23,000<span className="text-muted text-extra-small">/ per month</span></h2>
                                    <div className="text-success font-weight-bold d-flex justify-content-between align-items-center">
                                        <i className="mdi mdi-chevron-up mdi-24px"></i> <span className=" text-extra-small">130.5%</span>
                                    </div>
                                </div>
                                <Bar
                                    data={ordersData}
                                    options={ordersOptions}
                                />
                            </div>
                        </div>
                    </div> */}
          {/* <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Agent</h4>
                                <p className="text-danger text-small">6 Orders Pending</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="text-dark font-weight-bold">Ekam<span className="text-muted text-extra-small">/ zone</span></h2>
                                </div>
                                <Bar
                                    data={growthData}
                                    options={growthOptions}
                                />
                            </div>
                        </div>
                    </div> */}
          {/* <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Revenue</h4>
                                <p className="text-small"> <strong>+7.00%</strong> Since last month</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="text-dark font-weight-bold">USD 42,9536<span className="text-muted text-extra-small">/ per month</span></h2>
                                </div>
                                <Bar
                                    data={revenueData}
                                    options={revenueOptions}
                                />
                            </div>
                        </div>
                    </div> */}
        </div>
        <div className="row flex-grow">
          <div className="col-sm-12 grid-margin stretch-card flex-column d-flex">
            <div className="row flex-grow">
              <div className="col-sm-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="pb-3 pt-0 border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-account-outline mdi-24px mr-3 text-primary"></i>
                            <h6 className="text-dark font-weight-bold mb-0">
                              Products
                            </h6>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  align-items-center">
                            <h6 className="mb-0">{data.products}</h6>
                            <i className="mdi mdi-chevron-right mdi-24px ml-2 text-primary"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-layers-outline mdi-24px mr-3 text-primary"></i>
                            <h6 className="text-dark font-weight-bold mb-0">
                              Agents
                            </h6>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  align-items-center">
                            <h6 className="mb-0">{data.agents}</h6>
                            <i className="mdi mdi-chevron-right mdi-24px ml-2 text-primary"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-shield-outline mdi-24px mr-3 text-primary"></i>
                            <h6 className="text-dark font-weight-bold mb-0">
                              Exhibitions
                            </h6>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  align-items-center">
                            <h6 className="mb-0">{data.exhibitions}</h6>
                            <i className="mdi mdi-chevron-right mdi-24px ml-2 text-primary"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-tag-outline mdi-24px mr-3 text-primary"></i>
                            <h6 className="text-dark font-weight-bold mb-0">
                              Colors
                            </h6>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  align-items-center">
                            <h6 className="mb-0">{data.colors}</h6>
                            <i className="mdi mdi-chevron-right mdi-24px ml-2 text-primary"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-calculator mdi-24px mr-3 text-primary"></i>
                            <h6 className="text-dark font-weight-bold mb-0">
                              Suppliers
                            </h6>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  align-items-center">
                            <h6 className="mb-0">{data.suppliers}</h6>
                            <i className="mdi mdi-chevron-right mdi-24px ml-2 text-primary"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="py-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-logout mdi-24px mr-3 text-primary"></i>
                            <h6 className="text-dark font-weight-bold mb-0">
                              Logout
                            </h6>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  align-items-center" style={{cursor: 'pointer'}} onClick={()=>{this.logout()}}>
                            <h6 className="mb-0">Logout</h6>
                            <i className="mdi mdi-chevron-right mdi-24px ml-2 text-primary"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12 col-xl-8 grid-margin stretch-card flex-column">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Agents</th>
                        <th>Status</th>
                        <th>Orers</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../inc/images/faces/face29.png")}
                              alt=""
                            />
                            <div className="ml-3">
                              <div className="font-weight-bold  text-dark mb-1">
                                Benjamin Rodriguez
                              </div>
                              <div className="font-weight-medium">
                                bria_morissette@gmail.com
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-opacity-success">
                            Processed
                          </span>
                        </td>
                        <td>
                          <div className="font-weight-bold  text-dark mb-1">
                            20
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../inc/images/faces/face30.png")}
                              alt=""
                            />
                            <div className="ml-3">
                              <div className="font-weight-bold  text-dark mb-1">
                                Franklin Williams
                              </div>
                              <div className="font-weight-medium">
                                franklinwilliams@gmail.com
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-opacity-success">
                            Processed
                          </span>
                        </td>
                        <td>
                          <div className="font-weight-bold  text-dark mb-1">
                            40
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../inc/images/faces/face31.png")}
                              alt=""
                            />
                            <div className="ml-3">
                              <div className="font-weight-bold  text-dark mb-1">
                                Jordan Fox
                              </div>
                              <div className="font-weight-medium">
                                jordanfox@gmail.com
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-opacity-primary">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div className="font-weight-bold  text-dark mb-1">
                            8
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../inc/images/faces/face29.png")}
                              alt=""
                            />
                            <div className="ml-3">
                              <div className="font-weight-bold  text-dark mb-1">
                                Benjamin Rodriguez
                              </div>
                              <div className="font-weight-medium">
                                bria_morissette@gmail.com
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-opacity-success">
                            Processing
                          </span>
                        </td>
                        <td>
                          <div className="font-weight-bold  text-dark mb-1">
                            20
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../inc/images/faces/face30.png")}
                              alt=""
                            />
                            <div className="ml-3">
                              <div className="font-weight-bold  text-dark mb-1">
                                Franklin Williams
                              </div>
                              <div className="font-weight-medium">
                                franklinwilliams@gmail.com
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-opacity-success">
                            Processed
                          </span>
                        </td>
                        <td>
                          <div className="font-weight-bold  text-dark mb-1">
                            40
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../inc/images/faces/face31.png")}
                              alt=""
                            />
                            <div className="ml-3">
                              <div className="font-weight-bold  text-dark mb-1">
                                Jordan Fox
                              </div>
                              <div className="font-weight-medium">
                                jordanfox@gmail.com
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-opacity-primary">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div className="font-weight-bold  text-dark mb-1">
                            8
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
  componentWillReceiveProps = newProps => {
    if (
      newProps.salesData !== undefined &&
      newProps.salesData !== this.props.salesData
    ) {
      if (newProps.salesData.data.response) {
        let newSales = this.state.salesData;
        newSales.labels = newProps.salesData.data.labels;
        newSales.datasets[0].data = newProps.salesData.data.data;
        this.setState({ salesData: newSales });
      }
    }
  };
  componentDidMount = () => {
    this.props.fetchDashboardData();
    this.props.fetchDashboardSales();
  };
}

const mapStateToProps = () => state => ({
  dashboardData: state.Dashboard.dashboardAdminFetchResponse,
  salesData: state.Dashboard.dashboardSalesData
});

const mapDispatchToProps = dispatch => ({
  fetchDashboardData: () => dispatch(dashboardAdminFetchData()),
  fetchDashboardSales: () => dispatch(dashboardAdminSales())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
