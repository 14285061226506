import React, { Component } from "react";
import profileImage from "./images/faces/face28.png";
import { Link } from "react-router-dom";

//helper
import Helper from '../../src/helper/Helper';

export default class TopNavbar extends Component {
  logout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('role')
    this.props.history.push("/");
  };
  reset = () => {
    let confirm = window.confirm('Are you sure you want to reboot the server? The Server will not be available for 5 mins.');
    if (confirm) {
      let res = Helper('member/reboot', 'GET');
      res.then((response) => {
        this.logout();
        window.location = "";
      })
        .catch((error) => {
        })
    }

  }

  render() {
    return (
      <nav className="navbar top-navbar col-lg-12 col-12 p-0">
        <div className="container">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo" to="/">
              MINX
            </Link>
            <Link className="navbar-brand brand-logo-mini" to="/">
              MINX
            </Link>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul className="navbar-nav navbar-nav-right">
              {/* <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle d-flex align-items-center justify-content-center cursor"
                  id="notificationDropdown"
                  href="#"
                  data-toggle="dropdown"
                >
                  <img
                    src={require("./images/sidebar/notification.svg")}
                    alt=""
                    className="nav-icon-title"
                  />
                  <span className="count-circle"></span>
                </span>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  aria-labelledby="notificationDropdown"
                >
                  <p className="mb-0 font-weight-normal float-left dropdown-header">
                    Notifications
                  </p>
                  <span className="dropdown-item preview-item cursor">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="mdi mdi-information mx-0"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">
                        Application Error
                      </h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        Just now
                      </p>
                    </div>
                  </span>
                  <span className="dropdown-item preview-item cursor">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="mdi mdi-settings mx-0"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">
                        Settings
                      </h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        Private message
                      </p>
                    </div>
                  </span>
                  <span className="dropdown-item preview-item cursor">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="mdi mdi-account-box mx-0"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">
                        New user registration
                      </h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        2 days ago
                      </p>
                    </div>
                  </span>
                </div>
              </li> */}
              <li className="nav-item nav-profile dropdown">
                <span
                  className="nav-link dropdown-toggle cursor"
                  data-toggle="dropdown"
                  id="profileDropdown-navbar"
                >
                  <img src={profileImage} alt="profile" />
                </span>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown flat-dropdown"
                  aria-labelledby="profileDropdown-navbar"
                >
                  <div className="flat-dropdown-header">
                    <div className="d-flex">
                      <img
                        src={profileImage}
                        alt="profile"
                        className="profile-icon mr-2"
                      />
                      <div>
                        <span className="profile-name font-weight-bold">
                          LV Clothing
                        </span>
                        <p className="profile-designation">{localStorage.getItem('role').toUpperCase()}</p>
                      </div>
                    </div>
                  </div>
                  <div className="profile-dropdown-body">
                    <ul className="list-profile-items">
                      {/* <li className="profile-item">
                        <span className="profile-dropdown-link cursor">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-account-outline text-primary"></i>
                            <div>
                              <h5 className="item-title">My Profile</h5>
                              <p className="item-detail">
                                Account settings and more
                              </p>
                            </div>
                          </div>
                        </span>
                      </li>
                      <li className="profile-item">
                        <span className="profile-dropdown-link cursor">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-email-outline text-info"></i>
                            <div>
                              <h5 className="item-title">My Messages</h5>
                              <p className="item-detail">Inbox and tasks</p>
                            </div>
                          </div>
                        </span>
                      </li> */}
                      <li className="profile-item" onClick={this.logout}>
                        <span className="profile-dropdown-link cursor">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-power text-dark"></i>
                            <div>
                              <h5 className="item-title mt-0">Logout</h5>
                            </div>
                          </div>
                        </span>
                      </li>
                      <li className="profile-item bg-danger" onClick={this.reset}>
                        <span className="profile-dropdown-link cursor">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-power text-light"></i>
                            <div>
                              <h5 className="item-title mt-0 text-light">REBOOT MACHINE</h5>
                            </div>
                          </div>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="horizontal-menu-toggle"
              data-target="#bs-navbar-collapse"
              onClick={()=>{
                this.props.setIsOpen(prev=>!prev)
              }}
            >
              <img
                src={require("./images/sidebar/menu.svg")}
                alt=""
                className=""
              />
            </button>
          </div>
        </div>
      </nav>
    );
  }
}
