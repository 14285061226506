import {
    AGENT_ADD, AGENT_FETCH, AGENT_UPDATE, AGENT_SEARCH, AGENT_ASSIGN_PRODCUTS, AGENT_ASSIGN_PRODUCT_CHECK, AGENT_FETCH_ASSIGNED_PRODUCTS, AGENT_CUSTOMER_DETAILS, AGENT_CUSTOMER_DETAILS_RESET, AGENT_CUSTOMER_ORDER, AGENT_FETCH_ALL_ORDERS, AGENT_FETCH_ORDERS_BY_ID, AGENT_UPDATE_ORDER_STATUS, AGENT_FETCH_CUSTOMERS
} from './types'

import Helper from '../helper/Helper'

export const agentAdd = (data) => ({
    type: AGENT_ADD,
    payload: Helper('agent/save', 'POST', data)
})
export const agentFetch = (body) => ({
    type: AGENT_FETCH,
    payload: Helper('agent/fetch','POST', body)
})
export const agentUpdate = (id, data) => ({
    type: AGENT_UPDATE,
    payload: Helper('agent/edit/' + id, 'PUT', data)
})
export const agentSearch = (body) => ({
    type: AGENT_SEARCH,
    payload: Helper('agent/search', 'POST', body)
})
export const agentAssignProduct = (agentId, body) =>({
    type: AGENT_ASSIGN_PRODCUTS,
    payload: Helper('agent/assignProduct/'+agentId, 'PUT', body)
})
export const agentAssignProductCheck = (body)=>({
    type: AGENT_ASSIGN_PRODUCT_CHECK,
    payload: Helper('agent/checkAssignedProduct', 'POST', body)
})
export const agentFetchAssignedProducts = (agentId) =>({
    type: AGENT_FETCH_ASSIGNED_PRODUCTS,
    payload: Helper('agent/fetchAssignedProducts/'+agentId, 'GET', null)
})
export const agentCustomerDetails = (body) =>({
    type: AGENT_CUSTOMER_DETAILS,
    payload: Helper('customer', 'POST', body)
})
export const agentCustomerDetailsReset = () =>({
    type: AGENT_CUSTOMER_DETAILS_RESET,
    payload: {}
})
export const agentCustomerOrder=(body)=>({
    type: AGENT_CUSTOMER_ORDER,
    payload: Helper('customerOrder', 'POST', body)
})
export const agentFetchAllOrders=()=>({
    type: AGENT_FETCH_ALL_ORDERS,
    payload: Helper('customerOrder/fetchAllOrders', 'GET', null)
})
export const agentOrdersFetchByAgentId=()=>({
    type: AGENT_FETCH_ORDERS_BY_ID,
    payload: Helper('customerOrder/fetchAllOrdersByAgentId', 'GET', null)
})
export const agentUpdateOrderStatus=(body)=>({
    type: AGENT_UPDATE_ORDER_STATUS,
    payload: Helper('customerOrder/updateOrderStatus', 'POST', body)
})
export const agnetFetchCustomers=(body)=>({
    type: AGENT_FETCH_CUSTOMERS,
    payload: Helper('customer/fetchCustomersByAgentId', 'GET', null)
})