import React, { Component } from "react";
import { connect } from "react-redux";
import {
  productSearch,
  productSearchReset
} from "../../../actions/productActions";
import {
  agentAssignProduct,
  agentAssignProductCheck
} from "../../../actions/agentActions";
import _ from "lodash";
import { toast } from "react-toastify";
import Loader from "../../../inc/Loader";
import ConfirmModal from "./ConfirmModal";
import CurrentListAgent from "./CurrentListAgent";
import Helper from "../../../helper/Helper";

const defaultImage = "https://via.placeholder.com/150";
const defaultImage400 = "https://via.placeholder.com/300x400";

class AssingProductAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: {},
      searchText: "",
      productToSend: {},
      arrayOfProducts: [],
      loader: false
    };
  }
  //searching the typed product
  search = _.debounce(e => {
    let { searchText } = this.state;
    let body = {
      searchText
    };
    if (searchText !== "") {
      this.props.searchProducts(body);
    }
  }, process.env.REACT_APP_DEBOUNCE);

  //loader toggler
  toggleLoader = () => {
    this.setState({
      loader: !this.state.loader
    });
  };

  //save product temporarly as in selected product by user
  saveProduct = async v => {
    //if the product is already assigned then toast the user that product is alreayd assigned
    this.toggleLoader();
    let { agentId } = this.props.location.state;
    let body = {
      productId: v._id,
      agentId: agentId
    };
    let res = Helper("agent/checkAssignedProduct", "POST", body);
    res
      .then(response => {
        if (response.data.response) {
          toast.error(
            "Product is already assigned to agent, Please edit this "
          );
        } else {
          this.setState(
            {
              selectedProduct: v,
              searchText: ""
            },
            () => {
              this.props.searchProductsReset();
            }
          );
        }
      })
      .catch(error => {
        alert("Something went wrong, Please try again later.");
      })
      .finally(() => {
        this.toggleLoader();
      });

    // if () {

    // }

    //else save the product
    // else {
    // this.setState(
    //     {
    //         selectedProduct: v,
    //         searchText: ""
    //     },
    //     () => {
    //         this.props.searchProductsReset();
    //     }
    // );
    // }
  };

  //handeling price input
  handleChangePrice = value => {
    let { productToSend } = this.state;
    productToSend.price = value;
    this.setState({
      productToSend
    });
  };

  //handle check
  handleCheck = (e, product, indexcolor) => {
    let { checked } = e.target;
    let { productToSend, selectedProduct } = this.state;
    if (checked && Object.keys(productToSend).length === 0) {
      let tempColorVariations = [];
      tempColorVariations.push(product.color._id);
      let tempProductToSend = {
        productId: selectedProduct._id,
        colorVariations: tempColorVariations,
        mainCode: selectedProduct.mainCode,
        unitPrice: selectedProduct.unitPrice,
        imageUrl: selectedProduct.imageUrl
      };
      this.setState({
        productToSend: tempProductToSend
      });
    } else if (checked && Object.keys(productToSend).length !== 0) {
      let temporaryColorVariations = productToSend.colorVariations;
      temporaryColorVariations.push(product.color._id);
      this.setState({
        ...productToSend,
        colorVariations: temporaryColorVariations
      });
    } else {
      let indexOfColorToDelete = 0;
      for (const iterator of productToSend.colorVariations) {
        if (iterator === product.color._id) {
          let tempProductColors = productToSend.colorVariations;
          tempProductColors.splice(indexOfColorToDelete, 1);
          if (tempProductColors.length === 0) {
            this.setState({
              productToSend: {}
            });
          } else {
            this.setState({
              ...productToSend,
              colorVariations: tempProductColors
            });
          }
        }
        indexOfColorToDelete = indexOfColorToDelete + 1;
      }
    }
  };
  //delete product from arrray of selected products to assign
  deleteProduct = (from, howMuch) => {
    let { arrayOfProducts } = this.state;
    arrayOfProducts.splice(from, howMuch);
    this.setState({
      arrayOfProducts
    });
  };
  checkPrice = () => {
    let { productToSend } = this.state;
    if (productToSend.price === "" || productToSend.price === undefined) {
      return false;
    } else {
      return true;
    }
  };
  assignProduct = () => {
    let { productToSend, arrayOfProducts, selectedProduct } = this.state;
    if (productToSend.price === "" || productToSend.price === undefined) {
      toast.error("Please enter the price of the product");
    } else {
      if (arrayOfProducts.length === 0) {
        arrayOfProducts.push(productToSend);
        this.setState({
          arrayOfProducts: this.state.arrayOfProducts
        });
      } else {
        //check if product already exists or not
        let productAlreadyExisted = false;
        let i = 0;
        let indexOfProductFound = 0;
        for (i = 0; i < arrayOfProducts.length; i++) {
          if (arrayOfProducts[i].productId === selectedProduct._id) {
            // product already exists
            productAlreadyExisted = true;
            indexOfProductFound = i;
          }
          i = i + 1;
        }
        //if product alreayd existed delete that product and replace it with new item
        if (productAlreadyExisted) {
          arrayOfProducts.splice(indexOfProductFound, 1);
          arrayOfProducts.push(productToSend);
          this.setState({
            arrayOfProducts: this.state.arrayOfProducts
          });
        }
        //else push the product as it is
        else {
          arrayOfProducts.push(productToSend);
          this.setState({
            arrayOfProducts: this.state.arrayOfProducts
          });
        }
      }
      this.setState({
        productToSend: {},
        selectedProduct: {},
        searchText: ""
      });
    }
  };

  //hitting the api to assign the array of products in arrrya of products to a specific agent
  assignProductsNow = () => {
    let { arrayOfProducts } = this.state;
    let { agentId } = this.props.location.state;
    let body = {
      productAssigned: arrayOfProducts
    };
    this.props.assingProductsToAgent(agentId, body);
  };
  render() {
    let { selectedProduct } = this.state;
    let { agentName } = this.props.location.state;
    return (
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-12 pr-0">
                    <div className="d-lg-flex">
                      <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                        Assigning Product: {agentName}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 grid-margin stretch-card">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="row pb-3">
                      <div className="col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            aria-label="Product Name"
                            aria-describedby="button-addon2"
                            value={this.state.searchText}
                            onChange={e => {
                              this.setState(
                                {
                                  searchText: e.target.value,
                                  selectedProduct: {},
                                  productToSend: {}
                                },
                                () => {
                                  this.search();
                                }
                              );
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              type="button"
                              id="button-addon2"
                            >
                              <span>Search Products</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      Object.keys(selectedProduct).length !== 0 ? (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card shadow">
                              <div
                                className="card-body text-center d-flex justify-content-center align-items-center"
                                style={{ height: "411px" }}
                              >
                                {selectedProduct.imageUrl === null ||
                                  selectedProduct.imageUrl === "" ||
                                  selectedProduct.imageUrl === undefined ? null : (
                                    <img
                                      src={selectedProduct.imageUrl}
                                      className="img-fluid"
                                      alt="productImage"
                                      style={{ maxHeight: "100%" }}
                                      onError={(e) => { e.target.onerror = null; e.target.src = defaultImage400 }}
                                    />
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card shadow">
                              <div className="card-body">
                                <div className="row align-items-center">
                                  <div className="col-9">
                                    <h3 className="text-uppercase text-dark">
                                      {selectedProduct.mainCode}
                                      <small> ({selectedProduct.brand})</small>
                                    </h3>
                                    <h6>{selectedProduct.category}</h6>
                                  </div>
                                  <div className="col-3 text-right">
                                    <h2 className="text-dark">£ {selectedProduct.unitPrice}</h2>
                                  </div>
                                </div>
                                <p>{selectedProduct.code}</p>
                                <hr />
                                <div className="w-100 table-responsive">
                                  <table className="table no-border td-p">
                                    <tbody>
                                      <tr>
                                        <th>Category</th>
                                        <td>{selectedProduct.category}</td>
                                      </tr>
                                      <tr>
                                        <th>Main Code</th>
                                        <td>{selectedProduct.mainCode}</td>
                                      </tr>
                                      <tr>
                                        <th>Type</th>
                                        <td>{selectedProduct.type}</td>
                                      </tr>
                                      <tr>
                                        <th>Unit Price</th>
                                        <td>£ {selectedProduct.unitPrice}</td>
                                      </tr>
                                      <tr>
                                        <th>Cost Price</th>
                                        <td>
                                          £{" "}
                                          {selectedProduct.costPrice
                                            ? selectedProduct.costPrice
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Item Type</th>
                                        <td>{selectedProduct.itemType}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                          null
                        )
                    }
                    {/* <div className="col-md-8 offset-md-2 text-center">
                      {selectedProduct.imageUrl === null ||
                      selectedProduct.imageUrl === "" ||
                      selectedProduct.imageUrl === undefined ? null : (
                        <img
                          src={selectedProduct.imageUrl}
                          className="img-fluid"
                          alt="productImage"
                        />
                      )}
                      <h2 className="pt-3">{selectedProduct.mainCode}</h2>
                    </div> */}
                    {this.props.response.data.products.length === 0 ? null : (
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-hover min-w-700 table_heading_view">
                              <thead>
                                <tr className="heading_mine">
                                  <th>#</th>
                                  <th>Product Image</th>
                                  <th>Main Code</th>
                                  <th>Unit Price</th>
                                  <th>Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.response.data.products.map(
                                  (v, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>
                                          <img
                                            src={v.imageUrl}
                                            alt="product"
                                            style={{
                                              height: "130px",
                                              borderRadius: "25%",
                                              width: "auto",
                                              display: "block",
                                              margin: "0 auto"
                                            }}
                                            onError={e => {
                                              e.target.onerror = null;
                                              e.target.src = defaultImage;
                                            }}
                                          />
                                        </td>
                                        <td>{v.mainCode}</td>
                                        <td>£ {v.unitPrice}</td>
                                        <td>
                                          <span
                                            onClick={() => {
                                              this.props.history.push(
                                                "/product/view",
                                                { product: v }
                                              );
                                            }}
                                            className="text-decoration-none"
                                          >
                                            <button
                                              type="button"
                                              className="btn-sm btn btn-primary btn-icon-text"
                                            >
                                              <i className="mdi mdi-eye btn-icon-prepend"></i>
                                              <span>View</span>
                                            </button>
                                          </span>
                                          <span className="text-decoration-none">
                                            <button
                                              type="button"
                                              className="btn-sm btn ml-2 btn-dark btn-icon-text"
                                              onClick={() => {
                                                this.saveProduct(v);
                                              }}
                                            >
                                              <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                              <span>Assign</span>
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                            {!this.props.productsFound ? (
                              <div className="noProductsFound">
                                <span>No Products Found</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {Object.keys(this.state.selectedProduct).length ===
                      0 ? null : (
                        <div className="row pt-3">
                          <div className="col-12">
                            <div className="card shadow">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="table-responsive">
                                      <table className="table table-hover table-striped min-w-700">
                                        <thead>
                                          <tr>
                                            <th></th>
                                            <th>#</th>
                                            <th>Color</th>
                                            <th>Barcode</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.selectedProduct.colorVariations.map(
                                            (product, indexcolor) => {
                                              return (
                                                <tr key={indexcolor}>
                                                  <td>
                                                    <label className="checkbox">
                                                      <input
                                                        type="checkbox"
                                                        onClick={e => {
                                                          this.handleCheck(
                                                            e,
                                                            product,
                                                            indexcolor
                                                          );
                                                        }}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                  <td>{indexcolor + 1}</td>
                                                  <td>{product.color.name}</td>
                                                  <td>
                                                    {product.barcode.barcode}
                                                  </td>
                                                  <td>{product.name}</td>
                                                  <td>{product.description}</td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-9"></div>
              <div className="col-sm-3">
                <div className="dropdown float-right">
                  <span className="cursor">
                    {/* display add product button when at least one product is selected */}
                    {Object.keys(this.state.productToSend).length !== 0 ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        data-toggle="modal"
                        data-target="#confirmModal"
                      >
                        <i className="mdi mdi-clipboard-check mr-1"></i>{" "}
                        <span>Add Product</span>
                      </button>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
            {/* this is the current list of products to be assigned */}
            {this.state.arrayOfProducts.length === 0 ? null : (
              <CurrentListAgent
                arrayOfProducts={this.state.arrayOfProducts}
                history={this.props.history}
                deleteProduct={this.deleteProduct}
                assignProductsNow={this.assignProductsNow}
              />
            )}
          </div>
        </div>
        {/* confirmation modal for user */}
        <ConfirmModal
          assignProduct={this.assignProduct}
          checkPrice={this.checkPrice}
          handleChangePrice={this.handleChangePrice}
          productToSend={this.state.productToSend}
        />
        {/* lodaer */}
        <Loader
          loading={
            this.props.loading ||
            this.props.assigningProductsLoader ||
            this.state.loader
          }
        />
        {/* toaster */}
      </div>
    );
  }
  componentWillReceiveProps = newProps => {
    if (
      newProps.agentAssignProductResponse !==
      this.props.agentAssignProductResponse &&
      newProps.agentAssignProductResponse !== undefined
    ) {
      if (newProps.agentAssignProductResponse.data.response) {
        toast.success(newProps.agentAssignProductResponse.data.msg);
      } else {
        toast.error(newProps.agentAssignProductResponse.data.msg);
      }
      this.setState({
        arrayOfProducts: []
      });
    }
  };
}
const mapDispatchToProps = dispatch => ({
  searchProducts: body => dispatch(productSearch(body)),
  searchProductsReset: () => dispatch(productSearchReset()),
  assingProductsToAgent: (agentId, body) =>
    dispatch(agentAssignProduct(agentId, body)),
  agentAssignProductCheck: body => dispatch(agentAssignProductCheck(body))
});
const mapStateToProps = () => state => ({
  response: state.Product.productFetchResponse,
  loading: state.Product.loader,
  productsFound: state.Product.productsFound,
  assigningProductsLoader: state.Agent.loader,
  agentAssignProductResponse: state.Agent.agentAssignProduct,
  agentAssignProductCheck: state.Agent.agentAssignProductCheck
});

export default connect(mapStateToProps, mapDispatchToProps)(AssingProductAgent);
