export const revenueData = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov" ],
    datasets: [{
        label: 'New Tickets',
        data: [4, 9, 22, 29, 24, 15, 10, 4],
        backgroundColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderWidth: 1,
        fill: false
    }
    ]
};

export const revenueOptions = {
    scales: {
        xAxes: [{
        barPercentage: 1,
        position: 'bottom',
        display: true,
        gridLines: {
            display: false,
            drawBorder: false,
        },
        ticks: {
            display: false, //this will remove only the label
            stepSize: 300,
        }
        }],
        yAxes: [{
            display: false,
            gridLines: {
                drawBorder: false,
                display: true,
                color: "#f0f3f6",
                borderDash: [8, 4],
            },
            ticks: {
                display: false,
                beginAtZero: true,
            },
        }]
    },
    legend: {
        display: false
    },
    tooltips: {
        enabled: false,
        backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    plugins: {
        datalabels: {
            display: false,
            align: 'center',
            anchor: 'center'
        }
    }				
};

export const growthData= {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
    datasets: [{
        label: 'New Tickets',
        data: [4, 9, 22, 29, 39, 25, 16, 11],
        backgroundColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderWidth: 1,
        fill: false
    }
    ]
};

export const growthOptions= {
    scales: {
        xAxes: [{
            barPercentage: 1,
            position: 'bottom',
            display: true,
            gridLines: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false, //this will remove only the label
                stepSize: 300,
            }
        }],
        yAxes: [{
            display: false,
            gridLines: {
                drawBorder: false,
                display: true,
                color: "#f0f3f6",
                borderDash: [8, 4],
            },
            ticks: {
                display: false,
                beginAtZero: true,
            },
        }]
    },
    legend: {
        display: false
    },
    tooltips: {
        enabled: false,
        backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    plugins: {
        datalabels: {
            display: false,
            align: 'center',
            anchor: 'center'
        }
    }
};


export const ordersData = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
    datasets: [{
        label: 'New Tickets',
        data: [10, 10, 10, 10, 34, 10, 10, 10],
        backgroundColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderWidth: 1,
        fill: false
    }
    ]
};

export const ordersOptions = {
    scales: {
        xAxes: [{
            barPercentage: 1,
            position: 'bottom',
            display: true,
            gridLines: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false, //this will remove only the label
                stepSize: 300,
            }
        }],
        yAxes: [{
            display: false,
            gridLines: {
                drawBorder: false,
                display: true,
                color: "#f0f3f6",
                borderDash: [8, 4],
            },
            ticks: {
                display: false,
                beginAtZero: true,
            },
        }]
    },
    legend: {
        display: false
    },
    tooltips: {
        enabled: false,
        backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    plugins: {
        datalabels: {
            display: false,
            align: 'center',
            anchor: 'center'
        }
    }
};
export const customerData = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
    datasets: [{
        label: 'New Tickets',
        data: [12, 25, 35, 25, 17, 13, 10, 6],
        backgroundColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderColor: [
            '#e4e4e4', '#e4e4e4', '#e4e4e4', '#e4e4e4', '#464dee', '#e4e4e4', '#e4e4e4', '#e4e4e4',
        ],
        borderWidth: 1,
        fill: false
    }
    ]
};
export const customerOptions = {
    scales: {
        xAxes: [{
            barPercentage: 1,
            position: 'bottom',
            display: true,
            gridLines: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false, //this will remove only the label
                stepSize: 300,
            }
        }],
        yAxes: [{
            display: false,
            gridLines: {
                drawBorder: false,
                display: true,
                color: "#f0f3f6",
                borderDash: [8, 4],
            },
            ticks: {
                display: false,
                beginAtZero: true,
            },
        }]
    },
    legend: {
        display: false
    },
    tooltips: {
        enabled: false,
        backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    plugins: {
        datalabels: {
            display: false,
            align: 'center',
            anchor: 'center'
        }
    }
}

export const curvedLineOptions = {
    series: {
        curvedLines: {
                active: true,
        },
        shadowSize: 0,
        lines: {
                show: true,
                lineWidth: 2,
                fill: false
        },
    },
    
    grid: {
        borderWidth: 0,
        labelMargin: 0,
        color: '#b1b1b5'
    },
    yaxis: {
        show: false,
        min: 0,
        max: 80,
        position: "left",
        ticks: [
            [0, '600'],
            [50, '610'],
            [100, '620'],
            [150, '640']
        ],
        tickColor: '#e9e9e9',
        tickLength:0,
    },
    xaxis: {
        show: true,
        position: "bottom",
        ticks: [
                [0, 'Jan'],
                [11, 'Feb'],
                [22, 'Mar'],
                [33, 'Apr'],
                [44, 'May'],
                [55, 'Jun'],
                [66, 'Jul']
        ],
        tickColor: '#313235',
    },
    legend: {
        noColumns: 4,
    }
}

export const d1 = [
    [0,15],
    [1, 18],
    [2, 16],
    [3, 17],
    [4, 19],
    [5, 16],
    [6, 22],
    [7, 19],
    [8, 20],
    [9, 19],
    [10, 24],
    [11, 22],
    [12, 26],
    [13, 24],
    [14, 25],
    [15, 21],
    [16, 23],
    [17, 23],
    [18, 24],
    [19, 24],
    [20, 23],
    [21, 23],
    [22, 20],
    [23, 25],
    [24, 25],
    [24, 26],
    [26, 26],
    [29, 24],
    [30, 25],
    [31, 27],
    [32, 28],
    [33, 28],
    [34, 29],
    [35, 29],
    [36, 30],
    [37, 30],
    [38, 32],
    [39, 32],
    [40, 30],
    [41, 30],
    [42, 28],
    [43, 28],
    [44, 28],
    [45, 32],
    [46, 32],
    [47, 35],
    [48, 38],
    [49, 40],
    [50, 39],
    [51, 42],
    [52, 42],
    [53, 47],
    [54, 47],
    [55, 50],
    [56, 54],
    [57, 56],
    [57, 57],
    [58, 58],
    [59, 55],
    [60, 54],
    [61, 50],
    [62, 52],
    [63, 49],
    [64, 48],
    [65, 47],
    [66, 45],
];

export const visitorsDarkData = {
    labels: ["03/05", "03/06", "03/07", "03/08", "03/09", "03/10", "03/11", "Aug", "03/12", "03/13", "03/14", "03/15"],
    datasets: [{
        label: 'Unique Visitors',
        data: [50, 36, 67, 41, 94, 65, 67, 45, 37, 59, 57, 45],
        backgroundColor: [
            '#4c53ee',
        ],
        borderColor: [
            '#4c53ee',
        ],
        borderWidth: 2,
        fill: false,
        pointBorderWidth: 2,
        pointRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        pointBorderColor: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        pointBackgroundColor: ['#4c53ee', '#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee','#4c53ee'],

    },{
        label: 'Visitors',
        data: [36, 2, 44, 16, 60, 124, 24, 39, 29, 16, 110, 35],
        backgroundColor: [
            '#0ddbb9',
        ],
        borderColor: [
            '#0ddbb9',
        ],
        borderWidth: 2,
        fill: false,
        pointBorderWidth: 2,
        pointRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        pointBorderColor: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        pointBackgroundColor: ['#0ddbb9', '#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9','#0ddbb9'],

    },
        
    ],
};

export const visitorsDarkOptions = {
    scales: {
        yAxes: [{
            display: false
        }],
        xAxes: [{
            display: true,
            position: 'bottom',
            gridLines: {
                drawBorder: false,
                display: true,
                color: '#313235',
            },
            ticks: {
                display: true,
                beginAtZero: false,
                stepSize: 0,
                fontColor:'#6c7293'
            }
        }],


    },
    legend: {
        display: false,
    },
    elements: {
        point: {
            radius: 0
        },
        line: {
            tension: 0.000001
        }
    },
    tooltips: {
        backgroundColor: 'rgba(2, 171, 254, 1)',
    },
    legendCallback: function(chart) {
        var text = [];
        text.push('<div class="row">');
        for (var i = 0; i < chart.data.datasets.length; i++)  {
                text.push('<div class="col-sm-6 col mr-3 ml-3 ml-sm-0 mr-sm-0 pr-md-0"><div class="py-4"><span class="legend-label" style="background-color:' + chart.data.datasets[i].backgroundColor[i] + '"></span><span class="text-light font-weight-bold mr-lg-5 ml-3">' + chart.data.datasets[i].label + '</span><span class="mb-0 font-weight-bold text-light">' + chart.data.datasets[0].data[i] + '</span></div>');
                text.push('</div>');
        }
        text.push('</div>');
        return text.join("");
},
};

export const resourcesOverviewData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: 'Sessions',
        data: [20,69,73,66,69,73,40,59,63,91,73,40,73],
        backgroundColor: [
          '#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7',
        ],
        borderColor: [
          '#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7'
        ],
        borderWidth: 1,
        fill: false
      },
      {
      label: 'New Users',
      data: [11,12,1,26,12,20,2,7,18,7,7,24,7],
      backgroundColor: [
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
                  '#464dee',
                  '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
      ],
      borderColor: [
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
                  '#464dee',
                  '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
      ],
      borderWidth: 1,
      fill: false
    }]
  };
  export const resourcesOverviewOptions = {
    scales: {
      xAxes: [{
        barPercentage: 0.4,
        stacked: true,
        gridLines: {
          display: false, //this will remove only the label
                      drawBorder: false,
                      color: "#eaeaea",
                  },
                  ticks : {
                      display:true,
                      fontColor: "#6c7293",
                  }
      }],
      yAxes: [{
        stacked: true,
                  display: true,
                  gridLines: {
          display: true, //this will remove only the label
                      drawBorder: false,
                      color: "#eaeaea",
                  },
                  ticks : {
                      display:false,
                      stepSize: 40,
                      color: '#6c7293',
                  }
      }]
    },
    legend: {
      display: false,
      position: "bottom"
    },
    elements: {
      point: {
        radius: 0
      }
    } 
  };


  export const resourcesOverviewDarkData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: 'Sessions',
        data: [20,69,73,66,69,73,40,59,63,91,73,40,73],
        backgroundColor: [
          '#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7',
        ],
        borderColor: [
          '#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7','#0ad7f7'
        ],
        borderWidth: 1,
        fill: false
      },
      {
      label: 'New Users',
      data: [11,12,1,26,12,20,2,7,18,7,7,24,7],
      backgroundColor: [
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
                  '#464dee',
                  '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
      ],
      borderColor: [
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
                  '#464dee',
                  '#464dee',
        '#464dee',
        '#464dee',
        '#464dee',
      ],
      borderWidth: 1,
      fill: false
    }]
  };
  export const resourcesOverviewDarkOptions = {
    scales: {
      xAxes: [{
        barPercentage: 0.4,
        stacked: true,
        gridLines: {
          display: false, //this will remove only the label
                      drawBorder: false,
                      color: "#eaeaea",
                  },
                  ticks : {
                      display:true,
                      fontColor: "#6c7293",
                  }
      }],
      yAxes: [{
        stacked: true,
                  display: true,
                  gridLines: {
          display: true, //this will remove only the label
                      drawBorder: false,
                      color: "#313235",
                  },
                  ticks : {
                      display:false,
                      stepSize: 40,
                      color: '#6c7293',
                  }
      }]
    },
    legend: {
      display: false,
      position: "bottom"
    },
    elements: {
      point: {
        radius: 0
      }
    } 
  };

  export const salesOverviewData = {
    labels: ["Skirt", "Top", "Scarf"],
    datasets: [{
        label: 'New Tickets',
        data: [2260, 3340, 2770,],
        backgroundColor: [
            '#0ddbb9', '#464dee', '#fcd539'
        ],
        borderColor: [
            '#0ddbb9', '#464dee', '#fcd539',
        ],
        borderWidth: 1,
        fill: false
    }
    ]
};
export const salesOverviewOptions = {
    scales: {
        xAxes: [{
        barPercentage: 1,
        position: 'bottom',
        display: true,
        gridLines: {
            display: true,
            drawBorder: false,
            color: "#eaeaea",
        },
        ticks: {
            display: true, //this will remove only the label
            stepSize: 300,
        }
        }],
        yAxes: [{
            barPercentage: 1,
        categoryPercentage: .5,
            display: true,
            gridLines: {
                drawBorder: false,
                display: false,
                color: "#eaeaea",
                borderDash: [8, 4],
            },
            ticks: {
                display: true,
                beginAtZero: true,
            },
        }]
    },
    legend: {
        display: false
    },
    tooltips: {
        enabled: false,
        backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    plugins: {
        datalabels: {
            display: false,
            align: 'center',
            anchor: 'center'
        }
    }				
};

export const productsOverviewData = {
    labels: ["Aqua", "Blue", "Yellow"],
    datasets: [{
        label: 'New Tickets',
        data: [2260, 3340, 2770],
        backgroundColor: [
            '#0ddbb9', '#464dee', '#fcd539',
        ],
        borderColor: [
            '#0ddbb9', '#464dee', '#fcd539',
        ],
        borderWidth: 1,
        fill: false
    }
    ]
};
export const productsOverviewOptions = {
    scales: {
        xAxes: [{
        barPercentage: 0.1,
        position: 'bottom',
        display: true,
        gridLines: {
            display: true,
            drawBorder: false,
            color: "#eaeaea",
        },
        ticks: {
            display: true, //this will remove only the label
            stepSize: 300,
        }
        }],
        yAxes: [{
            barPercentage: 1,
        categoryPercentage: .5,
            display: true,
            gridLines: {
                drawBorder: false,
                display: false,
                color: "#eaeaea",
                borderDash: [8, 4],
            },
            ticks: {
                display: true,
                beginAtZero: true,
            },
        }]
    },
    legend: {
        display: false
    },
    tooltips: {
        enabled: false,
        backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    plugins: {
        datalabels: {
            display: false,
            align: 'center',
            anchor: 'center'
        }
    }				
};

export const circledata=  {
    datasets: [{
        data: [
            20,
            80,
        ],
        backgroundColor: [
            '#ff0000',
            '#000000',
        ],
    }],
};
export const circleOptions = {
    responsive : true,
    legend: {
        position: 'top',
    },
    title: {
        display: true,
    },
    animation: {
        animateScale: true,
        animateRotate: true
    }
}