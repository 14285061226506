//react import
import React from "react";

//routing imports
import { Switch, Route } from "react-router-dom";

//component imports
import TopNavbar from "../../inc/TopNavbar";
import NavBar from "../../inc/NavBar";
import Footer from "../../inc/Footer";

//dashboard
import Dashboard from "../Dashboard";

//products
import Products from "../Products";
import AddProduct from "../Products/AddProduct";
import ViewProduct from "../Products/ViewProduct";
import EditProduct from "../Products/EditProduct";
import ImportProduct from "../Products/ImportProduct";
import BarcodeImport from "../Products/BarcodeImport";
import ExportProduct from "../Products/ExportProduct";
import ExportProductAll from "../Products/ExportProductAll";
import ImportUpdatedProduct from "../Products/ImportUpdatedProduct";
import ViewAssignedProduct from "../Agent/AssignProduct/ViewAssignedProduct";
import ViewImportData from "../Products/ViewImportData";
import BarcodePrint from "../Products/BarcodePrint";
import SelectProducts from "../Products/BarcodePrint/SelectProducts";
import PrintBarcode from "../Products/PrintBarcode";

//suppliers
import Suppliers from "../Suppliers";
import AddSupplier from "../Suppliers/AddSupplier";
import EditSupplier from "../Suppliers/EditSupplier";

//exhibition
import Exhibition from "../Exhibition";
import AddExhibition from "../Exhibition/AddExhibition";
import EditExhibition from "../Exhibition/EditExhibition";

//color
import Color from "../Color";
import AddColor from "../Color/AddColor";
import EditColor from "../Color/EditColor";

//agent
import Agent from "../Agent";
import AddAgent from "../Agent/AddAgent";
import EditAgetnt from "../Agent/EditAgent";
import EditStore from "../Store/EditStore";
import AssingProductAgent from "../Agent/AssignProduct";
import EditAssignProduct from "../Agent/AssignProduct/EditAssignProduct";
import PlaceOrder from "../Agent/PlaceOrder";
import AgentOrderAdmin from "../Agent/ViewOrder";
import ViewOrderAgent from "../Agent/ViewOrderAgent";

//store
import Store from "../Store";
import AddStore from "../Store/AddStore";
import AssignProduct from "../Store/AssignProduct";
import UploadSalesReport from "../Store/UploadSalesReport";
import DeliveryNoteScreen from "../Store/DeliveryNoteScreen";

//staff routes
import Staff from "../Staff";
import CreateStaff from "../Staff/CreateStaff";

//this one is for new sales upload
import UploadStoreSalesReport from "../Store/UploadSalesReportNew";
// import UploadSalesReport from '../Store/UploadSalesReportNew'
import Orders from "../Store/Orders";
import EditOrder from "../Store/Orders/EditOrder";
import SalesReport from "../Store/SalesReport";
import StockReport from "../Store/StockReport";
// import Stats from '../Store/Stats'
import { Titles } from "../../Config";
import NotFound from "../NotFound";
import TopBar from "./TopBar";

export default function Main(props) {
  document.title = Titles.Dashboard;

  let { history } = props;
  return (
    <div className="container-scroller">
      <TopBar />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <Switch>
            <Route exact path="/" component={Dashboard} />

            {/* product routes */}
            <Route exact path="/product" component={Products} />
            <Route path="/product/create" component={AddProduct} />
            <Route path="/product/view" component={ViewProduct} />
            <Route path="/product/edit" component={EditProduct} />
            <Route path="/product/export" component={ExportProduct} />
            <Route path="/product/export_all" component={ExportProductAll} />
            <Route path="/product/import" component={ImportProduct} />
            <Route
              path="/product/importupdate"
              component={ImportUpdatedProduct}
            />
            <Route path="/product/barcode_import" component={BarcodeImport} />
            <Route path="/product/imported" component={ViewImportData} />
            <Route path="/product/barcode_print" component={BarcodePrint} />
            <Route
              path="/product/barcode_products"
              component={SelectProducts}
            />
            <Route path="/product/print-barcode" component={PrintBarcode} />

            {/* color routes */}
            <Route exact path="/colour" component={Color} />
            <Route path="/colour/create" component={AddColor} />
            <Route path="/colour/edit" component={EditColor} />

            {/* supllier routes */}
            <Route exact path="/supplier" component={Suppliers} />
            <Route path="/supplier/create" component={AddSupplier} />
            <Route path="/supplier/edit" component={EditSupplier} />

            {/* exhibition routes */}
            <Route exact path="/exhibition" component={Exhibition} />
            <Route path="/exhibition/create" component={AddExhibition} />
            <Route path="/exhibition/edit" component={EditExhibition} />

            {/* agent aroutes */}
            <Route exact path="/agent" component={Agent} />
            <Route path="/agent/create" component={AddAgent} />
            <Route path="/agent/edit" component={EditAgetnt} />
            <Route path="/agent/assign" component={AssingProductAgent} />
            <Route path="/agent/view_assign" component={ViewAssignedProduct} />
            <Route path="/agent/edit_assign" component={EditAssignProduct} />
            <Route path="/agent/place_order" component={PlaceOrder} />
            <Route path="/agent/order" component={AgentOrderAdmin} />
            <Route path="/agent/order_agent" component={ViewOrderAgent} />

            {/* store routes */}
            <Route exact path="/store" component={Store} />
            <Route path="/store/create" component={AddStore} />
            <Route path="/store/edit" component={EditStore} />
            <Route path="/store/assignProducts" component={AssignProduct} />
            <Route
              path="/store/uploadSalesReport"
              component={UploadSalesReport}
            />

            {/* this route is for new sales upload */}
            <Route
              path="/store/uploadStoreSalesReport"
              component={UploadStoreSalesReport}
            />

            <Route path="/store/orders" component={Orders} />
            <Route path="/store/editOrder" component={EditOrder} />
            <Route path="/store/salesreport" component={SalesReport} />
            <Route path="/store/stockreport" component={StockReport} />
            {/* <Route path='/store/stats' component={Stats}/> */}
            <Route path="/store/deliveryNote" component={DeliveryNoteScreen} />

            <Route exact path="/staff" component={Staff} />
            <Route path="/staff/create" component={CreateStaff} />

            {/* route not found */}
            <Route component={NotFound} />
          </Switch>

          <Footer />
        </div>
      </div>
    </div>
  );
}
