import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../../App.css';
import countries from '../../../Constants/constants';
import { connect } from 'react-redux';
import { updateSupplier } from '../../../actions/supplierActions'
import { toast } from 'react-toastify';
import Loader from '../../../inc/Loader'
import Helper from '../../../helper/Helper';

class AddSupplier extends Component {
    constructor(props) {
        super(props);
        let { supplier } = this.props.history.location.state;
        let businessCard = supplier.businessCard === null  ?  "No Image Selected" : supplier.businessCard;
        this.state = {
            id: supplier._id,
            name: supplier.fullName,
            supplierName: supplier.fullName,
            email: supplier.email,
            contactPersonName: supplier.contactPersonName,
            code: supplier.code,
            supplierCode: supplier.code,
            address: supplier.address,
            country: supplier.country,
            phone: supplier.phoneNumber,
            businessCard: supplier.businessCard,
            photo: '',
            formErrors: {
                name: '',
                code: '',
                email: ''
            },
            validMessages: {
                name: '',
                code: ''
            },
            displayurl: supplier.businessCard === null ? ('') : (supplier.businessCard),
            photoName: businessCard
        }
    }

    handleInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ [name]: value },
            () => { this.validateField(name, value) });
        if (name === 'photo') {
            this.setState({ [name]: e.target.files[0], displayurl: URL.createObjectURL(e.target.files[0]), photoName: e.target.files[0].name, businessCard: e.target.files[0] });
        }
    }

    handleBlur = (e) => {
        let name = e.target.id
        let value = e.target.value.trim();
        let validMessages = this.state.validMessages;
        if (value) {
            let data = {}
            if (name === 'name') {
                if (value === this.state.supplierName) {
                    validMessages[name] = `That is Current ${name}`
                    this.setState({
                        validMessages
                    })
                } else {
                    data = {
                        fullName: value
                    }
                    this.checkDuplicate(name, data)
                }
            } else {
                if (value === this.state.supplierCode) {
                    validMessages[name] = `That is Current ${name}`
                    this.setState({
                        validMessages
                    })
                } else {
                    data = {
                        code: value
                    }
                    this.checkDuplicate(name, data)
                }
            }
        }
    }

    checkDuplicate = (name, data) => {
        // api call for checking supplier name and code already exist
        let fieldValidationErrors = this.state.formErrors;
        let validMessages = this.state.validMessages;
        Helper("supplier/count", "POST", data)
            .then((result) => {
                if (result.data.count === 0) {
                    validMessages[name] = `That ${name} is valid.`
                    this.setState({
                        validMessages
                    })
                } else {
                    fieldValidationErrors[name] = `That ${name} is taken. Try another.`
                    validMessages[name] = ''
                    this.setState({
                        formErrors: fieldValidationErrors,
                        validMessages
                    })
                }
            })
            .catch(() => {
                toast.error("Check your internet connection.")
            })
    }

    validateField = (fieldName, value) => {
        if (fieldName === 'name' || fieldName === 'code' || fieldName === 'email') {
            let fieldValidationErrors = this.state.formErrors;
            let validMessages = this.state.validMessages;
            let field = value.trim() !== '';
            fieldValidationErrors[fieldName] = field ? '' : `${fieldName} can't be blank`;
            validMessages[fieldName] = '';
            if (fieldName === "email") {
                let isEmail = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors[fieldName] = isEmail ? '' : field ? `${fieldName} is invalid` : "";
            }
            this.setState({
                validMessages,
                formErrors: fieldValidationErrors
            });
        }
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'invalid-field');
    }

    showMsg = (msg, isError) => {
        if (msg) {
            if (isError) {
                return (
                    <div className="error-msg"><i className="mdi mdi-close mdi-18px" /><span>{msg}</span></div>
                );
            } else {
                return (
                    <div className="valid-msg"><i className="mdi mdi-check mdi-18px" /><span>{msg}</span></div>
                );
            }
        }
    }

    isValid = () => {
        let fieldValidationErrors = this.state.formErrors;
        let fields = ["name", "code"];
        let valid = true;
        fields.forEach((v, i) => {
            let field = this.state[`${v}`]
            if (field === '') {
                fieldValidationErrors[v] = `${v} can't be blank`
                this.setState({ formErrors: fieldValidationErrors });
                valid = false;
            }
        })
        if (fieldValidationErrors['email'] !== "") {
            valid = false;
        }
        let duplicateCheck = ["name", "code"];
        duplicateCheck.forEach((v, i) => {
            let err = fieldValidationErrors[v]
            if (err) {
                valid = false;
            }
        })
        return valid;
    }

    updateSupplierNow = () => {
        let valid = this.isValid();
        if (valid) {
            let { name, code, email, contactPersonName, address, phone, country, id, businessCard } = this.state;
            let supplier = {
                fullName: name,
                code,
                email: email,
                contactPersonName: contactPersonName,
                address: address,
                phoneNumber: phone,
                country: country,
                businessCard
            }
            this.props.updateSupplier(id, supplier)
        }
    }

    updateResponse = (res) => {
        if (res.data.response) {
            toast.success(res.data.msg);
            this.props.history.push('/supplier')
        }
        else {
            toast.error(res.data.msg)
        }
    }

    componentWillReceiveProps = (newProps) => {
        if (this.props.response !== newProps.response && newProps.response !== undefined) {
            this.updateResponse(newProps.response)
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">Edit Supplier
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="dropdown float-right">
                            <Link to="/supplier" href="./suppliers.html">
                                <button className="btn btn-sm bg-white font-weight-bold text-dark" type="button">
                                    <i className="mdi mdi-eye mr-1"></i>
                                    All Suppliers
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="name">Supplier Name</label>
                                                <input type="text" className={`form-control ${this.errorClass(this.state.formErrors.name)}`}
                                                    id="name"
                                                    placeholder="Supplier Name" required
                                                    value={this.state.name}
                                                    onChange={(event) => this.handleInput(event)}
                                                    onBlur={(event) => this.handleBlur(event)} />
                                                {this.showMsg(this.state.formErrors.name, true)}
                                                {this.showMsg(this.state.validMessages.name, false)}                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="code">Code (Unique Code
                                                        ALPHANUMERIC)</label>
                                                <input type="text" className={`form-control ${this.errorClass(this.state.formErrors.code)}`}
                                                    id="code"
                                                    value={this.state.code}
                                                    placeholder="Code (Unique Code ALPHANUMERIC)" required
                                                    onChange={(event) => this.handleInput(event)}
                                                    onBlur={(event) => this.handleBlur(event)} />
                                                {this.showMsg(this.state.formErrors.code, true)}
                                                {this.showMsg(this.state.validMessages.code, false)}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="email">Supplier Email</label>
                                                <input type="text" className={`form-control ${this.errorClass(this.state.formErrors.email)}`}
                                                    id="email"
                                                    placeholder="Supplier Email" required
                                                    value={this.state.email}
                                                    onChange={(event) => this.handleInput(event)} />
                                                {this.showMsg(this.state.formErrors.email, true)}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="contactPersonName">Contact Person Name</label>
                                                <input type="text" className="form-control"
                                                    id="contactPersonName"
                                                    value={this.state.contactPersonName}
                                                    placeholder="Contact Person Name" required
                                                    onChange={(event) => this.handleInput(event)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="address">Supplier Address</label>
                                                <input type="text" className="form-control"
                                                    id="address"
                                                    value={this.state.address}
                                                    placeholder="Supplier Address" required
                                                    onChange={(event) => this.handleInput(event)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="country">Country</label>
                                                <select className="form-control"
                                                    id="country"
                                                    value={this.state.country}
                                                    style={{ height: '46px', marginTop: '2px' }}
                                                    onChange={(event) => this.handleInput(event)}>
                                                    <option disabled selected>Select Country</option>
                                                    {
                                                        countries.map((country, i) => {
                                                            return <option key={country.code} value={country.name}>{country.name}</option>;
                                                        })
                                                    }
                                                </select>
                                                {/* <input type="number" className={`form-control ${this.errorClass(this.state.formErrors.name)}`} id="suppliercountry"
                                                    placeholder="Country" /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="phone">Phone Number</label>
                                                <input type="number" className="form-control"
                                                    id="phone"
                                                    value={this.state.phone}
                                                    placeholder="Phone Number" onChange={(event) => this.handleInput(event)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Supplier Business Card Photo</label>
                                        <div className="input-group col-xs-12">
                                            <input type="text"
                                                className="form-control file-upload-info" disabled
                                                placeholder="Upload Image" required id="photo" value={this.state.photoName} />
                                            <span className="input-group-append">
                                                <label className="file-upload-browse btn btn-primary uploadButton">
                                                    <span style={{ color: '#fff' }}>Upload</span>
                                                    <input id="photo" type="file" name="img[]" className="file-upload-default" onChange={(event) => this.handleInput(event)} />
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        this.state.displayurl !== '' ? (
                                            <div className="form-group">
                                                <div className="input-group col-xs-12">
                                                    <img src={this.state.displayurl} style={{ height: '100px', width: 'auto' }} alt="displayImage" />
                                                </div>
                                            </div>
                                        ) : (null)
                                    }
                                    <button type="button" onClick={() => this.updateSupplierNow()}
                                        className="btn btn-primary mr-2">Update</button>
                                    <button onClick={() => { this.props.history.push('/supplier/') }} type="button" className="btn btn-light">Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader loading={this.props.loading} />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSupplier: (id, data) => dispatch(updateSupplier(id, data))
    }
}

const mapStateToProps = () => state => ({
    response: state.Supplier.supplierUpdateResponse,
    loading: state.Supplier.loader
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplier);
