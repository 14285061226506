import React from 'react';

export default function ViewCustomerModal(props) {
    let { customer } = props;
    return (
        <div className="modal fade" id="customerModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Customer Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="w-100 table-responsive">
                            <table className="table no-border">
                                <tbody>
                                    <tr>
                                        <th>Company Name</th>
                                        <td>{customer.companyName}</td>
                                    </tr>
                                    <tr>
                                        <th>Contact Name</th>
                                        <td>{customer.contactName}</td>
                                    </tr>
                                    <tr>
                                        <th>Phone</th>
                                        <td>{customer.phone === ''?('-'):(customer.phone)}</td>
                                    </tr>
                                    <tr>
                                        <th>Country</th>
                                        <td>{customer.country === ''?('-'):(customer.country)}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>{customer.email === ''?('-'):(customer.email)}</td>
                                    </tr>
                                    <tr>
                                        <th>Mobile</th>
                                        <td>{customer.mobile === ''?('-'):(customer.mobile)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
