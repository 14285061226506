import { EXHIBITION_ADD, EXHIBITION_FETCH,EXHIBITION_UPDATE, EXHIBITION_FETCH_ALL } from "../actions/types";

const initialState = {

  exhibitionFetchResponse: {
    data:{
      exhibitions:[]
    }
  },
  loader: false,

  addExhibitionResponse:{},
  exhibitionUpdateResponse:{},

  exhibitionAllFetchResponse: {
    data:{
      exhibitions:[]
    }
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    // add exhibition
    case `${EXHIBITION_ADD}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${EXHIBITION_ADD}_FULFILLED`:
      return {
        ...state,
        loader: false,
        addExhibitionResponse: action.payload
      };
    case `${EXHIBITION_ADD}_REJECTED`:
      return {
        ...state,
        loader: false,
        addExhibitionResponse: action.payload.response
      };

      // fetch exhibition
    case `${EXHIBITION_FETCH}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${EXHIBITION_FETCH}_FULFILLED`:
      return {
        ...state,
        loader: false,
        exhibitionFetchResponse: action.payload
      };
    case `${EXHIBITION_FETCH}_REJECTED`:
      return {
        ...state,
        loader: false
      };

    // update exhibition
    case `${EXHIBITION_UPDATE}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${EXHIBITION_UPDATE}_FULFILLED`:
      return {
        ...state,
        loader: false,
        exhibitionUpdateResponse: action.payload
      };
    case `${EXHIBITION_UPDATE}_REJECTED`:
      return {
        ...state,
        loader: false,
      };

      // fetch exhibition
    case `${EXHIBITION_FETCH_ALL}_PENDING`:
      return {
        ...state,
        loader: true
      };
    case `${EXHIBITION_FETCH_ALL}_FULFILLED`:
      return {
        ...state,
        loader: false,
        exhibitionAllFetchResponse: action.payload
      };
    case `${EXHIBITION_FETCH_ALL}_REJECTED`:
      return {
        ...state,
        loader: false
      };

    default:
      return {
        ...state
      };
  }
}
