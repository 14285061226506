import React, { Component } from "react";
import { connect } from 'react-redux';
import { exhibitionUpdate } from '../../../actions/exhibitionActions'
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import errorHandling from '../../../Common/errorHandling';
import Loader from '../../../inc/Loader'

class EditExhibition extends Component {
  constructor(props) {
    super(props);
    let { editExhibition } = this.props.history.location.state;
    this.state = {
      exhibitionId: editExhibition._id,
      name: editExhibition.name,
      season: editExhibition.season,
      formErrors: {
        name: '',
        season: ''
      }
    }
  }

  handleInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let field = value.trim() !== '';
    fieldValidationErrors[fieldName] = field ? '' : `${fieldName} can't be blank`;
    this.setState({
      formErrors: fieldValidationErrors
    });
  }

  errorClass(error) {
    return (error.length === 0 ? '' : 'invalid-field');
  }

  isValid = () => {
    let fieldValidationErrors = this.state.formErrors;
    let fields = ["name", "season"];
    let valid = true;
    fields.map((v, i) => {
      let field = this.state[`${v}`]
      if (field === '') {
        fieldValidationErrors[v] = `${v} can't be blank`
        this.setState({ formErrors: fieldValidationErrors });
        valid = false;
      }
      return null
    })
    return valid;
  }

  updateExhibitionNow = async () => {
    let valid = this.isValid();
    if (valid) {
      let { name, season, exhibitionId } = this.state;
      let exhibition = {
        name: name,
        season: season
      };
      await this.props.updateExhibition(exhibitionId, exhibition);
    }
  }

  componentWillReceiveProps = (newProps) => {

    if (this.props.response !== newProps.response && newProps.response !== undefined) {
      if (newProps.response.data.response === true) {
        this.updateResponse(newProps.response)
      } else {
        //Error Handling
        let status = newProps.response.status
        let msg = newProps.response.data.msg
        errorHandling(this.props, status, msg)
      }
    }

  }

  updateResponse = (res) => {
    if (res.data.response) {
      toast.success(res.data.msg);
      this.props.history.push('/exhibition');
    }
  }


  render() {
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-12 pr-0">
                <div className="d-lg-flex">
                  <h3 className="text-dark font-weight-bold mb-0 mt-2 mr-5 text-uppercase">
                    Edit Exhibition
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="dropdown float-right">
              <Link to="/exhibition/">
                <button
                  className="btn btn-sm bg-white font-weight-bold text-dark"
                  type="button"
                >
                  <i className="mdi mdi-eye mr-1"></i>All Exhibition
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Exhibition Name</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(this.state.formErrors.name)}`}
                          id="name"
                          value={this.state.name}
                          placeholder="Exhibition Name"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">{this.state.formErrors.name}</span>

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                      <label htmlFor="name">Season</label>
                        <input
                          type="text"
                          className={`form-control ${this.errorClass(this.state.formErrors.season)}`}
                          id="season"
                          value={this.state.season}
                          placeholder="Season"
                          required
                          onChange={event => this.handleInput(event)}
                        />
                        <span className="error-msg">{this.state.formErrors.season}</span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.updateExhibitionNow()}
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <button type="reset" className="btn btn-light" onClick={() => { this.props.history.push('/exhibition/') }}>
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={this.props.loading} />
      </div>
    );
  }


}

const mapDispatchToProps = (dispatch) => {
  return {
    updateExhibition: (id, data) => dispatch(exhibitionUpdate(id, data))
  }
}

const mapStateToProps = () => state => ({
  response: state.Exhibition.exhibitionUpdateResponse,
  loading: state.Exhibition.loader
})

export default connect(mapStateToProps, mapDispatchToProps)(EditExhibition);

