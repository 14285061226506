import React, { Component } from 'react'

//components
import Loader from '../../../inc/Loader';
import { SketchPicker } from 'react-color'

//consts
import { toast } from 'react-toastify';

//css
import 'react-toastify/dist/ReactToastify.css';

//redux
import { connect } from 'react-redux';
import { colorAdd, updateAddColorResponse, colorFetch } from '../../../actions/colorActions'
import { productFetchAddProductThings } from '../../../actions/productActions';

class ViewColorModal extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            colorCode: '',
            colorSwatch: ''
        }
    }
    validateField = () => {
        let { name, colorCode } = this.state;
        if (name === "") {
            this.showErrorToast('name')
        } else if (colorCode === "") {
            this.showErrorToast('colorCode');
        } else {
            this.saveColor();
        }
    }
    handleChangeComplete = (color) => {
        this.setState({ colorSwatch: color.hex });
    };

    //post data to store state for api hit
    saveColor = () => {
        let { name, colorCode, colorSwatch } = this.state;
        let color = {
            name,
            colorCode,
            colorSwatch
        };
        this.props.colorAdd(color);
    };

    //showing notification of required field and focusing on the text input left empty
    //making the requied field red
    showErrorToast = (name) => {
        toast.error('PLEASE ENTER ' + name.toUpperCase() + '!');
        this.refs[name].focus();
        this.refs[name].classList.add('red')
    }

    //setting the state when changing the value of the text input
    //in value is made empty than making it red
    handleInput = (e) => {
        let { value, name } = e.target;
        if (value === '') {
            this.refs[name].classList.add('red')
        }
        else {
            this.refs[name].classList.remove('red')
        }
        this.setState({
            [name]: value
        })
    }
    render() {
        return (
            <div className="modal fade" id="viewModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalLabel">Add Color</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="colorname">Colour Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="colorname"
                                            name="name"
                                            ref="name"
                                            value={this.state.name}
                                            placeholder="Colour Name"
                                            required
                                            onChange={event => this.handleInput(event)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="colorcode">Color Code</label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            id="colorCode"
                                            name="colorCode"
                                            ref="colorCode"
                                            value={this.state.colorCode}
                                            placeholder="Colour Code"
                                            required
                                            onChange={event => this.handleInput(event)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="pickColor">Pick Color Code</label>
                                    <SketchPicker width={"96%"} color={this.state.colorSwatch} onChangeComplete={this.handleChangeComplete} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref="modalClose" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" onClick={() => this.validateField()} className="btn btn-primary mr-2">Submit</button>
                        </div>
                    </div>
                </div>
                <Loader loading={this.props.loading} />

                {
                    this.checkUpdates()
                }
            </div>
        )
    }
    updateAndEmptyBox = () => {
        this.props.updateAddColorResponse({
            data: {}
        });
        this.setState({
            name: '',
            colorCode: ''
        })
    }

    checkUpdates = () => {
        let { data } = this.props.addColorResponse;
        if (data.response === false) {
            toast.error(data.msg);
            this.updateAndEmptyBox();
        } else if (data.response === true) {
            if (!toast.isActive("ADD_COLOR_SUCSS")) {
                toast.success(data.msg, {
                    toastId: "ADD_COLOR_SUCSS"
                });
            }
            this.props.colorFetch();
            this.props.productFetchAddProductThings();
            this.refs['modalClose'].click();
            this.updateAndEmptyBox();
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        colorAdd: (data) => dispatch(colorAdd(data)),
        colorFetch: () => dispatch(colorFetch()),
        updateAddColorResponse: (data) => dispatch(updateAddColorResponse(data)),
        productFetchAddProductThings: () => dispatch(productFetchAddProductThings())
    }
}

const mapStateToProps = () => state => ({
    addColorResponse: state.Color.addColorResponse,
    loading: state.Color.loader
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewColorModal);
