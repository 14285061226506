import React, { Component } from 'react'
import countries from '../../../Constants/constants';
import { toast } from 'react-toastify';

import { connect } from 'react-redux'
import { agentFetchAssignedProducts, agentCustomerOrder, agnetFetchCustomers } from '../../../actions/agentActions'
import Loader from '../../../inc/Loader';
import Auto from '../../Store/SalesReport/AutoSuggest';

const defaultImage = "https://via.placeholder.com/150";

class PlaceOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeOrder: false,
            customer: {},
            companyName: '',
            contactName: '',
            phone: '',
            mobile: '',
            address: '',
            email: '',
            country: '',
            products: [],
            comment: ''
        }
    }
    clearDetailsCustomer = () => {
        this.setState({
            companyName: '',
            contactName: '',
            phone: '',
            mobile: '',
            address: '',
            email: '',
            country: '',
            _id: ''
        })
    }
    emptyAllFields = () => {
        this.setState({
            companyName: '',
            contactName: '',
            phone: '',
            mobile: '',
            address: '',
            email: '',
            country: ''
        })
    }
    toastAndFocus = (msg, focusKey) => {
        toast.error(msg);
        this.refs[focusKey].focus()
    }
    validate = () => {
        let status = true;
        let { companyName, contactName, phone } = this.state;
        if (companyName === '') {
            this.toastAndFocus('Please enter Company Name!!', 'companyName');
            status = false;
        }
        else if (contactName === '') {
            this.toastAndFocus('Please enter Contact Name!!', 'contactName');
            status = false;
        }
        else if (phone === '') {
            this.toastAndFocus('Please enter Phone Number!!', 'phone');
            status = false;
        }
        return status;
    }
    handleChange = (e) => {
        let { value, name } = e.target;
        this.setState({
            [name]: value
        });
    }
    handleCheckBox = (e, color, product, indexColor, i) => {
        //check whether the checkbox is checked or not
        let { products } = this.state;
        if (e.target.checked) {
            //user has checked the color variation
            //iterate the array of products to check that if the color variations selected already exited in the products array or not
            let firstCheck = true;
            let indexProduct = 0;
            let ProdcutIndexFound;
            for (const iterator of products) {
                if (iterator.product === product._id) {
                    firstCheck = false;
                    ProdcutIndexFound = indexProduct;
                }
                indexProduct = indexProduct + 1;
            }
            //color varition selected doesn't exist in the products array ie. no product selected with the sepecific color varition
            if (firstCheck) {
                let selectedProduct = {
                    product: product._id,
                    colorVariations: [
                        {
                            color: color.color._id,
                            qty: this.refs['qtyColor' + indexColor + i].value,
                            colorName: color.name
                        }
                    ],
                    costPrice: product.data.price,
                    productName: product.data.product.mainCode
                }
                products.push(selectedProduct);
                this.setState({ products })
            }
            //product already existed push the color varition into the existing index
            else {
                //product alreayd existed
                //index found in variable ProdcutIndexFound
                let selectedColorVariation = {
                    color: color.color._id,
                    qty: this.refs['qtyColor' + indexColor + i].value,
                    colorName: color.name
                }
                products[ProdcutIndexFound].colorVariations.push(selectedColorVariation);
                this.setState({ products })
            }

        }
        else {
            //user has uncheck the color variation
            let indexProduct = 0;
            let ProductIndex = 0;
            // product found at index ProductIndex
            for (const iterator of products) {
                if (iterator.product === product._id) {
                    ProductIndex = indexProduct
                }
                indexProduct = indexProduct + 1;
            }
            //index of found color Variation
            let indexColor = 0;
            let ColorIndex = 0;
            for (const foundColor of products[ProductIndex].colorVariations) {
                if (foundColor.color === color.color._id) {
                    ColorIndex = indexColor
                }
                indexColor = indexColor + 1;
            }

            //removing color variation of the product 
            products[ProductIndex].colorVariations.splice(ColorIndex, 1);

            //removing product if no color variation existed
            if (products[ProductIndex].colorVariations.length === 0) {
                products.splice(ProductIndex, 1)
            }

            this.setState({ products })
        }
    }
    handleQty = (e, product, color) => {
        let { products } = this.state;
        let { value } = e.target
        let indexProduct = products.findIndex(i => i.product === product._id);
        if (indexProduct === -1) {
            //product index not found ie. product doesn't exist in the array
        }
        else {
            let indexColor = products[indexProduct].colorVariations.findIndex(i => i.color === color.color._id);
            if (indexColor === -1) {

            }
            else {
                products[indexProduct].colorVariations[indexColor].qty = value;
                this.setState({ products })
            }
        }
    }
    validateProduct = () => {
        let status = true;
        let { products } = this.state;
        if (products.length === 0) {
            status = false;
            toast.error("Please sect any product to continue placing order!")
        }
        else {
            let breaked = false
            for (const iterator of products) {
                for (const color of iterator.colorVariations) {
                    if (color.qty === '') {
                        status = false
                        toast.error("Please enter Quantity in product " + iterator.productName + " and color" + color.colorName)
                        breaked = true
                        break;
                    }
                }
                if (breaked) {
                    break;
                }
            }
        }
        return status
    }
    handleCustomer = (customer) => {
        if (Object.keys(customer).length !== 0 && customer.companyName !== 'NO Customer Details FOUND') {
            this.setState({ ...customer })
        }
    }
    saveData = () => {
        //check if all the fields are validated or not
        let isValidated = this.validate();
        if (isValidated) {
            let isValidatedProduct = this.validateProduct();
            //if all the fields are validated then save the data
            if (isValidated && isValidatedProduct) {
                let { companyName, contactName, phone, mobile, address, country, email, products, comment, _id } = this.state;
                let body = {
                    companyName,
                    contactName,
                    phone: phone,
                    mobile: mobile,
                    address,
                    country,
                    email,
                    agent: this.props.response.data.agentId,
                    products,
                    comment,
                    customer_id: _id
                }
                if (_id === '') {
                    delete body.customer_id
                }
                this.props.agentCustomerOrder(body);
            }

        }
    }
    render() {
        let { companyName, contactName, phone, mobile, address, country, email } = this.state;
        let { products } = this.props.response.data;
        return (
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">Place Order</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Customer</label>
                                            <Auto
                                                what="Customer Details"
                                                data={this.props.customers.data.customers}
                                                how={['companyName', 'contactName', 'email']}
                                                selectedValue={this.handleCustomer}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button
                                            type="button"
                                            onClick={() => this.clearDetailsCustomer()}
                                            className="btn btn-secondary mr-2"
                                        >
                                            Clear
                  </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="suppliername">Company Name</label>
                                                <input
                                                    ref="companyName"
                                                    name="companyName"
                                                    type="text"
                                                    className="form-control"
                                                    id="companyName"
                                                    placeholder="Company Name"
                                                    required
                                                    value={companyName}
                                                    onChange={(e) => { this.handleChange(e) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="suppliername">Contact Name</label>
                                                <input
                                                    ref="contactName"
                                                    name="contactName"
                                                    type="text"
                                                    className="form-control"
                                                    id="contactName"
                                                    placeholder="Contact Name"
                                                    required
                                                    value={contactName}
                                                    onChange={(e) => { this.handleChange(e) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="suppliername">Phone Number</label>
                                                <input
                                                    ref="phone"
                                                    name="phone"
                                                    type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    placeholder="Phone Number"
                                                    required
                                                    value={phone}
                                                    onChange={(e) => { this.handleChange(e) }}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="suppliername">Mobile Number</label>
                                                <input
                                                    ref="mobile"
                                                    name="mobile"
                                                    type="text"
                                                    className="form-control"
                                                    id="mobile"
                                                    placeholder="Mobile Number"
                                                    required
                                                    value={mobile}
                                                    onChange={(e) => { this.handleChange(e) }}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="suppliername">Email</label>
                                                <input
                                                    ref="email"
                                                    name="email"
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Email"
                                                    required
                                                    value={email}
                                                    onChange={(e) => { this.handleChange(e) }}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="suppliername">Address</label>
                                                <input
                                                    ref="address"
                                                    name="address"
                                                    type="text"
                                                    className="form-control"
                                                    id="address"
                                                    placeholder="Address"
                                                    required
                                                    value={address}
                                                    onChange={(e) => { this.handleChange(e) }}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="country">Country</label>
                                                <select
                                                    className="form-control"
                                                    id="country"
                                                    ref="country"
                                                    name="country"
                                                    value={country}
                                                    style={{ height: "47px", marginTop: "2px" }}
                                                    onChange={(e) => { this.handleChange(e) }}

                                                >
                                                    <option disabled selected value="">
                                                        Select Country
                                                    </option>
                                                    {countries.map((country, i) => {
                                                        return (
                                                            <option key={country.code} value={country.name}>
                                                                {country.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="country">Comment</label>
                                                <input
                                                    className="form-control"
                                                    name="comment"
                                                    ref="comment"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">Products</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-3">
                    {
                        products.length === 0 ? (
                            <div className="col-12">
                                <h4>No Products to place order! </h4>
                                <span>Please contact admin to assign the products.</span>
                            </div>
                        ) : (
                                null
                            )
                    }
                    {
                        products.map((v, i) => {
                            return (
                                <div className="col-12 grid-margin stretch-card" key={i}>
                                    <div className="card shadow">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="px-2 pb-3 d-flex justify-content-center">
                                                        <img
                                                            src={v.data.product.imageUrl}
                                                            alt="product"
                                                            className="img-fluid"
                                                            onError={e => {
                                                                e.target.onerror = null;
                                                                e.target.src = defaultImage;
                                                            }}
                                                        />
                                                    </div>
                                                    <h4 className="text-center">
                                                        {v.data.product.mainCode}
                                                    </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-striped min-w-700">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Check</th>
                                                                    <th>Variation Code</th>
                                                                    <th>Color</th>
                                                                    <th>Barcode</th>
                                                                    <th>Quantity to Order</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    v.data.product.colorVariations.map((color, indexColor) => {
                                                                        if (color.assigned) {
                                                                            return (
                                                                                <tr key={indexColor}>
                                                                                    <td>{indexColor + 1}</td>
                                                                                    <td>
                                                                                        <input type="checkbox" className="checkBoxElements" onChange={(e) => { this.handleCheckBox(e, color, v, indexColor, i) }} />
                                                                                    </td>
                                                                                    <td>{color.name}</td>
                                                                                    <td>{color.color.name}</td>
                                                                                    <td>{color.barcode.barcode}</td>
                                                                                    <td>
                                                                                        <input
                                                                                            style={{ maxWidth: '150px' }}
                                                                                            type="number"
                                                                                            ref={`qtyColor${indexColor}${i}`}
                                                                                            className="form-control p-2 inputElements"
                                                                                            onChange={(e) => { this.handleQty(e, v, color) }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return null
                                                                        }
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    this.props.response.data.products.length !== 0 ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="dropdown float-right">
                                    <button className="btn btn-sm btn-primary" type="button" onClick={() => { this.saveData() }}>
                                        <i className="mdi mdi-clipboard-check mr-1"></i>
                                        <span>Place Order</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                            null
                        )
                }
                <Loader loading={this.props.loader} />
            </div>
        )
    }
    componentDidMount() {
        this.props.agentFetchAssignedProducts();
        this.props.agentCustomerFetch();
    }
    componentWillReceiveProps(newProps) {
        if (newProps.agentCustomerOrderResponse !== this.props.agentCustomerOrderResponse) {
            let { data } = newProps.agentCustomerOrderResponse;
            if (data.response) {
                toast.success(data.msg)
                this.setState({
                    placeOrder: false,
                    customer: {},
                    companyName: '',
                    contactName: '',
                    phone: '',
                    mobile: '',
                    address: '',
                    email: '',
                    country: '',
                    products: []
                }, () => {
                    this.emptyValues()
                    this.props.agentFetchAssignedProducts();
                    this.props.agentCustomerFetch();
                })
            }
            else {
                toast.error(data.msg)
            }
        }
    }
    emptyValues = () => {
        let elements = document.querySelectorAll('.inputElements');
        let checkBoxes = document.querySelectorAll('.checkBoxElements');
        for (const ele of elements) {
            ele.value = '';
        }
        for (const abc of checkBoxes) {
            abc.checked = false;
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    agentFetchAssignedProducts: _ => dispatch(agentFetchAssignedProducts(null)),
    agentCustomerOrder: (body) => dispatch(agentCustomerOrder(body)),
    agentCustomerFetch: () => dispatch(agnetFetchCustomers())
})

const mapStateToProps = () => state => ({
    response: state.Agent.agentFetchAssignedProducts,
    loader: state.Agent.loader,
    agentCustomerOrderResponse: state.Agent.agentCustomerOrderResponse,
    customers: state.Agent.agentAllCustomers
})

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrder)