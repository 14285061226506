import React, { Component } from "react";
import { toast } from "react-toastify";

export default class CurrentListColorVariations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.arrayOfColorVariations
        }
    }
    loadPreviousList = () => {
        if(this.props.savedList.length!==0){
            this.setState({ list: [...(this.props.savedList || [])] });
            this.props.changeParentList()
        }
        else{
            toast.error("No saved List to load, Please save the list to load!!")
        }
    }
    render() {
        return (
            <div className="row my-3">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row pb-3">
                                <div className="col-12">
                                    <div className="input-group mb-3 justify-content-between">
                                        <h3>List of Color Variations:</h3>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <div>
                                        <button
                                            type="button"
                                            className="btn-sm btn btn-success btn-icon-text ml-2"
                                            onClick={() => {
                                                if (this.state.list.length === 0) {
                                                    toast.error("Please select at least single color variation to save!");
                                                }
                                                else {
                                                    this.props.saveList(this.state.list);
                                                    toast.success("List saved locally!!")
                                                }
                                            }}
                                        >
                                            <i className="mdi mdi-content-save btn-icon-prepend"></i>
                                            <span>Save</span>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-sm btn btn-warning btn-icon-text ml-2"
                                            onClick={() => {
                                                this.loadPreviousList()
                                            }}
                                        >
                                            <i className="mdi mdi-download btn-icon-prepend"></i>
                                            <span>Load</span>
                                        </button>
                                    </div>
                                    {
                                        this.state.list.length === 0 ? (null) : (
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-sm btn btn-primary btn-icon-text ml-2"
                                                    onClick={() => {
                                                        this.props.validate('65');
                                                    }}
                                                >
                                                    <i className="mdi mdi-check btn-icon-prepend"></i>
                                                    <span>65Labels</span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn-sm btn btn-primary btn-icon-text ml-2"
                                                    onClick={() => {
                                                        this.props.validate('pdf');
                                                    }}
                                                >
                                                    <i className="mdi mdi-printer btn-icon-prepend"></i>
                                                    <span>Print PDF</span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn-sm btn btn-primary btn-icon-text ml-2"
                                                    onClick={() => {
                                                        this.props.validate('xls');
                                                    }}
                                                >
                                                    <i className="mdi mdi-chart-pie btn-icon-prepend"></i>
                                                    <span>Export To Excel</span>
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                this.state.list.length !== 0 ? (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table table-hover min-w-700 table_heading_view">
                                                    <thead>
                                                        <tr className="heading_mine">
                                                            <th>#</th>
                                                            <th>Main Code</th>
                                                            <th>SKU</th>
                                                            <th>Description</th>
                                                            <th>Barcode</th>
                                                            <th>Color</th>
                                                            <th>QTY</th>
                                                            <th>Options</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.list.map((v, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{v.mainCode}</td>
                                                                    <td>{v.name}</td>
                                                                    <td>{v.description === null || v.description === '' ? (' - ') : (v.description)}</td>
                                                                    <td>{v.barcode}</td>
                                                                    <td>{v.colorName}</td>
                                                                    <td>
                                                                        <input type="text" value={v.qty} onChange={(e) => { this.props.changeQty(i, e.target.value) }} />
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() => { this.props.deleteCurrentColorVariation(i) }}
                                                                        >
                                                                            <i className="mdi mdi-minus btn-icon-prepend"> </i></button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ) : (null)
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    componentWillReceiveProps(newProps) {
        if (newProps.arrayOfColorVariations !== this.props.arrayOfColorVariations) {
            this.setState({ list: newProps.arrayOfColorVariations })
        }
    }
}
