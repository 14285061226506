import React, { Component } from "react";
import { connect } from "react-redux";
import {
    productSearch,
    productSearchReset,
    printBarcodeSaveList,
    print65Labels,
    printXlsLabels
} from "../../../actions/productActions";
import _ from "lodash";
import { toast } from "react-toastify";
import Loader from "../../../inc/Loader";
import ViewColorVariations from "./ViewColorVariations";
import CurrentListColorVariations from "./CurrentListColorVariations";
import { DOCUMENTS_URL } from "../../../Config";

const defaultImage = "https://via.placeholder.com/150";

class PrintBarcode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            arrayOfColorVariations: [],
            loader: false,
            selectedProdutToView: {
                imageUrl: '',
                mainCode: '',
                brand: '',
                category: '',
                unitPrice: '',
                code: '',
                supplier: {
                    fullName: '',
                    code: '',
                    category: '',
                    mainCode: '',
                    type: '',
                    unitPrice: '',
                    costPrice: '',
                },
                type: '',
                itemType: '',
                exhibition: {
                    name: '',
                    season: ''
                },
                colorVariations: [
                    {
                        color: {
                            name: '',
                            colorSwatch: '',
                            colorCode: '',
                        },
                        barcode: {
                            barcode: ''
                        },
                        packSize: '',
                        stock: '',
                        description: '',
                    }
                ],
            }
        };
    }

    //searching the typed product
    search = _.debounce(e => {
        let { searchText } = this.state;
        let body = {
            searchText
        };
        if (searchText !== "") {
            this.props.searchProducts(body);
        }
    }, process.env.REACT_APP_DEBOUNCE);

    //loader toggler
    toggleLoader = () => {
        this.setState({
            loader: !this.state.loader
        });
    };

    validate = (what) => {
        let validated = true;
        let { arrayOfColorVariations } = this.state;
        let index = 0;
        for (const colorVariation of arrayOfColorVariations) {
            if (colorVariation.qty === '') {
                validated = false;
                toast.error("Please enter qty of SKU - " + colorVariation.name + " Line Number: " + (+index + 1));
                break;
            }
            else {
                colorVariation.qty = +colorVariation.qty
            }
            index += 1;
        }
        this.setState({ arrayOfColorVariations }, () => {
            if (validated) {
                if (what === '65') {
                    this.print65LabelsNow();
                }
                else if (what === 'xls') {
                    this.printXlsNow();
                }
            }
        });
    }
    printXlsNow = () => {
        let { arrayOfColorVariations } = this.state;
        let newArrayOfColorVariations = [];
        let header = ["Main Code", "SKU", "Product Description", "Quantity", "Barcode", "Colour"];
        for (const print of arrayOfColorVariations) {
            let obj = {
                "Main Code": print.mainCode,
                "SKU": print.name,
                "Product Description": print.description,
                "Quantity": print.qty,
                "Barcode": print.barcode,
                "Colour": print.colorName
            };
            newArrayOfColorVariations.push(obj);
        }
        this.props.printXlsLabels({
            data: newArrayOfColorVariations,
            headerInfo: header
        });
    }
    changeParentList = () => {
        this.setState({
            arrayOfColorVariations: this.props.list
        });
    }
    print65LabelsNow = () => {
        let { arrayOfColorVariations } = this.state;
        this.props.print65Labels({
            data: arrayOfColorVariations
        });
    }
    render() {
        return (
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-12 pr-0">
                                        <div className="d-lg-flex">
                                            <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                                Select Color Variations
                      </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <div className="row pb-3">
                                            <div className="col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product Name"
                                                        aria-label="Product Name"
                                                        aria-describedby="button-addon2"
                                                        value={this.state.searchText}
                                                        onChange={e => {
                                                            this.setState(
                                                                {
                                                                    searchText: e.target.value,
                                                                    selectedProduct: {},
                                                                },
                                                                () => {
                                                                    this.search();
                                                                }
                                                            );
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-primary"
                                                            type="button"
                                                            id="button-addon2"
                                                        >
                                                            <span>Search Products</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.response.data.products.length === 0 ? null : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover min-w-700 table_heading_view">
                                                            <thead>
                                                                <tr className="heading_mine">
                                                                    <th>#</th>
                                                                    <th>Product Image</th>
                                                                    <th>Main Code</th>
                                                                    <th>Unit Price</th>
                                                                    <th>Options</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.response.data.products.map(
                                                                    (v, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{i + 1}</td>
                                                                                <td>
                                                                                    <img
                                                                                        src={v.imageUrl}
                                                                                        alt="product"
                                                                                        style={{
                                                                                            height: "130px",
                                                                                            borderRadius: "25%",
                                                                                            width: "auto",
                                                                                            display: "block",
                                                                                            margin: "0 auto"
                                                                                        }}
                                                                                        onError={e => {
                                                                                            e.target.onerror = null;
                                                                                            e.target.src = defaultImage;
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>{v.mainCode}</td>
                                                                                <td>£ {v.unitPrice}</td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-sm btn ml-2 btn-dark btn-icon-text"
                                                                                        data-toggle="modal"
                                                                                        data-target="#viewColorVariations"
                                                                                        onClick={() => {
                                                                                            this.setState({ selectedProdutToView: v })
                                                                                        }}
                                                                                    >
                                                                                        <i className="mdi mdi-circle-edit-outline btn-icon-prepend"></i>
                                                                                        <span>Select Color Variations</span>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        {!this.props.productsFound ? (
                                                            <div className="noProductsFound">
                                                                <span>No Products Found</span>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <CurrentListColorVariations
                                            arrayOfColorVariations={this.state.arrayOfColorVariations}
                                            changeQty={this.changeQty}
                                            deleteCurrentColorVariation={this.deleteCurrentColorVariation}
                                            saveList={this.props.saveList}
                                            savedList={this.props.list}
                                            validate={this.validate}
                                            changeParentList={this.changeParentList}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ViewColorVariations
                    selectedProduct={this.state.selectedProdutToView}
                    selectColorVariation={this.selectColorVariation}
                    checkColorVariationExistence={this.checkColorVariationExistence}
                    deleteColorVariation={this.deleteColorVariation}
                />
                {/* lodaer */}
                <Loader
                    loading={
                        this.props.loading ||
                        this.props.assigningProductsLoader ||
                        this.state.loader
                    }
                />
                {/* toaster */}
            </div>
        );
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.response65 !== newProps.response65 && newProps.response65 !== undefined) {
            let { data } = newProps.response65;
            if (data.response) {
                toast.success(data.msg);
                window.open(DOCUMENTS_URL + data.filename, '_blank');
            }
            else {
                toast.error(data.msg)
            }
        }
        if (this.props.responseXls !== newProps.responseXls && newProps.responseXls !== undefined) {
            let { data } = newProps.responseXls;
            if (data.response) {
                toast.success(data.msg);
                window.open(DOCUMENTS_URL + data.filename, '_blank');
            }
            else {
                toast.error(data.msg)
            }
        }
    }
    deleteCurrentColorVariation = (index) => {
        let { arrayOfColorVariations } = this.state;
        let copyArrayOfColorVariations = arrayOfColorVariations.slice();
        copyArrayOfColorVariations.splice(index, 1);
        this.setState({ arrayOfColorVariations: copyArrayOfColorVariations })
    }
    changeQty = (index, value) => {
        let { arrayOfColorVariations } = this.state;
        let copyArrayOfColorVariations = arrayOfColorVariations.slice();
        copyArrayOfColorVariations[index].qty = value;
        this.setState({
            arrayOfColorVariations: copyArrayOfColorVariations
        })
    }
    checkColorVariationExistence = (mainCode, color) => {
        let { arrayOfColorVariations } = this.state;
        let index = arrayOfColorVariations.findIndex(colorVariation => (colorVariation.mainCode === mainCode && color.name === colorVariation.name));
        if (index === -1) {
            return false
        }
        else {
            return true
        }
    }
    deleteColorVariation = (mainCode, color) => {
        let { arrayOfColorVariations } = this.state;
        let index = arrayOfColorVariations.findIndex(colorVariation => (colorVariation.mainCode === mainCode && color.name === colorVariation.name));
        arrayOfColorVariations.splice(index, 1);
        this.setState({ arrayOfColorVariations });
    }
    selectColorVariation = (mainCode, description, color) => {
        let { arrayOfColorVariations } = this.state;
        let index = arrayOfColorVariations.findIndex(colorVariation => (colorVariation.mainCode === mainCode && color.name === colorVariation.name));
        if (index === -1) {
            arrayOfColorVariations.push({ mainCode, description, name: color.name, barcode: color.barcode.barcode, colorName: color.color.name, qty: '' })
        }
        this.setState({ arrayOfColorVariations })
    }
}
const mapDispatchToProps = dispatch => ({
    searchProducts: body => dispatch(productSearch(body)),
    searchProductsReset: () => dispatch(productSearchReset()),
    saveList: body => dispatch(printBarcodeSaveList(body)),
    print65Labels: body => dispatch(print65Labels(body)),
    printXlsLabels: body => dispatch(printXlsLabels(body))
});
const mapStateToProps = () => state => ({
    response: state.Product.productFetchResponse,
    loading: state.Product.loader,
    productsFound: state.Product.productsFound,
    list: state.Product.productPrintBarcodeLists,
    response65: state.Product.productSticker65Labels,
    responseXls: state.Product.productStickerXlsResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintBarcode);
