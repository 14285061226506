import React, { Component, Fragment } from 'react'
import FiltersStockReport from './FiltersStockReport'
import { toast } from 'react-toastify'

//redux imports
import { connect } from 'react-redux'

const defaultImage = "https://via.placeholder.com/150";
let heading = ''

class StockReport extends Component {
    constructor() {
        super();
        this.state = {
            supplier: {
                branches: [],
                companyName: ''
            },
            type: '',
            heading: '',
        }
    }

    //setting filters to state of this component got from FiltersStockReport
    setParentState = (what, to) => {
        this.setState({
            [to]: what
        });
    };

    //sending report table to FiltersStockReport
    sendReportTable = () => {
        let table = document.getElementById('generateReport').innerHTML;
        return table;
    }

    setHeading = (val) => {
        heading = val
    }

    decideHeading = () => {
        return this.state.type === 'product' ? (
            `Stock Report(Product Wise) For ${this.state.supplier.companyName}`
        ) : (
                `Stock Report(Category Wise) For ${this.state.supplier.companyName}`
            )
    }

    render() {
        let { data } = this.props.stockReport;
        return (
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-12 pr-0">
                                <div className="d-lg-flex">
                                    <h3 className="text-dark font-weight-bold mb-0 mr-5 text-uppercase">
                                        Stock Report Category wise
                                </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">

                                {/* filters for the report for the documentation of props go to the prop definition */}
                                <FiltersStockReport setParentState={this.setParentState} getReportTable={this.sendReportTable} heading={this.decideHeading()} />
                                {
                                    data.categoryWiseProductReport ? (
                                        data.categoryWiseProductReport.length > 0 || data.detailRows.length > 0 ? (
                                            this.state.type === 'category' ? (this.renderCategoryReport()) : (this.renderProductReport())
                                        ) : (null)
                                    ) : (
                                            data.detailRows.length > 0 ? (
                                                this.state.type === 'category' ? (this.renderCategoryReport()) : (this.renderProductReport())
                                            ) : (null)
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderProductReport = () => {
        let { topRows, branches, detailRows, imageUrls } = this.props.stockReport.data;
        // this.setHeading(`Stock Report(Product Wise) For ${this.state.supplier.companyName}`);
        return <div className="row">
            <div className="row">
                <div className="col-12" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <h4 id="pdfHeading">{this.decideHeading()}</h4>
                </div>
            </div>
            <div className="col-12 mt-2" id="generateReport">
                <div className="table-responsive">
                    <table className="table table-hover min-w-700 table_heading_view" style={{ width: '100%' }} cellPadding={5}>
                        <thead>
                            <tr className="heading_mine">
                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>#</th>
                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>Product</th>
                                {
                                    branches.map((branch, bi) => {
                                        return (
                                            <Fragment>
                                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>{branch} Stock</th>
                                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>{branch} Sold</th>
                                            </Fragment>
                                        )
                                    })
                                }
                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>Available Stock</th>
                                <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>Total Sold</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                topRows.map((topRow, ti) => {
                                    return (
                                        <Fragment key={ti}>
                                            <tr style={{ background: '#eee' }}>
                                                <td className="b_font manageFont" style={{ fontSize: '10px', textAlign: 'center' }}>{ti + 1}</td>
                                                {
                                                    topRow.map((data, di) => {
                                                        return (
                                                            <td key={di} className="b_font manageFont" style={{ fontSize: '10px', textAlign: 'center' }}>
                                                                {
                                                                    di === 0 ? (
                                                                        <Fragment>
                                                                            <img
                                                                                src={imageUrls[topRow[0]]}
                                                                                alt="profile"
                                                                                className="profile-icon mr-2"
                                                                                style={{
                                                                                    width: '100px',
                                                                                    height: 'auto',
                                                                                    borderRadius: '5%'
                                                                                }}
                                                                                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                                                                            />
                                                                            <br />
                                                                            <br />
                                                                        </Fragment>
                                                                    ) : (null)
                                                                }
                                                                {data}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            {
                                                detailRows.map((detRow, deti) => {
                                                    return (
                                                        Object.keys(detRow).map((detRowKey, ki) => {
                                                            if (detRowKey === topRow[0]) {
                                                                return (
                                                                    Object.keys(detRow[detRowKey]).map((data, di) => {
                                                                        return (
                                                                            <tr key={di}>
                                                                                <td className="manageFont" style={{ textAlign: 'center', fontSize: '10px', }}></td>
                                                                                <td className="manageFont" style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '10px', }}>{data}</td>
                                                                                {
                                                                                    detRow[detRowKey][data].map((d, dit) => {
                                                                                        return (
                                                                                            <td key={dit} className="manageFont" style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '10px', }}>{d}</td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        })
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    renderCategoryReport = () => {
        let { data } = this.props.stockReport;
        // this.setHeading(`Stock Report(Category Wise) For ${this.state.supplier.companyName}`);
        return <div className="row">
            <div className="row">
                <div className="col-12" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <h4 id="pdfHeading">{this.decideHeading()}</h4>
                </div>
            </div>
            <div className="col-12 mt-2" id="generateReport">
                <div className="table-responsive">
                    {
                        data.categoryWiseProductReport.map((cat, ci) => {
                            return (
                                <Fragment key={ci} >
                                    <table className="table table-hover min-w-700 table_heading_view" style={{ width: '100%' }} cellPadding={5}>
                                        {
                                            ci === 0 ? (
                                                <thead>
                                                    <tr className="heading_mine">
                                                        <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>#</th>
                                                        <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>SKU</th>
                                                        {
                                                            data.branches.map((branch, index) => {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>{branch} Stock</th>
                                                                        <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>{branch} Sold</th>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                        <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>Stock</th>
                                                        <th style={{ fontWeight: '800', background: '#001737', color: '#e5e5e5', border: '#fff 1px solid', textTransform: 'uppercase', textAlign: 'center', borderRadius: '5px', fontSize: '10px' }}>Sold</th>
                                                    </tr>
                                                </thead>
                                            ) : (
                                                    null
                                                )
                                        }
                                        <tbody>
                                            <tr>
                                                <td className="b_font" style={{ fontSize: '10px', textAlign: 'center', width: '5%' }}>{ci + 1}</td>
                                                <td className="b_font" style={{ fontSize: '10px', textAlign: 'center', width: '10%' }}>{cat.category}</td>
                                                {
                                                    cat.branches.map((s, i) => {
                                                        let value = cat.data.findIndex(i => i.branch === s);
                                                        return (
                                                            <Fragment>
                                                                <td className="b_font" style={{ fontSize: '10px', textAlign: 'center', width: '10%' }}>{value !== -1 ? (cat.data[value].totalStock) : ('-')}</td>
                                                                <td className="b_font" style={{ fontSize: '10px', textAlign: 'center', width: '10%' }}>{value !== -1 ? (cat.data[value].totalSold) : ('-')}</td>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                                <td className="b_font" style={{ fontSize: '10px', textAlign: 'center', width: '10%' }}>{cat.grandTotalStock}</td>
                                                <td className="b_font" style={{ fontSize: '10px', textAlign: 'center', width: '10%' }}>{cat.grandTotalSold}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Fragment>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    }
    componentWillReceiveProps(newProps) {
        if (newProps.stockReport !== this.props.stockReport && newProps.stockReport !== undefined) {
            let { data } = newProps.stockReport;
            if (data.response) {
                toast.success(data.msg);
            }
            else {
                toast.error(data.msg);
            }
        }
    }
}

const mapStateToProps = () => state => ({
    stockReport: state.Store.storeStockReport
});


export default connect(mapStateToProps, null)(StockReport);


//digital signature
//this.vikasChhabra(😂);